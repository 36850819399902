import React, { useState, useEffect }from 'react';
import useFetchApp from './services/useFetchApp';

import { BehaviorSubject, filter } from 'rxjs';

const ProjectContext = React.createContext()

export default ProjectContext;

export const ProjectContextProvider = ({  currentUser, children }) => {
    // API data pull
    // 1. Organisation projects
    const { data: rawProjectsData, fetchDataApp: fetchProjects } = useFetchApp(`${process.env.REACT_APP_API_URL}/Projects/Organisation/`, currentUser, "Organisation projects")
    
    // React hooks
    // 1. Selected project
    const [ selectedproject, setSelect ] = useState("");
    // 2. Project list
    const [ projectList, setProjectList ] = useState([]);
    // 3. Filtered project list
    const [ filteredProjects, setFilteredProjects ] = useState([]);
    // 4. Selected project status
    const [ isSelected, setIsSelected ] = useState(false)

    // Extract Project data from session storage
    const projectSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('Project')));

    // Get Project from session storage during the first page load
    useEffect(()=>{
        
        if (sessionStorage.getItem('Project')) {

            setIsSelected(true)
            setSelect(projectSubject.value)

        }

    },[])

    useEffect(()=>{
        // When project data from API is loaded,
        if (rawProjectsData) {
            // Re-map raw projects data into ID and project name
            const mappedProjectList = rawProjectsData.map((rows) => {
                return {
                    "Id" : rows.Id, 
                    "ProjectName": rows.ProjectName
                }
            })

            setProjectList(mappedProjectList)

            // Reduce and filter project by authorisation level (Site admin, Organisation admin, or regular user)
            const filteredProjectsByCurrentUser = rawProjectsData.reduce((projectData, project) => {
                if (currentUser.isOrganisationAdministrator || currentUser.isSiteAdministrator) {
                    return [...projectData, project]
                } else {
                    const findMemberRoles = project.ProjectMembers.filter(role => {
                        return role.UserId === currentUser.id
                    })
                    
                    if (findMemberRoles.length > 0) {
                        return [...projectData, project]
                    } else {
                        return projectData
                    }
                }
            }, [])            

            setFilteredProjects(filteredProjectsByCurrentUser)
        }

    },[rawProjectsData])

    return (
        <ProjectContext.Provider value = {{
            filteredProjects,
            projectList,
            selectedproject,
            isSelected,
            setIsSelected,
            setSelect,
            fetchProjects
        }}>{children}</ProjectContext.Provider>
    )
}