import React, {useContext } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid, Select, MenuItem, Menu, Button } from '@mui/material';

//Contexts
import ReviewContext from '../../../../contexts/ReviewContext';


export default function DataTable() {
  const { originalTranscript, review, setCurrentAudioTimeMark } = useContext(ReviewContext);

  const theme = createTheme({
    components: {
      // Name of the component
      MuiDataGrid: {
        styleOverrides: {
          // Name of the slot
          root: {
            '& p': {
                marginBottom: '0.2rem !important',
                marginTop: '0.2rem !important'
            }
          },
        },
      },
    },
  });

  const FilterOnlyToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton/>
      </GridToolbarContainer>
    )
  }

  const columns = [
    {
      field: 'time',
      headerName: 'Time',
      minWidth: 100,
      // align: 'center',
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const splitTime = params.value.split(':')
        const seconds = splitTime.reduce((acc,time) => (60 * acc) + +time);

        return (
          <Grid container direction="row" onClick={()  => setCurrentAudioTimeMark(seconds)} style={{cursor: "pointer"}} title={"Jump to audio timestamp"}> 
            {params.value} 
          </Grid>
        )
      }
    },
    {
      field: 'speaker',
      headerName: 'Speaker',
      minWidth: 150,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        review[`Speaker ${params.value}`] ? (review[`Speaker ${params.value}`].Value !== " "? review[`Speaker ${params.value}`].Value : `Speaker ${params.value}`): `Speaker ${params.value}`
        )
    },
    {
      field: 'transcript',
      headerName: 'Transcript',
      minWidth: 560,
      flex: 10,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      overflowWrap: 'break-word',
      renderCell: (params) => (
        <p style={{display: 'inline-block', verticalAlign: "middle"}}> {params.value} </p>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      {originalTranscript && originalTranscript.length !== 0 &&
      <>
      <div style={{ height: 700, width: '100%' }}>
        <div style={{ height: 700, width: '100%' }}>
          <DataGrid
            getRowHeight={() => 'auto'} 
            rows={originalTranscript}
            columns={columns}
            pageSize={100}
            disableSelectionOnClick
            hideFooter={true}
            components={{ Toolbar: FilterOnlyToolbar }}
          />
        </div>
      </div>
      
      {/* <br></br> */}
      {/* <button type="button" className="btn btn-primary" >Export</button> */}
      {/* <br></br> */}
      </>
      }
      {originalTranscript && originalTranscript.length === 0 &&
        <p> No Transcripts Available </p>
      }
    </ThemeProvider>
  );
}
