// Dashboard for file manager
// React and MUI component
import React, { useState,  useContext, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider, createTheme } from "@mui/material/styles";

import FileContext from '../../../contexts/FileContext';

export default function FileManagerDashboard() {
    // Batches and files context
    const { batches, files } = useContext(FileContext)

    // React hooks
    // 1. Batch data table list
    const [ tableBatchData, setTableBatchData ] = useState([
        {
            batchStatus: "New",
            uploadStatusId: 1,
            batchCount: 0,
        },
        {
            batchStatus: "Detected",
            uploadStatusId: 2,
            batchCount: 0,
        },
        {
            batchStatus: "Transcribed",
            uploadStatusId: 3,
            batchCount: 0,
        },
        {
            batchStatus: "Progressed",
            uploadStatusId: 4,
            batchCount: 0,
        },
        {
            batchStatus: "Deleted",
            uploadStatusId: 5,
            batchCount: 0,
        }
    ])

    // 2. File data table list
    const [ tableFileData, setTableFileData ] = useState([
        {
            fileStatus: "New",
            uploadStatusId: 1,
            filesCount: 0,
        },
        {
            fileStatus: "Detected",
            uploadStatusId: 2,
            filesCount: 0,
        },
        {
            fileStatus: "Transcribed",
            uploadStatusId: 3,
            filesCount: 0,
        },
        {
            fileStatus: "Progressed",
            uploadStatusId: 4,
            filesCount: 0,
        },
        {
            fileStatus: "Deleted",
            uploadStatusId: 5,
            filesCount: 0,
        }
    ])

    const tableTheme = createTheme({
        components: {
          // Name of the component
          MuiTableCell: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                padding: '5pt'
              },
            },
          },
        },
      });

    // Batch table header
    const tableBatchHeaders = [
        {
            name: "batchStatus",
            label: "Batch Status",
            options : {
              filter: true,
              sort: false,
            }
        },
        {
            name: "batchCount",
            label: "Batch Count",
            options : {
              filter: true,
              sort: false,
            }
        }
    ]

    // File table header
    const tableFileHeaders = [
        {
            name: "fileStatus",
            label: "File Status",
            options : {
              filter: true,
              sort: false,
            }
        },
        {
            name: "filesCount",
            label: "Files Count",
            options : {
              filter: true,
              sort: false,
            }
        },
    ]

    // Table configuration settings
    const tableOptions = {
        selectableRows: "none",
        print: false,
        search: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPageOptions: [],
        pagination: false,
    }

    useEffect(() => {
        // When batches context is fully loaded, set table batch data
        if (batches) {
            for (let i = 0 ; i < batches.length; i++) {
                const updatedTableBatchData = [...tableBatchData]

                let tableDataIndex = updatedTableBatchData.findIndex(x => x.uploadStatusId === batches[i].UploadStatusId)
                updatedTableBatchData[tableDataIndex].batchCount += 1

                setTableBatchData(updatedTableBatchData)
            }
        }
    }, [batches])

    useEffect(() => {
        // When file context is fully loaded, set table file data
        if (files) {
            for (let i = 0 ; i < files.length; i++) {
                const updatedTableFileData = [...tableFileData]

                let tableFileDataIndex = updatedTableFileData.findIndex(x => x.uploadStatusId === files[i].UploadStatusId)
                updatedTableFileData[tableFileDataIndex].filesCount += 1

                setTableFileData(updatedTableFileData)
            }
        }
    }, [files])

    return (
        <ThemeProvider theme={tableTheme}>
            <Container>
                <Row>
                    <Col md={12}>
                        <h5>
                            <strong>
                            File Manager Dashboard
                            </strong>
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        { batches && 
                            <MUIDataTable 
                                title={"Batch Summary"}
                                data={tableBatchData}
                                columns={tableBatchHeaders}
                                options={tableOptions}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    { files && 
                            <MUIDataTable 
                                title={"File Summary"}
                                data={tableFileData}
                                columns={tableFileHeaders}
                                options={tableOptions}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        </ThemeProvider>
    )
}