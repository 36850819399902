import React, {useContext, useRef, useState, useEffect} from 'react';
import ReviewContext from '../../../../contexts/ReviewContext';
import { Paper, Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { Forward10, Replay10 } from '@mui/icons-material';

const useStyles = makeStyles({
  grid: {
    height: "100%"
  }
})

export default function UploadBatchAudio() {
  // Review context
  // 1. Selected upload batch file index
  // 2. Upload batch files queue
  // 3. Current audio time mark
  // 4. Set current audio time mark (React hooks)
  const { 
    selectedUploadBatchFileIndex, 
    uploadBatchFilesQueue, 
    currentAudioTimeMark, 
    setCurrentAudioTimeMark 
  } = useContext(ReviewContext)

  const audioRef = useRef()
  const classes = useStyles()

  useEffect(() => {
    // Change playing audio timestamp into selected timestamp from the transcription row
    if (currentAudioTimeMark) {
      const audio = audioRef.current

      audio.currentTime = currentAudioTimeMark
      audio.play()
      setCurrentAudioTimeMark(0)
    }
  }, [currentAudioTimeMark])
  
  // Change playing audio timestamp by add or substract
  const shiftPlayTime = (timeLength) => {
    const audio = audioRef.current
    
    audio.currentTime = audio.currentTime + timeLength
    audio.play()
  }

  return (
      <div>
        {
          selectedUploadBatchFileIndex > -1 && uploadBatchFilesQueue && uploadBatchFilesQueue.length > 0 &&
          <Paper sx={{padding: 2, marginBottom: "10px", textAlign: 'center'}} elevation={4} style={{textAlign: "center"}}>
            <Grid container>
              <Grid item container xs={1} justifyContent="flex-start" alignItems="center" className="classes.grid">
                <button type="button" className="btn btn-primary" onClick={() => shiftPlayTime(-10)}>
                  <Replay10 fontSize="large"/>
                </button>
              </Grid>
              <Grid item container xs={10} justifyContent="flex-center" alignItems="center" className="classes.grid">
                <audio 
                  ref={audioRef} 
                  src={uploadBatchFilesQueue[selectedUploadBatchFileIndex].URL}
                  controls="controls" 
                  style={{width: '100%'}}
                />
              </Grid>
              <Grid item container xs={1} justifyContent="flex-end" alignItems="center" className="classes.grid">
                <button type="button" className="btn btn-primary" onClick={() => shiftPlayTime(10)}>
                  <Forward10 fontSize="large" />
                </button>
              </Grid>
            </Grid>
          </Paper>
        }
      </div>
  ); 
}