import React, {useEffect, useState } from 'react';
import { Grid, MenuItem, Select, InputLabel, TextField, createTheme, ThemeProvider, Autocomplete, FormGroup, FormControlLabel } from '@mui/material';
import { authHeader } from '../../../Services/_helpers/auth-header';
import useFetch from '../../../../contexts/services/useFetch';
import { authenticationService } from '../../../Services/_services/authenticationService';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EditProject(props) {
    // Selected project ID property
    const selectedProjectId = props.match.params.projectId

    // React hooks
    // 1. Selected project object
    const [ selectedProject, setSelectedProject] = useState({})
    // 2. Current project members of selected project list
    const [ currentProjectMembers, setCurrentProjectMembers ] = useState([])
    // 3. Current project member roles of selected project list
    const [ currentProjectMemberRoles, setCurrentProjectMemberRoles ] = useState([])
    // 4. Organisation list
    const [ organisations, setOrganisations ] = useState([])
    // 6. Users list
    const [ users, setUsers ] = useState([])
    // 7. Roles list
    const [ roles, setRoles ] = useState([])
    // 8. Initial organisation ID
    const [ initialOrganisationId, setInitialOrganisationId ] = useState(0)
    // 9. Current project form completion status
    const [ isCurrentProjectFormCompleted, setIsCurrentProjectFormCompleted ] = useState(false)
    // 10. Current project form submission status
    const [ isCurrentProjectFormSubmitted, setIsCurrentProjectFormSubmitted ] = useState(false)
    // 11. Current project stored status
    const [ isCurrentProjectStored, setIsCurrentProjectStored ] = useState(false)
    // 12. Submission status
    const [ submissionStatus, setSubmissionStatus ] = useState([])

    // API data pull
    // 1. Project
    const { data: selectedProjectData, isPending: selectedProjectDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Projects/` + selectedProjectId, authenticationService.currentUserValue, "Projects")
    // 2. Organisation
    const { data: organisationData, isPending: organisationDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Organisation/SiteAdmin`, authenticationService.currentUserValue, "Organisation")
    // 3. User
    const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/`, authenticationService.currentUserValue, "Users")
    // 4. Roles
    const { data: rolesData, isPending: rolesDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Roles/`, authenticationService.currentUserValue, "Roles")

    useEffect(() => {
        // One condition to set Organisation state: Organisation API data is fully loaded
        if (!organisationDataIsLoading) {
            // Re-map raw organisation data into ID and name only
           const mappedOrganisationData = organisationData.map(organisation => ({
             id: organisation.Id,
             name: organisation.OrganisationName
           }))

           setOrganisations(mappedOrganisationData)
        } 
    }, [organisationDataIsLoading])
     
    useEffect(() => {
        // One condition to set user state: User API data is fully loaded
        if (!userDataIsLoading) {
            // Filter active user only by termination date and re-map raw user data into ID and name
            const mappedUserData = userData.filter(user => user.TerminationDate ===  "9999-12-31T00:00:00")
            .map(user => ({
                id: user.Id,
                name: user.FullName
            }))
            setUsers(mappedUserData)
        }
    }, [userDataIsLoading])

    useEffect(() => {
        // Three conditions to set Roles, Initial organisation ID, Current project members list, and Current project members role
        // 1. Project API data is fully loaded
        // 2. Roles API data is fully loaded
        // 3. User API data is fully loaded
        if (!selectedProjectDataIsLoading && !rolesDataIsLoading && !userDataIsLoading) {
            // Filter active roles only by termination date and re-map raw roles data into ID and name
            const mappedRolesData = rolesData.filter(role => role.ModifiedByUser["TerminationDate"] === "9999-12-31T00:00:00")
            .map(role => ({
                id: role.Id,
                name: role.RoleName
            }))
            setRoles(mappedRolesData)

            // Filter users data by project's owner ID
            const searchUser = users.filter(user => user.id === selectedProjectData.OwnerId)

            setSelectedProject({
                "projectName": selectedProjectData.ProjectName,
                "projectDescription": selectedProjectData.ProjectDescription,
                "organisation_id": selectedProjectData.OrganisationId,
                "owner": searchUser[0]
            })

            setInitialOrganisationId(selectedProjectData.OrganisationId)

            // Re-map selected project's members into ID, name, and project ID
            const projectMembers = [...new Map(selectedProjectData.ProjectMembers.map(member => {
                const selectedMember = {
                    "id": member.User.Id,
                    "name": member.User.FullName,
                    "projectId": selectedProjectData.Id
                }

                return [selectedMember["id"], selectedMember]
            })).values()]

            setCurrentProjectMembers(projectMembers)

            // Re-map project members roles into ID, name, and Role ID
            const projectMembersRoles = projectMembers.map(member => {
                // Filter member roles by member's user ID
                const selectedMemberRoles = selectedProjectData.ProjectMembers.filter(selectedMember => selectedMember.UserId === member.id)

                // Find member's role ID
                const rolesList = mappedRolesData.reduce((roleList, role) => {
                    const searchedMemberRole = selectedMemberRoles.find(memberRole => {
                        return memberRole.RoleId === role.id
                    })

                    return [...roleList, searchedMemberRole !== undefined ? searchedMemberRole.RoleId : 0]
                }, [])

                return {
                    "id": member.id,
                    "name": member.name,
                    "roleId": rolesList
                }
            })

            setCurrentProjectMemberRoles(projectMembersRoles)
        }
    }, [selectedProjectDataIsLoading, rolesDataIsLoading, userDataIsLoading])

    useEffect(() => { 
        checkFormValidity()
    }, [currentProjectMembers, currentProjectMemberRoles, selectedProject])

    // Event handler: Change owner selection
    const changeOwner = (value) => {
        let updatedItem = selectedProject
        updatedItem["owner"] = value;

        setSelectedProject({...updatedItem});
    
        checkFormValidity()
    }

    // Event handler: Change selectedProject state
    const handleInput = (event) => {
        const updatedItem = selectedProject
        updatedItem[event.target.name] = event.target.value;
        
        setSelectedProject({...updatedItem});
    
        checkFormValidity()
    }

    // Event handler: Change project member selection
    const changeProjectMember = (value) => {
        // Re-map selected project members into ID and name
        const updatedProjectMembers = value.map((member, index) => ({
            "id": member.id,
            "name": member.name
        }))

        setCurrentProjectMembers(updatedProjectMembers)

        // Re-map selected project members' role into ID, name, and role ID
        const updatedProjectMemberRoles = value.map((member) => {
            const filteredProjectMemberRoles = currentProjectMemberRoles.find(role => role.id === member.id)

            if (filteredProjectMemberRoles !== undefined) {
                return {
                    "id": member.id,
                    "name": member.name,
                    "roleId": filteredProjectMemberRoles.roleId
                }
            } else {
                const roleIds = rolesData.reduce((next, _) => {
                    return next.concat(0)
                }, [])

                return {
                    "id": member.id,
                    "name": member.name,
                    "roleId": roleIds
                }
            }
        })
        
        setCurrentProjectMemberRoles(updatedProjectMemberRoles)
        checkFormValidity()
    }

    // Event handler: Change project member's role
    const changeProjectRole = (event, roleId, roleIndex, memberUserId) => {
        const currentMemberRoles = [...currentProjectMemberRoles]
        const filteredMemberRoles = currentMemberRoles.find(memberRole => memberRole.id === memberUserId)

        if (event.target.checked) {
            filteredMemberRoles.roleId[roleIndex] = roleId
        } else {
            filteredMemberRoles.roleId[roleIndex] = 0
        }
        
        setCurrentProjectMemberRoles(currentMemberRoles)
        checkFormValidity()
    }

    // Selected project form validator
    const checkFormValidity = () => {
        // Validation rules
        // 1. newProject["projectName"]             -> Not empty string
        // 2. newProject["projectDescription"]      -> Not empty string
        // 3. newProject["owner"]                   -> Not empty integer
        // 4. newProjectMembers.length              -> More than one member
        if (selectedProject["projectName"] !== "" && selectedProject["projectDescription"] !== "" && selectedProject["owner"] !== null && currentProjectMembers.length > 0) {
            setIsCurrentProjectFormCompleted(true)

            if (currentProjectMembers.length > 0) {
                for (var i = 0 ; i < currentProjectMembers.length; i++) {
                    if (currentProjectMembers[i].userId === 0) {
                        setIsCurrentProjectFormCompleted(false)
                    } 

                    setIsCurrentProjectFormCompleted(true)
                }
            }

            if (currentProjectMemberRoles.length > 0) {
                for (var j = 0 ; j < currentProjectMemberRoles.length; j++) {
                    const countRole = currentProjectMemberRoles[j].roleId.reduce((count, role) => {
                        if (role !== 0) {
                            return count + 1
                        } else {
                            return count
                        }
                    }, 0)

                    if (countRole === 0) {
                        setIsCurrentProjectFormCompleted(false)
                    } else {
                        setIsCurrentProjectFormCompleted(true)
                    }
                }
            }
        } else {
            setIsCurrentProjectFormCompleted(false)
        }
    }

    // Event handler: Update selected project
    const updateProject = () => {
        const putUpdateProject = {
            "ProjectName": selectedProject["projectName"],
            "ProjectDescription": selectedProject["projectDescription"],
            "OrganisationId": selectedProject["organisation_id"] === 0 ? organisations[0].id : selectedProject["organisation_id"],
            "OwnerId": selectedProject["owner"] === null ? users[0].id : selectedProject["owner"].id
        }    

        setIsCurrentProjectFormSubmitted(true)

        // Update project by project ID through '/Projects/Update/'
        axios.put(`${process.env.REACT_APP_API_URL}/Projects/Update/${selectedProjectId}`, putUpdateProject, { 
          headers: authHeader()
        })
        .then(updateProjectResponse => {
            if (updateProjectResponse.status === 200) {
                setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Project has been updated!"])

                // Hard delete project role by project ID through '/ProjectRoles/Delete/Hard/Project/' API
                axios.delete(`${process.env.REACT_APP_API_URL}/ProjectRoles/Delete/Hard/Project/${selectedProjectId}`, { 
                    headers: authHeader()
                })
                .then(deleteExistingMemberRolesResponse => {
                    if (deleteExistingMemberRolesResponse.status === 200) {
                        for (var i = 0; i < currentProjectMemberRoles.length; i++) {
                            for (var j = 0; j < currentProjectMemberRoles[i].roleId.length; j++) {
                                if (currentProjectMemberRoles[i].roleId[j] > 0) {
                                    const postNewProjectRoles = {
                                        "user_id": currentProjectMemberRoles[i].id,
                                        "project_id": selectedProjectId,
                                        "role_id": currentProjectMemberRoles[i].roleId[j]
                                    }
                
                                    // After removing existing project roles, create new project roles through '/ProjectRoles/Create' API
                                    axios.post(`${process.env.REACT_APP_API_URL}/ProjectRoles/Create`, postNewProjectRoles, { 
                                        headers: authHeader()
                                    })
                                    .then(createNewProjectRolesResponse => {
                                        if (createNewProjectRolesResponse.status === 200) {
                                            console.info(createNewProjectRolesResponse.status)
                                        } else {
                                            console.info(createNewProjectRolesResponse)
                                        }
                                    })
                                    .catch(error => {
                                        console.info("New project roles creation error")
                                        console.info(error)
                                        setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Project roles registration failed, please contact admin for troubleshooting"])
                                    })
                                }
                            }
            
                            setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, currentProjectMemberRoles[i].name + " is registered!"])
                        }
        
                        setIsCurrentProjectStored(true)
                    }
                })

                // Get organisation's project through '/OrganisationProject/OrganisationId/${initialOrganisationId}/ProjectId/${selectedProjectId}' API
                axios.get(`${process.env.REACT_APP_API_URL}/OrganisationProject/OrganisationId/${initialOrganisationId}/ProjectId/${selectedProjectId}`, {
                    headers: authHeader()
                })
                .then(resp => {
                    const putUpdateOrganisationProject = {
                        "OrganisationId" : selectedProject["organisation_id"] === 0 ? organisations[0].id : selectedProject["organisation_id"]
                    }
            
                    // Update Organisation project by organisation ID through '/OrganisationProject/Update/'
                    axios.put(`${process.env.REACT_APP_API_URL}/OrganisationProject/Update/${resp.data["Id"]}`, putUpdateOrganisationProject, { 
                        headers: authHeader()
                    })
                    .then(updateProjectResponse => {
                        if (updateProjectResponse.status === 200) {
                            setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Organisation project has been updated!"])
            
                        }
                    })
                    .catch(error => {
                        console.info("Organisation project record update error")
                        console.info(error)
                        setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Organisation project update failed, please contact admin for troubleshooting"])
                    })
                })
                .catch(err => {
                    if (err.response.status === 401 || err.response.status === 403) {
                        console.info("JWT token expired")
                        authenticationService.logout();
                    } else {
                        console.info(err)
                    }
                })
            }
        })
        .catch(updateProjectResponseError => {
            console.info(updateProjectResponseError)
        })
    }

    const muiDataTableTheme = createTheme({
        components: {
          // Name of the component
          MuiTableCell: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                padding: '5pt',
              },
            },
          },
        }
    });

    return (
        <Grid container direction="row" justifyContent="center" marginTop={5} marginLeft = {5}>
            <ThemeProvider theme={muiDataTableTheme}>
                { !isCurrentProjectFormSubmitted && !selectedProjectDataIsLoading && Object.keys(selectedProject).length > 0 ?
                    <div id="upload-box" style={{width: "50%"}}>
                        <h5>Edit project</h5>
                        <br/>
                        <InputLabel id="projectNameLabel">Project name</InputLabel>
                        <TextField id="projectName" 
                            name="projectName"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedProject["projectName"]}
                        /> 
                        <br/> 
                        <br/> 
                        <InputLabel id="projectDescriptionLabel">Project description</InputLabel>
                        <TextField id="projectDescription" 
                            name="projectDescription"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedProject["projectDescription"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="organisationLabel">Organisation</InputLabel>
                        {
                            !organisationDataIsLoading && organisations.length > 0 && 
                            <Select
                                labelId="organisationLabel"
                                id="organisation_id"
                                name="organisation_id"
                                style = {{width: "100%"}} 
                                onChange={handleInput}
                                value={selectedProject["organisation_id"]}
                                disabled={true}
                            >
                                {
                                    organisations.map((organisation, index) => {
                                        return <MenuItem key={index} value={organisation.id}>
                                            {organisation.name}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                        <br/>
                        <br/>
                        <InputLabel id="ownerLabel">Owner</InputLabel>
                        {
                            !userDataIsLoading && users.length > 0 && 
                            <Autocomplete
                                disablePortal
                                id="ownerLabel"
                                options={users}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, {}) => {
                                    return <li {...props}>
                                        {option.name}
                                    </li>
                                }}
                                sx={{ width: "100%" }}
                                onChange={(_, value) => {
                                    changeOwner(value)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={selectedProject["owner"]}
                            />
                        }
                        <hr/>
                        <h5>Project member and roles</h5>
                        <br/>
                        { !userDataIsLoading && users.length > 0 && <Autocomplete
                            disablePortal
                            multiple
                            disableCloseOnSelect
                            id="projectMemberList"
                            options={users}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => {
                                return <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            }}
                            sx={{ width: "100%" }}
                            onChange={(_, value) => {
                                changeProjectMember(value)
                            }}
                            renderInput={(params) => <TextField {...params} label="Select member" />}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={currentProjectMembers}
                        />}
                        <br/>
                        {
                            currentProjectMemberRoles.length > 0 ?
                            currentProjectMemberRoles.map((memberRole, memberRoleIndex) => {
                                return <div key={memberRoleIndex}>
                                    {memberRoleIndex > 0 && <hr/>}
                                    <Grid container direction="row">
                                        <Grid item xs={12}>
                                        <strong>{memberRole.name}</strong> role(s)
                                        <FormGroup>
                                            {
                                                roles.length > 0 && 
                                                roles.map((role, roleIndex) => {
                                                    return <FormControlLabel
                                                        key={roleIndex + Math.random()}
                                                        label={role.name}
                                                        control={
                                                            <Checkbox
                                                                checked={memberRole.roleId[roleIndex] > 0 ? true : false}
                                                                onChange={(event) => {
                                                                    changeProjectRole(event, role.id, roleIndex, memberRole.id)
                                                                }}
                                                            />
                                                        }/>
                                                })
                                            }
                                        </FormGroup>
                                        </Grid>
                                    </Grid>
                                </div>
                            }) : null
                        }
                        <hr/>
                        <Grid container justifyContent="right">
                            <button className="btn btn-primary" onClick={updateProject} disabled={!isCurrentProjectFormCompleted}>Update</button>
                        </Grid>
                    </div> :
                    <div id="upload-box" style={{width: "50%"}}>
                        <h5>Project submission progress</h5>
                        <hr/>
                        <ul>
                            {
                                submissionStatus.length > 0 ?
                                submissionStatus.map((status, index) => {
                                    return <li key={index}>
                                        {status}
                                    </li>
                                }) :
                                null
                            }
                        </ul>
                        {
                            isCurrentProjectStored ? <NavLink className="btn btn-primary" to="/Site/Projects"> Return to project list </NavLink> : null
                        }
                    </div>
                }
            </ThemeProvider>
        </Grid>    
    )
}