import React, { useContext, useState, useEffect } from 'react';
import useFetch from './services/useFetch';
import { authenticationService } from '../components/Services/_services/authenticationService';
import { authHeader }  from '../components/Services/_helpers/auth-header';
import ProjectContext from './ProjectContext';

// for calculating the time and seconds
import axios from 'axios';

const ReviewFileContext = React.createContext()

export default ReviewFileContext;

export const ReviewFileContextProvider = ({ currentUser, children }) => {
    // selectedproject context
    const {selectedproject} = useContext(ProjectContext)

    // API data pull
    // 1. Upload batch
    const { data: uploadBatch, fetchData: fetchUploadBatch } = useFetch(`${process.env.REACT_APP_API_URL}/UploadBatch/${selectedproject.Id}`, currentUser, "Upload batch")
    // 2. Review batch
    const { data: reviewBatch, fetchData: fetchReviewBatch } = useFetch(`${process.env.REACT_APP_API_URL}/ReviewBatch/${selectedproject.Id}`, currentUser, "Review batch")
    // 3. Review batch file
    const { data: reviewFiles, fetchData: fetchReviewFiles } = useFetch(`${process.env.REACT_APP_API_URL}/ReviewBatchFile/${selectedproject.Id}`, currentUser, "Review batch file")
    // 4. File
    const { data: rawFiles, fetchData: fetchRawFiles } = useFetch(`${process.env.REACT_APP_API_URL}/File/${selectedproject.Id}`, currentUser, "File")
    // 5. Review outcome master
    const { data: rawReviewOutcomeMaster, fetchData: fetchReviewOutcomeMaster } = useFetch(`${process.env.REACT_APP_API_URL}/ReviewOutcomeMaster/${selectedproject.Id}`, currentUser, "Review outcome master")
    // 6. Review outcome tags
    const { data: rawReviewOutcomeTags, fetchData: fetchReviewOutcomeTags } = useFetch(`${process.env.REACT_APP_API_URL}/ReviewOutcomeTags/${selectedproject.Id}`, currentUser, "Review outcome tags")
    // 7. Transcription
    const { data: rawTranscription, fetchData: fetchTranscription } = useFetch(`${process.env.REACT_APP_API_URL}/Transcription/${selectedproject.Id}`, currentUser, "Transcription")
    
    // React hooks
    // 1. Review queue list
    const [ reviewQueue, setReviewQueue ] = useState(reviewFiles)
    // 2. Review batch queue list
    const [ reviewBatchQueue, setReviewBatchQueue ] = useState([])
    // 3. Upload batch queue list
    const [ uploadBatchQueue, setUploadBatchQueue ] = useState([])
    // 4. Upload batch files list
    const [ uploadBatchFiles, setUploadBatchFiles ] = useState([])
    // 5. Review files queue list
    const [ reviewFilesQueue, setReviewFilesQueue ] = useState([])
    // 6. Review files progress display queue list
    const [ reviewFilesProgressDisplayQueue, setReviewFilesProgressDisplayQueue ] = useState([])
    // 7. Summary list
    const [ summary, setSummary ] = useState([])
    // 8. Progressed files list
    const [ progressedFiles, setProgressedFiles ] = useState([])
    // 9. Selected review batch ID for redirection
    const [ redirectSelectedReviewBatchId, setRedirectSelectedReviewBatchId ] = useState(0)
    // 10. Selected review file ID for redirection
    const [ redirectSelectedReviewFileId, setRedirectSelectedReviewFileId ] = useState(0)

    useEffect(() => {
        // When review batch data is loaded, set review batch queue
        if (reviewBatch && reviewBatch.length > 0) {
            const reviewBatchResult = reviewBatch.filter(rows => rows.Assignee === authenticationService.currentUserValue.id && !rows.Completed)
            setReviewBatchQueue(reviewBatchResult)
        }        
    }, [reviewBatch])

    useEffect(() => {
        // When upload batch data is loaded, set upload batch queue
        if (uploadBatch && uploadBatch.length > 0) {
            const filteredUploadBatch = uploadBatch.filter(item => item.UploadStatusId === 4)
            setUploadBatchQueue(filteredUploadBatch)
        }
    }, [uploadBatch])

    const selectedProgressedFilesProperties = ['Id', 'UploadBatchId', 'Filename_project', 'WorkFlowStatus', 'Language', 'WorkflowStep', 'UploadStatusId']
    const reviewBatchFilesProperties = ['Id', 'UploadBatchId', 'Filename_original', 'Filename_azure', 'Filename_project', 'Language', 'AudioLength', 'URL', 'CreationDate', 'WorkFlowStatus', 'WorkflowStep', 'UploadBatch']

    useEffect(() => {
        // When Files, Review outcome master, Review files, Review outcome tags, Upload batch queue are loaded
        // Set progressedFiles, reviewFilesQueue, reviewFilesProgressDisplayQueue, and uploadBatchFiles state
        if (rawFiles && rawReviewOutcomeMaster) {
            let filteredRawFiles = []

            // Reduce raw files data with selected properties for progressed files
            rawFiles.forEach(file => {
                if (file.UploadStatusId === 4) {
                    const allKeys = Object.keys(file)
                    const result = allKeys.reduce((next, key) => {
                        if (selectedProgressedFilesProperties.includes(key)) {
                            return { ...next, [key]: file[key] }
                        } else {
                            return next
                        }
                    }, {})
    
                    filteredRawFiles.push(result)
                }
            })

            // Merge filtered raw files with review outcome master data
            let filteredFilesAndReviewOutcomeMaster = filteredRawFiles.map((file) => {
                if (reviewFiles && reviewFiles.length > 0) {
                    const reviewBatchFileByFileId = reviewFiles.find(reviewBatchFilesData => reviewBatchFilesData.FileId === parseInt(file.Id) && !reviewBatchFilesData.Completed && reviewBatchFilesData.UploadStatusId !== 5)

                    if (reviewBatchFileByFileId) {
                        file.ReviewBatchId = reviewBatchFileByFileId.ReviewBatchId
                    } else {
                        file.ReviewBatchId = null
                    }
                    
                }

                if (rawReviewOutcomeMaster && rawReviewOutcomeMaster.length > 0) {
                    const reviewOutcomeMasterByFileId = rawReviewOutcomeMaster.find(reviewOutcomeMaster => reviewOutcomeMaster.FileId === parseInt(file.Id))

                    if (reviewOutcomeMasterByFileId) {
                        file.WorkflowStep.ShortDescription = reviewOutcomeMasterByFileId.WorkflowStep.LongDescription

                        return { 
                            ...file, 
                            Relevancy: reviewOutcomeMasterByFileId.Relevancy, 
                            LastReviewBatch: reviewOutcomeMasterByFileId.LastReviewBatch, 
                            LastReviewer: reviewOutcomeMasterByFileId.LastReviewer, 
                            LastReviewTime: reviewOutcomeMasterByFileId.LastReviewTime
                        }
                    } else {
                        file.WorkflowStep.ShortDescription = "-"
                        
                        return { 
                            ...file, 
                            Relevancy: "-", 
                            LastReviewBatch: "-", 
                            LastReviewer: "-", 
                            LastReviewTime: "-"
                        }
                    }
                    
                } else {
                    file.WorkflowStep.ShortDescription = "-"
                    return { 
                        ...file, 
                        Relevancy: "-", 
                        LastReviewBatch: "-", 
                        LastReviewer: "-", 
                        LastReviewTime: "-"
                    }
                }
            })

            setProgressedFiles(filteredFilesAndReviewOutcomeMaster)

            if (reviewFiles && reviewFiles.length > 0) {
                // Find review batch IDs assigned to assignee
                const reviewBatchIds = reviewBatchQueue.reduce((reviewBatchIdArray, reviewBatch) => {
                    return reviewBatchIdArray.concat(reviewBatch.Id)
                }, [])

                // Filter and get review batch file data
                const reviewFileIds = reviewFiles.filter(reviewFile => reviewBatchIds.includes(reviewFile.ReviewBatchId))
                    .reduce((reviewFileIdArray, reviewFile) => {
                    return reviewFileIdArray.concat({
                        "fileId": reviewFile.FileId,
                        "reviewBatchId": reviewFile.ReviewBatchId,
                        "completed": reviewFile.Completed,
                        "reviewBatchFileId": reviewFile.Id
                    })
                }, [])

                let filteredReviewBatchFiles = []
                let filteredReviewBatchFilesProgressDisplay = []

                // Reduce raw files data with selected properties for review batch files
                rawFiles.forEach(file => {
                    const reviewFileIdIndex = reviewFileIds.findIndex( item => item.fileId === file.Id)
                    if (reviewFileIdIndex > -1) {
                        const allKeys = Object.keys(file)
                        const result = allKeys.reduce((next, key) => {
                            if (reviewBatchFilesProperties.includes(key)) {
                                return { ...next, [key]: file[key] }
                            } else {
                                return next
                            }
                        }, {})

                        result.ReviewBatchId = reviewFileIds[reviewFileIdIndex].reviewBatchId
                        result.ReviewBatchFileId = reviewFileIds[reviewFileIdIndex].reviewBatchFileId
                        result.Completed = reviewFileIds[reviewFileIdIndex].completed

                        filteredReviewBatchFiles.push(result)
                    }

                    const reviewFileIdIndexProgressDisplay = reviewFileIds.findIndex( item => item.fileId === file.Id)
                    if (reviewFileIdIndexProgressDisplay > -1) {
                        const allKeys = Object.keys(file)
                        const result = allKeys.reduce((next, key) => {
                            if (reviewBatchFilesProperties.includes(key)) {
                                return { ...next, [key]: file[key] }
                            } else {
                                return next
                            }
                        }, {})

                        result.ReviewBatchId = reviewFileIds[reviewFileIdIndexProgressDisplay].reviewBatchId
                        result.ReviewBatchFileId = reviewFileIds[reviewFileIdIndexProgressDisplay].reviewBatchFileId
                        result.Completed = reviewFileIds[reviewFileIdIndexProgressDisplay].completed

                        filteredReviewBatchFilesProgressDisplay.push(result)
                    }


                })

                setReviewFilesQueue(filteredReviewBatchFiles)
                setReviewFilesProgressDisplayQueue(filteredReviewBatchFilesProgressDisplay)
            }
            
            if (uploadBatchQueue && uploadBatchQueue.length > 0) {
                // Filter upload batch files
                // Find upload batch IDs
                const uploadBatchIds = uploadBatchQueue.reduce((uploadBatchIdArray, uploadBatch) => {
                    return uploadBatchIdArray.concat(uploadBatch.Id)
                }, [])
    
                // Filter and get upload batch file data
                const reviewFileIds = rawFiles.filter(rawFile => uploadBatchIds.includes(rawFile.UploadBatchId))
                    .reduce((rawFileIdArray, rawFile) => {
                    return rawFileIdArray.concat({
                        "fileId": rawFile.Id,
                        "uploadBatchId": rawFile.UploadBatchId
                    })
                }, [])            
    
                let filteredUploadBatchFiles = []
    
                // Reduce raw files data with selected properties for review batch files
                rawFiles.forEach(file => {
                    const reviewFileIdIndex = reviewFileIds.findIndex( item => item.fileId === file.Id)
                    if (reviewFileIdIndex > -1) {
                        const allKeys = Object.keys(file)
                        const result = allKeys.reduce((next, key) => {
                            if (reviewBatchFilesProperties.includes(key)) {
                                return { ...next, [key]: file[key] }
                            } else {
                                return next
                            }
                        }, {})
    
                        result.ReviewBatchId = reviewFileIds[reviewFileIdIndex].reviewBatchId
                        result.ReviewBatchFileId = reviewFileIds[reviewFileIdIndex].reviewBatchFileId
                        result.Completed = reviewFileIds[reviewFileIdIndex].completed
    
                        filteredUploadBatchFiles.push(result)
                    }
                })
    
                setUploadBatchFiles(filteredUploadBatchFiles)
            }
        }
    }, [rawFiles, rawReviewOutcomeMaster, reviewFiles, rawReviewOutcomeTags, uploadBatchQueue])

    // Event handler: Complete a review batch
    const completeReviewBatch = (id) => {
        const currentDate = new Date()   
        const putReviewBatch = {
          "Completed": true,
          "updated_at": currentDate.toISOString()
        }

        // Update a review batch by project ID and review batch ID
        axios.put(`${process.env.REACT_APP_API_URL}/ReviewBatch/Update/${selectedproject.Id}/${id}`, putReviewBatch, { 
            headers: authHeader()
        })
        .then(updateReviewBatchResponse => {
            const putFiles = reviewFilesProgressDisplayQueue.filter(reviewOutcomeTags => reviewOutcomeTags.ReviewBatchId === id).map(item => {
                return {
                    "Id": item.Id,
                    "WorkFlowStatusId": 3, // Completed
                    "updated_at": currentDate.toISOString()
                }
            })

            // Update Files associated to this review batch into Completed workflow status
            axios.put(`${process.env.REACT_APP_API_URL}/File/Multiple/Update/${selectedproject.Id}`, putFiles, { 
                headers: authHeader()
            })
            .then(updateFileResponse => {
                fetchRawFiles()
                fetchReviewBatch()
                fetchReviewFiles()
            })
            .catch(updateFileError => {
                console.info(updateFileError)
            })
        })
        .catch(updateReviewBatchError => {
            console.info(updateReviewBatchError)
        })
    }

    return (
        <ReviewFileContext.Provider value = {{
            reviewFiles,
            fetchReviewFiles,
            reviewBatch,
            fetchReviewBatch,
            reviewQueue,
            reviewBatchQueue,
            reviewFilesQueue,
            summary,
            progressedFiles,
            fetchRawFiles,
            fetchReviewOutcomeMaster,
            fetchReviewOutcomeTags,
            rawTranscription,
            fetchTranscription,
            rawReviewOutcomeMaster,
            rawReviewOutcomeTags,
            redirectSelectedReviewBatchId,
            setRedirectSelectedReviewBatchId,
            redirectSelectedReviewFileId,
            setRedirectSelectedReviewFileId,
            reviewFilesProgressDisplayQueue,
            completeReviewBatch,
            uploadBatchQueue,
            uploadBatchFiles
        }}>{children}</ReviewFileContext.Provider>
    )
}