import React  from 'react';
import { Grid, Box } from '@mui/material';
import FileMeta from './components/FileMetaData';
import Transcript from './components/Transcript';
import TranscriptToolbar from './components/TranscriptToolbar'
import ReviewOutcome from './components/ReviewOutcome'
import ReviewBatchSelector from './components/ReviewBatchSelector'
import { ReviewContextProvider } from '../../../contexts/ReviewContext';
import Audio from './components/AudioPlayer'

export default function Review() {
  return (
    <ReviewContextProvider>
        <Grid container direction="row" justifyContent="center" >
          <Box elevation = {0} sx={{ width: '95%', height: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <ReviewBatchSelector/>
                <Grid container>
                  <Grid item xs={12}>
                    <Audio />
                  </Grid>
                </Grid>
                <Transcript />
              </Grid>
              <Grid item xs={3}>
                <TranscriptToolbar />
                <ReviewOutcome/>
                <FileMeta/>
              </Grid>
            </Grid>
          </Box>
        </Grid>
    </ReviewContextProvider>
  )
}
