import React, { useContext, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Grid,  Box, Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material';

// Contexts
import FileContext  from '../../../../contexts/FileContext'


export default function Step2(props) {
    // get all files from context
    const {files, batches } = useContext(FileContext)
    // state variable for what rows are expanded
    const [expand, setExpand ] = useState([])
  

      const selectedFiles = props.selectedFiles;
      const Allselected = props.Allselected;
      const handleSelect = props.handleSelect;
      const handleSelectAll = props.handleSelectAll;

      // headers for batch table
      const columns = [
        {
        name: "Id",
        label: "Id",
        // disable filter using id
        options : {
          filter: false
        }
        },
        {
          name: "Description",
          label: "Description",
            // define filter type as text search
          options : {
            filterType: 'textField'
          }
        },
        {
        name: "CreatedByUser",
        label: "Created By",
        options: {
            // define the row value to Full Name
            customBodyRender: (value) => {
                  return value[0].FullName;
                },
            // define filter type as multiselect
            filterType: 'multiselect'
              }
        },
        {
        name: "Status",
        label: "Status",
        options : {
          // define filter type as multiselect
          filterType: 'multiselect'
        }
        },
        {
          name: "CreationDate",
          label: "Created",
          options: {
            // clean create date
            customBodyRender: (value) => {
                  return `${value.split("T")[0]}`;
                },
            // define filter type as multiselect      
            filterType: 'multiselect'
              }
        },
        
      ];


    return (
      <Grid container justifyContent="center" marginTop={5}>
      <Box elevation = {0} sx={{ width: '95%', height: '100%' }}>

        <MUIDataTable
          data={batches}
          columns={columns}
          options={{
            selectableRows: "none",
            filter: false,
            search: false,
            download: false,
            print: false,
            viewColumns: false,
            rowsExpanded: expand,
            expandableRows: true,
            expandableRowsHeader: true,
            expandableRowsOnClick: false,
            onRowExpansionChange: (rowsExpanded, allRowsExpanded, ) => {             
              setExpand(allRowsExpanded.map((rows) => rows.index))
            },
            renderExpandableRow: (rowData, rowMeta) => {
              const colSpan = rowData.length + 1;
              return (
                <TableRow>
                <TableCell colSpan={colSpan}>
                <Table>
                <TableHead>
                <TableRow>
                  <TableCell>
                    Select All: 
                    <Checkbox 
                    onChange={() => handleSelectAll(rowData[0])} 
                    id={`checkbox-${rowData[0]}`} 
                    checked={Allselected[rowData[0]]}
                    />
                  </TableCell>
                  <TableCell>
                    File Name (Azure)
                  </TableCell>
                  <TableCell>
                    File Name
                  </TableCell>
                  <TableCell>
                    Duration
                  </TableCell>
                  <TableCell>
                    Language
                  </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {files.filter((file) => file.UploadBatchId === rowData[0]).map((row) => (
                <TableRow  key={row.Id}>
                  <TableCell>
                    <Checkbox 
                      onChange={() => handleSelect(row.Id)} 
                      id={`file-checkbox-${row.Id}`} 
                      checked={selectedFiles[row.Id]}
                      />
                  </TableCell>
                  <TableCell>
                    {row.Filename_project}
                  </TableCell>
                  <TableCell>
                    {row.Filename_original}
                  </TableCell>
                  <TableCell>
                    {row.AudioLength}
                  </TableCell>
                  <TableCell>
                    {row.Language}           
                  </TableCell>
                </TableRow>))
                }
                </TableBody>
                </Table>
                </TableCell>
                </TableRow>
              );
            }  

      }}
            />
        </Box>
      </Grid>
    ); 
}