import React, {useState, useEffect, useContext } from 'react';
import ReviewFileContext from './ReviewFileContext';
import StatusContext from './StatusContext';
import useFetch from './services/useFetch';
import { authenticationService } from '../components/Services/_services/authenticationService';
import ProjectContext from './ProjectContext';
import { authHeader }  from '../components/Services/_helpers/auth-header';
import axios from 'axios';

const ReviewContext = React.createContext()

export default ReviewContext

export const ReviewContextProvider = ({ idx,  children }) => {
    // Status and open context
    const { setStatus, setOpen } = useContext(StatusContext)
    const {selectedproject} = useContext(ProjectContext)

    // API data pull
    // 1. Tag
    const { data: tags } = useFetch(`${process.env.REACT_APP_API_URL}/Tag/${selectedproject.Id}`,  authenticationService.currentUserValue, "Tag") 

    // React hooks
    // set the which file is being review under Review Batch File (by id)
    const [ index, setIndex ] = useState(0)
    // obtain the full list of Review Files
    // 1. reviewFiles                           -> Review file list
    // 2. fetchReviewFiles                      -> Review file fetch (Function)
    // 3. reviewBatchQueue                      -> Review batch queue list
    // 4. rawTranscription                      -> Raw transcript list
    // 5. rawReviewOutcomeMaster                -> Raw review outcome master list
    // 6. rawReviewOutcomeTags                  -> Raw review outcome tags list 
    // 7. redirectSelectedReviewFileId          -> Selected review file ID for redirection
    // 8. redirectSelectedReviewBatchId         -> Selected review batch ID fro redirection
    // 9. fetchReviewOutcomeMaster              -> Review outcome master fetch (Function)
    // 10. fetchReviewOutcomeTags               -> Review outcome tags fetch (Function)
    // 11. fetchTranscription                   -> Transcription (Function)
    // 12. setRedirectSelectedReviewFileId      -> Set selected review file ID for redirection (React hooks)
    // 13. uploadBatchQueue                     -> Upload batch queue list
    // 14. progressedFiles                      -> Progressed files list
    const { 
      reviewFiles, 
      fetchReviewFiles, 
      reviewBatchQueue, 
      rawTranscription, 
      rawReviewOutcomeMaster, 
      rawReviewOutcomeTags, 
      redirectSelectedReviewFileId, 
      redirectSelectedReviewBatchId, 
      fetchReviewOutcomeMaster, 
      fetchReviewOutcomeTags,
      fetchTranscription,
      setRedirectSelectedReviewFileId,
      uploadBatchQueue,
      progressedFiles
    } = useContext(ReviewFileContext)
    // initiate fileReview (data for the file being reviewed)
    const [ fileReview, setFileReview ] = useState()
    // initiate original transcript (transcripts automated by Azure)
    const [ originalTranscript, setOriginalTranscript] = useState([]);
    // initial id of transcript
    const [ transcriptId, setTranscriptId] = useState([]);
    // initial review transcript (for review changes)
    const [ reviewedTranscript, setReviewedTranscript] = useState([]);
    // set the review instance
    const [ review, setReview ] = useState({});
  
    // Review batch selector data list
    const [ reviewBatchSelectorData, setReviewBatchSelectorData ] = useState([])
    // Upload batch selection data list
    const [ uploadBatchSelectorData, setUploadBatchSelectorData ] = useState([])
    // Review batch files queue list
    const [ reviewBatchFilesQueue, setReviewBatchFilesQueue ] = useState([])
    // Upload batch files queue list
    const [ uploadBatchFilesQueue, setUploadBatchFilesQueue ] = useState([])

    // Selected transcription list
    const [ selectedTranscription, setSelectedTranscription] = useState([])
    // Selected review outcome master
    const [ selectedReviewOutcomeMaster, setSelectedReviewOutcomeMaster ] = useState({})
    // Selected review outcome tags
    const [ selectedReviewOutcomeTags, setSelectedReviewOutcomeTags] = useState([])
    // Selected review batch ID
    const [ selectedReviewBatchId, setSelectedReviewBatchId ] = useState(0)
    // Selected review batch Index
    const [ selectedReviewBatchIndex, setSelectedReviewBatchIndex ] = useState(-1)
    // Selected review batch File ID
    const [ selectedReviewBatchFileId, setSelectedReviewBatchFileId ] = useState(0)
    // Selected review file ID
    const [ selectedReviewFileId, setSelectedReviewFileId ] = useState(0)
    // Selected review file index
    const [ selectedReviewFileIndex, setSelectedReviewFileIndex ] = useState(-1)
    // Selected upload batch ID
    const [ selectedUploadBatchId, setSelectedUploadBatchId ] = useState(0)
    // Selected upload batch index
    const [ selectedUploadBatchIndex, setSelectedUploadBatchIndex ] = useState(-1)
    // Selected upload batch file ID
    const [ selectedUploadBatchFileId, setSelectedUploadBatchFileId ] = useState(0)
    // Selected upload batch file index
    const [ selectedUploadBatchFileIndex, setSelectedUploadBatchFileIndex ] = useState(-1)
    // Selected transcript tab
    const [ selectedTranscriptTab, setSelectedTranscriptTab ] = useState(0)
    // Open edit transcript tools status
    const [ openEditTranscriptTools, setOpenEditTranscriptTools ] = useState(true)
    // Edit transcript mode status
    const [ isEditTranscriptMode, setIsEditTranscriptMode ] = useState(false)
    // Review outcome object
    const [ reviewOutcomeData, setReviewOutcomeData ] = useState({})
    // Review outcome tags status object
    const [ reviewOutcomeTagsStatus, setReviewOutcomeTagsStatus ] = useState({})
    // Current audio time mark
    const [ currentAudioTimeMark, setCurrentAudioTimeMark ] = useState(0)

    useEffect(() => {
        // When Selected review batch ID or file ID are changed, update selectedReviewBatchId and selectedReviewFileId
      if (redirectSelectedReviewBatchId > 0 && redirectSelectedReviewFileId > 0) {
        setSelectedReviewBatchId(redirectSelectedReviewBatchId)
        setSelectedReviewFileId(redirectSelectedReviewFileId)
      }
    }, [redirectSelectedReviewFileId, redirectSelectedReviewBatchId])

    useEffect(() => {
        // When Review batch queue and review batch files are loaded, update reviewBatchSelectorData
        if (reviewBatchQueue && reviewBatchQueue.length > 0 && reviewFiles && reviewFiles.length > 0) {
          // Reduce review batch queue into ID, name, workflow step, review batch files, and assignee list
          const reducedReviewBatch = reviewBatchQueue.reduce((reviewBatchArray, reviewBatch) => {
            const reviewBatchFiles = reviewFiles.filter(file => file.ReviewBatchId === reviewBatch.Id)
            return reviewBatchArray.concat({
              "id": reviewBatch.Id,
              "name": reviewBatch.BatchName,
              "workflowstep": reviewBatch.WorkflowStep.LongDescription,
              "files": reviewBatchFiles,
              "assignee": reviewBatch.Assignee,
            })
          }, [])

          setReviewBatchSelectorData(reducedReviewBatch)
        }
    }, [reviewBatchQueue, reviewFiles])

    useEffect(() => {
        // When upload batch queue and progressed files are loaded, update uploadBatchSelectorData
        if (uploadBatchQueue && uploadBatchQueue.length > 0 && progressedFiles && progressedFiles.length > 0) {
            // Reduce upload batch selector data into id, name, and upload batch files list
            const reducedUploadBatch = uploadBatchQueue.reduce((uploadBatchArray, uploadBatch) => {
                const uploadBatchFiles = progressedFiles.filter(file => (file.UploadBatchId === uploadBatch.Id && file.WorkFlowStatus.ProcessOrder !== 2) )
                
                if (uploadBatchFiles.length > 0) {
                    return uploadBatchArray.concat({
                        "id": uploadBatch.Id,
                        "name": uploadBatch.Description,
                        "files": uploadBatchFiles
                    })
                } else {
                    return uploadBatchArray
                }
                
            }, [])
            
            setUploadBatchSelectorData(reducedUploadBatch)
        }
    }, [uploadBatchQueue, progressedFiles])

    useEffect(() => {
        // When raw transcription, selected review file ID, upload batch file ID, review outcome master, and review outcome tags are loaded
        // Set selected transcription (Original and Reviewed), Transcript ID, selected review outcome master, and selected review outcome tags
        if (rawTranscription && rawTranscription.length > 0 && (selectedReviewFileId > 0 || selectedUploadBatchFileId > 0)) {
            // If review file ID is selected
            if (selectedReviewFileId > 0) {
                const filteredTranscription = rawTranscription.filter(transcription => transcription.FileId === selectedReviewFileId)
                setSelectedTranscription(filteredTranscription)

                // Set original transcript          
                const automatedTranscription = filteredTranscription.find(transcription => transcription.TranscriptionType === "Automated")
                const sortedAutomatedTranscription = JSON.parse(automatedTranscription.TranscriptionText)
                setOriginalTranscript(sortedAutomatedTranscription)
                
                // Set reviewed transcript
                const manualTranscription = filteredTranscription.find(transcription => transcription.TranscriptionType === "Manual")
                const sortedManualTranscription = JSON.parse(manualTranscription.TranscriptionText).sort((a, b) => a.order - b.order)
                setReviewedTranscript(sortedManualTranscription)
                setTranscriptId(manualTranscription.Id)    
                
                if (rawReviewOutcomeMaster && rawReviewOutcomeMaster.length > 0) {
                    const findReviewOutcomeMaster = rawReviewOutcomeMaster.find(reviewOutcomeMaster => reviewOutcomeMaster.FileId === selectedReviewFileId)
                    setSelectedReviewOutcomeMaster(findReviewOutcomeMaster)
                }

                if (rawReviewOutcomeTags && rawReviewOutcomeTags.length > 0) {
                    const findReviewOutcomeTags = rawReviewOutcomeTags.filter(reviewOutcomeTag => reviewOutcomeTag.FileId === selectedReviewFileId)
                    setSelectedReviewOutcomeTags(findReviewOutcomeTags)
                }
            // If upload batch file ID is selected
            } else if (selectedUploadBatchFileId > 0) {
                const filteredTranscription = rawTranscription.filter(transcription => transcription.FileId === selectedUploadBatchFileId)
                setSelectedTranscription(filteredTranscription)

                // Set original transcript          
                const automatedTranscription = filteredTranscription.find(transcription => transcription.TranscriptionType === "Automated")
                const sortedAutomatedTranscription = JSON.parse(automatedTranscription.TranscriptionText)
                setOriginalTranscript(sortedAutomatedTranscription)
                
                // Set reviewed transcript
                const manualTranscription = filteredTranscription.find(transcription => transcription.TranscriptionType === "Manual")
                const sortedManualTranscription = JSON.parse(manualTranscription.TranscriptionText).sort((a, b) => a.order - b.order)
                setReviewedTranscript(sortedManualTranscription)
                setTranscriptId(manualTranscription.Id)    
                
                if (rawReviewOutcomeMaster && rawReviewOutcomeMaster.length > 0) {
                    const findReviewOutcomeMaster = rawReviewOutcomeMaster.find(reviewOutcomeMaster => reviewOutcomeMaster.FileId === selectedUploadBatchFileId)
                    setSelectedReviewOutcomeMaster(findReviewOutcomeMaster)
                }

                if (rawReviewOutcomeTags && rawReviewOutcomeTags.length > 0) {
                    const findReviewOutcomeTags = rawReviewOutcomeTags.filter(reviewOutcomeTag => reviewOutcomeTag.FileId === selectedUploadBatchFileId)
                    setSelectedReviewOutcomeTags(findReviewOutcomeTags)
                }
            }
            
        }
    }, [rawTranscription, selectedReviewFileId, selectedUploadBatchFileId, rawReviewOutcomeMaster, rawReviewOutcomeTags])
  
    // Event handler: Save file
    const saveFile = (updateType) => {
        if (Object.keys(reviewOutcomeData).length > 0 && Object.keys(reviewOutcomeTagsStatus).length > 0) {
            setStatus("Saving file...")
            setOpen(true)

                // Update transcript
                if (isEditTranscriptMode) {            
                    const properties = { 'TranscriptionText' : JSON.stringify(reviewedTranscript) }
            
                    // Update transcript through '/Transcription/Update/' API
                    axios.put(`${process.env.REACT_APP_API_URL}/Transcription/Update/${selectedproject.Id}/${transcriptId}`,properties, { 
                        headers: authHeader()
                    })
                    .then(() => {
                        setIsEditTranscriptMode(false)
                        setStatus("Transcript is successfully Saved.")
                        setOpen(true)
                        fetchTranscription()    
                    }).catch((error) => {
                        setStatus("Transcript save failed. Please contact admin for troubleshooting.")
                        console.info(error)
                    })   
                }

            // Update Review Outcome Master 
            // Create review outcome master PUT data
            const currentDate = new Date()  
            let putReviewOutcomeMaster = {...reviewOutcomeData}
            delete putReviewOutcomeMaster.Id

            if (updateType === "REVIEW_BATCH") {
                putReviewOutcomeMaster.LastReviewBatch = reviewBatchSelectorData[selectedReviewBatchIndex].name
            } else if (updateType === "UPLOAD_BATCH") {
                putReviewOutcomeMaster.LastReviewBatch = uploadBatchSelectorData[selectedUploadBatchIndex].name
            } else {
                putReviewOutcomeMaster.LastReviewBatch = "Unknown"
            }

            putReviewOutcomeMaster.LastReviewer = authenticationService.currentUserValue.id
            putReviewOutcomeMaster.LastReviewTime = currentDate.toISOString()
            putReviewOutcomeMaster.updated_at = currentDate.toISOString()

            // Update review outcome master through '/ReviewOutcomeMaster/Update/' API
            axios.put(`${process.env.REACT_APP_API_URL}/ReviewOutcomeMaster/Update/${selectedproject.Id}/${reviewOutcomeData.Id}`, putReviewOutcomeMaster, { 
                headers: authHeader()
            })
            .then(updateReviewOutcomeMasterResponse => {
                    // Update Review Outcome Tags
                    // Re-map review outcome tags into array of objects and create review outcome tags PUT data
                    const putReviewOutcomeTags = Object.entries(reviewOutcomeTagsStatus).map(([key, value]) => {
                        return {
                            "id": key,
                            "TagValue": value ? 1 : 0,
                            "updated_at": currentDate.toISOString()
                        }
                    })

                    axios.put(`${process.env.REACT_APP_API_URL}/ReviewOutcomeTags/Multiple/Update/${selectedproject.Id}`, putReviewOutcomeTags, { 
                        headers: authHeader()
                    })
                    .then(updateReviewOutcomeTagsResponse => {
                        fetchReviewOutcomeMaster()
                        fetchReviewOutcomeTags()

                        setStatus("File is successfully saved")
                        setOpen(true)
                    })
                    .catch(updateReviewOutcomeTagsError => {
                        setStatus("File save failed. Please contact admin for troubleshooting.")
                        console.info(updateReviewOutcomeTagsError)
                    })
            })
            .catch(updateReviewOutcomeMasterError => {
                setStatus("File save failed. Please contact admin for troubleshooting.")
                console.info(updateReviewOutcomeMasterError)
            })
        }
    }

    // Event handler: Re-open finalised or cancelled review batch file
    const reopenFile = () => {
      // Update Review Batch File
      // Create Review Batch File PUT data

      const currentDate = new Date()   
      const putReviewBatchFile = {
        "Completed": false,
        "updated_at": currentDate.toISOString()
      }

      // Update review batch file through '/ReviewBatchFile/Update/' API
      axios.put(`${process.env.REACT_APP_API_URL}/ReviewBatchFile/Update/${selectedproject.Id}/${selectedReviewBatchFileId}`, putReviewBatchFile, { 
          headers: authHeader()
      })
      .then(updateReviewBatchFileResponse => {
          fetchReviewFiles()
          fetchReviewOutcomeMaster()
          fetchReviewOutcomeTags()
          fetchTranscription()

          // setSelectedReviewFileId(0)
          // setSelectedReviewFileIndex(-1)
          // setSelectedTranscription([])
          // setSelectedReviewOutcomeMaster({})
          // setSelectedReviewOutcomeTags([])
          // setSelectedReviewBatchFileId(0)

          setRedirectSelectedReviewFileId(reviewBatchSelectorData[selectedReviewBatchIndex].files[selectedReviewFileIndex].FileId)

          // if (reviewBatchSelectorData[selectedReviewBatchIndex].files && selectedReviewFileIndex + 1 < reviewBatchSelectorData[selectedReviewBatchIndex].files.length) {
          //   setRedirectSelectedReviewFileId(reviewBatchSelectorData[selectedReviewBatchIndex].files[selectedReviewFileIndex + 1].FileId)
          // } else {
          //   const newIndex = reviewBatchSelectorData[selectedReviewBatchIndex].files.length - 2
          //   setRedirectSelectedReviewFileId(reviewBatchSelectorData[selectedReviewBatchIndex].files[newIndex].FileId)
          // }

          setStatus("File is successfully updated")
          setOpen(true)
      })
      .catch(updateReviewBatchFileError => {
            setStatus("File update failed. Please contact admin for troubleshooting.")
            console.info(updateReviewBatchFileError)
      })
    }

    // Event handler: Complete a review batch file
    const completeReviewBatchFile = () => {
        // Save file before completing review
        saveFile("REVIEW_BATCH")

        // Update Review Batch File
        // Create Review Batch File PUT data

        const currentDate = new Date()   
        const putReviewBatchFile = {
          "Completed": true,
          "CompletionTime": currentDate.toISOString(),
          "updated_at": currentDate.toISOString()
        }

        // Update review batch file through '/ReviewBatchFile/Update/' API
        axios.put(`${process.env.REACT_APP_API_URL}/ReviewBatchFile/Update/${selectedproject.Id}/${selectedReviewBatchFileId}`, putReviewBatchFile, { 
            headers: authHeader()
        })
        .then(updateReviewBatchFileResponse => {
            fetchReviewFiles()
            fetchReviewOutcomeMaster()
            fetchReviewOutcomeTags()
            fetchTranscription()

            // setSelectedReviewFileId(0)
            // setSelectedReviewFileIndex(-1)
            // setSelectedTranscription([])
            // setSelectedReviewOutcomeMaster({})
            // setSelectedReviewOutcomeTags([])
            // setSelectedReviewBatchFileId(0)

            setRedirectSelectedReviewFileId(reviewBatchSelectorData[selectedReviewBatchIndex].files[selectedReviewFileIndex].FileId)

            // if (reviewBatchSelectorData[selectedReviewBatchIndex].files && selectedReviewFileIndex + 1 < reviewBatchSelectorData[selectedReviewBatchIndex].files.length) {
            //   setRedirectSelectedReviewFileId(reviewBatchSelectorData[selectedReviewBatchIndex].files[selectedReviewFileIndex + 1].FileId)
            // } else {
            //   const newIndex = reviewBatchSelectorData[selectedReviewBatchIndex].files.length - 2
            //   setRedirectSelectedReviewFileId(reviewBatchSelectorData[selectedReviewBatchIndex].files[newIndex].FileId)
            // }

            setStatus("File is successfully completed")
            setOpen(true)
        })
        .catch(updateReviewBatchFileError => {
            setStatus("File completion failed. Please contact admin for troubleshooting.")
            console.info(updateReviewBatchFileError)
        })
    }

    return (
        <ReviewContext.Provider 
          value={{   
            index, 
            setIndex,
            fileReview,     
            originalTranscript,
            reviewedTranscript,  
            transcriptId,      
            review,
            tags,
            setReview,
            setReviewedTranscript,
            fetchReviewFiles,
            reviewBatchSelectorData,
            selectedReviewBatchId, 
            setSelectedReviewBatchId,
            selectedReviewFileIndex, 
            setSelectedReviewFileIndex,
            reviewBatchFilesQueue, 
            setReviewBatchFilesQueue,
            selectedReviewFileId, 
            setSelectedReviewFileId,
            selectedReviewBatchIndex,
            selectedReviewBatchFileId, 
            setSelectedReviewBatchFileId,
            setSelectedReviewBatchIndex,
            selectedTranscription, 
            setSelectedTranscription,
            selectedReviewOutcomeMaster,
            selectedReviewOutcomeTags,
            selectedTranscriptTab, 
            setSelectedTranscriptTab,
            isEditTranscriptMode, 
            setIsEditTranscriptMode,
            openEditTranscriptTools, 
            setOpenEditTranscriptTools,
            reviewOutcomeTagsStatus, 
            setReviewOutcomeTagsStatus,
            reviewOutcomeData, 
            setReviewOutcomeData,
            saveFile,
            reopenFile,
            completeReviewBatchFile,
            currentAudioTimeMark, 
            setCurrentAudioTimeMark,
            uploadBatchSelectorData,
            selectedUploadBatchId,
            setSelectedUploadBatchId,
            setSelectedUploadBatchIndex,
            selectedUploadBatchFileId,
            setSelectedUploadBatchFileId,
            setSelectedUploadBatchFileIndex,
            selectedUploadBatchFileIndex,
            selectedUploadBatchIndex,
            uploadBatchFilesQueue, 
            setUploadBatchFilesQueue
          }}>
          {children}
        </ReviewContext.Provider>
    )
}