import { useState, useEffect } from 'react'
import { authHeader } from '../../components/Services/_helpers/auth-header';
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { addLog } from '../../features/activityLogs/activityLogsSlice'
import { addDataFetchProcess, removeDataFetchProcess, toggleSiteAdminAccessDeniedWarningPage, toggleOrgAdminAccessDeniedWarningPage } from '../../features/fetchData/fetchDataStatusSlice'
import { useDispatch } from 'react-redux'

const useFetchApp = (url, currentUser, fetchResourceType) => {
    // React hooks
    // 1. Response data
    const [ data, setData ] = useState(null);
    // 2. Data fetch progress status
    const [ isPending, setIsPending ] = useState(true);
    // 3. Error message
    const [ error, setError ] = useState(null);

    const dispatch = useDispatch()

    // Asynchronous API data pull
    const fetchDataApp = async () => {
        // If currentUser object is populated
        if (currentUser) {
            dispatch(addDataFetchProcess(1))
    
            try {
                // Set up axios API data pull
                const fetchDataAppAxios = axios.create()

                // Configure three API data pull attempts with 1s delay in-between
                // If error 403 is received, stop API call
                axiosRetry(fetchDataAppAxios, { 
                    retries: 3,
                    retryDelay: (retryCount) => {
                        // alert(`Fetch data app - ${url} Retry attempt : ${retryCount}`)
                        console.info(`Fetch data app - Retry attempt : ${retryCount}`)
                        return 1000
                    },
                    retryCondition: (_error) => true
                })

                let param = ""
                // For organisation projects API, organisation ID must be available before the API call or an error will be returned
                if (fetchResourceType === "Organisation projects") {
                    param = currentUser.organisationId

                    if (param === undefined) {
                        throw new Error("Parameter is undefined")
                    }
                }

                // Execute API call
                const resp = await fetchDataAppAxios.get(`${url}${param}`, { 
                    headers: authHeader()
                })

                setData(resp.data);
                setIsPending(false);
                setError(null);

                const currentDate = new Date()
                const currentTime = currentDate.toLocaleTimeString()

                dispatch(addLog({
                    "statusCode": "SUCCESS",
                    "status": "Success",
                    "resourceType": fetchResourceType,
                    "message": " data has been succesfully downloaded",
                    "timestamp": currentTime
                }))
            } catch (err) {
                console.info(err.response.data.detail)
                if (err.response.status === 403) {
                    // If error 403 is received, remove currentUser and Project object from session storage, then redirect to login page 
                    if (err.response.data.detail === "Invalid token or expired token.") {
                        sessionStorage.removeItem('currentUser');
                        sessionStorage.removeItem('Project');
                        const url = `${process.env.REACT_APP_PWC_ID_REDIRECT_URI}`
                        window.location.assign(url)
                    } else {
                        // If an API call was an unauthorised call (neither site admin or org admin), show access denied page
                        setError(err.response.data)
                        dispatch(toggleSiteAdminAccessDeniedWarningPage(true))
                        dispatch(toggleOrgAdminAccessDeniedWarningPage(true))
                    }
                }

                setIsPending(false);
                
                const currentDate = new Date()
                const currentTime = currentDate.toLocaleTimeString()

                dispatch(addLog({
                    "statusCode": "FAIL",
                    "status": "Fail",
                    "resourceType": fetchResourceType,
                    "message": " data request failed",
                    "timestamp": currentTime
                }))
            }

            // Remove data fetch progress
            dispatch(removeDataFetchProcess(1))
        }
    }

    useEffect (() => {
        fetchDataApp(url)  
    }, [url, currentUser])
  
  return { data, isPending, error, fetchDataApp }

}

export default useFetchApp;