import { BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { authHeader } from '../../Services/_helpers/auth-header';
import axios from 'axios';
import axiosRetry from 'axios-retry'
import qs from 'qs'

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));
let authenticationError = null

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    get authenticationError () { return authenticationError}
};

function login(authorizationCode) {
    const client_id = process.env.REACT_APP_PWC_ID_CLIENT_ID
    const redirect_uri = process.env.REACT_APP_PWC_ID_REDIRECT_URI   
    const client_secret = process.env.REACT_APP_PWC_ID_CLIENT_SECRET
    const grant_type = 'authorization_code'
    const code = authorizationCode

    const postAccessToken = qs.stringify({
        "code": code,
        "client_id": client_id,
        "client_secret": client_secret,
        "redirect_uri": redirect_uri,
        "grant_type": grant_type
    })

    try {

        const accessTokenAxios = axios.create()

        const config = {
            method: 'POST',
            url: `${process.env.REACT_APP_PWC_ID_ACCESS_ENDPOINT_URL}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded' 
            },
            data: postAccessToken
        }

        axiosRetry(accessTokenAxios, { 
            retries: 3,
            retryDelay: (retryCount) => {
                // alert(`Access token - Retry attempt : ${retryCount}`)
                console.info(`Access token - Retry attempt : ${retryCount}`)
                return 1000
            },
            retryCondition: (error) => {
                console.info(error)
                return error.message === "Network Error"
            }
        })

        accessTokenAxios(config)
        .then(response => {
            var _user = jwt_decode(response.data.id_token)
            _user['access_token'] = response.data.access_token
            const _keys = ['access_token', 'email', 'name', 'family_name', 'given_name', 'preferredMail']
            // store user details and jwt token in local storage to keep user logged in between page refreshes

            const _user_filtered = Object.keys(_user)
            .filter(key => _keys.includes(key))
            .reduce((obj, key) => {
              obj[key] = _user[key];
              return obj;
            }, {});

            sessionStorage.setItem('currentUser', JSON.stringify(_user_filtered));
            currentUserSubject.next(_user_filtered);
        
            const authoriseEmailAxios = axios.create()

            axiosRetry(authoriseEmailAxios, { 
                retries: 3,
                retryDelay: (retryCount) => {
                    console.info(`Authorise Email - Retry attempt : ${retryCount}`)
                    return 1000
                },
                retryCondition: (error) => {
                    console.info(error)
                    authenticationError = {
                        "message_row1": "Username is not registered",
                        "message_row2": "Please contact the admin for troubleshooting."
                    }
                    return error.response.status === 403
                }
            })

            authoriseEmailAxios.get(`${process.env.REACT_APP_API_URL}/Authorise/?email=${_user_filtered.email}`, {
                headers: authHeader()
            })
            .then((response) => {
                // Merge dictionaries together
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
                sessionStorage.setItem('currentUser', JSON.stringify(Object.assign({}, currentUser, response.data)));
                window.location.assign('/login');

                return sessionStorage.getItem('currentUser')
            })
            .catch(error => {
                sessionStorage.removeItem('currentUser');
                currentUserSubject.next(null);
                authenticationError = {
                    "message_row1": "Username is not registered",
                    "message_row2": "Please contact the admin for troubleshooting."
                }
            })
        })
        .catch(error => {
            console.info(error)
        })
    }
    catch (error) {
        console.info('Error:', error);
    }
}

function logout() {
    // remove user from session storage to log user out
    axios.post(`${process.env.REACT_APP_API_URL}/Authorise/Logout`, [], {
        headers: authHeader()
    })
    .then((response) => {
        axios.get(`${process.env.REACT_APP_PWC_ID_END_SESSION_ENDPOINT_URL}?id_token_hint=${currentUserSubject.value.access_token}`,)
        .then(resp => {
            if (resp.status === 200 || resp.status === 204) {
                const client_id = process.env.REACT_APP_PWC_ID_CLIENT_ID  
                const client_secret = process.env.REACT_APP_PWC_ID_CLIENT_SECRET

                axios.post(`${process.env.REACT_APP_PWC_ID_REVOKE_TOKEN}`, {
                    "token": currentUserSubject.value.access_token,
                    "client_id": client_id,
                    "client_secret": client_secret
                })
                .then(resp => {
                    sessionStorage.removeItem('currentUser');
                    sessionStorage.removeItem('Project');
                    currentUserSubject.next(null);
                    const url = `${process.env.REACT_APP_PWC_ID_REDIRECT_URI}`
                    window.location.assign(url)
                })
                .catch(err => {
                    sessionStorage.removeItem('currentUser');
                    sessionStorage.removeItem('Project');
                    currentUserSubject.next(null);
                    const url = `${process.env.REACT_APP_PWC_ID_REDIRECT_URI}`
                    window.location.assign(url)
                    console.info(err)
                })
            }
        })
        .catch(err => {
            sessionStorage.removeItem('currentUser');
            sessionStorage.removeItem('Project');
            currentUserSubject.next(null);
            const url = `${process.env.REACT_APP_PWC_ID_REDIRECT_URI}`
            window.location.assign(url)
            console.info(err)
        })
    })
    .catch(err => {
        console.info(err)

        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('Project');
        currentUserSubject.next(null);
        const url = `${process.env.REACT_APP_PWC_ID_REDIRECT_URI}`
        window.location.assign(url)
    })
    
}