// this page is the home page when the users logs into the system. It should contain the infographic dashboard required for the user

// React and MUI component
import React, { useContext, useEffect, useState } from 'react';
import { Paper, Grid, createTheme, ThemeProvider, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Bar, Pie } from 'react-chartjs-2';

// Contexts
import FileContext from '../../../contexts/FileContext';
import useFetch from '../../../contexts/services/useFetch';
import ProjectContext from '../../../contexts/ProjectContext';

import { authenticationService } from '../../Services/_services/authenticationService';

// Libraries
import moment from "moment"
import MUIDataTable from 'mui-datatables';
import { makeStyles, TableCell, TableFooter, TableRow } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footerCell: {
   borderBottom: "none",
   color: "black",
   fontSize: 16,
   fontWeight: "bolder"
  }
}))

export default function ProjectDashboard() {
  // React context
  // 1. File
  const { files } = useContext(FileContext)
  // 2. Project
  const { selectedproject } = useContext(ProjectContext)

  // React hooks
  // 1. Review workflow stages summary list
  const [reviewWorkflowStagesSummary, setReviewWorkflowStagesSummary] = useState([])
  // 2. Review workflow language distribution summary list
  const [reviewWorkflowLanguageDistributionSummary, setReviewWorkflowLanguageDistributionSummary] = useState([])
  // 3. Review workflow review state summary list
  const [reviewWorkflowReviewStateSummary, setReviewWorkflowReviewStateSummary] = useState({})
  // 4. Review workflow summary list
  const [reviewWorkflowSummary, setReviewWorkflowSummary] = useState([])
  // 5. Review workflow summary headers list
  const [reviewWorkflowSummaryHeaders, setReviewWorkflowSummaryHeaders] = useState([])
  // 6. Review outcome summary list
  const [reviewOutcomeSummary, setReviewOutcomeSummary] = useState([])
  // 7. Review outcome finalised upload batch summary list
  const [reviewOutcomeFinalisedUploadBatchSummary, setReviewOutcomeFinalisedUploadBatchSummary] = useState([])
  // 8. Review outcome summary headers list
  const [reviewOutcomeSummaryHeaders, setReviewOutcomeSummaryHeaders] = useState([])
  // 9. Review outcome distribution summary list
  const [reviewOutcomeDistributionSummary, setReviewOutcomeDistributionSummary] = useState([])
  // 10. Review outcome total outcome summary list
  const [reviewOutcomeTotalOutcomeSummary, setReviewOutcomeTotalOutcomeSummary] = useState([])
  // 11. Review outcome finalised distribution summary list
  const [reviewOutcomeFinalisedDistributionSummary, setReviewOutcomeFinalisedDistributionSummary] = useState([])
  // 12. Review outcome finalised total outcome summary list
  const [reviewOutcomeFinalisedTotalOutcomeSummary, setReviewOutcomeFinalisedTotalOutcomeSummary] = useState([])
  // 13. Review workflow rows per page count
  const [reviewWorkflowRowsPerPage, setReviewWorkflowRowsPerPage] = useState(10)
  // 14. Review workflow page number
  const [reviewWorkflowPage, setReviewWorkflowPage] = useState(0)
  // 15. Review outcome rows per page
  const [reviewOutcomeRowsPerPage, setReviewOutcomeRowsPerPage] = useState(10)
  // 16. Review outcome page number
  const [reviewOutcomePage, setReviewOutcomePage] = useState(0)
  // 17. Upload batch chart selection
  const [ uploadBatchChartSelection, setUploadBatchChartSelection] = useState("allUploadBatches")

  const classes = useStyles()
  
  // API data pull
  // 1. Review workflow summary
  const { data: reviewWorkflowSummaryData, isPending: reviewWorkflowSummaryDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Dashboard/Review/Workflow/${selectedproject.Id}`, authenticationService.currentUserValue, "Review workflow summary")
  // 2. Review outcome summary
  const { data: reviewOutcomeSummaryData, isPending: reviewOutcomeSummaryDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Dashboard/Review/Outcome/${selectedproject.Id}`, authenticationService.currentUserValue, "Review outcome summary")
  // 3. Issue tags
  const { data: issueTagsData, isPending: issueTagsDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/IssueTag/${selectedproject.Id}`, authenticationService.currentUserValue, "Issue tag")

  useEffect(() => {
    // When Review Workflow summary API data is fully loaded, set these states:
    // 1. reviewWorkflowSummary
    // 2. reviewWorkflowStagesSummary
    // 3. reviewWorkflowLanguageDistributionSummary
    // 4. reviewWorkflowReviewStateSummary
    // 5. reviewWorkflowSummaryHeaders
    if (!reviewWorkflowSummaryDataIsLoading) {
      setReviewWorkflowSummary(reviewWorkflowSummaryData[0])
      setReviewWorkflowStagesSummary(reviewWorkflowSummaryData[1])
      setReviewWorkflowLanguageDistributionSummary(reviewWorkflowSummaryData[2])
      setReviewWorkflowReviewStateSummary(reviewWorkflowSummaryData[3])
      
      setReviewWorkflowSummaryHeaders([
        {
          name: "upload_batch",
          label: "Upload Batch",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Upload batch: " + v
              }
            }
          }
        },
        {
          name: "file_count",
          label: "Files",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "File count: " + v
              }
            }
          }
        },
        {
          name: "files_stage_new",
          label: "New",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "New file count: " + v
              }
            },
            customHeadRender: () => {
              return (
                <th key={Math.random() * 1000}>
                  <span>
                    STAGES
                  </span>
                  <br/>
                  <span>New</span>
                </th>
              )
            }
          }
        },
        {
          name: "files_stage_assigned",
          label: "Assigned",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Assigned file count: " + v
              }
            },
            customHeadRender: () => {
              return (
                <th key={Math.random() * 1000}>
                  <span>
                    
                  </span>
                  <br/>
                  <span>Assigned</span>
                </th>
              )
            }
          }
        },
        {
          name: "files_stage_reviewed",
          label: "Reviewed",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Assigned file count: " + v
              }
            },
            customHeadRender: () => {
              return (
                <th key={Math.random() * 1000}>
                  <span>
                    
                  </span>
                  <br/>
                  <span>Reviewed</span>
                </th>
              )
            }
          }
        },
        {
          name: "files_stage_finalised",
          label: "Finalised",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Finalised file count: " + v
              }
            },
            customHeadRender: () => {
              return (
                <th key={Math.random() * 1000}>
                  <span>
                    
                  </span>
                  <br/>
                  <span>Finalised</span>
                </th>
              )
            }
          }
        },
        {
          name: "files_stage_cancelled",
          label: "Cancelled",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Cancelled file count: " + v
              }
            },
            customHeadRender: () => {
              return (
                <th key={Math.random() * 1000}>
                  <span>
                    
                  </span>
                  <br/>
                  <span>Cancelled</span>
                </th>
              )
            }
          }
        },
        {
          name: "is_finished",
          label: "Status",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Status: " + v
              }
            },
            customBodyRender: (data) => {
              return (
                <span>{data ? <strong>Completed</strong> : "In Progress"}</span>
              )
            }
          }
        }
      ])
    }
  }, [reviewWorkflowSummaryDataIsLoading])

  useEffect(() => {
    // When Review Outcome Summary API data is fully loaded, set these states:
    // 1. reviewOutcomeFinalisedUploadBatchSummary
    // 2. reviewOutcomeSummary
    // 3. reviewOutcomeTotalOutcomeSummary
    // 4. reviewOutcomeDistributionSummary
    // 5. reviewOutcomeFinalisedTotalOutcomeSummary
    // 6. reviewOutcomeFinalisedDistributionSummary
    if (!reviewOutcomeSummaryDataIsLoading) {
      setReviewOutcomeFinalisedUploadBatchSummary(reviewOutcomeSummaryData[0])
      setReviewOutcomeSummary(reviewOutcomeSummaryData[1])
      setReviewOutcomeTotalOutcomeSummary(reviewOutcomeSummaryData[2])
      setReviewOutcomeDistributionSummary(reviewOutcomeSummaryData[3])
      setReviewOutcomeFinalisedTotalOutcomeSummary(reviewOutcomeSummaryData[4])
      setReviewOutcomeFinalisedDistributionSummary(reviewOutcomeSummaryData[5])
    }
  }, [reviewOutcomeSummaryDataIsLoading])

  useEffect(() => {
    // When issue tags are fully loaded, set Review outcome summary headers
    if (!issueTagsDataIsLoading) {
      let reviewOutcomeSummaryHeaders = [
        {
          name: "upload_batch_name",
          label: "Upload Batch",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Upload batch: " + v
              }
            }
          }
        },
        {
          name: "file_count",
          label: "Files",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "File count: " + v
              }
            }
          }
        },
        {
          name: "relevant_file_count",
          label: "Relevant",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Relevant count: " + v
              }
            },
            customHeadRender: () => {
              return (
                <th key={Math.random() * 1000}>
                  <span>
                    RELEVANCY
                  </span>
                  <br/>
                  <span>Relevant</span>
                </th>
              )
            }
          }
        },
        {
          name: "irrelevant_file_count",
          label: "Irrelevant",
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return "Irrelevant count: " + v
              }
            },
            customHeadRender: () => {
              return (
                <th key={Math.random() * 1000}>
                  <span>
                    
                  </span>
                  <br/>
                  <span>Irrelevant</span>
                </th>
              )
            }
          }
        }
      ]

      for (var i = 0; i < issueTagsData.length; i++) {
        let groupName = ""
        if (i === 0) {
          groupName = "Issue Tags"
        }

        const columnLabel = issueTagsData[i].Tag

        reviewOutcomeSummaryHeaders.push({
          name: convertStringIntoSnakeCase(issueTagsData[i].Tag),
          label: columnLabel,
          options: {
            filterType: 'textField',
            customFilterListOptions: {
              render: v => {
                return columnLabel + " count: " + v
              }
            },
            customHeadRender: () => {
              return (
                <th key={Math.random() * 1000}>
                  <span>
                    {groupName.toUpperCase()}
                  </span>
                  <br/>
                  <span>{columnLabel}</span>
                </th>
              )
            }
          }
        })
      }

      setReviewOutcomeSummaryHeaders(reviewOutcomeSummaryHeaders)
    }
  }, [issueTagsDataIsLoading])

  useEffect(() => {
    if (uploadBatchChartSelection === "allUploadBatches") {
      
    } else if (uploadBatchChartSelection === "finalisedUploadBatchesOnly") {
      
    }
  }, [uploadBatchChartSelection])

  // Convert any string into snake case
  const convertStringIntoSnakeCase = (originalString) => {
    return originalString.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
  }

  // Event handler: change upload batch chart selection
  const changeUploadBatchChart = () => {
    if (uploadBatchChartSelection === "allUploadBatches") {
      setUploadBatchChartSelection("finalisedUploadBatchesOnly")
    } else {
      setUploadBatchChartSelection("allUploadBatches")
    }
  }

  // define calculated variables
  var language = {}
  var total_no = ""
  var audioLength = ""

  // if files are loaded, then calculate the relevant data
  if (files) {

    // no. of files 
    total_no = files.length
    // total length of audios
    audioLength = moment.duration(files.reduce((acc, rows) => {return acc + moment.duration(rows.AudioLength)}, moment.duration(0)))
    // statistics of count of languages
    language = files.reduce((acc, rows) => {
      if (acc[rows.Language]) {
        acc[rows.Language]++
        return(acc)
      } else {
        acc[rows.Language] = 1
        return(acc)
      }
    }, {})

    // Total issues
  }

  // Language distribution chart taken from reviewWorkflowLanguageDistributionSummary data
  const languageDistributionChartData = {
    labels: reviewWorkflowLanguageDistributionSummary["label"],
    datasets: [{
      label: 'Language distribution',
      data: reviewWorkflowLanguageDistributionSummary["value"],
      backgroundColor: [
        "#FFB600",
        "#D93954",
        "#E669A2",
        "#DB4E18",
        "#AD1B02",
        "#000000"
      ],
      hoverOffset: 2
    }]
  };

  // Review state chart taken from reviewWorkflowReviewStateSummary
  const reviewStateChartData = {
    labels: reviewWorkflowReviewStateSummary["label"],
    datasets: [{
      label: 'Review state',
      data: reviewWorkflowReviewStateSummary["value"],
      backgroundColor: [
        "#FFB600",
        "#D93954",
        "#E669A2",
        "#DB4E18",
        "#AD1B02",
        "#000000"
      ],
      hoverOffset: 2
    }]
  };

  const muiDataTableTheme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: '5pt',
          },
        },
      },
    }
  });

  // Review outcome's outcomes distribution taken from reviewOutcomeDistributionSummary
  const reviewOutcomeOutcomesDistributionChartData = {
    labels: reviewOutcomeDistributionSummary["label"],
    datasets: [
      {
        label: 'Issues',
        data: reviewOutcomeDistributionSummary["value"],
        backgroundColor: [
          "#FFB600",
          "#D93954",
          "#E669A2",
          "#DB4E18",
          "#AD1B02",
          "#000000"
        ],
      },
    ],
  };

  // Total outcomes chart data taken fromm reviewOutcomeTotalOutcomeSummary
  const totalOutcomesChartData = {
    labels: reviewOutcomeTotalOutcomeSummary["label"],
    datasets: [
      {
        label: 'Total outcomes',
        data: reviewOutcomeTotalOutcomeSummary["value"],
        backgroundColor: [
          "#FFB600",
          "#D93954",
          "#E669A2",
          "#DB4E18",
          "#AD1B02",
          "#000000"
        ],
      },
    ],
  };

  // Total outcomes of finalised upload batch only
  const totalOutcomesFinalisedChartData = {
    labels: reviewOutcomeFinalisedTotalOutcomeSummary["label"],
    datasets: [
      {
        label: 'Total outcomes',
        data: reviewOutcomeFinalisedTotalOutcomeSummary["value"],
        backgroundColor: [
          "#FFB600",
          "#D93954",
          "#E669A2",
          "#DB4E18",
          "#AD1B02",
          "#000000"
        ],
      },
    ],
  };
   
  // render page
    return (
      // Container for the page
      <div style={{ maxWidth: 1500, margin: "0 auto", paddingBottom: "50px" }}>
      {/* Container for the page */}
        <Grid container 
         direction="row"
         justifyContent="center"
         alignItems="flex-start"
         >
          <ThemeProvider theme={muiDataTableTheme}>
            <Grid item xs={12}>
              <h5>
                <strong>Review Workflow Summary</strong>
              </h5>
              <Grid container 
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                >
                <Grid item xs={4} >
                  <Paper elevation={3} style={{padding: 10, margin:10, minHeight:100}}>
                    <h5>Total files</h5>
                    <h2>
                      <strong>{reviewWorkflowStagesSummary["total_count"]}</strong>
                    </h2>
                  </Paper>
                </Grid>
                <Grid item xs={8} >
                  <Paper elevation={3} style={{padding: 10, margin: 10, minHeight:100}}>
                    <h5>Stages</h5>
                    <Grid container
                      direction="row"
                      justifyContent="left"
                      alignItems="flex-start">
                        <Grid item xs>
                          New
                          <br/>
                          <strong>{reviewWorkflowStagesSummary["new"]}</strong>
                        </Grid>
                        <Grid item xs>
                          Assigned
                          <br/>
                          <strong>{reviewWorkflowStagesSummary["assigned"]}</strong>
                        </Grid>
                        <Grid item xs>
                          Reviewed
                          <br/>
                          <strong>{reviewWorkflowStagesSummary["reviewed"]}</strong>
                        </Grid>
                        <Grid item xs>
                          Finalised
                          <br/>
                          <strong>{reviewWorkflowStagesSummary["finalised"]}</strong>
                        </Grid>
                        <Grid item xs>
                          Cancelled
                          <br/>
                          <strong>{reviewWorkflowStagesSummary["cancelled"]}</strong>
                        </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container 
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid item xs={4}>
                  <h5>Language</h5>
                  <Paper elevation={3} >
                    <Pie data={languageDistributionChartData} options={{layout: {padding: 10}, plugins: {legend: {position: 'top',},},}}/>
                  </Paper>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <h5>Review state</h5>
                  <Paper elevation={3} >
                    <Pie data={reviewStateChartData} options={{layout: {padding: 10}, plugins: {legend: {position: 'top',},},}}/>
                  </Paper>
                </Grid>
              </Grid>
              <br/>
              <MUIDataTable
                data={reviewWorkflowSummary}
                columns={reviewWorkflowSummaryHeaders}
                options={{
                  selectableRows: "none",
                  print: false,
                  search: true,
                  download: false,
                  filter: true,
                  viewColumns: false,
                  rowsPerPageOptions: [10, 25, 50, 100, 200],
                  customToolbarSelect: () => {},
                  onChangeRowsPerPage(numberOfRows) {
                    setReviewWorkflowRowsPerPage(numberOfRows)
                  },
                  onChangePage(page) {
                    setReviewWorkflowPage(page)
                  },
                  customTableBodyFooterRender: (opts) => {
                    const startIndex = reviewWorkflowPage * reviewWorkflowRowsPerPage
                    const endIndex = (reviewWorkflowPage + 1) * reviewWorkflowRowsPerPage

                    let totalFiles = opts.data
                    .slice(startIndex, endIndex)
                    .reduce((totalFiles, item) => {
                      return totalFiles + item.data[1]
                    }, 0)

                    let stagesNewTotal = opts.data
                    .slice(startIndex, endIndex)
                    .reduce((totalStagesNew, item) => {
                      return totalStagesNew + item.data[2]
                    }, 0)

                    let stagesAssignedTotal = opts.data
                    .slice(startIndex, endIndex)
                    .reduce((totalStagesAssigned, item) => {
                      return totalStagesAssigned + item.data[3]
                    }, 0)

                    let stagesReviewedTotal = opts.data
                    .slice(startIndex, endIndex)
                    .reduce((totalStagesReviewed, item) => {
                      return totalStagesReviewed + item.data[4]
                    }, 0)

                    let stagesFinalisedTotal = opts.data
                    .slice(startIndex, endIndex)
                    .reduce((totalStagesFinalised, item) => {
                      return totalStagesFinalised + item.data[5]
                    }, 0)

                    let stagesCancelledTotal = opts.data
                    .slice(startIndex, endIndex)
                    .reduce((totalStagesCancelled, item) => {
                      return totalStagesCancelled + item.data[6]
                    }, 0)

                    return (
                      <>
                        {
                          reviewWorkflowSummary.length > 0 && (
                            <TableFooter className={classes.footerCell}>
                              <TableRow>
                                {
                                  opts.columns.map((col, index) => {
                                    if (col.display === "true") {
                                      if (col.name === "upload_batch") {
                                        return (
                                          <TableCell key={index} className={classes.footerCell}>
                                            Total
                                          </TableCell>
                                        )
                                      }
                                      else if (col.name === "file_count") {
                                        return (
                                          <TableCell key={index} className={classes.footerCell}>
                                            {totalFiles}
                                          </TableCell>
                                        )
                                      }
                                      else if (col.name === "files_stage_new") {
                                        return (
                                          <TableCell key={index} className={classes.footerCell}>
                                            {stagesNewTotal}
                                          </TableCell>
                                        )
                                      }
                                      else if (col.name === "files_stage_assigned") {
                                        return (
                                          <TableCell key={index} className={classes.footerCell}>
                                            {stagesAssignedTotal}
                                          </TableCell>
                                        )
                                      }
                                      else if (col.name === "files_stage_reviewed") {
                                        return (
                                          <TableCell key={index} className={classes.footerCell}>
                                            {stagesReviewedTotal}
                                          </TableCell>
                                        )
                                      }
                                      else if (col.name === "files_stage_finalised") {
                                        return (
                                          <TableCell key={index} className={classes.footerCell}>
                                            {stagesFinalisedTotal}
                                          </TableCell>
                                        )
                                      }
                                      else if (col.name === "files_stage_cancelled") {
                                        return (
                                          <TableCell key={index} className={classes.footerCell}>
                                            {stagesCancelledTotal}
                                          </TableCell>
                                        )
                                      }
                                    }
                                  })
                                }
                              </TableRow>
                            </TableFooter>
                          )
                        }
                      </>
                    )
                  }
                }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <hr/>
              <h5>
                <strong>Review Outcome Summary</strong>
              </h5>
              <RadioGroup
                row
                aria-labelledby="review-outcome-chart-display-radio-button"
                name="review-outcome-chart-display-radio-button"
                value={uploadBatchChartSelection}
                onChange={changeUploadBatchChart}
              >
                <FormControlLabel value="allUploadBatches" control={<Radio />} label="All upload batches" />
                <FormControlLabel value="finalisedUploadBatchesOnly" control={<Radio />} label="Finalised upload batches only" />
              </RadioGroup>

              { uploadBatchChartSelection === "allUploadBatches" && 
                <>
                  <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start">
                    <Grid item xs={6} style={{ paddingLeft: '20px', paddingTop: '30px'}}>
                      <h5>Total outcomes</h5>
                      <Paper elevation={3}>
                        <Bar data={totalOutcomesChartData} options={
                          {
                            layout: {
                              padding: 10
                            }, 
                            plugins: { 
                              title: { 
                                display: true 
                              }, 
                              legend: { 
                                display: false 
                              },
                            }, 
                            scales: {
                              y: {
                                ticks: {
                                  beginAtZero: true,
                                  callback: function(value) {if (value % 1 === 0) {return value;}}
                                }
                              }
                            }}}/>
                      </Paper>
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '10px', paddingTop: '30px'}}>
                      <h5>Total outcomes</h5>
                      <Paper elevation={3} >
                        <Pie data={totalOutcomesChartData} options={{layout: {padding: 10}, plugins: {legend: {position: 'top',},},}}/>
                      </Paper>
                    </Grid>
                    {/* <Grid item xs={6} style={{ paddingLeft: '20px', paddingTop: '30px'}}>
                      <h5>Outcomes distribution</h5>
                      <Paper elevation={3}>
                        <Bar data={reviewOutcomeOutcomesDistributionChartData} options={{layout: {padding: 10}, plugins: { title: { display: true }, legend: {display: false,},},}}/>
                      </Paper>
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '10px', paddingTop: '30px'}}>
                      <h5>Total outcomes</h5>
                      <Paper elevation={3} >
                        <Pie data={totalOutcomesChartData} options={{layout: {padding: 10}, plugins: {legend: {position: 'top',},},}}/>
                      </Paper>
                    </Grid>  */}
                  </Grid>
                  <br/>
                  <MUIDataTable
                    data={reviewOutcomeSummary}
                    columns={reviewOutcomeSummaryHeaders}
                    options={{
                      selectableRows: "none",
                      print: false,
                      search: true,
                      download: false,
                      filter: true,
                      viewColumns: false,
                      rowsPerPageOptions: [10, 25, 50, 100, 200],
                      customToolbarSelect: () => {},
                      onChangeRowsPerPage(numberOfRows) {
                        setReviewOutcomeRowsPerPage(numberOfRows)
                      },
                      onChangePage(page) {
                        setReviewOutcomePage(page)
                      },
                      customTableBodyFooterRender: (opts) => {
                        const startIndex = reviewOutcomePage * reviewOutcomeRowsPerPage
                        const endIndex = (reviewOutcomePage + 1) * reviewOutcomeRowsPerPage

                        let totalFiles = opts.data
                        .slice(startIndex, endIndex)
                        .reduce((totalFiles, item) => {
                          return totalFiles + item.data[1]
                        }, 0)

                        let relevantFileTotal = opts.data
                        .slice(startIndex, endIndex)
                        .reduce((totalRelevantFiles, item) => {
                          return totalRelevantFiles + item.data[2]
                        }, 0)

                        let irrelevantFileTotal = opts.data
                        .slice(startIndex, endIndex)
                        .reduce((totalIrrelevantFiles, item) => {
                          return totalIrrelevantFiles + item.data[3]
                        }, 0)

                        let issueTagsTotal = []

                        for (let i = 4; i < opts.columns.length; i++) {
                          const issueTagCount = opts.data
                            .slice(startIndex, endIndex)
                            .reduce((issueTag, item) => {
                              return issueTag + item.data[i]
                            }, 0)

                          issueTagsTotal.push(issueTagCount)
                        }

                        return (
                          <>
                            {
                              reviewOutcomeSummary.length > 0 && (
                                <TableFooter className={classes.footerCell}>
                                  <TableRow>
                                    {
                                      opts.columns.map((col, index) => {
                                        if (col.display === "true") {
                                          if (col.name === "upload_batch_name") {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                Total
                                              </TableCell>
                                            )
                                          }
                                          else if (col.name === "file_count") {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                {totalFiles}
                                              </TableCell>
                                            )
                                          }
                                          else if (col.name === "relevant_file_count") {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                {relevantFileTotal}
                                              </TableCell>
                                            )
                                          }
                                          else if (col.name === "irrelevant_file_count") {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                {irrelevantFileTotal}
                                              </TableCell>
                                            )
                                          }
                                          else {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                {issueTagsTotal[index-4]}
                                              </TableCell>
                                            )
                                          }
                                        }
                                      })
                                    }
                                  </TableRow>
                                </TableFooter>
                              )
                            }
                          </>
                        )
                      }
                    }}
                  />
                </>
              }
              { uploadBatchChartSelection === "finalisedUploadBatchesOnly" && 
                <>
                  <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start">
                    <Grid item xs={6} style={{ paddingLeft: '20px', paddingTop: '30px'}}>
                      <h5>Total outcomes</h5>
                      <Paper elevation={3}>
                        <Bar data={totalOutcomesFinalisedChartData} options={{
                          layout: {padding: 10}, 
                          plugins: { 
                            title: { 
                              display: true 
                            }, 
                            legend: {
                              display: false
                            },
                          },
                          scales: {
                            y: {
                              ticks: {
                                beginAtZero: true,
                                callback: function(value) {if (value % 1 === 0) {return value;}}
                              }
                            }
                          }
                        }}/>
                      </Paper>
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '10px', paddingTop: '30px'}}>
                      <h5>Total outcomes</h5>
                      <Paper elevation={3} >
                        <Pie data={totalOutcomesFinalisedChartData} options={{layout: {padding: 10}, plugins: {legend: {position: 'top',},},}}/>
                      </Paper>
                    </Grid>
                    {/* <Grid item xs={6} style={{ paddingLeft: '20px', paddingTop: '30px'}}>
                      <h5>Outcomes distribution</h5>
                      <Paper elevation={3}>
                        <Bar data={reviewOutcomeOutcomesDistributionChartData} options={{layout: {padding: 10}, plugins: { title: { display: true }, legend: {display: false,},},}}/>
                      </Paper>
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '10px', paddingTop: '30px'}}>
                      <h5>Total outcomes</h5>
                      <Paper elevation={3} >
                        <Pie data={totalOutcomesChartData} options={{layout: {padding: 10}, plugins: {legend: {position: 'top',},},}}/>
                      </Paper>
                    </Grid>  */}
                  </Grid>
                  <br/>
                  <MUIDataTable
                    data={reviewOutcomeFinalisedUploadBatchSummary}
                    columns={reviewOutcomeSummaryHeaders}
                    options={{
                      selectableRows: "none",
                      print: false,
                      search: true,
                      download: false,
                      filter: true,
                      viewColumns: false,
                      rowsPerPageOptions: [10, 25, 50, 100, 200],
                      customToolbarSelect: () => {},
                      onChangeRowsPerPage(numberOfRows) {
                        setReviewOutcomeRowsPerPage(numberOfRows)
                      },
                      onChangePage(page) {
                        setReviewOutcomePage(page)
                      },
                      customTableBodyFooterRender: (opts) => {
                        const startIndex = reviewOutcomePage * reviewOutcomeRowsPerPage
                        const endIndex = (reviewOutcomePage + 1) * reviewOutcomeRowsPerPage

                        let totalFiles = opts.data
                        .slice(startIndex, endIndex)
                        .reduce((totalFiles, item) => {
                          return totalFiles + item.data[1]
                        }, 0)

                        let relevantFileTotal = opts.data
                        .slice(startIndex, endIndex)
                        .reduce((totalRelevantFiles, item) => {
                          return totalRelevantFiles + item.data[2]
                        }, 0)

                        let irrelevantFileTotal = opts.data
                        .slice(startIndex, endIndex)
                        .reduce((totalIrrelevantFiles, item) => {
                          return totalIrrelevantFiles + item.data[3]
                        }, 0)

                        let issueTagsTotal = []

                        for (let i = 4; i < opts.columns.length; i++) {
                          const issueTagCount = opts.data
                            .slice(startIndex, endIndex)
                            .reduce((issueTag, item) => {
                              return issueTag + item.data[i]
                            }, 0)

                          issueTagsTotal.push(issueTagCount)
                        }

                        return (
                          <>
                            {
                              reviewOutcomeSummary.length > 0 && (
                                <TableFooter className={classes.footerCell}>
                                  <TableRow>
                                    {
                                      opts.columns.map((col, index) => {
                                        if (col.display === "true") {
                                          if (col.name === "upload_batch_name") {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                Total
                                              </TableCell>
                                            )
                                          }
                                          else if (col.name === "file_count") {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                {totalFiles}
                                              </TableCell>
                                            )
                                          }
                                          else if (col.name === "relevant_file_count") {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                {relevantFileTotal}
                                              </TableCell>
                                            )
                                          }
                                          else if (col.name === "irrelevant_file_count") {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                {irrelevantFileTotal}
                                              </TableCell>
                                            )
                                          }
                                          else {
                                            return (
                                              <TableCell key={index} className={classes.footerCell}>
                                                {issueTagsTotal[index-4]}
                                              </TableCell>
                                            )
                                          }
                                        }
                                      })
                                    }
                                  </TableRow>
                                </TableFooter>
                              )
                            }
                          </>
                        )
                      }
                    }}
                  />
                </>
              }
            </Grid>    
          </ThemeProvider>
          
        </Grid> 
      </div>
    );

}