import React  from 'react';
import { Grid, Box } from '@mui/material';
import UploadBatchFileMetaData from './components/UploadBatchFileMetaData';
import UploadBatchTranscripts from './components/UploadBatchTranscript';
import UploadBatchTranscriptToolbar from './components/UploadBatchTranscriptToolbar'
import UploadBatchReviewOutcome from './components/UploadBatchReviewOutcome'
import UploadBatchSelector from './components/UploadBatchSelector';
import { ReviewContextProvider } from '../../../contexts/ReviewContext';
import UploadBatchAudio from './components/UploadBatchAudioPlayer';

export default function ManagerReviewAll() {
  return (
    <ReviewContextProvider>
        <Grid container direction="row" justifyContent="center" >
          <Box elevation = {0} sx={{ width: '95%', height: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <UploadBatchSelector/>
                <Grid container>
                  <Grid item xs={12}>
                    <UploadBatchAudio />
                  </Grid>
                </Grid>
                <UploadBatchTranscripts />
              </Grid>
              <Grid item xs={3}>
                <UploadBatchTranscriptToolbar />
                <UploadBatchReviewOutcome/>
                <UploadBatchFileMetaData/>
              </Grid>
            </Grid>
          </Box>
        </Grid>
    </ReviewContextProvider>
  )
}
