import React, { useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Contexts
import ReviewContext from '../../../../contexts/ReviewContext';
import ReviewFileContext from '../../../../contexts/ReviewFileContext';

export default function Queue() {
  // Review context
  // 1. Selected review batch ID 
  // 2. Selected review file index
  // 3. Set selected review file index 
  // 4. Review batch files queue
  // 5. Set review batch files queue 
  // 6. Set selected review file ID
  // 7. Set selected review batch file ID
  // 8. Save file (Function)
  const { 
    selectedReviewBatchId, 
    selectedReviewFileIndex, 
    setSelectedReviewFileIndex, 
    reviewBatchFilesQueue, 
    setReviewBatchFilesQueue, 
    setSelectedReviewFileId,
    setSelectedReviewBatchFileId,
    saveFile 
  } = useContext(ReviewContext)

  // Review file context
  // 1. Review files queue
  const { reviewFilesQueue } = useContext(ReviewFileContext)
  
  useEffect(() => {
    // When Selected review batch ID and Review files queue are loaded
    // Set review batch files queue, Selected review file index, Selected review file ID, and Selected review batch file ID
    if (selectedReviewBatchId && reviewFilesQueue && reviewFilesQueue.length > 0) {
      const filteredReviewFilesQueue = reviewFilesQueue.filter(reviewFile => reviewFile.ReviewBatchId === selectedReviewBatchId)
      
      if (filteredReviewFilesQueue.length > 0) {
        setReviewBatchFilesQueue(filteredReviewFilesQueue)
      
        if (selectedReviewFileIndex === -1) {
          setSelectedReviewFileIndex(0)
          setSelectedReviewFileId(filteredReviewFilesQueue[0].Id)
          setSelectedReviewBatchFileId(filteredReviewFilesQueue[0].ReviewBatchFileId)
        }
      } else {
        setReviewBatchFilesQueue([])
        setSelectedReviewFileIndex(-1)
        setSelectedReviewFileId(0)
        setSelectedReviewBatchFileId(0)
      }

    } else {
      setReviewBatchFilesQueue([])
      setSelectedReviewFileIndex(-1)
      setSelectedReviewFileId(0)
      setSelectedReviewBatchFileId(0)
    }
  }, [selectedReviewBatchId, reviewFilesQueue])

  // Event handler: Change queue to previous review file
  const PreviousPage = () => {
    if (selectedReviewFileIndex > 0) {
      if (!reviewBatchFilesQueue[selectedReviewFileIndex].Completed) {
        saveFile("REVIEW_BATCH")
      }

      const newReviewFileIndex = selectedReviewFileIndex - 1
      setSelectedReviewFileIndex(newReviewFileIndex)
      setSelectedReviewFileId(reviewBatchFilesQueue[newReviewFileIndex].Id)
      setSelectedReviewBatchFileId(reviewBatchFilesQueue[newReviewFileIndex].ReviewBatchFileId)
    }
  }

  // Event handler: Change queue to next review file
  const NextPage = () => {
    if (selectedReviewFileIndex < reviewBatchFilesQueue.length - 1) {
      if (!reviewBatchFilesQueue[selectedReviewFileIndex].Completed) {
        saveFile("REVIEW_BATCH")
      }

      const newReviewFileIndex = selectedReviewFileIndex + 1
      setSelectedReviewFileIndex(newReviewFileIndex)
      setSelectedReviewFileId(reviewBatchFilesQueue[newReviewFileIndex].Id)
      setSelectedReviewBatchFileId(reviewBatchFilesQueue[newReviewFileIndex].ReviewBatchFileId)
    }
  }
  
  return (
    <ThemeProvider theme={createTheme()}>
      {
        reviewBatchFilesQueue && reviewBatchFilesQueue.length > 0 &&
        <span>
          <button disabled={selectedReviewFileIndex === 0} type="button" className="btn btn-primary" align='center' style={{ fontSize: "0.8rem", margin: 5, padding: "5px", width: 70 }} onClick={PreviousPage}>Previous</button>
          &emsp;
          {selectedReviewFileIndex + 1} of {reviewBatchFilesQueue.length} file{reviewBatchFilesQueue.length > 1 ? '(s)' : ''}
          &emsp;
          <button disabled={selectedReviewFileIndex === reviewBatchFilesQueue.length - 1} type="button" className="btn btn-primary" align = 'center' style={{ fontSize: "0.8rem", margin: 5, padding: "5px", width: 70 }} onClick={NextPage}>Next</button>
        </span>
      }
      
    </ThemeProvider>
  );
}

