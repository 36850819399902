import React from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



export default function Modal(props) {
  const { children } = props;
  const idx = props.idx;
  const handleSubmit = props.handleSubmit;
  const clearInput = props.clearInput;
  const modalDialogTitle = props.modalDialogTitle ? props.modalDialogTitle : props.titlename;
  const saveButtonLabel = props.saveButtonLabel ? props.saveButtonLabel : "Save";
  const disableSaveButton = props.disableSaveButton
  const initialData = props.initialData ? props.initialData : () => {}
  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
    initialData()
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    clearInput();
  };

  return (
    <>
      { !props.Button && <button className="btn btn-primary" variant="outlined" onClick={handleClickOpenModal}>
        {props.titlename}
      </button>}
      {props.Button && <span onClick={handleClickOpenModal}><props.Button/></span>} 
      <Dialog 
        onClose={handleCloseModal}
        aria-labelledby="add-dialog-title"
        open={openModal}
      >
        <DialogTitle id="add-dialog-title" sx = {{ width : 500 }} >
          {modalDialogTitle}
          {handleCloseModal ? (
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
         style={{position : "absolute",
                top: 8,
              right: 8}}
        >
          <CloseIcon />
        </IconButton>
      ) : null}

        </DialogTitle>
        <DialogContent dividers>
        <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
          {children}
          </Box>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" disabled={disableSaveButton} autoFocus onClick={() => {handleSubmit(idx); handleCloseModal();}} >
            {saveButtonLabel}
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}
