import React, {useEffect, useState } from 'react';
import { Grid, MenuItem, Select, InputLabel, TextField, createTheme, ThemeProvider, Autocomplete, FormGroup, FormControlLabel } from '@mui/material';
import { authHeader } from '../../../Services/_helpers/auth-header';
import useFetch from '../../../../contexts/services/useFetch';
import { authenticationService } from '../../../Services/_services/authenticationService';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function NewProject() {
    // React hooks
    // 1. Organisations list
    const [ organisations, setOrganisations ] = useState([])
    // 2. Users list
    const [ users, setUsers ] = useState([])
    // 3. Roles list
    const [ roles, setRoles ] = useState([])
    // 4. New project form completion status
    const [ isNewProjectFormCompleted, setIsNewProjectFormCompleted ] = useState(false)
    // 5. New project form object
    const [newProject, setNewProject] = useState({
        "projectName": "",
        "projectDescription": "",
        "organisation": 0,
        "owner": 0
    })
    // 6. New project member list
    const [ newProjectMembers, setNewProjectMembers ] = useState([])
    // 7. New project member roles list
    const [ newProjectMemberRoles, setNewProjectMemberRoles ] = useState([])
    // 8. New project form submission status
    const [ isNewProjectFormSubmitted, setIsNewProjectFormSubmitted ] = useState(false)
    // 9. New project stored status
    const [ isNewProjectStored, setIsNewProjectStored ] = useState(false)
    // 10. Submission progress status list
    const [ submissionStatus, setSubmissionStatus ] = useState([])

    // API data pull
    // 1. Organisation
    const { data: organisationData, isPending: organisationDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Organisation/SiteAdmin`, authenticationService.currentUserValue, "Organisation")
    // 2. User
    const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/`, authenticationService.currentUserValue, "Users")
    // 3. Role
    const { data: rolesData, isPending: rolesDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Roles/`, authenticationService.currentUserValue, "Roles")

    useEffect(() => {
        // One condition to set organisation state: Organisation API data is fully loaded 
        if (!organisationDataIsLoading) {
            // Re-map raw Organisation data into ID and name only
            const mappedOrganisationData = organisationData.map(organisation => ({
                id: organisation.Id,
                name: organisation.OrganisationName
            }))
            setOrganisations(mappedOrganisationData)
        } 
    }, [organisationDataIsLoading])
     
    useEffect(() => {
        // One condition to set user and project state: User API data is fully loaded
        if (!userDataIsLoading) {
            // Filter active user only by termination date and re-map raw user data into ID and name
            const mappedUserData = userData.filter(user => user.TerminationDate ===  "9999-12-31T00:00:00")
            .map(user => ({
                id: user.Id,
                name: user.FullName
            }))
            setUsers(mappedUserData)

            if (mappedUserData.length > 0) {
                let updatedItem = newProject
                updatedItem["owner"] = mappedUserData[0];
    
                setNewProject({...updatedItem})
            }
        }
    }, [userDataIsLoading])

    useEffect(() => {
        // One condition to set role state: Role API data is fully loaded
        if (!rolesDataIsLoading) {
            // Filter active role only by termination date and re-map raw roles data into ID and name
            const mappedRolesData = rolesData.filter(role => role.ModifiedByUser["TerminationDate"] === "9999-12-31T00:00:00")
            .map(role => ({
                id: role.Id,
                name: role.RoleName
            }))
            setRoles(mappedRolesData)
        }
    }, [rolesDataIsLoading])

    // Check new project form validity when New project members, Project member roles, and project state changed
    useEffect(() => {
        checkFormValidity()
    }, [newProjectMembers, newProjectMemberRoles, newProject])

    // Event handler: Change owner selection
    const changeOwner = (value) => {
        let updatedItem = newProject
        updatedItem["owner"] = value;

        setNewProject({...updatedItem});
    
        checkFormValidity()
    }

    // Event handler: Change newProject state
    const handleInput = (event) => {
        let updatedItem = newProject
        updatedItem[event.target.name] = event.target.value;
        setNewProject(updatedItem);
    
        checkFormValidity()
    }

    // Event handler: Change project member selection
    const changeProjectMember = (value) => {
        // Re-map selected project members into updated project members state
        const updatedProjectMembers = value.map((member, index) => ({
            "userId": member.id,
            "name": member.name,
            "projectId": 0
        }))

        setNewProjectMembers(updatedProjectMembers)

        // Re-map selected project members' role into updated project members' role state
        // Usually newly-added project member doesn't have any roles selected a.k.a empty roles by default.
        const updatedProjectMemberRoles = value.map((member) => {
            const currentProjectMemberRoles = newProjectMemberRoles.find(role => role.userId === member.id)

            if (currentProjectMemberRoles !== undefined) {
                return {
                    "userId": member.id,
                    "name": member.name,
                    "roleId": currentProjectMemberRoles.roleId
                }
            } else {
                const roleIds = rolesData.reduce((next, _) => {
                    return next.concat(0)
                }, [])

                return {
                    "userId": member.id,
                    "name": member.name,
                    "roleId": roleIds
                }
            }
        })
        
        setNewProjectMemberRoles(updatedProjectMemberRoles)
        checkFormValidity()
    }

    // Event handler: Change project role for each role item
    const changeProjectRole = (event, roleId, roleIndex, memberUserId) => {
        const currentMemberRoles = [...newProjectMemberRoles]
        const filteredMemberRoles = currentMemberRoles.find(memberRole => memberRole.userId === memberUserId)

        if (event.target.checked) {
            filteredMemberRoles.roleId[roleIndex] = roleId
        } else {
            filteredMemberRoles.roleId[roleIndex] = 0
        }
        
        setNewProjectMemberRoles(currentMemberRoles)
        checkFormValidity()
    }

    // Selected project form validator
    const checkFormValidity = () => {
        // Validation rules
        // 1. newProject["projectName"]             -> Not empty string
        // 2. newProject["projectDescription"]      -> Not empty string
        // 3. newProject["owner"]                   -> Not empty integer
        // 4. newProjectMembers.length              -> More than one member
        if (newProject["projectName"] !== "" && newProject["projectDescription"] !== "" && newProject["owner"] !== null && newProjectMembers.length > 0) {
            setIsNewProjectFormCompleted(true)

            if (newProjectMembers.length > 0) {
                for (var i = 0 ; i < newProjectMembers.length; i++) {
                    if (newProjectMembers[i].userId === 0) {
                        setIsNewProjectFormCompleted(false)
                        break
                    } 

                    setIsNewProjectFormCompleted(true)
                }
            }

            if (newProjectMemberRoles.length > 0) {
                for (var j = 0 ; j < newProjectMemberRoles.length; j++) {
                    const countRole = newProjectMemberRoles[j].roleId.reduce((count, role) => {
                        if (role !== 0) {
                            return count + 1
                        } else {
                            return count
                        }
                    }, 0)

                    if (countRole === 0) {
                        setIsNewProjectFormCompleted(false)
                    } else {
                        setIsNewProjectFormCompleted(true)
                    }
                    
                }
            }
        } else {
            setIsNewProjectFormCompleted(false)
        }
    }

    // Event handler: Register new project
    const createProject = () => {
        const postNewProject = {
            "project_name": newProject["projectName"],
            "project_desc": newProject["projectDescription"],
            "organisation_id": newProject["organisation"] === 0 ? organisations[0].id : newProject["organisation"],
            "owner_id": newProject["owner"] === null ? users[0].id : newProject["owner"].id
        }   

        // New project form submission state change into TRUE, therefore disabling new project form
        setIsNewProjectFormSubmitted(true)

        // Register new project through '/Projects/Create/' API
        axios.post(`${process.env.REACT_APP_API_URL}/Projects/Create`, postNewProject, { 
          headers: authHeader()
        })
        .then(createNewProjectResponse => {
          if (createNewProjectResponse.status === 200) {
            const newProjectId = createNewProjectResponse.data["Id"]
            const newProjectname = createNewProjectResponse.data["ProjectName"]

            setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, newProjectname + " project is created!"])

            // Iterate through each project members' role and register it through '/ProjectRoles/Create' API one by one
            for (var i = 0; i < newProjectMemberRoles.length; i++) {
                for (var j = 0; j < newProjectMemberRoles[i].roleId.length; j++) {
                    if (newProjectMemberRoles[i].roleId[j] > 0) {
                        const postNewProjectRoles = {
                            "user_id": newProjectMemberRoles[i].userId,
                            "project_id": newProjectId,
                            "role_id": newProjectMemberRoles[i].roleId[j]
                        }

                        const memberName = newProjectMemberRoles[i].name
    
                        axios.post(`${process.env.REACT_APP_API_URL}/ProjectRoles/Create`, postNewProjectRoles, { 
                            headers: authHeader()
                        })
                        .then(createNewProjectRolesResponse => {
                            if (createNewProjectRolesResponse.status === 200) {
                                // console.info(createNewProjectRolesResponse.data)
                            }
                        })
                        .catch(error => {
                            console.info("New project roles creation error")
                            console.info(error)
                            setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Project roles registration failed, please contact admin for troubleshooting"])
                        })
                    }
                }

                setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, newProjectMemberRoles[i].name + " is registered!"])
            }

            
            const postNewOrganisationProject = {
                "organisation_id": newProject["organisation"] === 0 ? organisations[0].id : newProject["organisation"],
                "project_id": createNewProjectResponse.data["Id"]
            }

            // Register newly-stored project into organisation project
            axios.post(`${process.env.REACT_APP_API_URL}/OrganisationProject/Create`, postNewOrganisationProject, { 
                headers: authHeader()
            })
            .then(createNewOrganisationProjectResponse => {
                if (createNewOrganisationProjectResponse.status === 200) {
                    setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "New organisation project entry is created!"])

                    const postInitialPricing = {
                        "organisation_id": newProject["organisation"] === 0 ? organisations[0].id : newProject["organisation"],
                        "project_id": createNewProjectResponse.data["Id"]
                    }

                    // Register initial billing stats
                    axios.post(`${process.env.REACT_APP_API_URL}/BillingStats/Create/Initial`, postInitialPricing, { 
                        headers: authHeader()
                    })
                    .then(createNewBillingItemResponse => {
                        setIsNewProjectStored(true)
                        setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Initial billing entry is created!"])
                    })                    
                }
            })
            .catch(error => {
                console.info("New organisation project creation error")
                console.info(error)
                setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "New organisation project registration failed, please contact admin for troubleshooting"])
            })

            
          }
        })
        .catch(error => {
            console.info("New project creation error:")
            console.info(error)
            setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "New project submission failed, please contact admin for troubleshooting"])
        })
    }

    const muiDataTableTheme = createTheme({
        components: {
          // Name of the component
          MuiTableCell: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                padding: '5pt',
              },
            },
          },
        }
    });

    return (
        <Grid container direction="row" justifyContent="center" marginTop={5} marginLeft = {5}>
            <ThemeProvider theme={muiDataTableTheme}>
                {   !isNewProjectFormSubmitted ?
                    <div id="upload-box" style={{width: "50%"}}>
                        <h5>New project</h5>
                        <br/>
                        <InputLabel id="projectNameLabel">Project name</InputLabel>
                        <TextField id="projectName" 
                            name="projectName"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/> 
                        <br/> 
                        <InputLabel id="projectDescriptionLabel">Project description</InputLabel>
                        <TextField id="projectDescription" 
                            name="projectDescription"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="organisationLabel">Organisation</InputLabel>
                        {
                            organisations.length > 0 && 
                            <Select
                                labelId="organisationLabel"
                                id="organisation"
                                name="organisation"
                                defaultValue={organisations[0].id}
                                style = {{width: "100%"}} 
                                onChange={handleInput}
                            >
                                {
                                    organisations.map((organisation, index) => {
                                        return <MenuItem key={index} value={organisation.id}>
                                            {organisation.name}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                        <br/>
                        <br/>
                        <InputLabel id="ownerLabel">Owner</InputLabel>
                        {
                            users.length > 0 && 
                            <Autocomplete
                                disablePortal
                                id="ownerLabel"
                                options={users}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, {}) => {
                                    return <li {...props}>
                                        {option.name}
                                    </li>
                                }}
                                sx={{ width: "100%" }}
                                onChange={(_, value) => {
                                    changeOwner(value)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={newProject["owner"]}
                            />
                        }
                        <hr/>
                        <h5>Project member and roles</h5>
                        <br/>
                        { users.length > 0 && <Autocomplete
                            disablePortal
                            multiple
                            disableCloseOnSelect
                            id="projectMemberList"
                            options={users}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            )}
                            sx={{ width: "100%" }}
                            onChange={(_, value) => {
                                changeProjectMember(value)
                            }}
                            renderInput={(params) => <TextField {...params} label="Select member" />}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                        />}
                        <br/>
                        {
                            newProjectMemberRoles.length > 0 ?
                            newProjectMemberRoles.map((memberRole, memberRoleIndex) => {
                                return <div key={memberRoleIndex}>
                                    {memberRoleIndex > 0 && <hr/>}
                                    <Grid container direction="row">
                                        <Grid item xs={12}>
                                        <strong>{memberRole.name}</strong> role(s)
                                        <FormGroup>
                                            {
                                                roles.length > 0 && 
                                                roles.map((role, roleIndex) => {
                                                    return <FormControlLabel
                                                        key={roleIndex + Math.random()}
                                                        label={role.name}
                                                        control={
                                                            <Checkbox
                                                                checked={memberRole.roleId[roleIndex] > 0 ? true : false}
                                                                onChange={(event) => {
                                                                    changeProjectRole(event, role.id, roleIndex, memberRole.userId)
                                                                }}
                                                            />
                                                        }/>
                                                })
                                            }
                                        </FormGroup>
                                        </Grid>
                                    </Grid>
                                </div>
                            }) : null
                        }
                        <hr/>
                        <Grid container justifyContent="right">
                            <button className="btn btn-primary" onClick={createProject} disabled={!isNewProjectFormCompleted}>Submit</button>
                        </Grid>
                    </div> :
                    <div id="upload-box" style={{width: "50%"}}>
                        <h5>Project submission progress</h5>
                        <hr/>
                        <ul>
                            {
                                submissionStatus.length > 0 ?
                                submissionStatus.map((status, index) => {
                                    return <li key={index}>
                                        {status}
                                    </li>
                                }) :
                                null
                            }
                        </ul>
                        {
                            isNewProjectStored ? <NavLink className="btn btn-primary" to="/Site/Projects"> Return to project list </NavLink> : null
                        }
                    </div>
                }
            </ThemeProvider>
        </Grid>    
    )
}