// React realted imports
import React, { useEffect } from 'react';
import { Router, Route } from 'react-router-dom';

// Layout of the site
import { Layout } from './components/Page/Layout/Layout';

//Home Page
import Home from './components/Page/Home/Home';

// Upload Pages
import Upload from './components/Page/Upload/Upload';

// Dashboard Pages
import FileManagerDashboard from './components/Page/FileManagerDashboard/FileManagerDashboard'
import ProjectDashboard from './components/Page/Dashboard/Dashboard';

// Transcript Management
import TranscriptManagement from './components/Page/TranscriptManagement/TranscriptManagement';

// Review Pages
import FileManagement from './components/Page/FileManagement/FileManagement';
import Review  from './components/Page/Review/Review';
import ManagerReviewAll from './components/Page/Review/ManagerReviewAll';
import CreateReviewBatch from './components/Page/ReviewManagement/CreateReviewBatch';
import MyReviewQueue from './components/Page/MyReviewQueue/MyReviewQueue';
import FilePreview from './components/Page/FilePreview/FilePreview';

// Admin Page
import UsersSiteAdmin from './components/Page/Admin/ManageUsersSiteAdmin'
import UsersOrgAdmin from './components/Page/Admin/ManageUsersOrgAdmin';
import IssueTags from './components/Page/Admin/ManageIssueTags';
import Projects from './components/Page/Admin/Projects/ManageProjects'
import ProjectsOrgAdmin from './components/Page/Admin/Projects/ManageProjectsOrgAdmin'
import NewProject from './components/Page/Admin/Projects/NewProject'
import EditProject from './components/Page/Admin/Projects/EditProject'
import ManageBilling from './components/Page/Admin/Projects/ManageBilling'
import ManageProjectMembers from './components/Page/Admin/Projects/ManageProjectMembers'
import Organisations from './components/Page/Admin/Organisations/ManageOrganisations'
import NewOrganisation from './components/Page/Admin/Organisations/NewOrganisation'
import EditOrganisation from './components/Page/Admin/Organisations/EditOrganisation'

// Billing Page
import BillingCurrent from './components/Page/Billing/BillingCurrent'
import BillingHistory from './components/Page/Billing/BillingHistory'
import BillingExport from './components/Page/Billing/BillingExport'

//Bootstrap
import './components/Page/css/bootstrap.css';

// Authentication
import { history } from './components/Services/_helpers/history';
import { PrivateRoute } from './components/Services/_components/PrivateRoute';
import LoginPage from './components/Page/LoginPage/LoginPage';
import ContactUs from './components/Page/ContactUs/ContactUs';
import  SessionTimeout  from './components/Services/SessionTimeout/SessionTimeout';
import { authenticationService } from './components/Services/_services/authenticationService';

// Context
import { ReviewFileContextProvider } from './contexts/ReviewFileContext'
import { StatusContextProvider } from './contexts/StatusContext'
import { FileContextProvider } from './contexts/FileContext';
import { ProjectContextProvider } from './contexts/ProjectContext';

export default function App() {
    const [currentUser, setCurrentUser] = React.useState(null)

    useEffect(() => {
        if (authenticationService.currentUser) {
            authenticationService.currentUser.subscribe(x => setCurrentUser(x));
        }
    }, [])

    return (
        <Router history={history}>
            <ProjectContextProvider currentUser={currentUser}>
                <ReviewFileContextProvider currentUser={currentUser}>
                    <FileContextProvider currentUser={currentUser} >
                        <StatusContextProvider>
                            <SessionTimeout />                           
                            <Layout>
                                {/* <Switch>
                                    <Route path="/" component={LoginComp} />
                                    <Route path="/login" component={authenticationService} />
                                </Switch>                                     */}
                                <Route path="/login" component={LoginPage} />
                                <Route path="/ContactUs" component={ContactUs} />
                                <PrivateRoute exact path='/' component={Home} />
                                <PrivateRoute exact path='/FileManagerDashboard' roles={["File Manager"]} component={FileManagerDashboard}/>
                                <PrivateRoute exact path='/Dashboard' roles={["File Manager", "Review Manager"]} component={ProjectDashboard} />
                                <PrivateRoute exact path='/AudioUploader' roles={["File Manager"]} component={Upload} />
                                <PrivateRoute exact path='/UploadBatch' roles={["File Manager"]} component={TranscriptManagement} />
                                <PrivateRoute exact path='/CreateReviewBatch' roles={["Review Manager"]} component={CreateReviewBatch} />
                                <PrivateRoute exact path='/Review' roles={["Reviewer"]} component={Review} />
                                <PrivateRoute exact path='/Manager/Review/All' roles={["Review Manager"]} component={ManagerReviewAll} />
                                <PrivateRoute exact path='/Preview/File/:fileId' roles={["File Manager", "Review Manager"]} component={FilePreview} />
                                <PrivateRoute exact path='/MyReviewQueue' roles={["Reviewer"]} component={MyReviewQueue} />
                                <PrivateRoute exact path='/FileManagement' roles={["File Manager", "Review Manager"]}  component={FileManagement} />
                                <PrivateRoute exact path="/Site/Users" roles={["SiteAdmin"]}  component={UsersSiteAdmin} />
                                <PrivateRoute exact path="/Org/Users" roles={["OrganisationAdmin"]}  component={UsersOrgAdmin} />
                                <PrivateRoute exact path="/Org/IssueTags" roles={["OrganisationAdmin"]}  component={IssueTags} />
                                <PrivateRoute exact path="/Site/Projects" roles={["SiteAdmin"]}  component={Projects} />
                                <PrivateRoute exact path="/Org/Projects" roles={["OrganisationAdmin"]}  component={ProjectsOrgAdmin} />
                                <PrivateRoute exact path="/Site/Projects/New" roles={["SiteAdmin"]}  component={NewProject} />
                                <PrivateRoute exact path="/Site/Projects/Edit/:projectId" roles={["SiteAdmin"]}  component={EditProject} />
                                <PrivateRoute exact path="/Site/Projects/Billing/:projectId" roles={["SiteAdmin"]}  component={ManageBilling} />
                                <PrivateRoute exact path="/Org/Projects/Members/:projectId" roles={["OrganisationAdmin"]}  component={ManageProjectMembers} />
                                <PrivateRoute exact path="/Site/Organisations" roles={["SiteAdmin"]}  component={Organisations} />
                                <PrivateRoute exact path="/Site/Organisations/New" roles={["SiteAdmin"]}  component={NewOrganisation} />
                                <PrivateRoute exact path="/Site/Organisations/Edit/:organisationId" roles={["SiteAdmin"]}  component={EditOrganisation} />
                                <PrivateRoute exact path="/Org/Billing/Current" roles={["OrganisationAdmin"]}  component={BillingCurrent} />
                                {/* <PrivateRoute exact path="/Org/Billing/History" roles={["OrganisationAdmin"]}  component={BillingHistory} /> */}
                                <PrivateRoute exact path="/Org/Billing/Export" roles={["OrganisationAdmin"]}  component={BillingExport} />
                            </Layout>
                        </StatusContextProvider>
                    </FileContextProvider>
                </ReviewFileContextProvider>
            </ProjectContextProvider>
        </Router>
    );
}