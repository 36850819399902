import React, { useContext, useState, useEffect } from 'react';
import useFetch from './services/useFetch';
import ProjectContext from './ProjectContext';

// for calculating the time and seconds
import moment from "moment"

const FileContext = React.createContext()

export default FileContext;

export const FileContextProvider = ({ currentUser, children }) => {
    // Project context
    const {selectedproject} = useContext(ProjectContext)
    // API data pull
    // 1. Files
    const { data: files, fetchData: fetchFiles } = useFetch(`${process.env.REACT_APP_API_URL}/File/${selectedproject.Id}`, currentUser, "File")
    // 2. Upload batches
    const { data: batches, fetchData: fetchBatches } = useFetch(`${process.env.REACT_APP_API_URL}/UploadBatch/${selectedproject.Id}`, currentUser, "Upload batch")

    // React hooks
    // 1. Summary
    const [ summary, setSummary ] = useState([])

    useEffect(() => {
        // If files and upload batches data are available
        if (files && batches) {
            // Re-map upload batches with list of uploaded files, total count, formatted audio length, and transcription status
            const UpdatedBatch = batches.map((batch) =>
            {   
                const BatchFiles = files.filter((file) => file.UploadBatchId === batch.Id)
                
                const BatchAudioLength = moment.duration(
                    BatchFiles.reduce((acc, rows) => 
                    {return acc + moment.duration(rows.AudioLength)}, 
                    moment.duration(0)))

                let fileTranscriptionStatus = batch.StatusShortDesc

                for (let i = 0 ; i < BatchFiles.length; i++) {
                    if (BatchFiles[i].AzureTranscriptionId != null && BatchFiles[i].UploadStatusId === 2) {
                        fileTranscriptionStatus = "Under transcription"
                        break
                    }
                }

                return {
                    "BatchFiles": BatchFiles, 
                    "TotalCount" : BatchFiles.length, 
                    "AudioLength": BatchAudioLength,
                    "FileTranscriptionStatus": fileTranscriptionStatus
                }
             
            })

            setSummary(UpdatedBatch)
        }
    }, [files, batches])
   
    return (
        <FileContext.Provider value = {{
            files,
            fetchFiles,
            summary,
            batches,
            fetchBatches
        }}>{children}</FileContext.Provider>
    )
}