import React, {useEffect, useState } from 'react';
import { Grid, MenuItem, Select, InputLabel, TextField, createTheme, ThemeProvider, Autocomplete } from '@mui/material';
import { authHeader } from '../../../Services/_helpers/auth-header';
import useFetch from '../../../../contexts/services/useFetch';
import { authenticationService } from '../../../Services/_services/authenticationService';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { currencyList } from './Currency'

export default function NewOrganisation() {
    // Current date setup
    const date = new Date()
    const day = date.getDate()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    const currentDate = `${year}-${month}-${day}`

    // React hooks
    // 1. Users list
    const [ users, setUsers ] = useState([])
    // 2. New organisation form submission status
    const [ isNewOrganisationFormSubmitted, setIsNewOrganisationFormSubmitted ] = useState(false)
    // 3. New organisation stored status
    const [ isNewOrganisationStored, setIsNewOrganisationStored ] = useState(false)
    // 4. New Organisation failed store status
    const [ isNewOrganisationStoreFailed, setIsNewOrganisationStoreFailed ] = useState(false)
    // 5. New organisation form completion status
    const [ isNewOrganisationFormCompleted, setIsNewOrganisationFormCompleted ] = useState(false)
    // 6. Submission progress status list
    const [ submissionStatus, setSubmissionStatus ] = useState([])
    // 7. New organisation data form, it's initialised with empty field
    const [newOrganisation, setNewOrganisation] = useState({
        "organisationName": "",
        "organisationDescription": "",
        "iPowerClientCode": "",
        "owner": 0,
        "contractStartDate": "",
        "contractEndDate": "",
        "pricingName": "",
        "currency": "USD",
        "projectSetupFee": 0,
        "monthlyFee": 0,
        "transcriptionCost": 0,
        "addTranscriptionCost": 0,
        "transcriptionUnit": "",
        "storageCost": 0,
        "addStorageCost": 0,
        "storageUnit": "",
        "userCost": 0,
        "AddUserCost": 0,
        "AddProjectCost": 0,
        "includedProjects": 0,
        "includedTranscriptionHours": 0,
        "includedStorageHours": 0,
        "minimumMonths": 0,
        "includedUserCount": 0
    })

    // API data pull
    // 1. User
    const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/`, authenticationService.currentUserValue, "Users")

    useEffect(() => {
        // One condition to set user and organisation state: User API data is fully loaded
        if (!userDataIsLoading) {
            // Filter active user only by termination date
            const mappedUserData = userData.filter(user => user.TerminationDate ===  "9999-12-31T00:00:00")
            // Re-map raw user data into ID and name
            .map(user => ({
                id: user.Id,
                name: user.FullName
            }))
            setUsers(mappedUserData)

            if (mappedUserData.length > 0) {
                let updatedItem = newOrganisation
                updatedItem["owner"] = mappedUserData[0];
    
                setNewOrganisation({...updatedItem})
            }
            
        }
    }, [userDataIsLoading])

    // Event handler: Change owner selection
    const changeOwner = (value) => {
        let updatedItem = newOrganisation
        updatedItem["owner"] = value;

        setNewOrganisation({...updatedItem});
    
        checkFormValidity()
    }

    // Event handler: Change newOrganisation state
    const handleInput = (event) => {
        let updatedItem = newOrganisation
        updatedItem[event.target.name] = event.target.value;
        
        if (event.target.name === "contractStartDate" && event.target.value === "") {
            updatedItem["contractEndDate"] = ""
        }

        setNewOrganisation({...updatedItem});
    
        checkFormValidity()
    }

    // New organisation form validator
    const checkFormValidity = () => {
        // Validation rule
        // A. Organisation data
        // 1. organisationName              -> Not empty string
        // 2. organisationDescription       -> Not empty string
        // 3. iPowerClientCode              -> Not empty string
        // 4. owner                         -> Not empty string
        // 5. contractStartDate             -> Not empty datetime string
        // 6. contractEndDate               -> Not empty datetime string

        // B. Pricing data
        // 1. pricingName                   -> Not empty string
        // 2. pricingDescription            -> Not empty string
        // 3. currency                      -> Not empty string
        // 4. projectSetupFee               -> Not empty float
        // 5. monthlyFee                    -> Not empty float
        // 6. transcriptionCost             -> Not empty float
        // 7. addTranscriptionCost          -> Not empty float
        // 8. transcriptionUnit             -> Not empty string
        // 9. storageCost                   -> Not empty float
        // 10. addStorageCost               -> Not empty float
        // 11. storageUnit                  -> Not empty string
        // 12. userCost                     -> Not empty float
        // 13. addUserCost                  -> Not empty float
        // 14. addProjectCost               -> Not empty float
        // 15. includedProjects             -> Not empty integer
        // 16. includedTranscriptionHours   -> Not empty float
        // 17. includedStorageHours         -> Not empty float
        // 18. minimumMonths                -> Not empty integer
        // 19. includedUserCount            -> Not empty integer
        if (
            newOrganisation["organisationName"] !== "" && 
            newOrganisation["organisationDescription"] !== "" && 
            newOrganisation["iPowerClientCode"] !== "" &&
            newOrganisation["owner"] !== null &&
            newOrganisation["contractStartDate"] !== "" &&
            newOrganisation["contractEndDate"] !== "" &&
            newOrganisation["pricingName"] !== "" &&
            newOrganisation["pricingDescription"] !== "" &&
            newOrganisation["currency"] !== "" &&
            newOrganisation["projectSetupFee"] > 0 &&
            newOrganisation["monthlyFee"] > 0 &&
            newOrganisation["transcriptionCost"] > 0 &&
            newOrganisation["addTranscriptionCost"] > 0 &&
            newOrganisation["transcriptionUnit"] !== "" &&
            newOrganisation["storageCost"] > 0 &&
            newOrganisation["addStorageCost"] > 0 &&
            newOrganisation["storageUnit"] !== "" &&
            newOrganisation["userCost"] > 0 &&
            newOrganisation["addUserCost"] > 0 &&
            newOrganisation["addProjectCost"] > 0 &&
            newOrganisation["includedProjects"] > 0 &&
            newOrganisation["includedTranscriptionHours"] > 0 &&
            newOrganisation["includedStorageHours"] > 0 &&
            newOrganisation["minimumMonths"] > 0 &&
            newOrganisation["includedUserCount"] > 0
        ) {
            setIsNewOrganisationFormCompleted(true)
        } else {
            setIsNewOrganisationFormCompleted(false)
        }
    }

    // Event handler: Create new organisation
    const createOrganisation = () => {
        const postNewPricing = {
            "name" : newOrganisation["pricingName"],
            "description" : newOrganisation["pricingDescription"],
            "currency" : newOrganisation["currency"],
            "project_setup_fee" : newOrganisation["projectSetupFee"],
            "monthly_fee" : newOrganisation["monthlyFee"],
            "transcription_cost" : newOrganisation["transcriptionCost"],
            "add_transcription_cost" : newOrganisation["addTranscriptionCost"],
            "transcription_unit" : newOrganisation["transcriptionUnit"],
            "storage_cost" : newOrganisation["storageCost"],
            "add_storage_cost" : newOrganisation["addStorageCost"],
            "storage_unit" : newOrganisation["storageUnit"],
            "user_cost" : newOrganisation["userCost"],
            "add_user_cost" : newOrganisation["addUserCost"],
            "add_project_cost" : newOrganisation["addProjectCost"],
            "included_projects" : newOrganisation["includedProjects"],
            "included_transcription_hours" : newOrganisation["includedTranscriptionHours"],
            "included_storage_hours" : newOrganisation["includedStorageHours"],
            "minimum_months" : newOrganisation["minimumMonths"],
            "included_user_count" : newOrganisation["includedUserCount"]
        }

        // New organisation form submission state change into TRUE, therefore disabling new organisation form
        setIsNewOrganisationFormSubmitted(true)

        // Register new pricing through '/Pricing/Create' API
        axios.post(`${process.env.REACT_APP_API_URL}/Pricing/Create`, postNewPricing, { 
            headers: authHeader()
        })
        .then(createNewPricingResponse => {
            if (createNewPricingResponse.status === 200) {
                const newPricingId = createNewPricingResponse.data["Id"]

                const parsedContractStartDate = new Date(newOrganisation["contractStartDate"])
                const parsedContractEndDate = new Date(newOrganisation["contractEndDate"])

                const postNewOrganisation = {
                    "pricing_id": newPricingId,
                    "organisation_name" : newOrganisation["organisationName"], 
                    "organisation_description" : newOrganisation["organisationDescription"], 
                    "i_power_client_code" : newOrganisation["iPowerClientCode"],
                    "owner_id": newOrganisation["owner"] === null ? users[0].id : newOrganisation["owner"].id,
                    "contract_start_date": parsedContractStartDate,
                    "contract_end_date": parsedContractEndDate
                }

                setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "New pricing entry is created!"])

                // Register new organisation after pricing record has been created
                axios.post(`${process.env.REACT_APP_API_URL}/Organisation/Create`, postNewOrganisation, { 
                    headers: authHeader()
                })
                .then(createNewOrganisationResponse => {
                    if (createNewOrganisationResponse.status === 200) {
                        setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "New organisation entry is created!"])
                    }
                })
                .catch(error => {
                    console.info("New organisation creation error")
                    console.info(error)
                    // Change isNewOrganisationStoreFailed into TRUE, therefore enabling 'Return to organisation list' button
                    setIsNewOrganisationStoreFailed(true)
                    setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "New organisation registration failed, please contact admin for troubleshooting"])
                })

                // Change isNewOrganisationStored into TRUE, therefore enabling 'Return to organisation list' button
                setIsNewOrganisationStored(true)
            }
        })
        .catch(error => {
            console.info("New pricing creation error:")
            console.info(error)
            setIsNewOrganisationStoreFailed(true)
            setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "New pricing submission failed, please contact admin for troubleshooting"])
        })
    }

    const muiDataTableTheme = createTheme({
        components: {
          // Name of the component
          MuiTableCell: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                padding: '5pt',
              },
            },
          },
        }
    });

    return (
        <Grid container direction="row" justifyContent="center" marginTop={5} marginLeft = {5}>
            <ThemeProvider theme={muiDataTableTheme}>
                {   !isNewOrganisationFormSubmitted ?
                    <div id="upload-box" style={{width: "50%"}}>
                        <h4>New organisation</h4>
                        <br/>
                        <InputLabel id="organisationNameLabel">Organisation name</InputLabel>
                        <TextField id="organisationName" 
                            name="organisationName"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/> 
                        <br/> 
                        <InputLabel id="organisationDescriptionLabel">Organisation description</InputLabel>
                        <TextField id="organisationDescription" 
                            name="organisationDescription"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="iPowerClientCodeLabel">iPower client code</InputLabel>
                        <TextField id="iPowerClientCode" 
                            name="iPowerClientCode"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="ownerLabel">Owner</InputLabel>
                        {/* {
                            users.length > 0 && 
                            <Select
                                labelId="ownerLabel"
                                id="owner"
                                name="owner"
                                defaultValue={users[0].id}
                                style = {{width: "100%"}} 
                                onChange={handleInput}
                            >
                                {
                                    users.map((user, index) => {
                                        return <MenuItem key={index} value={user.id}>
                                            {user.name}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        } */}
                        {
                            users.length > 0 && 
                            <Autocomplete
                                disablePortal
                                id="ownerLabel"
                                options={users}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, {}) => {
                                    return <li {...props}>
                                        {option.name}
                                    </li>
                                }}
                                sx={{ width: "100%" }}
                                onChange={(_, value) => {
                                    changeOwner(value)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={newOrganisation["owner"]}
                            />
                        }
                        <br/>
                        <InputLabel id="contractStartDateLabel">Contract start date</InputLabel>
                        {
                            <TextField id="contractStartDate" 
                                name="contractStartDate"
                                type = "date"
                                InputProps={{inputProps: {min: currentDate, max: newOrganisation["contractEndDate"]}}}
                                style = {{width: "100%"}} 
                                onChange={handleInput}
                                variant="outlined"
                                value= {newOrganisation["contractStartDate"]}
                            /> 
                        }
                        <br/>
                        <br/>
                        <InputLabel id="contractEndDateLabel">Contract end date</InputLabel>
                        <TextField id="contractEndDate" 
                            name="contractEndDate"
                            type = "date"
                            disabled = {newOrganisation["contractStartDate"] === "" ? true : false}
                            InputProps={{inputProps: {min: newOrganisation["contractStartDate"]}}}
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={newOrganisation["contractEndDate"]}
                        /> 
                        <br/>
                        <br/>
                        <hr/>
                        <h4>Pricing details</h4>
                        <br/>
                        <InputLabel id="pricingNameLabel">Pricing name</InputLabel>
                        <TextField id="pricingName" 
                            name="pricingName"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/> 
                        <br/> 
                        <InputLabel id="pricingDescriptionLabel">Pricing description</InputLabel>
                        <TextField id="pricingDescription" 
                            name="pricingDescription"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="currencyLabel">Currency</InputLabel>
                        {
                            currencyList.length > 0 && 
                            <Select
                                labelId="currencyLabel"
                                id="currency"
                                name="currency"
                                defaultValue={"USD"}
                                style = {{width: "100%"}} 
                                onChange={handleInput}
                            >
                                {
                                    currencyList.map((organisation, index) => {
                                        return <MenuItem key={index} value={organisation.code}>
                                            {organisation.name}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                        <br/>
                        <br/>
                        <InputLabel id="monthlyFeeLabel">Monthly fee</InputLabel>
                        <TextField id="monthlyFee" 
                            name="monthlyFee"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="projectSetupFeeLabel">Project setup fee</InputLabel>
                        <TextField id="projectSetupFee" 
                            name="projectSetupFee"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <h5>Transcription</h5>
                        <br/>
                        <InputLabel id="transcriptionCostLabel">Transcription Cost</InputLabel>
                        <TextField id="transcriptionCost" 
                            name="transcriptionCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="addTranscriptionCostLabel">Additional transcription Cost</InputLabel>
                        <TextField id="addTranscriptionCost" 
                            name="addTranscriptionCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="transcriptionUnitLabel">Transcription unit</InputLabel>
                        <TextField id="transcriptionUnit" 
                            name="transcriptionUnit"
                            type = "string"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <h5>Storage</h5>
                        <br/>
                        <InputLabel id="storageCostLabel">Storage Cost</InputLabel>
                        <TextField id="storageCost" 
                            name="storageCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="addStorageCostLabel">Additional storage Cost</InputLabel>
                        <TextField id="addStorageCost" 
                            name="addStorageCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="storageUnitLabel">Storage unit</InputLabel>
                        <TextField id="storageUnit" 
                            name="storageUnit"
                            type = "string"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <h5>User</h5>
                        <br/>
                        <InputLabel id="userCostLabel">User Cost</InputLabel>
                        <TextField id="userCost" 
                            name="userCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="addUserCostLabel">Additional User Cost</InputLabel>
                        <TextField id="addUserCost" 
                            name="addUserCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <h5>Additional cost details</h5>
                        <br/>
                        <InputLabel id="addProjectCostLabel">Additional Project Cost</InputLabel>
                        <TextField id="addProjectCost" 
                            name="addProjectCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="includedProjectsLabel">Included project count</InputLabel>
                        <TextField id="includedProjects" 
                            name="includedProjects"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="includedTranscriptionHoursLabel">Included transcription hours</InputLabel>
                        <TextField id="includedTranscriptionHours" 
                            name="includedTranscriptionHours"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="includedStorageHoursLabel">Included storage hours</InputLabel>
                        <TextField id="includedStorageHours" 
                            name="includedStorageHours"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="minimumMonthsLabel">Minimum hours</InputLabel>
                        <TextField id="minimumMonths" 
                            name="minimumMonths"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="includedUserCountLabel">Included user count</InputLabel>
                        <TextField id="includedUserCount" 
                            name="includedUserCount"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                        /> 
                        <br/>
                        <br/>
                        <hr/>
                        <Grid container justifyContent="right">
                            <button className="btn btn-primary" onClick={createOrganisation} disabled={!isNewOrganisationFormCompleted}>Submit</button>
                        </Grid>
                    </div> :
                    <div id="upload-box" style={{width: "50%"}}>
                        <h5>Organisation submission progress</h5>
                        <hr/>
                        <ul>
                            {
                                submissionStatus.length > 0 ?
                                submissionStatus.map((status, index) => {
                                    return <li key={index}>
                                        {status}
                                    </li>
                                }) :
                                null
                            }
                        </ul>
                        {
                            isNewOrganisationStored || isNewOrganisationStoreFailed ? <NavLink className="btn btn-primary" to="/Site/Organisations"> Return to organisation list </NavLink> : null
                        }
                    </div>
                }
            </ThemeProvider>
        </Grid>
    )
}