import { authenticationService } from '../_services/authenticationService';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.access_token) {
        return { 
            Authorization: `Bearer ${currentUser.access_token}`,
            ContentType: 'text/plain',
            CacheControl: 'must-revalidate, no-cache, no-store, no-transform, public, private, proxy-revalidate, max-age: 31536000, s-maxage=31536000',
        };
    } else {
        return {};
    }
}