export const currencyList = [
    {name: "Australian Dollar", code: "AUD"},
    {name: "British Pound Sterling", code: "GBP"},
    {name: "Canadian Dollar", code: "CAD"},
    {name: "Chinese Yuan", code: "CNY"},
    {name: "Euro", code: "EUR"},
    {name: "Hong Kong Dollar", code: "HKD"},
    {name: "Japanese Yen", code: "JPY"},
    {name: "Singapore Dollar", code: "SGD"},
    {name: "Swiss Franc", code: "CHF"},
    {name: "US Dollar", code: "USD"}
];