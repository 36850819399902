import React, { useState, useContext, useEffect } from 'react';
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { Grid, Box, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';


// Services
import axios from 'axios';
import { authenticationService } from '../../Services/_services/authenticationService';
import { authHeader } from '../../Services/_helpers/auth-header';

// Contexts
import FileContext  from '../../../contexts/FileContext'
import ReviewFileContext from '../../../contexts/ReviewFileContext';
import ProjectContext from '../../../contexts/ProjectContext';


//Components
import Step1 from './components/Step1'
import Step2 from './components/Step2'

export default function CreateReviewBatch()  {    
  // get all files from context
  const {files, batches } = useContext(FileContext)
  // get all reviewbatches
  const { fetchReviewBatch, fetchReviewFiles } = useContext(ReviewFileContext)
  // state variable for storing the files selected in step 2
  const [selectedFiles, setSelectedFiles ] = useState({})
  // state variable for what batches are selected
  const[ Allselected, setAllSelected ] = useState({})
  // state variable for storing the selected reviewer for each file
  const[ selectedReviewer, setSelectedReviewer ] = useState({})
  // state variable for current step
  const [ activeStep, setActiveStep] = useState(0);
  // state variable for the selected 
  const [ selected, setSelected ] = useState({"batchname" : "", "description" : ""})
  // state variable for storing the files selected for table in step 3
  const [ , setFilteredFiles ] = useState([])
  // state variable showing error status
  const [ error, setError ] = useState()

  const {selectedproject} = useContext(ProjectContext)

  // set steps for creating the review batch
  const steps = [ "Create Review Batch", "Select Audio Files", "Assign Files to Reviewers" ]


   // load select checkboxes for files
   useEffect (() => {
    // Initiate select all                        
    if (files) { 
      
      // Initiate select as false
      var updateSelected = files.reduce((acc, row) => 
      { return {...acc, [row.Id]  : false} }, {})
    
      setSelectedFiles(updateSelected)

    }
  }, [files])

  //load selectcheckboxes for batches
  useEffect (() => {

    if (batches) { 
        // Initiate select as false
        var updateAllSelected = batches.reduce((acc, row) => 
        { return {...acc, [row.Id]  : false}}, {})
      
        setAllSelected(updateAllSelected) 

    }
    
    }, [batches])
 
  // handle next for steps
  const handleNext = () => {

    if (selected.batchname && selected.description) {

      setError()
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

    }

    else {

      setError("Batch Name and Batch Description is required. ")
    }

    // if last step then submit
    if (activeStep === steps.length -1 )
    {
      handleSubmit();
    }
  };

  // handle back step
  const handleBack = () => {

    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    // if last step then submit
    if (activeStep === steps.length -1 )
    {
      handleSubmit();
    }
  };

  // reset steps
  const handleReset = () => {
    setActiveStep(0);
  };

  // submit to create reviewbatch
  const handleSubmit = () => {

        const properties = Object.entries(selectedReviewer).map(([key,value]) => {
          return ({"Id" : key, "Assignee": value})
        
        })

        // set batch data
        // Create an object of formData
        const formData = new FormData();
        formData.append("batch_name", selected.batchname)
        formData.append("batch_desc",  selected.description)
        formData.append("created_by",  authenticationService.currentUserValue.id)
        formData.append("status", 3) 
        formData.append("files", JSON.stringify(properties))     


        // Send BatchData object
        axios.post(`${process.env.REACT_APP_API_URL}/ReviewBatch/Create/${selectedproject.Id}`, formData, { 
          headers: authHeader()
          })
        .then(function (items) {
          fetchReviewBatch()
          fetchReviewFiles()
        })
  
  }

  const handleChange = event => {
    const name = event.target.id;
    const newValue = event.target.value;
    setSelected({...selected, [name]: newValue })
  
  };


  // handle selection for checkboxes
  const handleSelect = (idx) => {
    // update the selected to toggle the checkbox
    setSelectedFiles({...selectedFiles, [idx] : !selectedFiles[idx]});

    setFilteredFiles(files.filter((rows) => {return {...selectedFiles, [idx] : !selectedFiles[idx]}[rows.Id]}))

    var updatedSelectedReviewer = files.filter((rows) => {return {...selectedFiles, [idx] : !selectedFiles[idx]}[rows.Id]})
      .reduce((acc, row) => { return {...acc, [row.Id]  : 0}}, {})
    
    setSelectedReviewer(updatedSelectedReviewer);
  }

  // handle selection for select all checkboxes
  const handleSelectAll = (idx) => {

    // get current toggle status
    const check = Allselected[idx]

    // update it as toggled
    setAllSelected({...Allselected, [idx] : !check});
    
    // for all files
    var updateSelected = files.reduce((acc, file) => {   
      
      // check if part of the batch, and update the toggle status 
      if (file.UploadBatchId === idx) {
        acc = {...acc,  [file.Id] : !check }
      }
      return acc
    }, {})
    
    // set files that are selected
    setSelectedFiles({...selectedFiles, ...updateSelected});

    setFilteredFiles(files.filter((rows) => { return {...selectedFiles, ...updateSelected}[rows.Id]}))

    var updatedSelectedReviewer = files.filter((rows) => {return {...selectedFiles, ...updateSelected}[rows.Id]})
      .reduce((acc, row) => { return {...acc, [row.Id]  : 0}}, {})
    
    setSelectedReviewer(updatedSelectedReviewer);
    
  }



   
    


    
    return (
      <ThemeProvider theme={createTheme()} >
        <Grid container  direction="row" justifyContent="center" marginTop={5} >
          <Box sx={{ width: '80%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Review Batch Created
          </Typography>
          <Button onClick={handleReset}>Create another review batch</Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Create Review Batch' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
      {error &&
        <div className={'alert alert-danger'}>{error}</div>
                }

            {(activeStep === 0 &&
                  <Step1 selected={selected} handleChange={handleChange}/>
                )
              || (activeStep === 1 &&
                  <Step2 selectedFiles={selectedFiles} Allselected={Allselected} handleSelect={handleSelect} handleSelectAll={handleSelectAll} />
                  )
              ||
                  <> 
                  </>
              }

     

        
   
    </Box>
  </Grid>
      </ThemeProvider>
    );
  }




