import React, { useContext } from 'react';

// Modal Component
import Modal from '../../SharedComponents/Modal';

// Material UI Elements
import { FindInPage, RecordVoiceOver } from '@mui/icons-material';
import { Tooltip, Button } from '@mui/material';
// for calculating the time and seconds
import moment from "moment"

// for authentication
import { authHeader }  from '../../../Services/_helpers/auth-header';

//Contexts
import StatusContext from '../../../../contexts/StatusContext';
import FileContext from '../../../../contexts/FileContext';
import ProjectContext from '../../../../contexts/ProjectContext';


export default function CustomToolBar(props) {
// state variable for status bar (setting the status and setting open)
const {setStatus, setOpen } = useContext(StatusContext)
// context data for files and batches and its corresponding fetch functions
const {fetchFiles, fetchBatches } = useContext(FileContext)
// context data for project
const {selectedproject} = useContext(ProjectContext)

const selected = props.selected;
const displayData = props.displayData;
const setSelected = props.setSelected;

  // calculate audio length and return string  
  const AudioLength = () => {
    // add the time of selected files
    const a = moment.duration(
      
      selected.reduce((acc, rows) => { 
      return acc+moment.duration(displayData[rows].AudioLength)}, 
      moment.duration(0)))
  // return the time string in m and s format
  return( a.minutes()+"m "+a.seconds()+"s ")
}


// calculate price for audio transcribtion
const AudioPrice = () => {
  // add the time of selected files
  const a = moment.duration(
    
    selected.reduce((acc, rows) => { 
    return acc+moment.duration(displayData[rows].AudioLength)}, 
    moment.duration(0)))
// return the string of cost
return("$ " + a*0.001*0.15)
}
  

  // handle the audio transcribtion
  const Transcribe = async () => {
    
    // open status dialog
    setOpen(true);
    // set status
    setStatus("Processing of files has started and will run in the background.  A notification will appear when detection has been completed.")
    
    // for each selected file
    selected.forEach((row) => {
  
          // Create an object of formData
          const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', ...authHeader()}
          };

          // Send Audio to be transcribed
          fetch(`${process.env.REACT_APP_API_URL}/File/Azure/Transcribe/${selectedproject.Id}/${displayData[row].Id}`, requestOptions )
          .then((response) => {

          // if success
          if (response.statusText === 'OK') {

              // get file name
              const filename = displayData[row].Filename_original

              // refresh batches and files
              fetchBatches(false);
              fetchFiles(false);
              setSelected([])

              // set open status
              setOpen(true);
              // set status 
              setStatus("Audio transcribed for: " + filename )
          } else {

            // get file name
            const filename = displayData[row].Filename_original
            // refresh batches and files
            fetchBatches(false);
            fetchFiles(false);
            setSelected([])
            // set open status
            setOpen(true);
            // set status 
            setStatus("Error in transcribing audio file : " + filename )

          }
        }).catch((error) => {
          console.info(error)
      })
    })
  }


  // handle the language detection
  const DetectLanguage = async () => {
    
    // open status dialog
    setOpen(true);
    // set the status
    setStatus("Detecting audio language... A notification will be prompted when detection has been completed.")
    
    // for each selected file
    selected.forEach((row) => {


        
          // Create an object of formData
          const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', ...authHeader()}
          };
          

        fetch(`${process.env.REACT_APP_API_URL}/File/Azure/DetectLanguage/${selectedproject.Id}/${displayData[row].Id}`, requestOptions)
        .then(() => {

          // get filename
          const filename = displayData[row].Filename_original

          // refresh batches and files
          fetchBatches(false);
          fetchFiles(false);
          

          // open status dialog
          setOpen(true);
          // update status
          setStatus("Audio language detected for: " + filename )
        }).catch((error) => {
          console.info(error)
      })

    })

  }
  


    return (
    <div style={{marginRight: "25px"}}>                    
      <Modal 
      Button = {() => { return (
      <Tooltip title="Detect Language" >
        <Button style={{minWidth: 0, borderRadius: "50%", padding: "8px"}}>
          <FindInPage style={{color: "rgba(0, 0, 0, 0.54)"}}/>
        </Button>
      </Tooltip>)}}
      titlename = {"Detect Audio Language"} 
      idx = {1}
      handleSubmit = {DetectLanguage}
      clearInput = {() => {}} >

        Do you want to pass the selected audio files for language detection?
        <br/>
        <p>No. of Audio selected: {selected.length} </p>
        <p>Length of audio: {AudioLength()} </p>
        <p>Estimated price: {AudioPrice()} </p>
      </Modal>
      <Modal 
          Button = {() => { return (
            <Tooltip title="Transcribe Audio" >
              <Button style={{minWidth: 0, borderRadius: "50%", padding: "8px"}}>
                <RecordVoiceOver style={{color: "rgba(0, 0, 0, 0.54)"}}/>
              </Button>
            </Tooltip>)}}
          titlename = {"Transcribe Audio"} 
          idx = {1}
          handleSubmit = {Transcribe}
          clearInput = {() => {}} >
        
        Do you want to transcribe all selected audio files?
        <br/>
        <p>No. of Audio selected: {selected.length} </p>
        <p>Length of audio: {AudioLength()} </p>
        <p>Estimated price: {AudioPrice()} </p>
      </Modal>
    </div>

    );

}

