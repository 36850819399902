import React  from 'react';
import { Container, Navbar, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

// Files
import TermsOfUse from '../../Files/Terms_of_Use.pdf'
import PrivacyStatement from '../../Files/Privacy_Statement.pdf'

export default function Footer() {
    const currentYear = new Date().getFullYear()

    return (
        <footer>
            <Navbar className="navbar-expand-sm navbar-light" style={{backgroundColor:"#464646", marginTop:"50px"}}>
            <Container fluid>  
                <div className="row align-items-start">
                    <ul className="navbar-nav flex-grow">
                    <NavItem>
                        <NavLink className="footer-nav-link" href={TermsOfUse} target="_blank" rel="noreferrer">Terms of Use</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="footer-nav-link" href={PrivacyStatement} target="_blank" rel="noreferrer">Privacy Statement</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="footer-nav-link" to="/ContactUs">Contact Us</NavLink>
                    </NavItem>
                    </ul>
                </div>
            </Container>
          </Navbar>
          <div style={{color: "white", backgroundColor:"#464646", fontSize: "80%", paddingLeft: "8px"}}>
            <span>
                © {currentYear} PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a style={{color: "white"}} href="https://www.pwc.com/structure" target="_blank" rel="noreferrer">www.pwc.com/structure</a> for further details.
            </span>
            <div>&nbsp;</div>
          </div>
        </footer>
      );

    // return (
    //     <footer class="border-top footer" style={{position: "absolute", bottom:"0px", height:"50px"}}>
    //         <div class="container-fluid" style={{ backgroundColor:"#DEDEDE", color:"white", height: "50px",  width:"100vw"}}>
    //             <a style={{color: "white"}} href="/dev/Resources/Terms of Business_v1.pdf">Terms of use </a>
    //             | <a style={{color: "white"}} href="/dev/Resources/Privacy Statement_v1.pdf">Privacy Statement </a>
    //             | <a style={{color: "white"}} href="/dev/Resources/Disclaimers_v1.pdf">Disclaimers </a>
    //             | <a style={{color: "white"}} href="">Cookies </a> |
    //             © 2022 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a style={{color: "white"}} href="https://www.pwc.com/structure">www.pwc.com/structure</a> for further details.
    //         </div>
    //     </footer>
    // )
}