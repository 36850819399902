import React, {useState} from 'react';
import { Snackbar, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const StatusContext = React.createContext()

export default StatusContext

export const StatusContextProvider = ({ children }) => {
    const [ Status, setStatus ] = useState("")
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

      
    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
     


    return (
        <StatusContext.Provider value = {{
            Status,
            setStatus,
            open,
            setOpen
        }}>
            <div>
                <Snackbar
                open={open}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={6000}
                onClose={handleClose}
                message={Status}
                action={action}
                />
            </div>

            

            {children}
            </StatusContext.Provider>
    )
}