// this page is used to manage the files that are transcribed and to be reviewed by next reviewer

// React Component
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// Material UI Elements
import { Tabs, Tab, Grid, Box } from '@mui/material';

//Components
import FileTable from './components/FileTable';
import  useFetchApp  from '../../../contexts/services/useFetchApp';
import { authenticationService } from '../../Services/_services/authenticationService';
import ProjectContext from '../../../contexts/ProjectContext';
import useFetch from '../../../contexts/services/useFetch';
import ReviewFileContext from '../../../contexts/ReviewFileContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function FileManagement() {
  // state variable for tab
  const [value, setValue] = React.useState(0);
  // context data for project
  const { selectedproject } = useContext(ProjectContext)
  const { fetchRawFiles } = useContext(ReviewFileContext)

  const [reviewers, setReviewers] = useState([])
  const [workflowStep, setWorkflowStep] = useState([])
  const [uploadBatch, setUploadBatch] = useState([])

  // fetch the reviewers from api
  const { data: reviewersData, isPending: reviewersDataIsLoading } = useFetchApp(`${process.env.REACT_APP_API_URL}/User/Reviewers/Organisation/${authenticationService.currentUserValue.organisationId}/Project/${selectedproject.Id}`, authenticationService.currentUserValue? true : false, "Reviewer")
  // Fetch workflow step from API
  const { data: workflowStepData, isPending: workflowStepDataIsLoading } = useFetch(`${process.env.REACT_APP_API_URL}/WorkflowStep/${selectedproject.Id}`,  authenticationService.currentUserValue, "Workflow step")  
  const { data: uploadBatchData, isPending: uploadBatchIsLoading } = useFetch(`${process.env.REACT_APP_API_URL}/UploadBatch/${selectedproject.Id}`, authenticationService.currentUserValue, "Upload batch")

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!reviewersDataIsLoading && !workflowStepDataIsLoading && !uploadBatchIsLoading) {
      setReviewers(reviewersData)
      setWorkflowStep(workflowStepData)
      setUploadBatch(uploadBatchData)
      fetchRawFiles()
    }
  }, [reviewersDataIsLoading, workflowStepDataIsLoading, uploadBatchIsLoading])

  return (
      <Grid container  direction="row" justifyContent="center">
        {!reviewersDataIsLoading && !workflowStepDataIsLoading && 
          <Box elevation = {0} sx={{ width: '95%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="New" {...a11yProps(0)} />
                <Tab label="Assigned" {...a11yProps(1)} />
                <Tab label="Reviewed" {...a11yProps(2)} />
                <Tab label="Finalised" {...a11yProps(3)} />
                <Tab label="Cancelled" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid container  direction="row" justifyContent="center">
                  <Box elevation = {0} sx={{ width: '100%' }}>
                    <FileTable 
                      workflowStatusId="1" 
                      reviewersData={reviewers}
                      workflowStepData={workflowStep}
                      uploadBatchData={uploadBatch}/>
                  </Box>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container  direction="row" justifyContent="center">
                  <Box elevation = {0} sx={{ width: '100%' }}>
                    <FileTable 
                      workflowStatusId="2"
                      reviewersData={reviewers}
                      workflowStepData={workflowStep}
                      uploadBatchData={uploadBatch}/>
                  </Box>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container  direction="row" justifyContent="center">
                  <Box elevation = {0} sx={{ width: '100%' }}>
                    <FileTable 
                      workflowStatusId="3"
                      reviewersData={reviewers}
                      workflowStepData={workflowStep}
                      uploadBatchData={uploadBatch}/>
                  </Box>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid container  direction="row" justifyContent="center">
                  <Box elevation = {0} sx={{ width: '100%' }}>
                    <FileTable 
                      workflowStatusId="4"
                      reviewersData={reviewers}
                      workflowStepData={workflowStep}
                      uploadBatchData={uploadBatch}/>
                  </Box>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Grid container  direction="row" justifyContent="center">
                  <Box elevation = {0} sx={{ width: '100%' }}>
                    <FileTable 
                      workflowStatusId="5"
                      reviewersData={reviewers}
                      workflowStepData={workflowStep}
                      uploadBatchData={uploadBatch}/>
                  </Box>
              </Grid>
            </TabPanel>
          </Box>
        }
      </Grid>
    );
  };


