import React, { useContext, useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper }  from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import moment from 'moment';

import useFetchApp from '../../../../contexts/services/useFetchApp';
import { authenticationService } from '../../../Services/_services/authenticationService';

// Contexts
import ReviewContext from '../../../../contexts/ReviewContext';

const theme = createTheme({
  components: {
    // Name of the component
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          padding: '3pt',
          fontSize: '0.8rem'
        },
      },
    },
  },
});

export default function CenteredGrid() {
  // API data pull
  // 1. Users (as a reviewers data list)
  const { data: reviewersData, isPending: reviewersDataIsLoading } = useFetchApp(`${process.env.REACT_APP_API_URL}/User/`, authenticationService.currentUserValue? true : false, "User")
  
  // Review context
  // 1. Review batch selector data
  // 2. Selected review batch index
  // 3. Selected review file index
  // 4. Review batch files queue
  // 5. Selected review outcome master
  const { 
    reviewBatchSelectorData, 
    selectedReviewBatchIndex, 
    selectedReviewFileIndex, 
    reviewBatchFilesQueue, 
    selectedReviewOutcomeMaster 
  } = useContext(ReviewContext)

  // React hooks
  // 1. Meta data
  const [ displayData, setDisplayData ] = useState({})
  // 2. Open metadata tab
  const [ open, setOpen ] = useState(false)

  useEffect(() => {
      // When Review batch files queue, Selected review file index, Review batch selector data, Selected review batch index, Reviewers data, and Selected review outcome master are loaded
      // Set display data state
      if (reviewBatchFilesQueue && reviewBatchFilesQueue.length > 0) {
        if (selectedReviewFileIndex > -1) {
          // Parsed audio length
          const audioLength = moment.duration(reviewBatchFilesQueue[selectedReviewFileIndex].AudioLength)
          const pad = (input) => { if(input <= 9) {return(`0${input}`)}else{return(`${input}`)}}
          const parsedAudioLength = `${pad(audioLength.hours())}:${pad(audioLength.minutes())}:${pad(audioLength.seconds())}`

          // Find assignee/reviewer full name
          let assigneeFullName = ""
          if (reviewersData && reviewersData.length > 0 && selectedReviewOutcomeMaster && Object.keys(selectedReviewOutcomeMaster).length > 0) {
            const findAssignee = reviewersData.find(reviewer => reviewer.Id === parseInt(selectedReviewOutcomeMaster.LastReviewer))
            assigneeFullName = findAssignee.FullName
          }

          // Parse language code into proper language name
          let fileLanguage = ""
          switch (reviewBatchFilesQueue[selectedReviewFileIndex].Language) {
            case 'en-us':
              fileLanguage = "English"
              break
            case 'zh-hk':
              fileLanguage = "Cantonese"
              break
            case 'zh-cn':
              fileLanguage = "Mandarin"
              break
            case 'ja-jp':
              fileLanguage = "Japanese"
              break
            default:
              fileLanguage = ""
          }

          const metadata = {
            "Upload Batch Name": reviewBatchFilesQueue[selectedReviewFileIndex].UploadBatch.Description,
            "Workflow Status": reviewBatchFilesQueue[selectedReviewFileIndex].WorkFlowStatus.LongDescription,
            "Workflow Step": reviewBatchFilesQueue[selectedReviewFileIndex].WorkflowStep.LongDescription,
            "File Name" : reviewBatchFilesQueue[selectedReviewFileIndex].Filename_original,
            "Duration" : parsedAudioLength,
            "Language" : fileLanguage,
            "Last Reviewer" : assigneeFullName,
            "Last Review Time": moment(selectedReviewOutcomeMaster.LastReviewTime).format('MMMM Do YYYY, h:mm:ss A'),
          }

          setDisplayData(metadata)
        }
      }
  }, [reviewBatchFilesQueue, selectedReviewFileIndex, reviewBatchSelectorData, selectedReviewBatchIndex, reviewersData, selectedReviewOutcomeMaster])

  return (
    <ThemeProvider theme={theme}>
      {
        reviewBatchFilesQueue.length > 0 &&
          <Paper sx={{padding: 2,  marginTop: "20px", marginBottom: "20px", textAlign: 'center'}} elevation={4}>
            <div style={{ textAlign: 'center', height: "25px", cursor:"pointer"}} onClick={() => {setOpen(!open)}}>
              <h5 style={{display: "inline-block"}}>File Metadata</h5>
              {
                  open && 
                  <h5 style={{display: "inline-block", float: "right"}}><KeyboardArrowUp/> </h5>
              }
              {
                  !open && 
                  <h5 style={{display: "inline-block", float: "right"}}><KeyboardArrowDown/> </h5>
              }
            </div>  
            {
              open && Object.keys(displayData).length > 0 && 
                <TableContainer sx={{margin: "2px"}}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Metadata</TableCell>
                        <TableCell align="left">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        Object.entries(displayData).map(([key,value]) => {
                          return (
                            <TableRow key={key.replace(/\s+/g, '')}>
                              <TableCell align="left" size='medium'>{key}</TableCell>
                              <TableCell align="left" size='medium'>{value}</TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
            }
        </Paper>
      }
    </ThemeProvider>
  );
}

