import React, { useContext, useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import ReviewFileContext from '../../../contexts/ReviewFileContext';
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import moment from 'moment';


export default function MyReviewQueue(props) {
  // Review file context:
  // 1. Review batch queue
  // 2. Review files progress display queue
  // 3. Set redirect selected review batch ID (React hooks)
  // 4. Set redirect selected review file ID (React hooks)
  // 5. Complete review batch (Function)
  // 6. Fetch review outcome tags (API data pull)
  // 7. Fetch review files (API data pull)
  // 8. Fetch raw files (API data pull)
  const { 
    reviewBatchQueue, 
    reviewFilesProgressDisplayQueue,
    setRedirectSelectedReviewBatchId, 
    setRedirectSelectedReviewFileId,
    completeReviewBatch,
    fetchReviewOutcomeTags,
    fetchReviewFiles, 
    fetchRawFiles
  } = useContext(ReviewFileContext)

  // React hooks
  // 1. Row expand status list
  const [expand, setExpand ] = useState([])
  // 2. Review batch queue list
  const [ reviewBatchQueueData, setReviewBatchQueueData] = useState([])
  // 3. Review files queue list
  const [ reviewFilesQueueData, setReviewFilesQueueData] = useState([])

  // theme overrides
  const myTheme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: '5pt',
          },
        },
      },
    }
  });

  // Fetch raw and review files in the beginning
  useEffect(() => {
    fetchRawFiles()
    fetchReviewFiles()
  }, [])
  
  useEffect(() => {
    // When review batch queue context data is available, set review batch queue data state
    if (reviewBatchQueue) {
      if (reviewBatchQueue.length > 0) {
        setReviewBatchQueueData(reviewBatchQueue)
      } else {
        setReviewBatchQueueData([])
      }
    }
  }, [reviewBatchQueue])

  useEffect(() => {
    // When review files progress display queue context data is available, set review files queue data state
    if (reviewFilesProgressDisplayQueue && reviewFilesProgressDisplayQueue.length > 0) {
      setReviewFilesQueueData(reviewFilesProgressDisplayQueue)
    }
  }, [reviewFilesProgressDisplayQueue])

  // Convert audio length into desired format
  const formatAudioLength = (input) => 
  {
    const audioLength = moment.duration(input)
    const pad = (input) => { if(input <= 9) {return(`0${input}`)}else{return(`${input}`)}}

    return `${pad(audioLength.hours())}:${pad(audioLength.minutes())}:${pad(audioLength.seconds())}`
  }

  // Convert language codename into proper English
  const languageLabel = (value) => {
    switch (value) {
      case "en-us" : {
        return "English"
      }
      case "zh-hk" : {
        return "Cantonese"
      }
      case "zh-cn" : {
        return "Mandarin"
      }
      case "ja-jp" : {
        return "Japanese"
      }
      default : {
        return value
      }
    }
  }

  // Convert boolean into English status
  const completionStatus = (value) => {
    let status = null

    switch (value) {
      case true : {
        status = (
          <strong style={{ color: "#299d8f"}}>Completed</strong>
        )
        break
      }
      case false : {
        status = (
          <strong style={{color: "#e72b34"}}>Incomplete</strong>
        )
        break
      }
      default : {
        status = (
          <p>Unknown</p>
        )
      }
    }

    return status
  }

  // Event handler: Open selected review batch file and redirect to review page
  const openReviewBatchFile = (row) => {
    const fileId = row.Id
    const reviewBatchId = row.ReviewBatchId

    fetchReviewOutcomeTags()
    setRedirectSelectedReviewBatchId(reviewBatchId)
    setRedirectSelectedReviewFileId(fileId)
  }

  // Review queue table header
  const headers = [
    {
      name: "Id",
      label: "Review Batch ID",
      options : {
        filterType: 'textField' 
      }
    },
    {
      name: "BatchName",
      label: "Batch name",
      options : {
        filterType: 'textField' 
      }
    },
    {
      name: "WorkflowStep",
      label: "Workflow Step",
      options: {
        filterType: "textField",
        customBodyRender: (value) => {
          return value.LongDescription
        }
      }
    },
    {
      name: "CreationDate",
      label: "Creation date",
      options: {
        filterType: "textField",
        customBodyRender: (value) => {
          if (value !== "-" && value !== "") {
            return moment(value).format('MMMM Do YYYY, h:mm:ss A')
          } else {
            return "-"
          }
        }
      }
    },
    {
      name: "Id",
      label: "Status",
      options: {
        filterType: "textField",
        customBodyRender: (value) => {
          const totalReviewFiles = reviewFilesQueueData.filter(file => file.ReviewBatchId === value)

          // Find total incomplete files in every review batch
          const completedFiles = totalReviewFiles.reduce((totalFiles, reviewFile) => {
            if (reviewFile.Completed) {
              return totalFiles + 1
            } else {
              return totalFiles
            }
          }, 0)
          
          return completedFiles + " out " + totalReviewFiles.length + " completed"
        }
      }
    },
    {
      name: "Id",
      label: "",
      options: {
        filter: false,
        customHeadLabelRender: () => {
          return ""
        },
        customBodyRender: (value) => {
          let isAvailableForCompletion = false
          const totalReviewFiles = reviewFilesQueueData.filter(file => file.ReviewBatchId === value)

          const completedFiles = totalReviewFiles.reduce((totalFiles, reviewFile) => {
            if (reviewFile.Completed) {
              return totalFiles + 1
            } else {
              return totalFiles
            }
          }, 0)

          if (completedFiles > 0 && totalReviewFiles.length > 0 && completedFiles === totalReviewFiles.length) {
            isAvailableForCompletion = true
          }

          return (
            <div style={{textAlign: "right"}}>
              {
                isAvailableForCompletion &&
                <button className="btn btn-primary" onClick={() => completeReviewBatch(value)}>Complete batch</button>
              }
            </div>
          )
        }
      }
    }
  ]

  return (
    <ThemeProvider theme={myTheme}>
      <div style={{textAlign: "center"}}>
        <strong>
          My review queue
        </strong>
      </div>
      <br/>
      {
        reviewBatchQueueData && reviewBatchQueueData.length > 0 &&
        <MUIDataTable
          data={reviewBatchQueueData}
          columns={headers}
          options={{
            selectableRows: "none",
            print: false,
            search: true,
            download: false,
            filter: true,
            viewColumns: false,
            rowsExpanded: expand,
            expandableRows: true,
            expandableRowsHeader: true,
            expandableRowsOnClick: false,
            rowsPerPage: 50,
            rowsPerPageOptions: [50, 100, 200],
            customToolbarSelect: () => {},
            onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) => {
              setExpand(allRowsExpanded.map((rows) => rows.index))
            },
            renderExpandableRow: (rowData, rowMeta) => {
              const colSpan = rowData.length + 1;
              return (
                <TableRow>
                  <TableCell colSpan={colSpan}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            File Name
                          </TableCell>
                          <TableCell>
                            Duration
                          </TableCell>
                          <TableCell>
                            Language
                          </TableCell>
                          <TableCell>
                            Status
                          </TableCell>
                          <TableCell>
                            Audio preview
                          </TableCell>
                          <TableCell>

                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          reviewFilesQueueData.filter(reviewFile => reviewFile.ReviewBatchId === rowData[0]).map((row, index) => (
                            <TableRow key={row.Id}>
                              <TableCell>
                                {row.Filename_project}
                              </TableCell>
                              <TableCell>
                                {formatAudioLength(row.AudioLength)}
                              </TableCell>
                              <TableCell>
                                {languageLabel(row.Language)}
                              </TableCell>
                              <TableCell>
                                {completionStatus(row.Completed)}
                              </TableCell>
                              <TableCell>
                                {<audio style={{height:35}} src={row.URL} controls="controls" />}
                              </TableCell>
                              <TableCell style={{textAlign: "right"}}>
                                {
                                  !row.Completed &&
                                  <NavLink className="btn btn-secondary" onClick={() => {openReviewBatchFile(row)}} to="/Review"> Open {row.Filename_project} </NavLink>
                                }
                                {
                                  row.Completed &&
                                  <NavLink className="btn btn-success" onClick={() => {openReviewBatchFile(row)}} to="/Review"> {row.Filename_project} completed review</NavLink>
                                }
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              )
            }
          }}
        />
      }
      {
        reviewBatchQueueData && reviewBatchQueueData.length === 0 &&
        <div style={{textAlign: 'center'}}>No assigned review batch</div>
      }
    </ThemeProvider>
  )

}


