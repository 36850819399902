import React, { useContext, useState, useEffect } from 'react';

// Material UI Elements
import { MenuItem, Select, Table, TableHead, TableBody, TableRow, TableCell, Menu } from '@mui/material';
import { KeyboardArrowDown} from '@mui/icons-material';
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles"

import icon_delete from '../../../Images/PwC_Funct_Icons_Delete_Fill_Grey_RGB.svg';
import icon_detect from '../../../Images/PwC_Funct_Icons_Talent_Fill_Grey_RGB.svg';
import icon_transcribe from '../../../Images/PwC_Funct_Icons_AccountConvert_Fill_Grey_RGB.svg';

//Services
import axios from 'axios';
// for calculating the time and seconds
import moment from "moment"

// for authentication
import { authHeader }  from '../../../Services/_helpers/auth-header';

//Contexts
import FileContext from '../../../../contexts/FileContext';
import ProjectContext from '../../../../contexts/ProjectContext';
import StatusContext from '../../../../contexts/StatusContext';

import { addLog } from "../../../../features/activityLogs/activityLogsSlice"
import { useDispatch } from 'react-redux'

export default function BatchTable() {
  // context data for files and batches and its corresponding fetch functions
  const {files, batches, fetchFiles, fetchBatches, summary } = useContext(FileContext)
  // state variable for what rows are expanded
  const [expand, setExpand ] = useState([])
  // state variable for what files are selected
  const[ , setSelected ] = useState({})
  // state variable for what batches are selected
  const[ , setAllSelected ] = useState({})
  // set anchor point for menu
  const [anchorEl, setAnchorEl] = useState(null);
  // state variable for status bar (setting the status and setting open)
  const {setStatus, setOpen } = useContext(StatusContext)

  const {selectedproject} = useContext(ProjectContext)

  const [ uploadBatchActionMenu, setUploadBatchActionMenu ] = useState([])
  
  const open = anchorEl? {[anchorEl.id] : true} : false;

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay)) 

  const dispatch = useDispatch()

  const [ currentFilesStatus, setCurrentFilesStatus ] = useState([])

  const handleClick = (event, dataIndex) => {
    const updateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
      if (uploadBatch.actionMenuId === dataIndex) {
        return {
            ...uploadBatch,
            menuActionElement: event.currentTarget
        }
      } else {
        return {...uploadBatch}
      }
    })

    setUploadBatchActionMenu(updateUploadBatchActionMenu)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (dataIndex) => {
    const updateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
      if (uploadBatch.actionMenuId === dataIndex) {
        return {
            ...uploadBatch,
            isActionMenuDisabled: false
        }
      } else {
        return {...uploadBatch}
      }
    })

    setUploadBatchActionMenu(updateUploadBatchActionMenu)
    setAnchorEl(null);
  };

  // load select checkboxes for files
  useEffect (() => {
    // Initiate select all                        
    if (files) { 
  
      // Initiate select as false
      var updateSelected = files.reduce((acc, row) => 
      { return {...acc, [row.Id]  : false} }, {})
    
      setSelected(updateSelected)

      setCurrentFilesStatus(files.reduce((prevFile, currFile, index) => {
        let shortDescription = currFile.UploadStatus.ShortDescription

        if (currFile.AzureTranscriptionId != null && currFile.UploadStatusId === 2) {
          shortDescription = "Under transcription"
        }
        
        return {
          ...prevFile,
          [currFile.Id]: {
            "status": shortDescription,
            "isLanguageSelectionEnabled": true
          }
        }
      }, {}))

    }
  }, [files])

  //load selectcheckboxes for batches
  useEffect (() => {
    if (batches) { 
        // Initiate select as false
        if (summary) {
          batches.forEach((batch, index) => {
            // Set total duration
            const audioLength = summary[index].AudioLength
            const pad = (input) => { if(input <= 9) {return(`0${input}`)}else{return(`${input}`)}}
            batch.Duration = `${pad(audioLength.hours())}:${pad(audioLength.minutes())}:${pad(audioLength.seconds())}`
           
            // Set total file count
            batch.Files = summary[index].TotalCount
          })
        }

        

        var updateAllSelected = batches.reduce((acc, row) => 
        { 
          return {
            ...acc, 
            [row.Id]  : false
          }
        }, {})
      
        setAllSelected(updateAllSelected) 

        var updateUploadBatchActionMenu = batches.map((batch, batchIndex) => {
          let isTranscriptionCompleted = true
          if (files && files.length > 0) {
            const filtered_files = files.filter(file => {
              return file.AzureTranscriptionId != null && file.UploadBatchId === batch.Id && file.UploadStatusId === 2
            })

            if (filtered_files.length > 0) {
              isTranscriptionCompleted = false
            }
          }
          return {
            "actionMenuId": batchIndex,
            "isActionMenuDisabled": false,
            "isActionMenuOpen": false,
            'isTranscriptionCompleted': isTranscriptionCompleted,
            "actionMenuMessage": "",
            "menuActionElement": null
          }
        })

        setUploadBatchActionMenu(updateUploadBatchActionMenu)
    }
    
  }, [batches])

  const updateStatus = async (idx, actionMenuMessage, isActionMenuDisabled) => {
    const updateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
      if (uploadBatch.actionMenuId === idx) {
        return {
            ...uploadBatch,
            isActionMenuDisabled: isActionMenuDisabled,
            actionMenuMessage: actionMenuMessage
        }
      } else {
        return {...uploadBatch}
      }
    })

    setUploadBatchActionMenu(updateUploadBatchActionMenu)

    await sleep(3000)
  }

  // handle changes in languages
  const handleLanguage = (event) => {

    // get the file id to be changed
    const Id =  event.target.name;
    // set data payload
    const properties = { "Language": event.target.value
    }
    
    // initiate api to change language
    axios.put(`${process.env.REACT_APP_API_URL}/File/Update/${selectedproject.Id}/${Id}`, properties, { 
      headers: authHeader()
      })
    .then(() => {
      // Change batch upload status after language change
      axios.put(`${process.env.REACT_APP_API_URL}/UploadBatch/UpdateUploadBatchStatus/${selectedproject.Id}/${Id}`, {}, {
        headers: authHeader()
      })
      .then(() => {
        // refresh batches and files
        fetchBatches(false);
        fetchFiles(false);
      })
      .catch((error) => {
        console.info(error)
      })
    }).catch((error) => {
      console.info(error)
  })
  }

   //////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////// Delete Batch Button Push /////////////////////////
   //////////////////////////////////////////////////////////////////////////////////

  const DeleteBatch = async (idx) => 
  {
     const uploadbatch = batches[idx].Id
     const uploadBatchName = batches[idx].Description

     setAnchorEl(null)

     // open status dialog
     setOpen(true);
     // set status
     setStatus("Flagging files for deletion.  A notification will appear when detection has been completed.")
    
     // Start of file loop (for each file loop)
     files.forEach((row) => 
     {

        if (row.UploadBatchId === uploadbatch) 
        {
  
          // Create an object of formData
          const requestOptions = 
          {
            method: 'POST',
            headers: {'Content-Type': 'application/json', ...authHeader()}
          };

          // Send file to be deleted
          fetch(`${process.env.REACT_APP_API_URL}/File/DeleteUploadedFilePreReview/${selectedproject.Id}/${row.Id}`, requestOptions )
          .then((response) => 
           {
              // if success
              if (response.statusText === 'OK') 
              {
                // get file name
                const filename = row.Filename_original

                // refresh batches and files (currently moved to async at end)
                // fetchBatches();
                // fetchFiles();

                // set open status
                setOpen(true);
                // set status 
                setStatus("File deleted: " + filename )
              } else 
              
              {

                // get file name
                const filename = row.Filename_original
                // refresh batches and files (currently moved to async at end)
                // fetchBatches();
                // fetchFiles();

                // set open status
                setOpen(true);
                // set status 
                setStatus("Error in deleting audio file : " + filename )
              } 
           }).catch((error) => { 
            console.info(error) 
          })
         }  // End of DeleteUploadedFilePreReview API Call
     }) // End of file loop

  
    // After the loop through the batch, this code call the code to update the batch status flag 
          
    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', ...authHeader()}
    };
  
  
  fetch(`${process.env.REACT_APP_API_URL}/UploadBatch/UpdateUploadBatchStatus/${selectedproject.Id}/${uploadbatch}`, requestOptions)
      .then(() => {
        // open status dialog
        setOpen(true);
        // update status
        setStatus("Batch deletion process completed")

        }).catch((error) => {
          console.info(error)
      })  

    // Finally refresh batches and files at end to update screen
    fetchBatches(false);
    fetchFiles(false);
  }

   //////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////// End Delete Batch Button //////////////////////////
   //////////////////////////////////////////////////////////////////////////////////




   //////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////// Progress Batch Button Push ///////////////////////
   //////////////////////////////////////////////////////////////////////////////////
  const ProgressBatch = (idx) => 
  {
    const uploadbatch = batches[idx].Id

    setAnchorEl(null)

    // open status dialog
    setOpen(true);
    // set status
    setStatus("Progressing Files to Review")
    const currentDate = new Date()
  
    // for each file
    files.forEach((row) => {

      if (row.UploadBatchId === uploadbatch) {
          // Create an object of formData
          const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', ...authHeader()}
          };

          // Send file to be deleted
          fetch(`${process.env.REACT_APP_API_URL}/File/ProgressToReviewStage/${selectedproject.Id}/${row.Id}`, requestOptions )
          .then((response) => {

          // if success
          if (response.statusText === 'OK') {
              // Create review outcome master POST data
              const postReviewOutcomeMaster = []
              postReviewOutcomeMaster.push({
                "Id": 0,
                "FileId": row.Id,
                "Relevancy": "",
                "Speaker1": "",
                "Speaker2": "",
                "Speaker1Organisation": "",
                "Speaker2Organisation": "",
                "WorkflowStepId": 1,
                "LastReviewBatch": "-",
                "LastReviewer": "-",
                "LastReviewTime": currentDate.toISOString(),
                "created_at": currentDate.toISOString(),
                "updated_at": currentDate.toISOString(),
                "deleted_at": currentDate.toISOString()
              })

              // Create new review outcome master and review outcome tags
              axios.post(`${process.env.REACT_APP_API_URL}/ReviewOutcomeMaster/Create/${selectedproject.Id}`, postReviewOutcomeMaster, { 
                headers: authHeader()
              })
              .then(createReviewOutcomeMasterResponse => {
                if (createReviewOutcomeMasterResponse.status === 200) {
                    // Create new review outcome tags
                    // Create review outcome master POST data
                    const postReviewOutcomeTags = []
                    createReviewOutcomeMasterResponse.data.forEach(reviewOutcomeTags => {
                      postReviewOutcomeTags.push({
                        "FileId": reviewOutcomeTags.FileId,
                        "ReviewOutcomeMasterId": reviewOutcomeTags.Id,
                      })
                    })

                    axios.post(`${process.env.REACT_APP_API_URL}/ReviewOutcomeTags/Generate/${selectedproject.Id}`, postReviewOutcomeTags, { 
                      headers: authHeader()
                    })
                    .then(generateReviewOutcomeTagsResponse => {
                      if (generateReviewOutcomeTagsResponse.status === 200) {
                        // get file name
                        const filename = row.Filename_original

                        // refresh batches and files
                        fetchBatches(false);
                        fetchFiles(false);

                        // set open status
                        setOpen(true);
                        // set status 
                        setStatus("File progressed: " + filename )
                      } else {
                        console.info(generateReviewOutcomeTagsResponse)
                      }
                    })
                    .catch(generateReviewOutcomeTagsError => {
                        console.info(generateReviewOutcomeTagsError)
                    })                    
                } else {
                  console.info(createReviewOutcomeMasterResponse)
                }
              })
              .catch(createReviewOutcomeMasterError => {
                console.info(createReviewOutcomeMasterError)
              })
          } else {

            // get file name
            const filename = row.Filename_original
            // refresh batches and files
            fetchBatches(false);
            fetchFiles(false);
            // set open status
            setOpen(true);
            // set status 
            setStatus("Error in progressing audio file to review : " + filename )

          }
        }).catch((error) => {
          console.info(error)
        })
      }  
    })
  }

   //////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////// End Progress Batch Button ////////////////////////
   //////////////////////////////////////////////////////////////////////////////////


   
   //////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////// Transcribe Batch Button //////////////////////////
   //////////////////////////////////////////////////////////////////////////////////

   const TranscribeUploadBatch = async (idx, isTranscriptionCompleted) => {
      const uploadBatch = batches[idx].Id

      setAnchorEl(null)

      let finishedFiles = 0

      for (const file of files) {
        if (file.UploadBatchId === uploadBatch) {
          let updatedFilesStatus = currentFilesStatus

          if (isTranscriptionCompleted) {
            updatedFilesStatus[file.Id].status = "Transcribing file..."
          } else {
            updatedFilesStatus[file.Id].status = "Checking file..."
          }

          updatedFilesStatus[file.Id].isLanguageSelectionEnabled = false
          
          setCurrentFilesStatus(updatedFilesStatus)
        }
      }

      // Initialise file transcribe to speech service for first time
      if (isTranscriptionCompleted) {
        for (const file of files) {
          if (file.UploadBatchId === uploadBatch) {
            // Send Audio to be transcribed
            let currentDate = new Date()
            let currentTime = currentDate.toLocaleTimeString()

            dispatch(addLog({
              "statusCode": "PROCESSING",
              "status": "Transcription under process",
              "resourceType": file.Filename_project + " [" + file.Filename_original + "]",
              "message": " is under transcription",
              "timestamp": currentTime
            }))

            try {
              const azureTranscribeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/File/Azure/Transcribe/${selectedproject.Id}/${file.Id}`, [], { 
                headers: authHeader()
              })
              .catch(azureTranscribeError => {
                console.info(azureTranscribeError)
              })
              
              if (azureTranscribeResponse) {
                if (azureTranscribeResponse.data) {
                  const transcriptionStatus = azureTranscribeResponse.data.transcription_status
                  const transcriptionMessage = azureTranscribeResponse.data.transcription_message

                  if (transcriptionStatus === "TRANSCRIPTION_COMPLETED") {
                    let updatedFilesStatus = currentFilesStatus
                    updatedFilesStatus[file.Id].status = "Transcribed!"
                    setCurrentFilesStatus(updatedFilesStatus)
                    finishedFiles += 1

                    dispatch(addLog({
                      "statusCode": "TRANSCRIPTION_COMPLETED",
                      "status": "File is transcribed!",
                      "resourceType": file.Filename_project + " [" + file.Filename_original + "]",
                      "message": " is transcribed",
                      "timestamp": currentTime
                    }))
                  } else if (transcriptionStatus === "TRANSCRIBING_FILE") {
                    // console.info(transcriptionMessage)
                  }

                  updateStatus(idx, finishedFiles + " out of " + summary[idx].TotalCount + " file(s) transcribed", true)
                }  
              }
            }
            catch (error) {
                dispatch(addLog({
                  "statusCode": "TRANSCRIPTION_FAILED",
                  "status": "File transcription failed!",
                  "resourceType": file.Filename_project + " [" + file.Filename_original + "]",
                  "message": " is unsuccessfully transcribed",
                  "timestamp": currentTime
                }))
            }
          }
        }
      }
      
      // Check transcription status periodically (in every 3 seconds)
      for (const file of files) {
        if (file.UploadBatchId === uploadBatch) {
          // Send Audio to be transcribed
          let currentDate = new Date()
          let currentTime = currentDate.toLocaleTimeString()

          for (;true;) {
            try {
              const azureTranscribeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/File/Azure/Transcribe/${selectedproject.Id}/${file.Id}`, [], { 
                headers: authHeader()
              })
              .catch(azureTranscribeError => {
                console.info(azureTranscribeError)
              })
              
              if (azureTranscribeResponse) {
                if (azureTranscribeResponse.data) {
                  const transcriptionStatus = azureTranscribeResponse.data.transcription_status
                  const transcriptionMessage = azureTranscribeResponse.data.transcription_message

                  if (transcriptionStatus === "TRANSCRIPTION_COMPLETED") {
                    let updatedFilesStatus = currentFilesStatus
                    updatedFilesStatus[file.Id].status = "Transcribed!"
                    setCurrentFilesStatus(updatedFilesStatus)
                    finishedFiles += 1

                    dispatch(addLog({
                      "statusCode": "TRANSCRIPTION_COMPLETED",
                      "status": "File is transcribed!",
                      "resourceType": file.Filename_project + " [" + file.Filename_original + "]",
                      "message": " is transcribed",
                      "timestamp": currentTime
                    }))

                    updateStatus(idx, finishedFiles + " out of " + summary[idx].TotalCount + " file(s) transcribed", true)

                    break
                    
                  } else if (transcriptionStatus === "TRANSCRIPTION_EXPIRED") {
                    let updatedFilesStatus = currentFilesStatus
                    updatedFilesStatus[file.Id].status = "Transcript expired, please contact admin"
                    setCurrentFilesStatus(updatedFilesStatus)

                    dispatch(addLog({
                      "statusCode": "TRANSCRIPTION_EXPIRED",
                      "status": "Transcript is expired!",
                      "resourceType": file.Filename_project + " [" + file.Filename_original + "]",
                      "message": " is transcript is expired",
                      "timestamp": currentTime
                    }))

                    updateStatus(idx, finishedFiles + " out of " + summary[idx].TotalCount + " file(s) transcribed", false)

                    break
                  } else {
                    // console.info(transcriptionMessage)
                  }
                }  
              }

              await sleep(3000)
            }
            catch (error) {
                dispatch(addLog({
                  "statusCode": "TRANSCRIPTION_FAILED",
                  "status": "File transcription failed!",
                  "resourceType": file.Filename_project + " [" + file.Filename_original + "]",
                  "message": " is unsuccessfully transcribed",
                  "timestamp": currentTime
                }))

                break  
            }
          }
          
        }
      }

      if (summary[idx].TotalCount === finishedFiles) {
        updateStatus(idx, "All files transcribed!", true)
        updateStatus(idx, "", false)

        fetchBatches()
        fetchFiles()
      }
    }

   //////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////// End Transcribe Batch Button //////////////////////
   //////////////////////////////////////////////////////////////////////////////////

   

   //////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////// Detect Audio Language Batch Button ////////////////////////
   //////////////////////////////////////////////////////////////////////////////////

  const DetectAudioBatch = async (idx) =>  //LG 3/11/2022 - changed to async for allowing batch update to occur after.  To do is lock record during processes.
  {
      const uploadbatch = batches[idx].Id
      
      setAnchorEl(null)

      const updateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
        if (uploadBatch.actionMenuId === idx) {
          return {
              ...uploadBatch,
              isActionMenuDisabled: true,
              actionMenuMessage: "Detecting language..."
          }
        } else {
          return {...uploadBatch}
        }
      })
  
      setUploadBatchActionMenu(updateUploadBatchActionMenu)

      for (const file of files) {
        if (file.UploadBatchId === uploadbatch) {
          const filenameProject = file.Filename_project
          try {
            const detectLanguageResponse = await axios.post(`${process.env.REACT_APP_API_URL}/File/Azure/DetectLanguage/${selectedproject.Id}/${file.Id}`, {}, {
              headers: {
                'Content-Type': 'application/json', 
                ...authHeader()
              }
            })
            .catch((error) => {
              let errorMessage = "Language detection error"
              if (parseInt(error.response.status) === 400) {
                errorMessage = error.response.data.detail
              }

              const updateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
                if (uploadBatch.actionMenuId === idx) {
                  return {
                      ...uploadBatch,
                      isActionMenuDisabled: true,
                      actionMenuMessage: filenameProject + ": " + errorMessage
                  }
                } else {
                  return {...uploadBatch}
                }
              })
          
              setUploadBatchActionMenu(updateUploadBatchActionMenu)
            })

            await sleep(3000)

            if (detectLanguageResponse) {
              const updateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
                if (uploadBatch.actionMenuId === idx) {
                  return {
                      ...uploadBatch,
                      isActionMenuDisabled: true,
                      actionMenuMessage: filenameProject + " language is detected! Detecting next file..."
                  }
                } else {
                  return {...uploadBatch}
                }
              })

              setUploadBatchActionMenu(updateUploadBatchActionMenu)

              await sleep(3000)
            }
          } catch (error) {
            const updateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
              if (uploadBatch.actionMenuId === idx) {
                return {
                    ...uploadBatch,
                    isActionMenuDisabled: true,
                    actionMenuMessage: filenameProject + " language detection error"
                }
              } else {
                return {...uploadBatch}
              }
            })
        
            setUploadBatchActionMenu(updateUploadBatchActionMenu)

            await sleep(3000)
          }
        }
      }

      try {
        let updateUploadBatchStatusResponse = await axios.put(`${process.env.REACT_APP_API_URL}/UploadBatch/UpdateUploadBatchStatus/${selectedproject.Id}/${uploadbatch}`, {}, {
          headers: {
            'Content-Type': 'application/json', 
            ...authHeader()
          }
        })

        const finalUpdateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
          if (uploadBatch.actionMenuId === idx) {
            return {
              ...uploadBatch,
              isActionMenuDisabled: true,
              actionMenuMessage: "Refreshing data..."
            };
          } else {
            return { ...uploadBatch };
          }
        });

        setUploadBatchActionMenu(finalUpdateUploadBatchActionMenu)

        await sleep(3000)

        if (updateUploadBatchStatusResponse.status === 200) {
          fetchBatches(false);
          fetchFiles(false);
        }
      } catch (error) {
        const updateUploadBatchActionMenu = uploadBatchActionMenu.map((uploadBatch) => {
          if (uploadBatch.actionMenuId === idx) {
            return {
                ...uploadBatch,
                isActionMenuDisabled: true,
                actionMenuMessage: "Upload batch status update failed"
            }
          } else {
            return {...uploadBatch}
          }
        })
    
        setUploadBatchActionMenu(updateUploadBatchActionMenu)

        await sleep(3000)
      }
  } 
  
   //////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////// End Detect Audio Batch Button ////////////////////
   //////////////////////////////////////////////////////////////////////////////////

  const formatAudioLength = (input) => 
  {

    const audioLength = moment.duration(input)

    const pad = (input) => { if(input <= 9) {return(`0${input}`)}else{return(`${input}`)}}
    return `${pad(audioLength.hours())}:${pad(audioLength.minutes())}:${pad(audioLength.seconds())}`

  }
 
  // theme overrides
  const myTheme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: '5pt'
          },
        },
      }
    },
  });

  // headers for batch table
  const headers = [
    {
      name: "Id",
      label: "Upload Batch Id",
      // disable filter using id
      options : {
        filter: false,
        sortOrder: 'desc'
      }
    },
    {
      name: "Description",
      label: "Description",
        // define filter type as text search
      options : {
        filterType: 'textField',
        customFilterListOptions: {
          render: v => {
            return "Description: " + v
          }
        }
      }
    },
    {
      name: "Duration",
      label: "Duration",
      options: {
        customBodyRender: (duration) => {
          return duration
        },
        filter: true,
        filterType: 'textField',
        customFilterListOptions: {
          render: v => {
            return "Duration: " + v
          }
        }
      }
    },
    {
      name: "Files",
      label: "Files",
      options: {
        customBodyRender: (files) => {
          return files
        },
        filter: true,
        filterType: 'textField',
        customFilterListOptions: {
          render: v => {
            return "Files: " + v
          }
        }
      }
    },
    {
      name: "CreationDate",
      label: "Creation date",
      options: {
        // clean create date
        customBodyRender: (value) => {
              return `${value.split("T")[0]}`;
            },
        // define filter type as multiselect      
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => {
            return "Creation date: " + v
          }
        }
      }
    },  
    {
      name: "CreatedByUser",
      label: "Created By",
      options: {
          // define the row value to Full Name
          customBodyRender: (value) => {            
                return  `${value.FullName}`;
              },
          // define filter type as multiselect
          filterType: 'multiselect',
          customFilterListOptions: {
            render: v => {
              return "Created by: " + v
            }
          }
      }
    },
    {
      name: "StatusShortDesc",
      label: "Status",
      options : {
        // define filter type as multiselect
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => {
            return "Status: " + v
          }
        },
        customBodyRenderLite: (dataIndex) => {
          return summary[dataIndex].FileTranscriptionStatus
        }
      }
    },
    {
      name: "Id",
      label: " ",
      options: {
        // clean create date
        customBodyRenderLite: (dataIndex) => {
          const selectedUploadBatchRow = uploadBatchActionMenu.find((uploadBatch) => {
              return uploadBatch.actionMenuId === dataIndex
          })
          
          if (selectedUploadBatchRow) {
            if (selectedUploadBatchRow.isActionMenuDisabled) {
              return <div>
                {selectedUploadBatchRow.actionMenuMessage}
              </div>
            } else {
              return (
                <div>
                  <button 
                    id= {`menu-button-${dataIndex}`}
                    aria-controls= {`menu-${dataIndex}`}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    style={{margin: "5px"}} 
                    type="button" 
                    className="btn btn-outline-primary btn-sm" 
                    onClick={(event) => handleClick(event, dataIndex)}> 
                      Actions <KeyboardArrowDown sx={{fontSize: "1rem"}}/>
                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={selectedUploadBatchRow.menuActionElement}
                    open={open[`menu-button-${dataIndex}`] === undefined ? false : open[`menu-button-${dataIndex}`]}
                    onClose={() => handleClose(dataIndex)}
                    MenuListProps={{
                      'aria-labelledby': `menu-${dataIndex}`,
                    }}
                  >
                    <MenuItem disabled={batches[dataIndex].UploadStatusId === 1 || batches[dataIndex].UploadStatusId === 2 || batches[dataIndex].UploadStatusId === 3 ? false : true} onClick={() => {DeleteBatch(dataIndex)}}><img src={icon_delete} style={{width: 40, height: 40}} alt="icon_delete"/>&nbsp;Delete</MenuItem>
                    <MenuItem disabled={batches[dataIndex].UploadStatusId === 1 ? false : true} onClick={() => {DetectAudioBatch(dataIndex)}}><img src={icon_detect} style={{width: 40, height: 40}} alt="icon_detect"/>&nbsp;Detect Audio Language </MenuItem>
                    <MenuItem disabled={batches[dataIndex].UploadStatusId === 2 ? false : true} onClick={() => {TranscribeUploadBatch(dataIndex, selectedUploadBatchRow.isTranscriptionCompleted)}}><img src={icon_transcribe} style={{width: 40, height: 40}} alt="icon_transcribe"/>&nbsp;{selectedUploadBatchRow.isTranscriptionCompleted ? "Transcribe Audio" : "Check Transcription"}</MenuItem>
                    <MenuItem disabled={batches[dataIndex].UploadStatusId === 3 ? false : true} onClick={() => {ProgressBatch(dataIndex)}}><img src={icon_transcribe} style={{width: 40, height: 40}} alt="icon_transcribe"/>&nbsp;Progress To Review</MenuItem>
                  </Menu>
                </div>
              )
            }
          }
        },
        filter: false
      }
    }
  ]

    return (
      <ThemeProvider theme={myTheme} >
        { batches && files &&                
          <MUIDataTable
            data={batches}
            columns={headers}
            options={{
              selectableRows: "none",
              print: false,
              search: true,
              download: false,
              filter: true,
              viewColumns: false,         
              rowsExpanded: expand,
              expandableRows: true,
              expandableRowsHeader: true,
              expandableRowsOnClick: false,
              onSearchChange: () => {
                setExpand([])
              },
              onRowExpansionChange: (_, allRowsExpanded) => {
                setExpand(allRowsExpanded.map((rows) => rows.dataIndex))
              },
              renderExpandableRow: (rowData, rowMeta) => {
                const colSpan = rowData.length + 1;
                return (
                  <TableRow>
                    <TableCell colSpan={colSpan}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Document ID
                            </TableCell>                  
                            <TableCell>
                              File Name
                            </TableCell>
                            <TableCell>
                              Duration
                            </TableCell>
                            <TableCell>
                              Language
                            </TableCell>
                            <TableCell>
                              Status
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {files.filter((file) => file.UploadBatchId === rowData[0]).sort((a, b) => a.Filename_project.localeCompare(b.Filename_project)).map((row) => (
                            <TableRow  key={row.Id}>
                              <TableCell>
                                {row.Filename_project}
                              </TableCell>                  
                              <TableCell>
                                {row.Filename_original}
                              </TableCell>
                              <TableCell>
                                {formatAudioLength(row.AudioLength)}             
                              </TableCell>
                              <TableCell>
                                <Select
                                      value = {row.Language}
                                      id="language"
                                      name= {`${row.Id}`}
                                      style = {{height: 35, width: 200}} 
                                      variant="standard"
                                      onChange={handleLanguage}
                                      disabled={row.UploadStatusId === 3 || row.UploadStatusId === 4 || row.UploadStatusId === 5 || row.AzureTranscriptionId != null || !currentFilesStatus[row.Id].isLanguageSelectionEnabled ? true : false}
                                      inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                      <MenuItem value="Unknown">Unknown</MenuItem>
                                      <MenuItem value="en-us">English</MenuItem>
                                      <MenuItem value="zh-hk">Cantonese</MenuItem>
                                      <MenuItem value="zh-cn">Mandarin</MenuItem>
                                      <MenuItem value="ja-jp">Japanese</MenuItem>
                                  </Select>            
                              </TableCell>
                              <TableCell>
                                {/* {row.UploadStatus.ShortDescription} */}
                                {currentFilesStatus[row.Id].status}
                              </TableCell>
                              <TableCell>
                                {/* <AudioPlayer src={row.URL} /> */}
                                {<audio style={{height:35}} src={row.URL} controls="controls" />}
                              </TableCell>
                            </TableRow>))
                          }
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                );
              }  
            }}
          />
        }
        { 
          
        }
      </ThemeProvider>

    );

}

