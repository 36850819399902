import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fetchingDataProcessCount: 0,
    isSiteAdminAccessDenied: false,
    isOrgAdminAccessDenied: false
}

export const fetchDataStatusSlice = createSlice({
    name: 'fetchDataStatus',
    initialState,
    reducers: {
        addDataFetchProcess: (state, action) => {
            let newState = state.fetchingDataProcessCount

            newState += parseInt(action.payload)

            state.fetchingDataProcessCount = newState
        },
        removeDataFetchProcess: (state, action) => {
            let newState = state.fetchingDataProcessCount

            newState -= parseInt(action.payload)

            state.fetchingDataProcessCount = newState
        },
        toggleSiteAdminAccessDeniedWarningPage: (state, action) => {
            state.isSiteAdminAccessDenied = action.payload
        },
        toggleOrgAdminAccessDeniedWarningPage: (state, action) => {
            state.isOrgAdminAccessDenied = action.payload
        }
    }
})


export const { addDataFetchProcess, removeDataFetchProcess, toggleSiteAdminAccessDeniedWarningPage, toggleOrgAdminAccessDeniedWarningPage } = fetchDataStatusSlice.actions

export default fetchDataStatusSlice.reducer