import React, { useContext, useEffect } from 'react';
import ReviewContext from '../../../../contexts/ReviewContext'
import ReviewFileContext from '../../../../contexts/ReviewFileContext';
import { Select, MenuItem, Paper, Grid } from '@mui/material';
import Queue from './Queue';
import Modal from '../../SharedComponents/Modal';

export default function ReviewBatchSelector() {
    // Review file context
    // 1. Redirect selected review file ID
    // 2. Redirect selected review batch ID
    // 3. Set Redirect selected review batch ID (Function)
    const { redirectSelectedReviewFileId, redirectSelectedReviewBatchId, setRedirectSelectedReviewBatchId } = useContext(ReviewFileContext)
    
    // Review context
    // 1. Review batch selector data
    // 2. Set selected review batch ID 
    // 3. Set selected review file ID
    // 4. Set selected review batch index 
    // 5. Set selected review file index
    // 6. Selected review file index
    // 7. Selected review batch index,
    // 8. Set selected review batch file ID,
    // 9. Review batch files queue,
    // 10. Save file,
    // 11. Reopen file,
    // 12. Complete review batch file
    const { 
        reviewBatchSelectorData, 
        setSelectedReviewBatchId, 
        setSelectedReviewFileId, 
        setSelectedReviewBatchIndex, 
        setSelectedReviewFileIndex,
        selectedReviewFileIndex, 
        selectedReviewBatchIndex,
        setSelectedReviewBatchFileId,
        reviewBatchFilesQueue,
        saveFile,
        reopenFile,
        completeReviewBatchFile
    } = useContext(ReviewContext)

    useEffect(() => {
        // When Review batch selector data, Redirect selected review file ID, and Redirect selected review batch ID are loaded
        // Set Selected review batch ID, Selected review batch index, Selected review batch file ID, and Selected review batch file index
        if (reviewBatchSelectorData && reviewBatchSelectorData.length > 0) {
            if (selectedReviewBatchIndex === -1) {
                setSelectedReviewBatchId(reviewBatchSelectorData[0].id)
                setSelectedReviewBatchIndex(0)
                setSelectedReviewBatchFileId(0)

                if (reviewBatchSelectorData[0].files.length > 0) {
                    setSelectedReviewFileId(reviewBatchSelectorData[0].files[0].FileId)
                    setSelectedReviewBatchFileId(reviewBatchSelectorData[0].files[0].Id)
                } else {
                    setSelectedReviewFileId(0)
                    setSelectedReviewBatchFileId(0)
                }
            } else {
                setSelectedReviewBatchId(reviewBatchSelectorData[selectedReviewBatchIndex].id)

                if (reviewBatchSelectorData[selectedReviewBatchIndex].files.length > 0) {
                    setSelectedReviewFileId(reviewBatchSelectorData[selectedReviewBatchIndex].files[0].FileId)
                    setSelectedReviewBatchFileId(reviewBatchSelectorData[selectedReviewBatchIndex].files[0].Id)
                } else {
                    setSelectedReviewFileId(0)
                    setSelectedReviewBatchFileId(0)
                }
            }

            if (redirectSelectedReviewBatchId > 0 && redirectSelectedReviewFileId > 0) {
                setSelectedReviewBatchId(redirectSelectedReviewBatchId)
    
                const findSelectedReviewBatchIndex = reviewBatchSelectorData.findIndex(reviewBatch => reviewBatch.id === redirectSelectedReviewBatchId)
                setSelectedReviewBatchIndex(findSelectedReviewBatchIndex)
                if (reviewBatchSelectorData[findSelectedReviewBatchIndex].files.length > 0) {
                    const findSelectedReviewFileIndex = reviewBatchSelectorData[findSelectedReviewBatchIndex].files.findIndex(file => file.FileId === redirectSelectedReviewFileId)
                    setSelectedReviewFileIndex(findSelectedReviewFileIndex)
                    setSelectedReviewFileId(reviewBatchSelectorData[findSelectedReviewBatchIndex].files[findSelectedReviewFileIndex].FileId)
                    setSelectedReviewBatchFileId(reviewBatchSelectorData[findSelectedReviewBatchIndex].files[findSelectedReviewFileIndex].Id)
                } else {
                    setSelectedReviewFileIndex(0)
                    setSelectedReviewFileId(0)
                    setSelectedReviewBatchFileId(0)
                }
            }  
        }
    }, [reviewBatchSelectorData, redirectSelectedReviewFileId, redirectSelectedReviewBatchId])

    // Event handler: Change transcription review queue
    const changeTranscriptionReviewQueue = (event) => {
        const selectedReviewBatchId = event.target.value
        setSelectedReviewBatchId(selectedReviewBatchId)

        const findSelectedReviewBatchIndex = reviewBatchSelectorData.findIndex(reviewBatch => reviewBatch.id === selectedReviewBatchId)
        setSelectedReviewBatchIndex(findSelectedReviewBatchIndex)
        if (reviewBatchSelectorData[findSelectedReviewBatchIndex].files.length > 0) {
            setSelectedReviewFileId(reviewBatchSelectorData[findSelectedReviewBatchIndex].files[0].FileId)
            setSelectedReviewFileIndex(0)
            setSelectedReviewBatchFileId(reviewBatchSelectorData[findSelectedReviewBatchIndex].files[0].Id)
        } else {
            setSelectedReviewFileId(0)
            setSelectedReviewFileIndex(-1)
            setSelectedReviewBatchFileId(0)
        }

        setRedirectSelectedReviewBatchId(0)
    }

    return (
        <>
            {
                reviewBatchSelectorData && reviewBatchSelectorData.length > 0 && selectedReviewBatchIndex > -1 &&
                <Paper sx={{padding: 1, marginBottom: "10px", textAlign: 'center'}} elevation={4}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} style={{textAlign: "center"}} justifyContent="center">
                            <strong>Review batch</strong>&emsp;
                            <Select
                                value={reviewBatchSelectorData[selectedReviewBatchIndex].id}
                                id="reviewBatchSelector"
                                variant='standard'
                                name="reviewBatchSelector"
                                style={{height: 50, width: 200}}
                                onChange={changeTranscriptionReviewQueue}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                { 
                                    reviewBatchSelectorData.map(reviewBatch => {
                                        return (
                                            <MenuItem key={reviewBatch["id"]} value={reviewBatch["id"]}>{reviewBatch["name"]}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            &emsp;
                            <Queue />
                            &emsp;
                            {
                                reviewBatchFilesQueue.length > 0 && !reviewBatchFilesQueue[selectedReviewFileIndex].Completed &&
                                <span>
                                    {reviewBatchFilesQueue[selectedReviewFileIndex].Filename_project}
                                    &emsp;
                                    <button 
                                        type="button" 
                                        className="btn btn-warning" 
                                        align = 'center' 
                                        onClick={() => saveFile("REVIEW_BATCH")}
                                        style={{ fontSize: "0.8rem", margin: 5, padding: "5px", width: 100 }}>
                                            Save file
                                    </button>
                                    <button 
                                        type="button" 
                                        className="btn btn-primary" 
                                        align = 'center' 
                                        onClick={completeReviewBatchFile}
                                        style={{ fontSize: "0.8rem", margin: 5, padding: "5px", width: 100 }}>
                                            Complete file
                                    </button>
                                </span>
                            }
                            {
                                reviewBatchFilesQueue.length > 0 && reviewBatchFilesQueue[selectedReviewFileIndex].Completed &&
                                <>
                                    <span>{reviewBatchFilesQueue[selectedReviewFileIndex].Filename_project} review is <strong>completed</strong></span>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <button 
                                        type="button" 
                                        className="btn btn-success" 
                                        align = 'center' 
                                        onClick={reopenFile}
                                        style={{ fontSize: "0.8rem", margin: 5, padding: "5px", width: 100 }}>
                                            Re-open file
                                    </button>
                                </>
                                
                            }
                            {
                                reviewBatchFilesQueue.length === 0 &&
                                <span>
                                    All files reviewed
                                </span>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            }
            {
                reviewBatchSelectorData && reviewBatchSelectorData.length === 0 && selectedReviewBatchIndex === -1 &&
                <>
                    <Grid container spacing={2} >
                        <Grid item xs={12} style={{textAlign: "center"}} justifyContent="center">
                            <span>
                                No files available for review
                            </span>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}