// this page is the home page when the users logs into the system. It should contain the infographic dashboard required for the user

// React and MUI component
import React, {  useContext } from 'react';
import { authenticationService } from '../../Services/_services/authenticationService';
import ProjectContext from '../../../contexts/ProjectContext';
// import { Paper, Grid } from '@mui/material';
// import HomeTile from '../SharedComponents/HomeTiles'
// import { Doughnut, Bar } from 'react-chartjs-2';

// Contexts
// import FileContext from '../../../contexts/FileContext';

const textAlign = {
  textAlign: "center"
}

export default function Home() {
  const user = authenticationService.currentUserValue
  const {selectedproject} = useContext(ProjectContext)
  // initialise the file context
  return (
    <div className='home-grid' >
      <div style={textAlign}>
        Hi {user.firstName}
      </div>
      <div style={textAlign}>
        Welcome to Voice Detect
      </div>
      <div style={textAlign}>
        You are currently logged into project {selectedproject.ProjectName}
      </div>    
      <div style={textAlign}>
        On this project you have the following permissions
      </div>   
      <div style={textAlign}>
        {user.claims.filter(claim => claim.ProjectId === selectedproject.Id).map(( claim, index ) => {
            return (
              // <tr key={index}>
                <p key={index}>
                  <strong>
                    {claim.RoleName}
                  </strong>
                </p>
              // </tr>
            );
          })}
      </div>          
    </div>
  )

}