// this page is the admin page to manage the tags used for review, administrators could add, edit and delete tags from this page 
// further development required once api are set

// React and MUI Components
import React, {useEffect, useState, useContext, useRef } from 'react';
import { Box, Grid, TextField, InputLabel, Select, MenuItem } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles"

//Components
import Modal from '../SharedComponents/Modal';

//Authentication
import { authHeader } from '../../Services/_helpers/auth-header';

//Context 
import ProjectContext from '../../../contexts/ProjectContext';
import useFetch from '../../../contexts/services/useFetch';
import { authenticationService } from '../../Services/_services/authenticationService';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function IssueTags() {
  // React hooks
  // 1. Issue tags list
  const[ issueTags, setIssueTags ] = useState([])
  // 2. Selected issue object
  const [selectedIssueTag, setSelectedIssueTag] = useState({
    "issueTagName": "",
    "issueTagGroup": "",
    "issueTagEnabled": true
  })
  // 3. Progress status
  const [status, setStatus] = useState()
  // 4. New issue tag form completion status
  const [ isNewIssueTagFormCompleted, setIsNewIssueTagFormCompleted ] = useState(false)

  // Project context variable
  const { selectedproject } = useContext(ProjectContext)

  // API data pull
  // 1. Issue tags
  const { data: issueTagsData, isPending: issueTagsDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/IssueTag/${selectedproject.Id}`, authenticationService.currentUserValue, "Issue tag")

  // Redux
  // 1. Organisation admin access denied status
  let isOrgAdminAccessDenied = useSelector((state) => state.fetchDataStatus.isOrgAdminAccessDenied)

  useEffect(() => {
    // Two conditions to set Issue Tag state
    // 1. Issue tags API data is fully loaded
    // 2. Organisation administrator access level is required (Only organisation admin can open this page) 
    if (!issueTagsDataIsLoading && !isOrgAdminAccessDenied) {
      // Filter issue tags by enabled issue tag
      const activeIssueTags = issueTagsData.filter((rows) => rows.deleted_at === null)
      setIssueTags(activeIssueTags)
    }
  }, [issueTagsDataIsLoading, isOrgAdminAccessDenied])

  // Refresh issue tags list by calling Issue tag API
  const fetchIssueTagData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/IssueTag/${selectedproject.Id}`, {
      headers: authHeader()
    })
    .then(resp => {
      const activeIssueTags = resp.data.filter((rows) => rows.deleted_at === null)
      setIssueTags(activeIssueTags)
    })
    .catch(err => {
      if (err.response.status === 401 || err.response.status === 403) {
        console.info("JWT token expired")
        authenticationService.logout();
      } else {
        console.info(err)
      }
    })
  }

  // Event handler: Change selectedIssueTag state and validate issue tag form
  const handleInput = (event) => {
    let updatedItem = selectedIssueTag
    updatedItem[event.target.name] = event.target.value;
    setSelectedIssueTag(updatedItem);

    // Validation rule
    // 1. Issue tag name    -> Not empty string
    // 2. Issue tag group   -> Not empty string
    if (selectedIssueTag["issueTagName"] !== "" && selectedIssueTag["issueTagGroup"] !== "") {
      setIsNewIssueTagFormCompleted(true)
    } else {
      setIsNewIssueTagFormCompleted(false)
    }
  }
  
  // Clear all inputs when modals are closed
  const clearInput = ()  => {
    setSelectedIssueTag({
      "issueTagName": "",
      "issueTagGroup": "",
      "issueTagEnabled": true
    });
    setIsNewIssueTagFormCompleted(false)
  }

  const convertBooleanIntoInteger = (item) => {
    if (item) {
      return 1
    } else {
      return 0
    }
  }
  
  // Event handler: Register new issue tag
  const handleSubmit = async () => {
    const currentDate = new Date() 
    
    const postNewIssueTag = {
      "Id": issueTags.length + 1,
      "Tag":  selectedIssueTag["issueTagName"],
      "Group": selectedIssueTag["issueTagGroup"],
      "IsEnabled": selectedIssueTag["issueTagEnabled"] === undefined ? 1 : selectedIssueTag["issueTagEnabled"],
      "created_at": currentDate.toISOString()
    }    

    // Register new issue tag through '/IssueTag/Create/' API
    axios.post(`${process.env.REACT_APP_API_URL}/IssueTag/Create/${selectedproject.Id}`, postNewIssueTag, { 
      headers: authHeader()
    })
    .then(createNewIssueTagResponse => {
      if (createNewIssueTagResponse.status === 200) {
        if (createNewIssueTagResponse.data.IsEnabled) {
          // Generate newly created issue tag into review outcome tags through '/ReviewOutcomeTags/Generate_Newly_Added/' API
          axios.post(`${process.env.REACT_APP_API_URL}/ReviewOutcomeTags/Generate_Newly_Added/${selectedproject.Id}/${createNewIssueTagResponse.data.Id}`, {}, { 
            headers: authHeader()
          })
          .then(generateReviewOutcomeTagsResponse => {
            if (generateReviewOutcomeTagsResponse.status === 200) {
              setStatus("Issue tag is successfully created")
            } else {
              console.info(generateReviewOutcomeTagsResponse)
            }
          })
          .catch(generateReviewOutcomeTagsError => {
              console.info(generateReviewOutcomeTagsError)
          })
        }
        
        fetchIssueTagData()
      }
    })
    .catch(createNewIssueTagResponseError => {
      console.info(createNewIssueTagResponseError)
    })
  }

  // Event handler: Update existing issue tags
  const updateTags = async (dataIndex, issueTagId) => {
    const currentDate = new Date() 

    const updatedIssueTagData = {
      "Tag":  selectedIssueTag["issueTagName"] === undefined ? issueTags[dataIndex].Tag : selectedIssueTag["issueTagName"],
      "Group": selectedIssueTag["issueTagGroup"] === undefined ? issueTags[dataIndex].Group : selectedIssueTag["issueTagGroup"],
      "IsEnabled": selectedIssueTag["issueTagEnabled"] === undefined ? convertBooleanIntoInteger(issueTags[dataIndex].IsEnabled) : convertBooleanIntoInteger(selectedIssueTag["issueTagEnabled"]),
      "updated_at": currentDate.toISOString()
    }

    // Update existing issue tag by Issue tag ID
    axios.put(`${process.env.REACT_APP_API_URL}/IssueTag/Update/${selectedproject.Id}/${issueTagId}`, updatedIssueTagData, { 
      headers: authHeader()
    })
    .then(updateIssueTagResponse => {
      if (updateIssueTagResponse.status === 200) {
        setStatus("Issue Tag is successfully updated")
        fetchIssueTagData()
      }
    })
    .catch(updateIssueTagResponseError => {
      console.info(updateIssueTagResponseError)
    })
  }

  // Event handler: Delete issue tag
  const deleteTags = async (issueTagId) => {
    // Soft delete issue tag through '/IssueTag/Delete/' API
    axios.delete(`${process.env.REACT_APP_API_URL}/IssueTag/Delete/${selectedproject.Id}/${issueTagId}`, { 
      headers: authHeader()
    })
    .then(updateIssueTagResponse => {
      if (updateIssueTagResponse.status === 200) {
        setStatus("Issue Tag is successfully removed")
        fetchIssueTagData()
      }
    })
    .catch(updateIssueTagResponseError => {
      console.info(updateIssueTagResponseError)
    })
  }
  
  // Issue tags header table
  const headers = [
    {
      name: "Group",
      label: "Group", 
      options: {
        filter: true,
        filterType: 'textField'
      }  
    },
    {
      name: "Tag",
      label: "Tag Name",
      options: {
        filter: true,
        filterType: 'textField'
      } 
    
    },
    {
      name: "IsEnabled",
      label: "Enabled?",
      options: {
        filter: true,
        customBodyRender: (value) => {
          if (value) {
            return "Yes"
          } else {
            return "No"
          }
        }
      }
    }
  ];
  // edit button
  const edit = {
    name: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Modal 
            titlename = {"Edit"} 
            idx = {issueTags[dataIndex]["Id"]}
            handleSubmit = {() => {updateTags(dataIndex, issueTags[dataIndex]["Id"])}}
            saveButtonLabel="Update"
            disableSaveButton={!isNewIssueTagFormCompleted}
            clearInput = {clearInput} 
            initialData = {() => {setSelectedIssueTag({
              "issueTagName": issueTags[dataIndex].Tag,
              "issueTagGroup": issueTags[dataIndex].Group,
              "issueTagEnabled": issueTags[dataIndex].IsEnabled
            })}}>
              <InputLabel id="issueTagName">Issue Tag Name</InputLabel>
              <TextField id="issueTagName" 
                      name="issueTagName"
                      type = "text"
                      style = {{width: 250}} 
                      onChange={handleInput}
                      defaultValue = {issueTags[dataIndex].Tag}
                      variant="outlined"
                      />
              <br/>
              <InputLabel id="issueTagGroup">Group</InputLabel>
              <TextField id="issueTagGroup" 
                      name="issueTagGroup"
                      type = "text"
                      style = {{width: 250}} 
                      onChange={handleInput}
                      defaultValue = {issueTags[dataIndex].Group}
                      variant="outlined"
                      />
              <br/>
              <InputLabel id="issueTagEnabled">Enable Issue Tag</InputLabel>
              <Select
                id="issueTagEnabled"
                name="issueTagEnabled"
                style = {{width: 250}}
                defaultValue={issueTags[dataIndex].IsEnabled}
                onChange={handleInput}
              >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
              </Select>
              <div className={'alert alert-danger'} style={{width: '100%'}}>
                Notes: Disabling issue tag will remove entire existing review outcome issue tags. Please double check before disabling issue tag
              </div>
              <br/>
          </Modal>
        );
      }
    }
  }
  // delete button
  const Delete = {
    name: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Modal 
          titlename = {"Delete"} 
          idx = {issueTags[dataIndex]["Id"]}
          handleSubmit = {() => {deleteTags(issueTags[dataIndex]["Id"])}}
          saveButtonLabel = "Delete"
          clearInput = {clearInput} >
            
            Are you sure you want to delete the issue tag?
          </Modal>
        );
      }
    }
  }
  
    
  // rendering page
  return (

    // container and box for the page
    <Grid container  direction="row" justifyContent="center" marginTop={5} >
      <Box elevation = {0} sx={{ width: '90%', height: '100%' }}>

        {/* // theme provider for the MUI datatable */}
        {
          !isOrgAdminAccessDenied &&
          <ThemeProvider theme={createTheme()}>
            {/* // Modal for adding new tags */}
              <Modal           
                titlename = {"Add Issue Tags"} 
                idx = {issueTags.length + 1}
                handleSubmit = {handleSubmit}
                clearInput = {clearInput}
                saveButtonLabel="Submit"
                disableSaveButton={!isNewIssueTagFormCompleted}
              >
                <InputLabel id="issueTagName">Issue Tag Name</InputLabel>
                <TextField id="issueTagName" 
                        name="issueTagName"
                        type = "text"
                        style = {{width: 250}} 
                        onChange={handleInput}
                        variant="outlined"
                        /> 
                <br/>
                <InputLabel id="issueTagGroup">Group</InputLabel>
                <TextField id="issueTagGroup" 
                        name="issueTagGroup"
                        type = "text"
                        style = {{width: 250}} 
                        onChange={handleInput}
                        variant="outlined"
                        /> 
                <br/>
                <InputLabel id="issueTagEnabled">Enable Issue Tag</InputLabel>
                <Select
                  id="issueTagEnabled"
                  name="issueTagEnabled"
                  style = {{width: 250}}
                  defaultValue={true}
                  onChange={handleInput}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>     
              </Modal>
              <br/>
              <br/>
              {/* // if tags are loaded, display the tags in datatable */}
              {status && <div className={'alert alert-danger'}>{status} </div>}
                {issueTags !== {} &&
                  <MUIDataTable
                    data={issueTags}
                    columns={headers.concat(edit)}
                    options={{
                      selectableRows: "none",
                      print: false,
                      viewColumns: false
                    }}
                  />
                }
          </ThemeProvider>
        }
        {
            isOrgAdminAccessDenied &&
            <div style={{textAlign: 'center'}}>
                You're unauthorised to access this page
            </div>
        }
        </Box>
    </Grid>
    );
  };


