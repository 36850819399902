import React, {useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, MenuItem, Select, Box, InputLabel, TextField, createTheme, ThemeProvider, Autocomplete, FormGroup, FormControlLabel, Input, Button, FormControl } from '@mui/material';
import { authHeader } from '../../../Services/_helpers/auth-header';
import useFetch from '../../../../contexts/services/useFetch';
import { authenticationService } from '../../../Services/_services/authenticationService';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

export default function ManageBilling(props) {
    // Selected project ID property
    const selectedProjectId = props.match.params.projectId

    // React hooks
    // 1. Organisation name
    const [ organisationName, setOrganisationName ] = useState("")
    // 2. Selected billing stats list
    const [ selectedBillingStats, setSelectedBillingStats ] = useState([])
    // 3. Billing months list
    const [ billingMonths, setBillingMonths] = useState([])
    // 4. Selected billing month list
    const [ selectedBillingMonth, setSelectedBillingMonth] = useState([])
    // 5. Pricing details object
    const [ pricingDetails, setPricingDetails ] = useState({})

    // API data pull
    // 1. Billing stats
    const { data: selectedBillingStatsData, isPending: selectedBillingStatsDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/BillingStats/Project/` + selectedProjectId, authenticationService.currentUserValue, "Project billing details")

    useEffect(() => {
        // One condition to set Selected billing stats, Billing months, Organisation name, Pricing details, and Selected billing month: Billing stats API data is fully loaded
        if (!selectedBillingStatsDataIsLoading) {
            setSelectedBillingStats(selectedBillingStatsData[0])
            setBillingMonths(selectedBillingStatsData[1])
            setOrganisationName(selectedBillingStatsData[2])
            setPricingDetails(selectedBillingStatsData[3])

            if (selectedBillingStatsData[1].length > 0) {
                setSelectedBillingMonth(selectedBillingStatsData[1][0])
            }
        }
    }, [selectedBillingStatsDataIsLoading])

    // Event handler: Change billing month
    const changeBillingMonth = (event) => {
        setSelectedBillingMonth(event.target.value)
    }

    return (
        <div>
            {
                selectedBillingStats.length > 0 ? 
                <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                Organisation
                                <h5><strong>{organisationName}</strong></h5>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: "right"}}>
                            {
                                selectedBillingMonth !== "" && billingMonths.length > 0 ? 
                                <FormControl>
                                    <InputLabel id="billingMonthLabel">Billing Month</InputLabel>
                                    <Select
                                        labelId="billingMonthLabel"
                                        id="billingMonthSelect"
                                        value={selectedBillingMonth}
                                        label="Billing Months"
                                        onChange={changeBillingMonth}
                                    >
                                        {
                                            billingMonths.map((item, index) => {
                                                return <MenuItem key={index} value={item}>{item}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl> 
                                :
                                <div></div>
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <br/>
                    <br/>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell align="right">Included</TableCell>
                                    <TableCell align="right">Actual</TableCell>
                                    <TableCell>Unit type</TableCell>
                                    <TableCell align="right">Excess</TableCell>
                                    <TableCell align="right">Excess Cost</TableCell>
                                    <TableCell align="right">Total cost</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    selectedBillingStats.map((row) => 
                                        {
                                            if (row.BillingDate === selectedBillingMonth) {
                                                return (
                                                    <TableRow
                                                        key={row.Id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.Item}
                                                        </TableCell>
                                                        <TableCell align="right">{row.IncludedUnit}</TableCell>
                                                        <TableCell align="right">{row.Unit}</TableCell>
                                                        <TableCell>{row.UnitType}</TableCell>
                                                        <TableCell align="right">0</TableCell>
                                                        <TableCell align="right">{row.UnitCost}</TableCell>
                                                        <TableCell align="right">{row.TotalCost}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer> 
                </Grid> 
                : 
                <Grid container direction="row" justifyContent="center">
                    <div>Billing unavailable</div>
                </Grid>
            }      
        </div>
    )
}