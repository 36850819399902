import React, { useContext, useState, useEffect } from 'react';

// Material UI Elements
import MUIDataTable from "mui-datatables";
import {Select,  MenuItem } from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/material/styles"

//Services
// for calculating the time and seconds
import moment from "moment"

// for authentication
import { authHeader }  from '../../../Services/_helpers/auth-header';

//Contexts
import FileContext from '../../../../contexts/FileContext';
import ProjectContext from '../../../../contexts/ProjectContext';

//Custom Tool Bar
import CustomToolBar from '../components/CustomToolbar'


export default function FileTable() {
  // context data for files and batches and its corresponding fetch functions
  const {files,fetchFiles, fetchBatches } = useContext(FileContext)
  // state variable for what files are selected
  const[ selected, setSelected ] = useState([])
  // context data for project
  const { selectedproject } = useContext(ProjectContext)
  // state variable showing the loaded queue
  const [ displayFiles, setDisplayFiles ] = useState([])


  // load initial set of files (i.e. transcribed but unassigned files)
  useEffect (() => {
    // Initiate select all                        
    if (files) { 
      
      // Filter files that are 
      const filtered = files.filter((rows) => 
      rows.WorkFlowStatus.Id < 3)

      setDisplayFiles(filtered)
    
    }
  }, [files])
  


  // handle selection for checkboxes
  const handleSelect = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    // update the selected to toggle the checkbox
    setSelected(rowsSelected);
  }

  // handle changes in languages
  const handleLanguage = (event) => {

    // get the file id to be changed
    const Id =  event.target.name;
    const currentFile = displayFiles[Id]

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', ...authHeader()},
      body: JSON.stringify({
        "Language": event.target.value
      })
    };

    fetch(`${process.env.REACT_APP_API_URL}/File/Update/${selectedproject.Id}/${currentFile.Id}/`, requestOptions)
    .then((response) => {
  
      // refresh batches and files
      fetchBatches(false);
      fetchFiles(false);
    }).catch((error) => {
      console.info(error)
  })
  }


  const formatAudioLength = (input) => {

    const audioLength = moment.duration(input)

    const pad = (input) => { if(input <= 9) {return(`0${input}`)}else{return(`${input}`)}}
    return `${pad(audioLength.hours())}:${pad(audioLength.minutes())}:${pad(audioLength.seconds())}`

  }

  const RenderCustomerToolBar = () => {
    return <CustomToolBar selected={selected} displayData={displayFiles} setSelected={setSelected}/>
  }

  // theme overrides
  const myTheme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: '5pt',
          },
        },
      },
    }
  });

  // headers for batch table
  const headers = [
  {
  name: "Filename_project",
  label: "Document ID",
  // disable filter using id
  options : {
    filterType: 'textField'
  }
  },
  {
    name: "Filename_original",
    label: "File Name",
      // define filter type as text search
    options : {
      filterType: 'textField'
    }
  },
  {
  name: "AudioLength",
  label: "Duration",
  options: {
      // define the row value to Full Name
      customBodyRender: (value) => {
            return formatAudioLength(value);
          },
      // define filter type as multiselect
      filterType: 'multiselect'
        }
  },
  {
  name: "Language",
  label: "Language",
  options : {
    // define filter type as multiselect
    filterType: 'multiselect',
    customBodyRenderLite: (dataIndex) => {
      if (displayFiles[dataIndex].WorkFlowStatus.Id < 3) {
        return <Select
                value = {displayFiles[dataIndex].Language}
                id="language"
                name= {`select-${dataIndex}`}
                style = {{height: 35, width: 150}} 
                onChange={handleLanguage}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="Unknown">Unknown</MenuItem>
                <MenuItem value="en-us">English</MenuItem>
                <MenuItem value="zh-hk">Cantonese</MenuItem>
                <MenuItem value="zh-cn">Mandarin</MenuItem>
                <MenuItem value="ja-jp">Japanese</MenuItem>
            </Select>    }
            
          else {
            switch (displayFiles[dataIndex].Language) {
              case 'en-us':
                return  "English"
              case 'zh-hk':
                return "Cantonese"
              case 'zh-cn':
                return "Mandarin"
              case 'ja-jp':
                return "Japanese"
              default:
                return ""
          }
      }    
    }  
  }
  },
  {
    name: "WorkFlowStatus",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      filterType: 'multiselect',
      customBodyRender: (value) => {
      return `${value.ShortDescription}`
        }
      }    
  },
  {
    name: "URL",
    label: "Audio Player",
    options: {
      filter: false,
      customBodyRenderLite: (dataIndex) => {
      return <audio style = {{height: 35}} src={displayFiles[dataIndex].URL} controls="controls" />
        }
      }
    },

      ]

    return (
      <ThemeProvider theme={myTheme}>  
        {displayFiles &&                
          <MUIDataTable
            data={displayFiles}
            columns={headers}
            options={{
              selectableRows: "multiple",
              rowsSelected: selected,
              onRowSelectionChange: handleSelect,
              customToolbarSelect: RenderCustomerToolBar,
              print: false,
              search: true,
              download: false,
              filter: true,
              viewColumns: false,
              rowsPerPage: 50,
              rowsPerPageOptions: [50, 100, 200]
            }}
        />}
      </ThemeProvider>

    );

}

