// React and MUI Components
import React, {useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { NavLink } from 'react-router-dom';

//Components
import Modal from '../../SharedComponents/Modal';

//Authentication
import { authHeader } from '../../../Services/_helpers/auth-header';

import useFetch from '../../../../contexts/services/useFetch';
import { authenticationService } from '../../../Services/_services/authenticationService';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function Projects() {
  // React hooks
  // 1. Projects list
  const [ projects, setProjects ] = useState([])
  // 2. Organisations list
  const [ organisations, setOrganisations ] = useState([])
  // 3. Users list
  const [ users, setUsers ] = useState([])
  // 4. Project deletion status
  const [status, setStatus] = useState()

  // API data pull
  // 1. Projects
  const { data: projectsData, isPending: projectsDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Projects/SiteAdmin`, authenticationService.currentUserValue, "Projects")
  // 2. Organisation
  const { data: organisationData, isPending: organisationDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Organisation/SiteAdmin`, authenticationService.currentUserValue, "Organisation")
  // 3. Users
  const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/`, authenticationService.currentUserValue, "Users")

  // Site administrator access permission from Redux
  let isSiteAdminAccessDenied = useSelector((state) => state.fetchDataStatus.isSiteAdminAccessDenied)

  useEffect(() => {
    // Two conditions to set projects state
    // 1. Projects API data is fully loaded
    // 2. Site administrator access level is required (Only site admin can open this page)
    if (!projectsDataIsLoading && !isSiteAdminAccessDenied) {
      const filteredProjectsData = projectsData.filter(project => project.TerminationDate === "9999-12-31T00:00:00")
      setProjects(filteredProjectsData)
    }
  }, [projectsDataIsLoading, isSiteAdminAccessDenied])

  useEffect(() => {
    // Two conditions to set organisation state
    // 1. Organisation API data is fully loaded
    // 2. Site administrator access level is required (Only site admin can open this page)
    if (!organisationDataIsLoading && !isSiteAdminAccessDenied) {
      // Re-map raw organisation data into ID and name
        const mappedOrganisationData = organisationData.map(organisation => ({
          id: organisation.Id,
          name: organisation.OrganisationName
        }))
        setOrganisations(mappedOrganisationData)
    } 
  }, [organisationDataIsLoading, isSiteAdminAccessDenied])

  useEffect(() => {
    // Two conditions to set user state
    // 1. Users API data is fully loaded
    // 2. Site administrator access level is required (Only site admin can open this page)
    if (!userDataIsLoading && !isSiteAdminAccessDenied) {
      const mappedUserData = userData.filter(user => user.TerminationDate === "9999-12-31T00:00:00")
      // Re-map raw user data into id and name
      .map(user => ({
        id: user.Id,
        name: user.FullName
      }))
      setUsers(mappedUserData)
    }
  }, [userDataIsLoading, isSiteAdminAccessDenied])

  // Refresh project state by calling Project for site administrator API
  const fetchProjectData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/Projects/SiteAdmin`, {
      headers: authHeader()
    })
    .then(resp => {
      // Filter active project only by termination date
      const filteredProjectsData = resp.data.filter(project => project.TerminationDate === "9999-12-31T00:00:00")
      setProjects(filteredProjectsData)
    })
    .catch(err => {
      if (err.response.status === 401 || err.response.status === 403) {
        console.info("JWT token expired")
        authenticationService.logout();
      } else {
        console.info(err)
      }
    })
  }

  // Event handler: Soft delete project by project ID
  const deleteProject = async (projectId, projectName) => {
    // 1. Delete project roles
    axios.delete(`${process.env.REACT_APP_API_URL}/ProjectRoles/Delete/Soft/Project/${projectId}`, { 
      headers: authHeader()
    })
    .then(deleteExistingMemberRolesResponse => {
      if (deleteExistingMemberRolesResponse.status === 200) {
        // 2. Delete project
        axios.delete(`${process.env.REACT_APP_API_URL}/Projects/Delete/${projectId}`, { 
          headers: authHeader()
        })
        .then(deleteProjectResponse => {
          if (deleteProjectResponse.status === 200) {
            // 3. Delete organisation project
            axios.delete(`${process.env.REACT_APP_API_URL}/OrganisationProject/Delete/Project/${projectId}`, { 
              headers: authHeader()
            })
            .then(deleteOrganisationProjectResponse => {
              if (deleteOrganisationProjectResponse.status === 200) {
                setStatus("Project " + projectName +" is successfully removed")
                fetchProjectData()
              }
            })
          }
        })
        .catch(error => {
          console.info("Project removal error:")
          console.info(error)
          setStatus("Project " + projectName + " removal failed, please contact admin for troubleshooting")
        })
      }
    })
    .catch(error => {
      console.info("Project roles removal error:")
      console.info(error)
      setStatus("Project roles removal failed, please contact admin for troubleshooting")
    })
  }
  
  // Projects table header
  const projectsHeaders = [
    {
      name: "ProjectName",
      label: "Name",
      options: {
        filter: true,
        filterType: 'textField'
      } 
    },
    {
      name: "ProjectDescription",
      label: "Description",
      options: {
        filter: true,
        filterType: 'textField'
      } 
    },
    {
      name: "Organisation",
      label: "Organisation",
      options: {
        filter: true,
        filterType: 'textField',
        customBodyRender: (value) => {
          return value["OrganisationName"]
        }
      }
    },
    {
      name: "DatabaseName",
      label: "Database",
      options: {
        filter: true,
        filterType: 'textField'
      }
    },
    {
      name: "CreatedByUser",
      label: "Creator",
      options: {
        filter: true,
        filterType: 'textField',
        customBodyRender: (value) => {
          return value["FullName"]
        }
      }
    },
    {
      name: "Id",
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <span>
            <NavLink className="btn btn-light" to={"/Site/Projects/Edit/" + value}> Edit</NavLink>
            &nbsp;
            <NavLink className="btn btn-light" to={"/Site/Projects/Billing/" + value}> Billing</NavLink>
          </span>
        }
      }
    }
  ]
  
  // Delete project warning modal dialog
  const deleteProjectModal = {
    name: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Modal 
          titlename = {"Delete"} 
          idx = {projects[dataIndex]["Id"]}
          handleSubmit = {() => {deleteProject(projects[dataIndex].Id, projects[dataIndex].ProjectName)}}
          saveButtonLabel = "Delete"
          clearInput={() => {}}>
            
            Are you sure you want to delete<strong>{projects[dataIndex].ProjectName}</strong>project?
          </Modal>
        );
      }
    }
  }
  
    
  // rendering page
  return (
    // container and box for the page
    <Grid container  direction="row" justifyContent="center" marginTop={5} >
      <Box elevation = {0} sx={{ width: '90%', height: '100%' }}>

        {/* // theme provider for the MUI datatable */}
        {
          !isSiteAdminAccessDenied &&
          <ThemeProvider theme={createTheme()}>
          <NavLink className="btn btn-primary" to="/Site/Projects/New"> New project</NavLink>
          <br/>
          <br/>
          {/* // if tags are loaded, display the tags in datatable */}
          {status && <div className={'alert alert-danger'}>{status} </div>}
            { projects !== {} &&
              <MUIDataTable
                data={projects}
                columns={projectsHeaders.concat(deleteProjectModal)}
                options={{
                  selectableRows: "none",
                  print: false,
                  viewColumns: false
                }}
              />
            }
          </ThemeProvider>
        }
        {
          isSiteAdminAccessDenied &&
          <div style={{textAlign: 'center'}}>
              You're unauthorised to access this page
          </div>
        }
      </Box>
    </Grid>
    );
  };


