// This page is for the user to login and viewing and select the project instance that the user will work on

// React & MUI Components
import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Paper, Box, Grid } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { v4 as uuidv4 } from 'uuid';
import { history } from '../../Services/_helpers/history';

// Images 
import Background from '../../Images/background.jpg';
import logo from '../../Images/PwC-logo.svg';

// Files
import TermsOfUse from '../../Files/Terms_of_Use.pdf'
import PrivacyStatement from '../../Files/Privacy_Statement.pdf'

// authentication services
import { authenticationService } from '../../Services/_services/authenticationService';

// Contexts
import ProjectContext from '../../../contexts/ProjectContext';

export default function LoginPage() {
    //  Filtered project, Project selection status, and project select hooks context
    const { filteredProjects, setIsSelected, setSelect } = useContext(ProjectContext)

    // React hooks
    // 1. Login steps
    const [steps, setSteps] = useState(2)
    // 2. Status
    const [status] = useState(null)
    // 3. Is login button disabled status
    const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true)

    let isProjectSelected = false

    // Get project details from session storage, if available
    if(sessionStorage.getItem('Project')) {
        isProjectSelected = true
    }

    const urlParams = new URLSearchParams(window.location.search)
    const authorizationCode = urlParams.get('code')

    useEffect(() => {
        // When authorization code is available, redirect user for login
        if (authorizationCode != null) {
            authenticationService.login(authorizationCode)
        }
    }, [authorizationCode])
    
    useEffect(() => {
        // redirect to login step if not logged in
        if (!authenticationService.currentUserValue) { 
            setSteps(1);
        } else {
            // redirect to project selector if project is not selected
            if (!isProjectSelected) { 
                setSteps(2);
            }
        }

        // redirect to home if already logged in
        if (authenticationService.currentUserValue && isProjectSelected) { 
            history.push('/');
        }
    }, [isProjectSelected])

    // Event handler: Change login button status
    const toggleEnableLoginButton = () => {
        setIsLoginButtonDisabled(!isLoginButtonDisabled)
    }

    // Project table header
    const headers = [
        {
            name: "ProjectName",
            label: "Project Name",   
        },
        {
            name: "ProjectDescription",
            label: "Project Description",
        },
        {
            name: "Organisation",
            label: "Organisation",
            options: {
                // define the row value to Full Name
                customBodyRender: (value) => {
                    return value.OrganisationName;
                },
            }
        },
        {
            name: "CreationDate",
            label: "Created",
            options: {
                // clean create date
                customBodyRender: (value) => {
                    return `${value.split("T")[0]}`;
                },
            }
        },
        {
            name: "",
            label: "",
            options: {
                // Select Project
                customBodyRenderLite: (dataIndex) => {
                    return (
                        // button to select project
                        <button type="submit" className="btn btn-primary"  onClick={() => { 
                            sessionStorage.setItem('Project', JSON.stringify(filteredProjects[dataIndex]));
                            // set state to be already selected
                            setIsSelected(true)
                            // set state to project data
                            setSelect(filteredProjects[dataIndex])
                            // check history and redicrect to last page or home page
                            // const { from } = location.pathname || { from: { pathname: "/" } };
                            history.push('/');
                        }}>
                            Select Project                          
                        </button>
                    );
                }
            }
        }
    ];

    return (
        // Container for the background
        <Grid container direction="row" justifyContent="center" >
            <Box style={{            
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100vw',
                height: '90vh',
                backgroundImage: `url(${Background})`,
                }} >
            
                    
                {/* Contianer for the dark overlay */}
                <Box style={{            
                    width: "100vw",
                    height: '90vh',
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    }} >
            
                    {/* Contianer for the steps to be in middle */} 
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',

                    }}>
                        
                        {/* Underlying box for step components */} 
                        <Paper elevation={3} sx={{backgroundColor : '#ffffff',  margin: '10vh', padding: 8, maxHeight: '80vh', overflow: 'auto'}} >
                            {/* Step 1 - Login Page */}
                            { !authenticationService.currentUserValue  && steps === 1 &&
                                <Formik
                                    onSubmit={() => {
                                        // setStatus();
                                        const response_type = "code"
                                        const client_id = encodeURIComponent(process.env.REACT_APP_PWC_ID_CLIENT_ID)
                                        const redirect_url = encodeURIComponent(process.env.REACT_APP_PWC_ID_REDIRECT_URI)
                                        const scope = encodeURIComponent(process.env.REACT_APP_PWC_ID_SCOPE)
                                        const state = uuidv4();               
                                        const url = `${process.env.REACT_APP_PWC_ID_AUTH_URL}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_url}&scope=${scope}&state=${state}`
                                        window.location.assign(url);
                                    }}
                                >
                                    {({ errors, status, touched, isSubmitting }) => (
                                        <Form sx={{'alginItems':'center'}}>
                                            <div style={{ display: "flex",  justifyContent: "center"}} >
                                                <img src={logo} width="100" height="65" sx={{jxustify: 'center'}} alt="" />
                                            </div>                            
                                            {/* <br/> */}
                                            {/* <h2 style={{ display: "flex",  justifyContent: "center", fontFamily: "Georgia, serif"}}> Voice Detect </h2> */}
                                            {/* <br/> */}
                                            <div style={{ display: "flex", width:450}} >
                                                <p>Welcome to Voice Detect!</p>
                                            </div>
                                            <div style={{ display: "flex", width:450}} >
                                                <p>This website is for authorised users only.</p>
                                            </div>
                                            <div style={{ display: "flex", width:450}} >
                                                <label style={{ fontSize: "17px"}}>
                                                    <input 
                                                        type="checkbox"
                                                        defaultChecked={!isLoginButtonDisabled}
                                                        onChange={toggleEnableLoginButton}></input> I acknowledge that I have read and understand the <a href={PrivacyStatement} target="_blank" rel="noreferrer">Privacy Statement</a> and <a href={TermsOfUse} target="_blank" rel="noreferrer">Terms of Use</a>
                                                </label>
                                            </div>
                                            <br/>
                                            <div style={{ display: "flex",  justifyContent: "center"}} >
                                                <button 
                                                    type="submit" 
                                                    style={{width:450}} 
                                                    className="btn btn-primary" 
                                                    disabled={isLoginButtonDisabled}>
                                                    Login                            
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            }

                            {
                                authenticationService.authenticationError && 
                                <>
                                    <hr/>
                                    <div style={{ display: "flex",  justifyContent: "center", textAlign: "center"}}>
                                        {authenticationService.authenticationError.message_row1}
                                        <br/>
                                        {authenticationService.authenticationError.message_row2}
                                    </div>
                                </>
                            }
                            
                            {/* Step 2 For selecting project context */}
                            {authenticationService.currentUserValue && steps === 2 &&
                                <>
                                {status &&
                                    <div className={'alert alert-danger'}>{status}</div>
                                }

                                <div style={{ display: "flex",  justifyContent: "center"}} >
                                    <h5> Select Project </h5>
                                </div>   
                                <br/>

                                <ThemeProvider theme={createTheme()}>
                                    {filteredProjects &&
                                        <MUIDataTable
                                        data={filteredProjects}
                                        columns={headers}
                                        options={{
                                            selectableRows: "none",
                                            print: false,
                                            filter: false,
                                            download: false,
                                            search: false,
                                            viewColumns: false,
                                            rowsPerPage: 5,
                                            rowsPerPageOptions: []

                                        }}
                                        />
                                    }
                                </ThemeProvider>
                                </>
                            }
                        </Paper>

                        
                    </Box>
                </Box>  
            </Box>  
        </Grid>  
    )
}