import React, { useContext, useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, FormControlLabel, FormGroup, Select, MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import ReviewContext from '../../../../contexts/ReviewContext';

const theme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: '3pt',
            fontSize: '0.8rem'
          },
        },
      },
    },
  });

export default function UploadBatchReviewOutcome() {
    // Review context
    // 1. Selected review outcome master
    // 2. Selected review outcome tags
    // 3. Review outcome tags Status
    // 4. Set review outcome tags status (React hooks)
    // 5. Review outcome data
    // 6. Set review outcome data (React hooks)
    // 7. Upload batch files queue
    const { 
        selectedReviewOutcomeMaster, 
        selectedReviewOutcomeTags, 
        reviewOutcomeTagsStatus, 
        setReviewOutcomeTagsStatus, 
        reviewOutcomeData, 
        setReviewOutcomeData,
        uploadBatchFilesQueue
    } = useContext(ReviewContext)

    // React hooks
    // 1. Is content open state
    const [ isContentOpen, setIsContentOpen ] = useState(true)
    // 2. Display review outcome content object
    const [ displayReviewOutcomeContent, setDisplayReviewOutcomeContent ] = useState({})
    // 2. Display review outcome tags object
    const [ displayReviewOutcomeTags, setDisplayReviewOutcomeTags ] = useState({})

    useEffect(() => {
        // When Selected review outcome master and Selected review outcome tags are loaded
        // Set Display review outcome content, Review outcome data, Review outcome tags status, and Display review outcome tags
        if (Object.keys(selectedReviewOutcomeMaster).length > 0) {
            const reviewOutcomeMasterData = {
                "Relevancy": selectedReviewOutcomeMaster.Relevancy === "" ? "Relevant" : selectedReviewOutcomeMaster.Relevancy,
                "Speaker 1": selectedReviewOutcomeMaster.Speaker1,
                "Speaker 1 Organisation": selectedReviewOutcomeMaster.Speaker1Organisation,
                "Speaker 2": selectedReviewOutcomeMaster.Speaker2,
                "Speaker 2 Organisation": selectedReviewOutcomeMaster.Speaker2Organisation
            }

            setDisplayReviewOutcomeContent(reviewOutcomeMasterData)

            const reviewOutcomeMasterFormData = {
                "Id": selectedReviewOutcomeMaster.Id,
                "Relevancy": selectedReviewOutcomeMaster.Relevancy === "" ? "Relevant" : selectedReviewOutcomeMaster.Relevancy,
                "Speaker1": selectedReviewOutcomeMaster.Speaker1,
                "Speaker1Organisation": selectedReviewOutcomeMaster.Speaker1Organisation,
                "Speaker2": selectedReviewOutcomeMaster.Speaker2,
                "Speaker2Organisation": selectedReviewOutcomeMaster.Speaker2Organisation
            }

            setReviewOutcomeData(reviewOutcomeMasterFormData)
        }

        if (selectedReviewOutcomeTags.length > 0) {
            const initialReviewOutcomeTags = selectedReviewOutcomeTags.reduce((reviewOutcomeTagsObject, reviewOutcomeTag) => {
                return {...reviewOutcomeTagsObject, [reviewOutcomeTag.Id.toString()]: reviewOutcomeTag.TagValue}
            }, {})

            setReviewOutcomeTagsStatus(initialReviewOutcomeTags)
            setDisplayReviewOutcomeTags(selectedReviewOutcomeTags)
        }
    }, [selectedReviewOutcomeMaster, selectedReviewOutcomeTags])

    // Event handler: Change review outcome tag
    const changeReviewOutcomeTagHandler = (event) => {
        setReviewOutcomeTagsStatus({
            ...reviewOutcomeTagsStatus,
            [event.target.name]: event.target.checked,
        });
    };

    // Event handler: Change review outcome
    const changeReviewOutcomeHandler = (event) => {
        const updatedReviewOutcomeData = {...reviewOutcomeData}
        updatedReviewOutcomeData[event.target.name] = event.target.value

        setReviewOutcomeData(updatedReviewOutcomeData)
    }

    return (
        <ThemeProvider theme={theme}>
            {
                Object.keys(displayReviewOutcomeContent).length > 0 && Object.keys(displayReviewOutcomeTags).length > 0 && uploadBatchFilesQueue.length > 0 &&
                <Paper sx={{padding: 2, marginBottom: "20px", textAlign: 'left'}} elevation={4} >
                    <div style={{ textAlign: 'center', height: "25px", cursor: "pointer"}} onClick={() => {setIsContentOpen(!isContentOpen)}}>
                        <h5 style={{display: "inline-block"}}>Review outcome</h5>
                        {
                            isContentOpen && 
                            <h5 style={{display: "inline-block", float: "right"}}><KeyboardArrowUp/> </h5>
                        }
                        {
                            !isContentOpen && 
                            <h5 style={{display: "inline-block", float: "right"}}><KeyboardArrowDown/> </h5>
                        }
                    </div>
                    {
                        isContentOpen &&
                        <div>
                            <FormGroup>
                                <TableContainer sx={{margin: "2px"}}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Property</TableCell>
                                            <TableCell align="left">Value</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {
                                            Object.entries(displayReviewOutcomeContent).map(([key,value]) => {
                                                return (
                                                    <TableRow key={key.replace(/\s+/g, '')}>
                                                        <TableCell align="left" size='medium'>{key}</TableCell>
                                                        <TableCell align="left" size='medium'>
                                                            {
                                                                key === "Relevancy" &&
                                                                <Select
                                                                    value={reviewOutcomeData[key.replace(/\s/g, "")]}
                                                                    name={key.replace(/\s/g, "")}
                                                                    onChange={changeReviewOutcomeHandler}
                                                                    >
                                                                    <MenuItem value="Relevant">Relevant</MenuItem>
                                                                    <MenuItem value="Irrelevant">Irrelevant</MenuItem>
                                                                </Select>
                                                            }
                                                            {
                                                                key !== "Relevancy" &&
                                                                <TextField 
                                                                    value={reviewOutcomeData[key.replace(/\s/g, "")]} 
                                                                    name={key.replace(/\s/g, "")}
                                                                    size="small"
                                                                    onChange={changeReviewOutcomeHandler}/>
                                                            }
                                                            
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {
                                    displayReviewOutcomeTags.map((reviewOutcomeTag) => {
                                        return (
                                            <FormControlLabel 
                                                key={reviewOutcomeTag.Id}
                                                control={
                                                    <Checkbox 
                                                        checked={reviewOutcomeTagsStatus[reviewOutcomeTag.Id]}
                                                        name={reviewOutcomeTag.Id.toString()}
                                                        onChange={changeReviewOutcomeTagHandler}
                                                    />
                                                }
                                                label={reviewOutcomeTag.IssueTag.Tag}
                                            />
                                        )
                                    })
                                }
                            </FormGroup>
                        </div>
                    }
                </Paper>
            }
        </ThemeProvider>
    )
}