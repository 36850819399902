import React, {useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { authenticationService } from '../../../Services/_services/authenticationService';
import useFetch from '../../../../contexts/services/useFetch';
import ProjectContext from '../../../../contexts/ProjectContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TranscriptTable(props) {
  // Selected File ID property 
  const { fileId } = props

  // Project context
  // 1. Selected project
  const { selectedproject } = useContext(ProjectContext)

  // API data pull
  // 1. Transcription
  const { data: rawTranscription, isPending: rawTranscriptionIsLoading } = useFetch(`${process.env.REACT_APP_API_URL}/Transcription/${selectedproject.Id}/File/${fileId}`, authenticationService.currentUserValue, "Transcription")

  // React hooks
  // 1. Review object
  const [ review, setReview ] = useState({})
  // 2. Automated transcript list
  const [ automatedTranscript, setAutomatedTranscript] = useState([])
  // 3. Manual transcript list
  const [ manualTranscript, setManualTranscript] = useState([])
  // 4. Transcript tab selection
  const [tabValue, setTabValue] = useState(0);

  // Event handler: Change transcription tabs selection
  const changeTranscriptionTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // When transcription data is fully loaded, set automated and manual transcript state
    if (!rawTranscriptionIsLoading) {
        const automatedTranscription = rawTranscription.find(transcription => transcription.TranscriptionType === "Automated")
        const sortedAutomatedTranscription = JSON.parse(automatedTranscription.TranscriptionText)
        setAutomatedTranscript(sortedAutomatedTranscription)

        const manualTranscription = rawTranscription.find(transcription => transcription.TranscriptionType === "Manual")
        const sortedManualTranscription = JSON.parse(manualTranscription.TranscriptionText).sort((a, b) => a.order - b.order)
        setManualTranscript(sortedManualTranscription)
    }
  }, [rawTranscriptionIsLoading])

  const theme = createTheme({
    components: {
      // Name of the component
      MuiDataGrid: {
        styleOverrides: {
          // Name of the slot
          root: {
            '& p': {
                marginBottom: '0.2rem !important',
                marginTop: '0.2rem !important'
            }
          },
        },
      },
    },
  });

  const FilterOnlyToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton/>
      </GridToolbarContainer>
    )
  }

  // Transcription table header
  const columns = [
    {
      field: 'time',
      headerName: 'Time',
      minWidth: 100,
      // align: 'center',
      editable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'speaker',
      headerName: 'Speaker',
      minWidth: 150,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        review[`Speaker ${params.value}`] ? (review[`Speaker ${params.value}`].Value !== " "? review[`Speaker ${params.value}`].Value : `Speaker ${params.value}`): `Speaker ${params.value}`
        )
    },
    {
      field: 'transcript',
      headerName: 'Transcript',
      minWidth: 560,
      flex: 10,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      overflowWrap: 'break-word',
      renderCell: (params) => (
        <p style={{display: 'inline-block', verticalAlign: "middle"}}> {params.value} </p>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={changeTranscriptionTabs} aria-label="transcription tabs">
              <Tab label="Original" {...a11yProps(0)} />
              <Tab label="Reviewed" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            { automatedTranscript && automatedTranscript.length !== 0 &&
              <>
                <div style={{ height: 700, width: '100%' }}>
                  <div style={{ height: 700, width: '100%' }}>
                    <DataGrid
                      getRowHeight={() => 'auto'} 
                      rows={automatedTranscript}
                      columns={columns}
                      pageSize={100}
                      disableSelectionOnClick
                      hideFooter={true}
                      components={{ Toolbar: FilterOnlyToolbar }}
                    />
                  </div>
                </div>
              </>
            }
            { automatedTranscript && automatedTranscript.length === 0 &&
              <span> No Original Transcript Available </span>
            }
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            { manualTranscript && manualTranscript.length !== 0 &&
              <>
                <div style={{ height: 700, width: '100%' }}>
                  <div style={{ height: 700, width: '100%' }}>
                    <DataGrid
                      getRowHeight={() => 'auto'} 
                      rows={manualTranscript}
                      columns={columns}
                      pageSize={100}
                      disableSelectionOnClick
                      hideFooter={true}
                      components={{ Toolbar: FilterOnlyToolbar }}
                    />
                  </div>
                </div>
              </>
            }
            { manualTranscript && manualTranscript.length === 0 &&
              <span> No Reviewed Transcript Available </span>
            }
          </TabPanel>
        </Box>
      </>
      
    </ThemeProvider>
  );
}
