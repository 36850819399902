import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Box } from '@mui/material';
import OriginalTable from './OriginalTable'
import ReviewTable from './ReviewTable'

// Context
import ReviewContext from '../../../../contexts/ReviewContext'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Transcripts() {
  // Review context
  // 1. Original transcript
  // 2. Reviewed transcript
  // 3. Selected transcript tab
  // 4. Review batch files queue
  const { 
    originalTranscript, 
    reviewedTranscript, 
    selectedTranscriptTab,
    reviewBatchFilesQueue
  } = useContext(ReviewContext);

  return (
    <div>
      { reviewBatchFilesQueue.length > 0 &&
        <Paper sx={{marginBottom: "20px", textAlign: 'center'}} elevation={4}>
          {
            originalTranscript && originalTranscript.length !== 0 && reviewedTranscript && reviewedTranscript.length === 0 &&
            <div>
              <TabPanel value={selectedTranscriptTab} index={0}>
                <OriginalTable /> 
              </TabPanel>
              <TabPanel value={selectedTranscriptTab} index={1}>
                <ReviewTable />
              </TabPanel>
            </div>
          }
          {originalTranscript && originalTranscript.length !== 0 && reviewedTranscript && reviewedTranscript.length !== 0 &&
            <div>
              <TabPanel value={selectedTranscriptTab} index={0} style={{padding: 0}}>
                <ReviewTable />
              </TabPanel>
              <TabPanel value={selectedTranscriptTab} index={1}>
                <OriginalTable />
              </TabPanel>
            </div>
          }
        </Paper> 
      }
    </div>
  );
}
