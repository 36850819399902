// React and MUI Components
import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, MenuItem, InputLabel, Select } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles"
import MUIDataTable from "mui-datatables";

// authentication
import { authHeader } from '../../Services/_helpers/auth-header';

// Component
import Modal from '../SharedComponents/Modal';
import useFetch from '../../../contexts/services/useFetch';
import { authenticationService } from '../../Services/_services/authenticationService';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function UsersSiteAdmin() {
  // React hooks
  // 1. Organisation list
  const [ organisations, setOrganisations ] = useState([])
  // 2. New user form completion status
  const [ isNewUserFormCompleted, setIsNewUserFormCompleted ] = useState(false)
  // 3. User object
  const [selectedUser, setSelectedUser] = useState({
    "OrganisationId": "",
    "UID": "",
    "FirstName": "",
    "LastName": "",
    "EmailAddress": "",
    "SiteAdministrator": false,
    "OrganisationAdministrator": false
  })
  // 4. Progress status 
  const [status, setStatus] = useState()
  // 5. Users list
  const[ users, setUsers ] = useState([])

  // API data pull
  // 1. Users
  const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/`, authenticationService.currentUserValue, "User")
  // 2. Organisation
  const { data: organisationData, isPending: organisationDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Organisation/SiteAdmin`, authenticationService.currentUserValue, "Organisation")

  // Redux
  // 1. Site admin access denied status
  let isSiteAdminAccessDenied = useSelector((state) => state.fetchDataStatus.isSiteAdminAccessDenied)

  useEffect(() => {
    // Three conditions to set User state:
    // 1. Users API data is fully loaded
    // 2. Organisation API data is fully loaded
    // 3. Site administrator access level is required (Only site admin can open this page) 
    if (!userDataIsLoading && !organisationDataIsLoading && !isSiteAdminAccessDenied) {
      // Filter users by termination date, only active users are loaded
      const ActiveUsers = userData.filter((rows) => rows.TerminationDate ===  "9999-12-31T00:00:00").map(item => {
        const searchOrganisation = organisationData.filter(organisation => organisation.Id === item.OrganisationId)
        return {
          ...item,
          OrganisationName: searchOrganisation.length > 0 ? searchOrganisation[0].OrganisationName : ""
        }
      })

      setUsers(ActiveUsers)
    }
  }, [userDataIsLoading, organisationDataIsLoading, isSiteAdminAccessDenied])

  useEffect(() => {
    // Two conditions to set Organisation and User state:
    // 1. Organisation API data is fully loaded
    // 2. Site administrator access level is required (Only site admin can open this page) 
    if (!organisationDataIsLoading && !isSiteAdminAccessDenied) {
      // Re-map organisation data into ID and name
       const mappedOrganisationData = organisationData.map(organisation => ({
         id: organisation.Id,
         name: organisation.OrganisationName
       }))
       setOrganisations(mappedOrganisationData)
       setSelectedUser({
          "OrganisationId": mappedOrganisationData[0].id,
          "UID": "",
          "FirstName": "",
          "LastName": "",
          "EmailAddress": "",
          "SiteAdministrator": false,
          "OrganisationAdministrator": false
      })
    } 
  }, [organisationDataIsLoading, isSiteAdminAccessDenied])

  // Event handler: Change selectedUser state and validate new user form
  const handleInput = (event) => {
    let updatedItem = selectedUser
    updatedItem[event.target.name] = event.target.value;
    setSelectedUser(updatedItem);

    // Validation rule
    // 1. First name      -> Not empty string
    // 2. Last name       -> Not empty string
    // 3. Email address   -> Not empty string
    if (selectedUser["FirstName"] !== "" && selectedUser["LastName"] !== "" && selectedUser["EmailAddress"] !== "") {
      setIsNewUserFormCompleted(true)
    } else {
      setIsNewUserFormCompleted(false)
    }
  }
  
  // Clear all inputs when modals are closed
  const clearInput = ()  => {
    setSelectedUser({
      "OrganisationId": "",
      "UID": "",
      "FirstName": "",
      "LastName": "",
      "EmailAddress": "",
      "SiteAdministrator": false,
      "OrganisationAdministrator": false
    });
    setIsNewUserFormCompleted(false)
  }

  // Refresh user state by calling User API
  const fetchUserData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/User/`, {
      headers: authHeader()
    })
    .then(resp => {
      // Filter users by termination date, only active users are loaded
      const ActiveUsers = resp.data.filter((rows) => rows.TerminationDate ===  "9999-12-31T00:00:00").map(item => {
        const searchOrganisation = organisationData.filter(organisation => organisation.Id === item.OrganisationId)
        return {
          ...item,
          OrganisationName: searchOrganisation.length > 0 ? searchOrganisation[0].OrganisationName : ""
        }
      })

      setUsers(ActiveUsers)
    })
    .catch(err => {
      if (err.response.status === 401 || err.response.status === 403) {
        console.info("JWT token expired")
        authenticationService.logout();
      } else {
        console.info(err)
      }
      
    })
  }

  const convertBooleanIntoInteger = (item) => {
    if (item) {
      return 1
    } else {
      return 0
    }
  }

  // Event handler: Register new user
  const handleSubmit = async () => {
    let orgId = 1
    if (organisationData.length > 0) {
      orgId = organisationData[0].Id
    }

    const postNewUserData = {
      "organisation_id": selectedUser["OrganisationId"] === "" ? orgId : selectedUser["OrganisationId"],
      "uid": selectedUser["UID"],
      "first_name": selectedUser["FirstName"],
      "last_name":  selectedUser["LastName"],
      "email_address": selectedUser["EmailAddress"],
      "site_administrator": selectedUser["SiteAdministrator"] === undefined ? true : selectedUser["SiteAdministrator"],
      "organisation_administrator": selectedUser["OrganisationAdministrator"] === undefined ? true : selectedUser["OrganisationAdministrator"],
    }

    // Register new user through '/User/Create' API
    axios.post(`${process.env.REACT_APP_API_URL}/User/Create`, postNewUserData, { 
      headers: authHeader()
    })
    .then(createNewUserResponse => {
      if (createNewUserResponse.status === 200) {
        let adminRole = ""
        if (postNewUserData["site_administrator"]) {
          adminRole = " [Site admin]"
        }

        if (postNewUserData["organisation_administrator"]) {
          adminRole = " [Organisation admin]"
        }

        if (postNewUserData["site_administrator"] && postNewUserData["organisation_administrator"]) {
          adminRole = " [Site admin; Organisation admin]"
        }

        setStatus("User is successfully created")
        fetchUserData()
      }
    })
    .catch(error => {
      console.info("New user creation error")
      console.info(error)
    })
  }

  // Event handler: Update user
  const updateUsers = async (dataIndex, userId) => {
    const updatedUserData = {
      "OrganisationId":  selectedUser["OrganisationId"] === undefined ? users[dataIndex].OrganisationId : selectedUser["OrganisationId"],
      "UID": selectedUser["UID"] === undefined ? users[dataIndex].UID : selectedUser["UID"],
      "FirstName": selectedUser["FirstName"] === undefined ? users[dataIndex].FirstName : selectedUser["FirstName"],
      "LastName":  selectedUser["LastName"] === undefined ? users[dataIndex].LastName : selectedUser["LastName"],
      "EmailAddress": selectedUser["EmailAddress"] === undefined ? users[dataIndex].EmailAddress : selectedUser["EmailAddress"],
      "SiteAdministrator": selectedUser["SiteAdministrator"] === undefined ? convertBooleanIntoInteger(users[dataIndex].SiteAdministrator) : convertBooleanIntoInteger(selectedUser["SiteAdministrator"]),
      "OrganisationAdministrator": selectedUser["OrganisationAdministrator"] === undefined ? convertBooleanIntoInteger(users[dataIndex].OrganisationAdministrator) : convertBooleanIntoInteger(selectedUser["OrganisationAdministrator"]),
    }

    const updatedForm = []

    if (users[dataIndex].FirstName !== selectedUser["FirstName"]) {
      updatedForm.push("First name")
    }

    if (users[dataIndex].LastName !== selectedUser["LastName"]) {
      updatedForm.push("Last name")
    }

    if (users[dataIndex].EmailAddress !== selectedUser["EmailAddress"]) {
      updatedForm.push("Email")
    }

    // Update user through '/User/Update/' API
    axios.put(`${process.env.REACT_APP_API_URL}/User/Update/${userId}`, updatedUserData, { 
      headers: authHeader()
    })
    .then(updateUserResponse => {
      if (updateUserResponse.status === 200) {
        setStatus("User is successfully updated")
        fetchUserData()
      }
    })
  }

  // Event handler: Delete user
  const deleteUsers = async (dataIndex, userId) => {
    // Delete user through '/User/Delete/' API
    axios.delete(`${process.env.REACT_APP_API_URL}/User/Delete/${userId}`, { 
      headers: authHeader()
    })
    .then(updateUserResponse => {
      if (updateUserResponse.status === 200) {
        setStatus("User is successfully removed")
        fetchUserData()
      }
    }).catch(error => {

      console.info("Delete user error")
      console.info(error)
    })
  }

  // User table header
  const headers = [
    {
      name: "OrganisationName",
      label: "Organisation",   
    },      
    {
      name: "UID",
      label: "UID",
      options: {
        filter: true,
        filterType: 'textField'
      } 
    },
    {
      name: "FirstName",
      label: "First Name",
      options: {
        filter: true,
        filterType: 'textField'
      }  
    },
    {
      name: "LastName",
      label: "Last Name",
      options: {
        filter: true,
        filterType: 'textField'
      } 
    },
    {
      name: "EmailAddress",
      label: "Email Address",
      options: {
        filter: true,
        filterType: 'textField'
      } 
    },
    {
      name: "SiteAdministrator",
      label: "Site Administrator",
      options: {
        customBodyRender: (value) => {

          if (value) {
            return "Yes"
          }
          else {
            return "No"
          }
        }
      }
    },
    {
      name: "OrganisationAdministrator",
      label: "Organisation Administrator",
      options: {
        customBodyRender: (value) => {

          if (value) {
            return "Yes"
          }
          else {
            return "No"
          }
        }
      }
    }          
    
  ];
  
  const edit = {
    name: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Modal 
            titlename = {"Edit User"} 
            idx = {users[dataIndex]["Id"]}
            handleSubmit = {() => {updateUsers(dataIndex, users[dataIndex]["Id"])}}
            clearInput = {clearInput} 
            disableSaveButton={!isNewUserFormCompleted}
            initialData = {() => {setSelectedUser({
              "OrganisationId": users[dataIndex].OrganisationId,
              "FirstName": users[dataIndex].FirstName,
              "LastName": users[dataIndex].LastName,
              "EmailAddress": users[dataIndex].EmailAddress
            })}}>
            <InputLabel id="organisationLabel">Organisation</InputLabel>
            {
                organisations.length > 0 && 
                <Select
                    labelId="organisationLabel"
                    id="OrganisationId"
                    name="OrganisationId"
                    defaultValue={users[dataIndex].OrganisationId}
                    style = {{width: "100%"}} 
                    onChange={handleInput}
                >
                    {
                        organisations.map((organisation, index) => {
                            return <MenuItem key={index} value={organisation.id}>
                                {organisation.name}
                            </MenuItem>
                        })
                    }
                </Select>
            }
            <br/>            
            <InputLabel id="UID-label">UID</InputLabel>
            <TextField id="UID" 
                    name="UID"
                    type = "text"
                    style = {{width: 250}} 
                    onChange={handleInput}
                    defaultValue = {users[dataIndex].UID}
                    variant="outlined"
                    /> 
            <br/>
            <InputLabel id="FirstName-label">First Name</InputLabel>
            <TextField id="FirstName" 
                    name="FirstName"
                    type = "text"
                    style = {{width: 250}} 
                    onChange={handleInput}
                    defaultValue = {users[dataIndex].FirstName}
                    variant="outlined"
                    /> 
            <br/>
            <InputLabel id="LastName-label">Last Name</InputLabel>
            <TextField id="LastName" 
                    name="LastName"
                    type = "text"
                    style = {{width: 250}} 
                    onChange={handleInput}
                    defaultValue = {users[dataIndex].LastName}
                    variant="outlined"
                    /> 
            <br/>
            <InputLabel id="EmailAddress-label">Email Address</InputLabel>
            <TextField id="EmailAddress" 
                    name="EmailAddress"
                    type = "email"
                    style = {{width: 250}} 
                    onChange={handleInput}
                    defaultValue = {users[dataIndex].EmailAddress}
                    variant="outlined"
                    /> 
            <br/>
            <InputLabel id="SiteAdministrator-label">Site Administrator</InputLabel>
            <Select
                  labelId="SiteAdministrator-label"
                  id="SiteAdministrator"
                  name="SiteAdministrator"
                  defaultValue = {users[dataIndex].SiteAdministrator}
                  style = {{width: 200}} 
                  onChange={handleInput}
                >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
            </Select>       
            <InputLabel id="OrganisationAdministrator-label">Organisation Administrator</InputLabel>
            <Select
                  labelId="OrganisationAdministrator-label"
                  id="OrganisationAdministrator"
                  name="OrganisationAdministrator"
                  defaultValue = {users[dataIndex].OrganisationAdministrator}
                  style = {{width: 200}} 
                  onChange={handleInput}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
              </Select> 
          </Modal>
        );
      }
    }
  }

  const Delete = {
    name: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Modal 
          titlename = {"Delete User"} 
          idx = {users[dataIndex]["Id"]}
          handleSubmit = {() => deleteUsers(dataIndex, users[dataIndex]["Id"])}
          saveButtonLabel = "Delete"
          clearInput = {clearInput} >
            
            Are you sure you want to delete the user?
          </Modal>
        );
      }
    }
  }
  
    
  
  return (
    <Grid container  direction="row" justifyContent="center" marginTop={5} >
      <Box elevation = {0} sx={{ width: '90%', height: '100%' }}>
        {
          !isSiteAdminAccessDenied &&
          <ThemeProvider theme={createTheme()}>
            {users && <Modal           
              titlename = {"Add User"} 
              idx = {users.length + 1}
              handleSubmit = {handleSubmit}
              clearInput = {clearInput} 
              disableSaveButton={!isNewUserFormCompleted}>
                  <InputLabel id="organisationLabel">Organisation</InputLabel>
                  {
                      organisations.length > 0 && 
                      <Select
                          labelId="organisationLabel"
                          id="OrganisationId"
                          name="OrganisationId"
                          defaultValue={organisations[0].id}
                          style = {{width: "100%"}} 
                          onChange={handleInput}
                      >
                          {
                              organisations.map((organisation, index) => {
                                  return <MenuItem key={index} value={organisation.id}>
                                      {organisation.name}
                                  </MenuItem>
                              })
                          }
                      </Select>
                  }
                  <br/>                    
                  <InputLabel id="UID-label">UID</InputLabel>
                  <TextField id="UID" 
                          name="UID"
                          type = "text"
                          style = {{width: 250}} 
                          onChange={handleInput}
                          variant="outlined"
                          /> 
                  <br/>
                  <InputLabel id="FirstName-label">First Name</InputLabel>
                  <TextField id="FirstName" 
                          name="FirstName"
                          type = "text"
                          style = {{width: 250}} 
                          onChange={handleInput}
                          variant="outlined"
                          /> 
                  <br/>
                  <InputLabel id="LastName-label">Last Name</InputLabel>
                  <TextField id="LastName" 
                          name="LastName"
                          type = "text"
                          style = {{width: 250}} 
                          onChange={handleInput}
                          variant="outlined"
                          /> 
                  <br/>
                  <InputLabel id="EmailAddress-label">Email Address</InputLabel>
                  <TextField id="EmailAddress" 
                          name="EmailAddress"
                          type = "email"
                          style = {{width: 250}} 
                          onChange={handleInput}
                          variant="outlined"
                          /> 
                  <br/>
                  <InputLabel id="SiteAdministrator-label">Site Administrator</InputLabel>
                  <Select
                        labelId="SiteAdministrator-label"
                        id="SiteAdministrator"
                        name="SiteAdministrator"
                        defaultValue={false}
                        style = {{width: 200}} 
                        onChange={handleInput}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>       
                  <InputLabel id="OrganisationAdministrator-label">Organisation Administrator</InputLabel>
                  <Select
                      labelId="OrganisationAdministrator-label"
                      id="OrganisationAdministrator"
                      name="OrganisationAdministrator"
                      defaultValue={false}
                      style = {{width: 200}} 
                      onChange={handleInput}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                  </Select>
                <br/>
            </Modal>}
            <br/>
            <br/>
              {status && <div className={'alert alert-danger'}>{status} </div>}
              {users  &&
                <MUIDataTable
                  data={users}
                  columns={headers.concat(edit).concat(Delete)}
                  options={{
                    selectableRows: "none",
                    print: false,
                    viewColumns: false

                  }}
                />
              }
          </ThemeProvider>
        }
        {
            isSiteAdminAccessDenied &&
            <div style={{textAlign: 'center'}}>
                You're unauthorised to access this page
            </div>
        }
      </Box>
    </Grid>

    );
  };


