import React, { useContext, useEffect, useRef, useState } from 'react';
// Material UI Elements
import MUIDataTable from "mui-datatables";
import {Select, MenuItem, TextField, InputLabel, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/material/styles"

//Services
// for calculating the time and seconds
import moment from "moment"
import { authenticationService } from '../../../Services/_services/authenticationService';

// for authentication
import { authHeader }  from '../../../Services/_helpers/auth-header';

//Contexts
import ReviewFileContext from '../../../../contexts/ReviewFileContext';
import ProjectContext from '../../../../contexts/ProjectContext';

import Modal from '../../SharedComponents/Modal';

import axios from 'axios';
import { useDispatch } from 'react-redux';

import { addDataFetchProcess, removeDataFetchProcess } from '../../../../features/fetchData/fetchDataStatusSlice';
import { addLog } from '../../../../features/activityLogs/activityLogsSlice';

import { NavLink } from 'react-router-dom';

import TranscriptTable from '../../Review/components/TranscriptTable'

import StatusContext from '../../../../contexts/StatusContext';

export default function FileTable(props) {
  const { setStatus, setOpen } = useContext(StatusContext)
  // context data for files and batches and its corresponding fetch functions
  const {
    fetchReviewFiles, 
    fetchReviewBatch, 
    progressedFiles, 
    fetchRawFiles, 
    fetchReviewOutcomeMaster,
    fetchTranscription
  } = useContext(ReviewFileContext)
  // state variable for what files are selected
  const[ selectedFiles, setSelectedFiles ] = useState([])
  // state variable of which Review Batch are selected
  const[ selectedReviewBatchId, setSelectedReviewBatchId ] = useState([])
  // state variable for which file indexes are selected
  const[ selectedFilesIndex, setSelectedFilesIndex ] = useState([])
  // context data for project
  const { selectedproject } = useContext(ProjectContext)
  const [reviewers, setReviewers] = useState([])
  const [workflowStep, setWorkflowStep] = useState([])
  const [uploadBatch, setUploadBatch] = useState([])

  const [ assignData, setAssignData ] = useState({
    "batchName": "",
    "batchDescription": "",
    "assignee": "",
    "workflowStep": ""
  })

  const [ filteredProgressedFiles, setFilteredProgressedFiles ] = useState([])

  const { workflowStatusId, reviewersData, workflowStepData, uploadBatchData } = props

  const [ isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
  const [ selectedFileId, setSelectedFileId ] = useState(0)

  const [ isAssignUserFormCompleted, setIsAssignUserFormCompleted ] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (progressedFiles && progressedFiles.length > 0) {
      const filteredData = progressedFiles.filter(file => {
        return file.WorkFlowStatus.Id === parseInt(workflowStatusId)
      })

      setFilteredProgressedFiles(filteredData) 
    }
  }, [progressedFiles])

  useEffect(() => {
    if (reviewersData && reviewersData.length > 0) {
      setReviewers(reviewersData)
    }
  }, [reviewersData])

  useEffect(() => {
    if (workflowStepData && workflowStepData.length > 0) {
      setWorkflowStep(workflowStepData)
    }
  }, [workflowStepData])

  useEffect(() => {
    if (uploadBatchData && uploadBatchData.length > 0) {
      setUploadBatch(uploadBatchData)
    }
  }, [uploadBatchData])

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (isTranscriptModalOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isTranscriptModalOpen]);

  const closeTranscriptModal = () => {
    setIsTranscriptModalOpen(false)
    setSelectedFileId(0)
  };

  const openTranscriptModal = (fileId) => {
    if (fileId !== 0) {
      setIsTranscriptModalOpen(true)
      setSelectedFileId(fileId)
    }
  };

  const revertFileIntoNew = (fileId) => {
    const putFile = {
      "Id": fileId,
      "WorkFlowStatusId": 1, // New
    }

    axios.put(`${process.env.REACT_APP_API_URL}/File/Update/${selectedproject.Id}/${fileId}`, putFile, { 
        headers: authHeader()
    })
    .then(updateFileResponse => {
        setStatus("File is successfully reverted to New")
        setOpen(true)

        fetchRawFiles()
        fetchReviewOutcomeMaster()
        fetchReviewBatch()
        fetchReviewFiles()
        fetchTranscription()
    })
    .catch(updateFileError => {
        setStatus("File update failed, please contact admin for troubleshooting")
        setOpen(true)
    })
  }

  // handle selection for checkboxes
  const handleSelect = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    // update the selected to toggle the checkbox
    const selectedFilesId = rowsSelected.map((index) => {
        return filteredProgressedFiles[index].Id
    })

    const selectedReviewBatchId = rowsSelected.map((index) => {
      return filteredProgressedFiles[index].ReviewBatchId
    })

    const sortedSelectedFilesId = selectedFilesId.sort((a, b) => {
      return a - b
    })
    
    const sortedRowsSelected = rowsSelected.sort((a, b) => {
      return a - b
    })

    setSelectedFiles(sortedSelectedFilesId)
    setSelectedFilesIndex(sortedRowsSelected);

    if (selectedReviewBatchId && selectedReviewBatchId.length > 0) {
      setSelectedReviewBatchId(selectedReviewBatchId[0])
    } else {
      setSelectedReviewBatchId(0)
    }
    
  }

  // theme overrides
  const myTheme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: '5pt',
          },
        },
      },
    }
  });

  // headers for batch table
    const headers = [
      {
        name: "Filename_project",
        label: "File ID",
        // disable filter using id
        options : {
          filterType: 'textField' 
        }
      },
      {
        name: "UploadBatchId",
        label: "Upload Batch",
        options : {
          filterType: "textField",
          customFilterListOptions: {
            render: v => {
              return "Upload batch: " + v
            }
          },
          customBodyRender: (value) => {
            if (uploadBatchData && uploadBatchData.length > 0) {
              const uploadBatchDataById = uploadBatchData.find(uploadBatch => uploadBatch.Id === parseInt(value))
              return uploadBatchDataById.Description
            } 
          }
        }
      },
      {
        name: "WorkflowStep",
        label: "Workflow Step",
        options: {
          filterType: "textField",
          customFilterListOptions: {
            render: v => {
              return "Workflow step: " + v
            }
          },
          customBodyRender: (value) => {
            return value.ShortDescription
          }
        }
      },
      {
        name: "Language",
        label: "Language",
        options: {
          // define filter type as multiselect
          filterType: 'multiselect',
          customFilterListOptions: {
            render: v => {
              return "Language: " + v
            }
          },
          // define the row value to Full Name
          customBodyRender: (value) => {
            switch (value) {
              case 'en-us':
                return "English"
              case 'zh-hk':
                return "Cantonese"
              case 'zh-cn':
                return "Mandarin"
              case 'ja-jp':
                return "Japanese"
              default:
                return ""
            }
          }
        }
      },
      {
        name: "Relevancy",
        label: "Relevancy",
        options: {
          filterType: 'multiselect',
          customFilterListOptions: {
            render: v => {
              return "Relevancy: " + v
            }
          },
          customBodyRender: (value) => {
            if (value === "") {
              return "-" 
            }

            return value
          }
        }
      },
      {
        name: "LastReviewBatch",
        label: parseInt(workflowStatusId) === 2 ? "Review Batch" : "Last Review Batch",
        options: {
          filterType: 'multiselect',
          customFilterListOptions: {
            render: v => {
              if (parseInt(workflowStatusId) === 2) {
                return "Review batch: " + v
              } else {
                return "Last review batch: " + v
              }
            }
          }
        }
      },
      {
        name: "LastReviewer",
        label: parseInt(workflowStatusId) === 2 ? "Reviewer" : "Last Reviewer",
        options: {
          filterType: 'multiselect',
          customFilterListOptions: {
            render: v => {
              if (parseInt(workflowStatusId) === 2) {
                return "Reviewer: " + v
              } else {
                return "Last reviewer: " + v
              }
            }
          },
          customBodyRender: (value) => {
            if (reviewers && reviewers.length > 0) {
              if (value !== "-" && value !== "") {
                const reviewerData = reviewers.find(reviewer => {
                  return reviewer.Id === parseInt(value)
                })
  
                return reviewerData.FullName
              } else {
                return "-"
              }
              
            } else {
              return "-"
            }
          }
        }
      },
      {
        name: "LastReviewTime",
        label: parseInt(workflowStatusId) === 2 ? "Assigned Date" : "Last Review Time",
        options: {
          filterType: 'textField',
          customFilterListOptions: {
            render: v => {
              if (parseInt(workflowStatusId) === 2) {
                return "Assigned date: " + v
              } else {
                return "Last review time: " + v
              }
            }
          },
          customBodyRender: (value) => {
            if (value !== "-" && value !== "") {
              return moment(value).format('MMMM Do YYYY, h:mm:ss A')
            } else {
              return "-"
            }
          }
        }
      },
      {
        name: "Id",
        label: "Actions",
        options: {
          customBodyRender: (value) => {
            // Cancelled tab only
            if (workflowStatusId === "5") {
              return (
                <div style={{textAlign: 'right'}}>
                  <button className="btn btn-primary" onClick={() => openTranscriptModal(value)}>Preview transcript</button>
                  <br/><br/>
                  <button className="btn btn-warning" onClick={() => revertFileIntoNew(value)}>Revert to New</button>
                </div>
              )
            } else {
              return (
                <div style={{textAlign: 'right'}}>
                  <button className="btn btn-primary" onClick={() => openTranscriptModal(value)}>Preview transcript</button>
                </div>
              )
            }


          }
        }
      }
    ]

    const updateAssignData = (event) => {
      const newAssignData = {...assignData}
      newAssignData[event.target.name] = event.target.value
      setAssignData(newAssignData)

      if (newAssignData["batchName"] !== "" && newAssignData["batchDescription"] !== "" && newAssignData["assignee"] !== "" && newAssignData["workflowStep"] !== "") {
        setIsAssignUserFormCompleted(true)
      } else {
        setIsAssignUserFormCompleted(false)
      }
    }

    const saveReviewBatch = () => {
      dispatch(addDataFetchProcess(4))

      // Create new review batch from "New", "Reviewed", and "Cancelled" workflow status
      if (parseInt(workflowStatusId) === 1 || parseInt(workflowStatusId) === 3 || parseInt(workflowStatusId) === 4) {
        // Create new review batch data
        // Create review batch POST data 
        const currentDate = new Date()        
        const postReviewBatchData = {
          "Id": 0,
          "BatchName": assignData.batchName,
          "BatchDescription": assignData.batchDescription,
          "CreationDate": currentDate.toISOString(),
          "CreatedBy": authenticationService.currentUserValue.id,
          "WorkflowStepId": assignData.workflowStep,
          "Assignee": assignData.assignee,
          "Completed": false,
          "created_at": currentDate.toISOString(),
          "updated_at": currentDate.toISOString(),
          "deleted_at": currentDate.toISOString()
        }

        axios.post(`${process.env.REACT_APP_API_URL}/ReviewBatch/Create/${selectedproject.Id}`, postReviewBatchData, { 
          headers: authHeader()
        })
        .then(createReviewBatchResponse => {
          if (createReviewBatchResponse.status === 200) {
            const currentTime = currentDate.toLocaleTimeString()

            dispatch(addLog({
              "statusCode": "SUCCESS",
              "status": "Success",
              "resourceType": "Review batch ",
              "message": " has been created",
              "timestamp": currentTime
            }))

            dispatch(removeDataFetchProcess(1))

            // Create new review batch file data
            // Create review batch file POST data
            const postReviewBatchFileData = []
            selectedFiles.forEach(file => {
                postReviewBatchFileData.push({
                  "Id": 0,
                  "ReviewBatchId": createReviewBatchResponse.data.Id,
                  "FileId": file,
                  "Completed": false,
                  "CompletionTime": currentDate.toISOString(),
                  "created_at": currentDate.toISOString(),
                  "updated_at": currentDate.toISOString(),
                  "deleted_at": currentDate.toISOString()
                })
            })

            axios.post(`${process.env.REACT_APP_API_URL}/ReviewBatchFile/Create/${selectedproject.Id}`, postReviewBatchFileData, { 
              headers: authHeader()
            })
            .then(createReviewBatchFileResponse => {
              if (createReviewBatchFileResponse.status === 200) {
                dispatch(addLog({
                  "statusCode": "SUCCESS",
                  "status": "Success",
                  "resourceType": "Review batch file",
                  "message": " has been created",
                  "timestamp": currentTime
                }))
    
                dispatch(removeDataFetchProcess(1))

                // Update review outcome master
                // Create review outcome master PUT data
                const putReviewOutcomeMaster = []
                createReviewBatchFileResponse.data.forEach(reviewBatchFileResponse => {
                  putReviewOutcomeMaster.push({
                    "FileId": reviewBatchFileResponse.FileId,
                    "WorkflowStepId": assignData.workflowStep,
                    "LastReviewBatch": createReviewBatchResponse.data.BatchName,
                    "LastReviewer": createReviewBatchResponse.data.Assignee,
                    "LastReviewTime": currentDate.toISOString(),
                    "updated_at": currentDate.toISOString()
                  })
                })

                axios.put(`${process.env.REACT_APP_API_URL}/ReviewOutcomeMaster/Multiple/Update/${selectedproject.Id}`, putReviewOutcomeMaster, { 
                  headers: authHeader()
                })
                .then(updateReviewOutcomeMasterResponse => {
                  if (updateReviewOutcomeMasterResponse.status === 200) {
                    dispatch(addLog({
                      "statusCode": "SUCCESS",
                      "status": "Success",
                      "resourceType": "Review outcome master ",
                      "message": " has been created",
                      "timestamp": currentTime
                    }))
        
                    dispatch(removeDataFetchProcess(1))

                    // Update files' workflow step assigned into review batch
                    const putFiles = []
                    createReviewBatchFileResponse.data.forEach(reviewBatchFileResponse => {
                      putFiles.push({
                        "Id": reviewBatchFileResponse.FileId,
                        "WorkFlowStatusId": 2, // Assigned
                        "WorkflowStepId": assignData.workflowStep
                      })
                    })
                    axios.put(`${process.env.REACT_APP_API_URL}/File/Multiple/Update/${selectedproject.Id}`, putFiles, { 
                      headers: authHeader()
                    })
                    .then(updateFileResponse => {
                      dispatch(addLog({
                        "statusCode": "SUCCESS",
                        "status": "Success",
                        "resourceType": "Review files ",
                        "message": " have been updated",
                        "timestamp": currentTime
                      }))
          
                      dispatch(removeDataFetchProcess(1))

                      // Clear selected files index
                      setSelectedFilesIndex([])

                      // Refresh files and review outcome master data
                      fetchRawFiles()
                      fetchReviewOutcomeMaster()
                      fetchReviewBatch()
                      fetchReviewFiles()
                      fetchTranscription()
                    })
                    .catch(updateFileError => {
                      console.info(updateFileError)
                    })
                  } else {
                    console.info(updateReviewOutcomeMasterResponse)
                  }
                })
                .catch(createReviewOutcomeMasterError => {
                  console.info(createReviewOutcomeMasterError)
                })
              } else {
                console.info(createReviewBatchFileResponse)
              }          
            })
            .catch(createReviewBatchFileError => {
              console.info(createReviewBatchFileError)
            })
          } else {
            console.info(createReviewBatchResponse)
          }
        })
        .catch(createReviewBatchError => {
          console.info(createReviewBatchError)
        })
      } else {
        console.info("ERROR - Create new review batch is not allowed")
      }
    }

    const cancelFileWorkflowStatus = () => {
        // Update existing file record
        // Update files' workflow step into 'cancelled'
        
        const putFiles = []
        selectedFiles.forEach(file => {
          putFiles.push({
            "Id": file,
            "WorkFlowStatusId": 5, // Cancelled
          })
        })

        axios.put(`${process.env.REACT_APP_API_URL}/File/Multiple/Update/${selectedproject.Id}`, putFiles, { 
          headers: authHeader()
        })
        .then(updateFileResponse => {
          // Clear selected files index
          setSelectedFilesIndex([])

          // Refresh files and review outcome master data
          fetchRawFiles()
          fetchReviewOutcomeMaster()
        })
        .catch(updateFileError => {
          console.info(updateFileError)
        })
    }

    const finaliseReviewBatch = () => {
        // Update existing file record
        // Update files' workflow step into 'finalised'
        const putFiles = []
        selectedFiles.forEach(file => {
          putFiles.push({
            "Id": file,
            "WorkFlowStatusId": 4, // Finalised
          })
        })

        axios.put(`${process.env.REACT_APP_API_URL}/File/Multiple/Update/${selectedproject.Id}`, putFiles, { 
          headers: authHeader()
        })
        .then(updateFileResponse => {
          // Clear selected files index
          setSelectedFilesIndex([])

          // Refresh files and review outcome master data
          fetchRawFiles()
          fetchReviewOutcomeMaster()
        })
        .catch(updateFileError => {
          console.info(updateFileError)
        })
    }

    return (
      <ThemeProvider theme={myTheme}>
        { filteredProgressedFiles && filteredProgressedFiles.length > 0 &&                
          <div>
            {
              selectedFilesIndex.length === 0 && (parseInt(workflowStatusId) === 1 || parseInt(workflowStatusId) === 3 || parseInt(workflowStatusId) === 4) &&
              <div style={{textAlign: "center", marginTop: "30px"}}>
                Select any files to be assigned, cancelled, or finalised
              </div>
            }
            { 
              selectedFilesIndex.length > 0 && (parseInt(workflowStatusId) === 1 || parseInt(workflowStatusId) === 3 || parseInt(workflowStatusId) === 4) &&
              <Modal
                titlename = {"Assign"}
                modalDialogTitle = {"Assign file(s)"}
                clearInput = {() => {}}
                saveButtonLabel = "Submit"
                disableSaveButton = {!isAssignUserFormCompleted}
                handleSubmit={saveReviewBatch}>
                    <InputLabel id="batchName-label">Review batch name</InputLabel>
                    <TextField id="batchName" 
                      name="batchName"
                      type = "text"
                      style = {{width: 250}}
                      variant="outlined"
                      onChange={updateAssignData}
                    />
                    <InputLabel id="batchName-label">Review batch description</InputLabel>
                    <TextField id="batchDescription" 
                      name="batchDescription"
                      type = "text"
                      style = {{width: 250}}
                      variant="outlined"
                      onChange={updateAssignData}
                    />
                    <InputLabel id="Assignee-label">Assignee</InputLabel>
                    <Select
                        id="assignee"
                        variant = 'standard'
                        name= "assignee"
                        style = {{height: 35, width: 250}} 
                        defaultValue= ""
                        onChange={updateAssignData}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        { reviewers && reviewers.length > 0 && reviewers.map((reviewer) => {
                            return(
                              <MenuItem key={reviewer.Id} value={reviewer.Id}> {reviewer.FirstName} {reviewer.LastName} </MenuItem> 
                            )   
                          })
                        }
                    </Select>
                    <InputLabel id="WorkflowStep-label">Workflow Step</InputLabel>
                    <Select
                        id="workflowStep"
                        variant = 'standard'
                        name= "workflowStep"
                        style = {{height: 35, width: 250}} 
                        defaultValue= ""
                        onChange={updateAssignData}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        { workflowStep && workflowStep.length > 0 && workflowStep.map((step) => {
                            return(
                              <MenuItem key={step.Id} value={step.Id}> {step.LongDescription} </MenuItem> 
                            )   
                          })
                        }
                    </Select>
              </Modal>
            }
            &nbsp;&nbsp;
            { 
              selectedFilesIndex.length > 0 && (parseInt(workflowStatusId) === 1 || parseInt(workflowStatusId) === 3 || parseInt(workflowStatusId) === 4) &&
              <Modal
                titlename = {"Cancel"}
                modalDialogTitle = {"Cancel file(s)"}
                clearInput = {() => {}}
                saveButtonLabel = "Proceed"
                handleSubmit={cancelFileWorkflowStatus}>
                    <div style={{width: '100%'}}>Are you sure you want to cancel selected file(s)?</div>
              </Modal>
            }
            &nbsp;&nbsp;
            { 
              selectedFilesIndex.length > 0 && (parseInt(workflowStatusId) === 1 || parseInt(workflowStatusId) === 3) &&
              <Modal
                titlename = {"Finalise"}
                modalDialogTitle = {"Finalise file(s)"}
                clearInput = {() => {}}
                saveButtonLabel = "Finalise"
                handleSubmit={finaliseReviewBatch}>
                    <div style={{width: '100%'}}>Are you sure you want to finalise selected file(s)?</div>
              </Modal>
            }
            <br/>
            <br/>
            <MUIDataTable
              data={filteredProgressedFiles}
              columns={headers}
              options={{
                selectableRows: parseInt(workflowStatusId) === 1 || parseInt(workflowStatusId) === 3 || parseInt(workflowStatusId) === 4 ? "multiple" : "none",
                rowsSelected: selectedFilesIndex,
                onRowSelectionChange: handleSelect,
                print: false,
                search: true,
                download: false,
                filter: true,
                viewColumns: false,
                rowsPerPage: 50,
                rowsPerPageOptions: [50, 100, 200],
                customToolbarSelect: () => {}
              }}
            />
          </div>
        }
        {
          filteredProgressedFiles && filteredProgressedFiles.length === 0 && 
          <div style={{textAlign: "center"}}>
            Data unavailable
          </div>
        }
        {
          selectedFileId != 0 &&
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={isTranscriptModalOpen}
            onClose={closeTranscriptModal}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Transcript</DialogTitle>
            <DialogContent dividers={true}>
              <TranscriptTable fileId={selectedFileId}/> 
            </DialogContent>
            <DialogActions>
              <button className="btn btn-primary" onClick={() => closeTranscriptModal()}>Close</button>
            </DialogActions>
          </Dialog>
        }
      </ThemeProvider>

    );

}

