import React, { useState } from 'react';
// Material UI Elements
import { Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
//Components
import BatchTable from './components/BatchTable'
import FileTable from './components/FileTable'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function TranscriptManagement() {
  // state variable for 
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
   
  return (
    <Grid container  direction="row" justifyContent="center" marginTop={2} >
      <Box elevation = {0} sx={{ width: '95%' }}>
        <Grid container  direction="row" justifyContent="center" marginTop={2} >
          {/* <Box elevation = {0} sx={{ width: '95%' }}>
            <FormGroup>
              <FormControlLabel control={<Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
            label="Group by Upload Batch"
            />
            </FormGroup>
          </Box> */}
        </Grid>        
            <Grid container  direction="row" justifyContent="center" marginTop={2} >
              <Box elevation = {0} sx={{ width: '95%' }}>
                {checked && <BatchTable />}
                {!checked && <FileTable />}
              </Box>
        </Grid>
        {/* </TabPanel> */}
        {/* <TabPanel value={value} index={0}>
            Container for Infographs 
        </TabPanel> 
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel> */}
      </Box>
    </Grid>
    );
  };


