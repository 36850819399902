import React, {useEffect, useState } from 'react';
import { Grid, MenuItem, Select, InputLabel, TextField, createTheme, ThemeProvider, Autocomplete } from '@mui/material';
import { authHeader } from '../../../Services/_helpers/auth-header';
import useFetch from '../../../../contexts/services/useFetch';
import { authenticationService } from '../../../Services/_services/authenticationService';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { currencyList } from './Currency'

export default function EditOrganisation(props) {
    // Selected organisation ID property
    const selectedOrganisationId = props.match.params.organisationId

    // React hooks
    // 1. Users list
    const [ users, setUsers ] = useState([])
    // 2. Pricing ID
    const [ pricingId, setPricingId ] = useState(0)
    // 3. Current organisation form submission status
    const [ isCurrentOrganisationFormSubmitted, setIsCurrentOrganisationFormSubmitted ] = useState(false)
    // 4. Current organisation form stored status
    const [ isCurrentOrganisationStored, setIsCurrentOrganisationStored ] = useState(false)
    // 5. Current organisation form completed status
    const [ isCurrentOrganisationFormCompleted, setIsCurrentOrganisationFormCompleted ] = useState(false)
    // 6. Selected organisation object
    const [ selectedOrganisation, setSelectedOrganisation] = useState({})
    // 7. Submission status list
    const [ submissionStatus, setSubmissionStatus ] = useState([])

    // API data pull
    // 1. Organisation
    const { data: selectedOrganisationData, isPending: selectedOrganisationDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Organisation/` + selectedOrganisationId, authenticationService.currentUserValue, "Organisations")
    // 2. Users
    const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/`, authenticationService.currentUserValue, "Users")

    useEffect(() => {
        // One condition to set user state: User API data is fully loaded
        if (!userDataIsLoading) {
            // Filter active user only by termination date
            const mappedUserData = userData.filter(user => user.TerminationDate ===  "9999-12-31T00:00:00")
            // Re-map raw user data into ID and name
            .map(user => ({
                id: user.Id,
                name: user.FullName
            }))
            setUsers(mappedUserData)
        }
    }, [userDataIsLoading])

    useEffect(() => {
        // One condition to set existing organisation state: Organisation API data is fully loaded
        if (!selectedOrganisationDataIsLoading) {
            const pricingId = selectedOrganisationData["PricingId"]
            setPricingId(pricingId)

            // Get Pricing API data to complement Organisation API data
            axios.get(`${process.env.REACT_APP_API_URL}/Pricing/${pricingId}`, {
                headers: authHeader()
            })
            .then(resp => {
                const contractStartDate = new Date(selectedOrganisationData["ContractStartDate"])
                const contractStartDay = ('0' + (contractStartDate.getDate())).slice(-2)
                const contractStartMonth = ('0' + (contractStartDate.getMonth() + 1)).slice(-2)
                const contractStartYear = contractStartDate.getFullYear()
                const parsedContractStartDate = `${contractStartYear}-${contractStartMonth}-${contractStartDay}`
                
                const contractEndDate = new Date(selectedOrganisationData["ContractEndDate"])
                const contractEndDay = ('0' + (contractEndDate.getDate())).slice(-2)
                const contractEndMonth = ('0' + (contractEndDate.getMonth() + 1)).slice(-2)
                const contractEndYear = contractEndDate.getFullYear()
                const parsedContractEndDate = `${contractEndYear}-${contractEndMonth}-${contractEndDay}`

                const searchUser = users.filter(user => user.id === selectedOrganisationData["OwnerId"])

                setSelectedOrganisation({
                    "organisationName": selectedOrganisationData["OrganisationName"],
                    "organisationDescription": selectedOrganisationData["OrganisationDescription"],
                    "iPowerClientCode": selectedOrganisationData["iPowerClientCode"],
                    "owner": searchUser[0],
                    "contractStartDate": parsedContractStartDate,
                    "contractEndDate": parsedContractEndDate,
                    "pricingName": resp.data["Name"],
                    "pricingDescription": resp.data["Description"],
                    "currency": resp.data["Currency"],
                    "projectSetupFee": resp.data["ProjectSetupFee"],
                    "monthlyFee": resp.data["MonthlyFee"],
                    "transcriptionCost": resp.data["TranscriptionCost"],
                    "addTranscriptionCost": resp.data["AddTranscriptionCost"],
                    "transcriptionUnit": resp.data["TranscriptionUnit"],
                    "storageCost": resp.data["StorageCost"],
                    "addStorageCost": resp.data["AddStorageCost"],
                    "storageUnit": resp.data["StorageUnit"],
                    "userCost": resp.data["UserCost"],
                    "addUserCost": resp.data["AddUserCost"],
                    "addProjectCost": resp.data["AddProjectCost"],
                    "includedProjects": resp.data["IncludedProjects"],
                    "includedTranscriptionHours": resp.data["IncludedTranscriptionHours"],
                    "includedStorageHours": resp.data["IncludedStorageHours"],
                    "minimumMonths": resp.data["MinimumMonths"],
                    "includedUserCount": resp.data["IncludedUserCount"]
                })
            })
            .catch(err => {
                if (err.response.status === 401 || err.response.status === 403) {
                    console.info("JWT token expired")
                    authenticationService.logout();
                } else {
                    console.info(err)
                }
            })
        }
    }, [selectedOrganisationDataIsLoading])

    // When selected organisation state changed, check form validity
    useEffect(() => {
        checkFormValidity()
    }, [selectedOrganisation])

    // Event handler: Change owner selection
    const changeOwner = (value) => {
        let updatedItem = selectedOrganisation
        updatedItem["owner"] = value;

        setSelectedOrganisation({...updatedItem});
    
        checkFormValidity()
    }

    // Event handler: Change selectedOrganisation state
    const handleInput = (event) => {
        let updatedItem = selectedOrganisation
        updatedItem[event.target.name] = event.target.value;
        
        if (event.target.name === "contractStartDate" && event.target.value === "") {
            updatedItem["contractEndDate"] = ""
        }

        setSelectedOrganisation({...updatedItem});
    
        checkFormValidity()
    }

    // Selected organisation form validator
    const checkFormValidity = () => {
        // Validation rule
        // A. Organisation data
        // 1. organisationName              -> Not empty string
        // 2. organisationDescription       -> Not empty string
        // 3. iPowerClientCode              -> Not empty string
        // 4. owner                         -> Not empty string
        // 5. contractStartDate             -> Not empty datetime string
        // 6. contractEndDate               -> Not empty datetime string

        // B. Pricing data
        // 1. pricingName                   -> Not empty string
        // 2. pricingDescription            -> Not empty string
        // 3. currency                      -> Not empty string
        // 4. projectSetupFee               -> Not empty float
        // 5. monthlyFee                    -> Not empty float
        // 6. transcriptionCost             -> Not empty float
        // 7. addTranscriptionCost          -> Not empty float
        // 8. transcriptionUnit             -> Not empty string
        // 9. storageCost                   -> Not empty float
        // 10. addStorageCost               -> Not empty float
        // 11. storageUnit                  -> Not empty string
        // 12. userCost                     -> Not empty float
        // 13. addUserCost                  -> Not empty float
        // 14. addProjectCost               -> Not empty float
        // 15. includedProjects             -> Not empty integer
        // 16. includedTranscriptionHours   -> Not empty float
        // 17. includedStorageHours         -> Not empty float
        // 18. minimumMonths                -> Not empty integer
        // 19. includedUserCount            -> Not empty integer
        if (
            selectedOrganisation["organisationName"] !== "" && 
            selectedOrganisation["organisationDescription"] !== "" && 
            selectedOrganisation["iPowerClientCode"] !== "" &&
            selectedOrganisation["owner"] !== null &&
            selectedOrganisation["contractStartDate"] !== "" &&
            selectedOrganisation["contractEndDate"] !== "" &&
            selectedOrganisation["pricingName"] !== "" &&
            selectedOrganisation["pricingDescription"] !== "" &&
            selectedOrganisation["currency"] !== "" &&
            selectedOrganisation["projectSetupFee"] > 0 &&
            selectedOrganisation["monthlyFee"] > 0 &&
            selectedOrganisation["transcriptionCost"] > 0 &&
            selectedOrganisation["addTranscriptionCost"] > 0 &&
            selectedOrganisation["transcriptionUnit"] !== "" &&
            selectedOrganisation["storageCost"] > 0 &&
            selectedOrganisation["addStorageCost"] > 0 &&
            selectedOrganisation["storageUnit"] !== "" &&
            selectedOrganisation["userCost"] > 0 &&
            selectedOrganisation["addUserCost"] > 0 &&
            selectedOrganisation["addProjectCost"] > 0 &&
            selectedOrganisation["includedProjects"] > 0 &&
            selectedOrganisation["includedTranscriptionHours"] > 0 &&
            selectedOrganisation["includedStorageHours"] > 0 &&
            selectedOrganisation["minimumMonths"] > 0 &&
            selectedOrganisation["includedUserCount"] > 0
        ) {
            setIsCurrentOrganisationFormCompleted(true)
        } else {
            setIsCurrentOrganisationFormCompleted(false)
        }
    }

    // Event handler: Update selected organisation
    const updateOrganisation = () => {
        const putUpdateOrganisation = {
            "OrganisationName" : selectedOrganisation["organisationName"], 
            "OrganisationDescription" : selectedOrganisation["organisationDescription"], 
            "iPowerClientCode" : selectedOrganisation["iPowerClientCode"],
            "OwnerId": selectedOrganisation["owner"] === null ? users[0].id : selectedOrganisation["owner"].id,
            "ContractStartDate": selectedOrganisation["contractStartDate"],
            "ContractEndDate": selectedOrganisation["contractEndDate"]
        }

        // Selected organisation form submission state change into TRUE, therefore disabling selected organisation form
        setIsCurrentOrganisationFormSubmitted(true)

        // Update organisation data through '/Organisation/Update' API
        axios.put(`${process.env.REACT_APP_API_URL}/Organisation/Update/${selectedOrganisationId}`, putUpdateOrganisation, { 
            headers: authHeader()
        })
        .then(updateProjectResponse => {
            if (updateProjectResponse.status === 200) {
                setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Project has been updated!"])

                const putUpdatePricing = {
                    "Name" : selectedOrganisation["pricingName"],
                    "Description" : selectedOrganisation["pricingDescription"],
                    "Currency" : selectedOrganisation["currency"],
                    "ProjectSetupFee" : selectedOrganisation["projectSetupFee"],
                    "MonthlyFee" : selectedOrganisation["monthlyFee"],
                    "TranscriptionCost" : selectedOrganisation["transcriptionCost"],
                    "AddTranscriptionCost" : selectedOrganisation["addTranscriptionCost"],
                    "TranscriptionUnit" : selectedOrganisation["transcriptionUnit"],
                    "StorageCost" : selectedOrganisation["storageCost"],
                    "AddStorageCost" : selectedOrganisation["addStorageCost"],
                    "StorageUnit" : selectedOrganisation["storageUnit"],
                    "UserCost" : selectedOrganisation["userCost"],
                    "AddUserCost" : selectedOrganisation["addUserCost"],
                    "AddProjectCost" : selectedOrganisation["addProjectCost"],
                    "IncludedProjects" : selectedOrganisation["includedProjects"],
                    "IncludedTranscriptionHours" : selectedOrganisation["includedTranscriptionHours"],
                    "IncludedStorageHours" : selectedOrganisation["includedStorageHours"],
                    "MinimumMonths" : selectedOrganisation["minimumMonths"],
                    "IncludedUserCount" : selectedOrganisation["includedUserCount"]
                }

                // Update pricing data through '/Pricing/Update' API
                axios.put(`${process.env.REACT_APP_API_URL}/Pricing/Update/${pricingId}`, putUpdatePricing, { 
                    headers: authHeader()
                })
                .then(updatePricingResponse => {
                    if (updatePricingResponse.status === 200) {
                        setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Pricing has been updated!"])
                        // Change isCurrentOrganisationStored into TRUE, therefore enabling 'Return to organisation list' button
                        setIsCurrentOrganisationStored(true)
                    } else {
                        console.info(updatePricingResponse)
                    }
                })
                .catch(error => {
                    console.info("Pricing record update error")
                    console.info(error)
                    setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Pricing update failed, please contact admin for troubleshooting"])
                })
            }
        })
        .catch(error => {
            console.info("Organisation record update error")
            console.info(error)
            setSubmissionStatus(currentSubmissionStatus => [...currentSubmissionStatus, "Organisation update failed, please contact admin for troubleshooting"])
        })
    }

    const muiDataTableTheme = createTheme({
        components: {
          // Name of the component
          MuiTableCell: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                padding: '5pt',
              },
            },
          },
        }
    });

    return (
        <Grid container direction="row" justifyContent="center" marginTop={5} marginLeft = {5}>
            <ThemeProvider theme={muiDataTableTheme}>
                {   !isCurrentOrganisationFormSubmitted && Object.keys(selectedOrganisation).length > 0 ?
                    <div id="upload-box" style={{width: "50%"}}>
                        <h4>Edit organisation</h4>
                        <br/>
                        <InputLabel id="organisationNameLabel">Organisation name</InputLabel>
                        <TextField id="organisationName" 
                            name="organisationName"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["organisationName"]}
                        /> 
                        <br/> 
                        <br/> 
                        <InputLabel id="organisationDescriptionLabel">Organisation description</InputLabel>
                        <TextField id="organisationDescription" 
                            name="organisationDescription"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["organisationDescription"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="iPowerClientCodeLabel">iPower client code</InputLabel>
                        <TextField id="iPowerClientCode" 
                            name="iPowerClientCode"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["iPowerClientCode"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="ownerLabel">Owner</InputLabel>
                        {
                            users.length > 0 && 
                            <Autocomplete
                                disablePortal
                                id="ownerLabel"
                                options={users}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, {}) => {
                                    return <li {...props}>
                                        {option.name}
                                    </li>
                                }}
                                sx={{ width: "100%" }}
                                onChange={(_, value) => {
                                    changeOwner(value)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={selectedOrganisation["owner"]}
                            />
                        }
                        <br/>
                        <br/>
                        <InputLabel id="contractStartDateLabel">Contract start date</InputLabel>
                        <TextField id="contractStartDate" 
                                name="contractStartDate"
                                type = "date"
                                InputProps={{inputProps: {max: selectedOrganisation["contractEndDate"]}}}
                                style = {{width: "100%"}} 
                                onChange={handleInput}
                                variant="outlined"
                                value= {selectedOrganisation["contractStartDate"]}
                            /> 
                        <br/>
                        <br/>
                        <InputLabel id="contractEndDateLabel">Contract end date</InputLabel>
                        <TextField id="contractEndDate" 
                                name="contractEndDate"
                                type = "date"
                                disabled = {selectedOrganisation["contractStartDate"] === "" ? true : false}
                                InputProps={{inputProps: {min: selectedOrganisation["contractStartDate"]}}}
                                style = {{width: "100%"}} 
                                onChange={handleInput}
                                variant="outlined"
                                value={selectedOrganisation["contractEndDate"]}
                            /> 
                        <br/>
                        <br/>
                        <hr/>
                        <h4>Pricing details</h4>
                        <br/>
                        <InputLabel id="pricingNameLabel">Pricing name</InputLabel>
                        <TextField id="pricingName" 
                            name="pricingName"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["pricingName"]}
                        /> 
                        <br/> 
                        <br/> 
                        <InputLabel id="pricingDescriptionLabel">Pricing description</InputLabel>
                        <TextField id="pricingDescription" 
                            name="pricingDescription"
                            type = "text"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["pricingDescription"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="currencyLabel">Currency</InputLabel>
                        {
                            currencyList.length > 0 &&
                            <Select
                                labelId="currencyLabel"
                                id="currency"
                                name="currency"
                                value={selectedOrganisation["currency"]}
                                style = {{width: "100%"}} 
                                onChange={handleInput}
                            >
                                {
                                    currencyList.map((organisation, index) => {
                                        return <MenuItem key={index} value={organisation.code}>
                                            {organisation.name}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                        <br/>
                        <br/>
                        <InputLabel id="monthlyFeeLabel">Monthly fee</InputLabel>
                        <TextField id="monthlyFee" 
                            name="monthlyFee"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["monthlyFee"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="projectSetupFeeLabel">Project setup fee</InputLabel>
                        <TextField id="projectSetupFee" 
                            name="projectSetupFee"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["projectSetupFee"]}
                        /> 
                        <br/>
                        <br/>
                        <h5>Transcription</h5>
                        <br/>
                        <InputLabel id="transcriptionCostLabel">Transcription Cost</InputLabel>
                        <TextField id="transcriptionCost" 
                            name="transcriptionCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["transcriptionCost"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="addTranscriptionCostLabel">Additional transcription Cost</InputLabel>
                        <TextField id="addTranscriptionCost" 
                            name="addTranscriptionCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["addTranscriptionCost"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="transcriptionUnitLabel">Transcription unit</InputLabel>
                        <TextField id="transcriptionUnit" 
                            name="transcriptionUnit"
                            type = "string"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["transcriptionUnit"]}
                        /> 
                        <br/>
                        <br/>
                        <h5>Storage</h5>
                        <br/>
                        <InputLabel id="storageCostLabel">Storage Cost</InputLabel>
                        <TextField id="storageCost" 
                            name="storageCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["storageCost"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="addStorageCostLabel">Additional storage Cost</InputLabel>
                        <TextField id="addStorageCost" 
                            name="addStorageCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["addStorageCost"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="storageUnitLabel">Storage unit</InputLabel>
                        <TextField id="storageUnit" 
                            name="storageUnit"
                            type = "string"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["storageUnit"]}
                        /> 
                        <br/>
                        <br/>
                        <h5>User</h5>
                        <br/>
                        <InputLabel id="userCostLabel">User Cost</InputLabel>
                        <TextField id="userCost" 
                            name="userCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["userCost"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="addUserCostLabel">Additional User Cost</InputLabel>
                        <TextField id="addUserCost" 
                            name="addUserCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["addUserCost"]}
                        /> 
                        <br/>
                        <br/>
                        <h5>Additional cost details</h5>
                        <br/>
                        <InputLabel id="addProjectCostLabel">Additional Project Cost</InputLabel>
                        <TextField id="addProjectCost" 
                            name="addProjectCost"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["addProjectCost"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="includedProjectsLabel">Included project count</InputLabel>
                        <TextField id="includedProjects" 
                            name="includedProjects"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["includedProjects"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="includedTranscriptionHoursLabel">Included transcription hours</InputLabel>
                        <TextField id="includedTranscriptionHours" 
                            name="includedTranscriptionHours"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["includedTranscriptionHours"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="includedStorageHoursLabel">Included storage hours</InputLabel>
                        <TextField id="includedStorageHours" 
                            name="includedStorageHours"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["includedStorageHours"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="minimumMonthsLabel">Minimum hours</InputLabel>
                        <TextField id="minimumMonths" 
                            name="minimumMonths"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["minimumMonths"]}
                        /> 
                        <br/>
                        <br/>
                        <InputLabel id="includedUserCountLabel">Included user count</InputLabel>
                        <TextField id="includedUserCount" 
                            name="includedUserCount"
                            type = "number"
                            style = {{width: "100%"}} 
                            onChange={handleInput}
                            variant="outlined"
                            value={selectedOrganisation["includedUserCount"]}
                        /> 
                        <br/>
                        <br/>
                        <hr/>
                        <Grid container justifyContent="right">
                            <button className="btn btn-primary" onClick={updateOrganisation} disabled={!isCurrentOrganisationFormCompleted}>Submit</button>
                        </Grid>
                    </div> :
                    <div id="upload-box" style={{width: "50%"}}>
                        <h5>Organisation update progress</h5>
                        <hr/>
                        <ul>
                            {
                                submissionStatus.length > 0 ?
                                submissionStatus.map((status, index) => {
                                    return <li key={index}>
                                        {status}
                                    </li>
                                }) :
                                null
                            }
                        </ul>
                        {
                            isCurrentOrganisationStored ? <NavLink className="btn btn-primary" to="/Site/Organisations"> Return to organisation list </NavLink> : null
                        }
                    </div>
                }
            </ThemeProvider>
        </Grid>
    )
}