import React, { useContext, useEffect } from 'react';
import ReviewContext from '../../../../contexts/ReviewContext'
import ReviewFileContext from '../../../../contexts/ReviewFileContext';
import { Select, MenuItem, Paper, Grid } from '@mui/material';
import UploadBatchFileQueue from './UploadBatchFileQueue';
import Modal from '../../SharedComponents/Modal';

export default function UploadBatchSelector() {
    // Review context
    // 1. Save file
    // 2. UploadBatchSelectorData
    // 3. Set selected upload batch ID (React hooks)
    // 4. Set selected upload batch file ID (React hooks)
    // 5. Set selected upload batch index (React hooks)
    // 6. Set selected upload batch file index (React hooks)
    // 7. Selected upload batch file index
    // 8. Selected upload batch index
    // 9. Upload batch files queue
    const { 
        saveFile,
        uploadBatchSelectorData,
        setSelectedUploadBatchId, 
        setSelectedUploadBatchFileId, 
        setSelectedUploadBatchIndex, 
        setSelectedUploadBatchFileIndex,
        selectedUploadBatchFileIndex, 
        selectedUploadBatchIndex,
        uploadBatchFilesQueue
    } = useContext(ReviewContext)

    useEffect(() => {
        // When Upload batch selector data is fully loaded
        // Set Selected upload batch index, Selected upload batch ID, and Selected upload batch file ID
        if (uploadBatchSelectorData && uploadBatchSelectorData.length > 0) {
            if (selectedUploadBatchIndex === -1) {
                setSelectedUploadBatchIndex(0)
                setSelectedUploadBatchId(uploadBatchSelectorData[0].id)
    
                if (uploadBatchSelectorData[0].files.length > 0) {
                    setSelectedUploadBatchFileId(uploadBatchSelectorData[0].files[0].Id)
                } else {
                    setSelectedUploadBatchFileId(0)
                }
            } else {
                setSelectedUploadBatchId(uploadBatchSelectorData[selectedUploadBatchIndex].id)

                if (uploadBatchSelectorData[selectedUploadBatchIndex].files.length > 0) {
                    setSelectedUploadBatchFileId(uploadBatchSelectorData[selectedUploadBatchIndex].files[selectedUploadBatchFileIndex].Id)
                } else {
                    setSelectedUploadBatchFileId(0)
                }
            }
        }
    }, [uploadBatchSelectorData])

    // Event handler: Change transcription review queue
    const changeTranscriptionReviewQueue = (event) => {
        const selectedUploadBatchId = event.target.value
        setSelectedUploadBatchId(selectedUploadBatchId)

        const findSelectedUploadBatchIndex = uploadBatchSelectorData.findIndex(uploadBatch => uploadBatch.id === selectedUploadBatchId)
        setSelectedUploadBatchIndex(findSelectedUploadBatchIndex)

        if (uploadBatchSelectorData[findSelectedUploadBatchIndex].files.length > 0) {
            setSelectedUploadBatchFileId(uploadBatchSelectorData[findSelectedUploadBatchIndex].files[0].Id)
            setSelectedUploadBatchFileIndex(0)
        } else {
            setSelectedUploadBatchFileId(0)
            setSelectedUploadBatchFileIndex(-1)
        }

    }

    return (
        <>
            {
                uploadBatchSelectorData && uploadBatchSelectorData.length > 0 && selectedUploadBatchIndex > -1 &&
                <Paper sx={{padding: 1, marginBottom: "10px", textAlign: 'center'}} elevation={4}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} style={{textAlign: "center"}} justifyContent="center">
                            <strong>Upload batch</strong>&emsp;
                            <Select
                                value={uploadBatchSelectorData[selectedUploadBatchIndex].id}
                                id="uploadBatchSelector"
                                variant='standard'
                                name="uploadBatchSelector"
                                style={{height: 50, width: 200}}
                                onChange={changeTranscriptionReviewQueue}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                { 
                                    uploadBatchSelectorData.map(uploadBatch => {
                                        return (
                                            <MenuItem key={uploadBatch["id"]} value={uploadBatch["id"]}>{uploadBatch["name"]}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            &emsp;
                            <UploadBatchFileQueue />
                            &emsp;
                            {
                                uploadBatchFilesQueue.length > 0 &&
                                <span>
                                    {uploadBatchFilesQueue[selectedUploadBatchFileIndex].Filename_project}
                                    &emsp;
                                    <button 
                                        type="button" 
                                        className="btn btn-warning" 
                                        align = 'center' 
                                        onClick={() => saveFile("UPLOAD_BATCH")}
                                        style={{ fontSize: "0.8rem", margin: 5, padding: "5px", width: 100 }}>
                                            Save file
                                    </button>
                                </span>
                            }
                            {
                                uploadBatchFilesQueue.length === 0 &&
                                <span>
                                    All files reviewed
                                </span>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            }
            {
                uploadBatchSelectorData && uploadBatchSelectorData.length === 0 &&
                <>
                    <Grid container spacing={2} >
                        <Grid item xs={12} style={{textAlign: "center"}} justifyContent="center">
                            <span>
                                No files available for review
                            </span>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}