import React from 'react';
import { Grid, TextField, Box } from '@mui/material';


export default function Step1(props) {

      const selected = props.selected;
      const handleChange = props.handleChange;

    
    return (
      <Grid container justifyContent="left" marginTop={5}>
        <Box component="form" sx={{ minwidth: 120 }} >
        <h5> Review Batch Information:</h5>
        <br />
        <div>
        <TextField
              id="batchname"
              label="Batch Name"
              sx = {{width: 300}}
              value = {selected.batchname}
              onChange={handleChange}
            />
        </div>
        <br/>
        <div>
        <TextField
              id="description"
              label="Batch Description"
              sx = {{width: 500}}
              rows={4}
              multiline
              value = {selected.description}
              onChange={handleChange}
            />
        </div>
        <br/>   
      </Box>  
    </Grid>
    ); 
}