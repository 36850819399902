import { useState, useEffect, useContext } from 'react'
import { authHeader } from '../../components/Services/_helpers/auth-header';
import ProjectContext from '../ProjectContext';
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { addLog } from '../../features/activityLogs/activityLogsSlice'
import { addDataFetchProcess, removeDataFetchProcess, toggleSiteAdminAccessDeniedWarningPage, toggleOrgAdminAccessDeniedWarningPage } from '../../features/fetchData/fetchDataStatusSlice'
import { useDispatch } from 'react-redux'

const useFetch = (url, currentUser, fetchResourceType) => {
    // React hooks
    // 1. Response data
    const [ data, setData ] = useState(null);
    // 2. Data fetch progress status
    const [ isPending, setIsPending ] = useState(true);
    // 3. Error message
    const [ error, setError ] = useState(null);
    // 4. Is project has been selected
    const { isSelected } = useContext(ProjectContext)

    const dispatch = useDispatch()

    // Asynchronous API data pull
    const fetchData = async (isLoadingScreenOverlayEnabled) => {
        // If currentUser object is populated and a project has been selected
        if (currentUser && isSelected) {

            // Add data fetch progress
            if (isLoadingScreenOverlayEnabled) {
                dispatch(addDataFetchProcess(1))
            }
            
            try {
                // Set up axios API call
                const fetchDataAxios = axios.create()

                // Configure three API call attempts with 1s delay in-between
                // If error 403 is received, re-try API call
                axiosRetry(fetchDataAxios, { 
                    retries: 3,
                    retryDelay: (retryCount) => {
                        console.info(`Fetch data - Retry attempt : ${retryCount}`)
                        return 1000
                    },
                    retryCondition: (_error) => {
                        if (_error.response.status === 403) {
                            return false
                        }

                        return true
                    }
                })

                // Execute API call
                const resp = await fetchDataAxios.get(`${url}`, { 
                    headers: authHeader()
                })

                setData(resp.data);
                setIsPending(false);
                setError(null);

                const currentDate = new Date()
                const currentTime = currentDate.toLocaleTimeString()

                dispatch(addLog({
                    "statusCode": "SUCCESS",
                    "status": "Success",
                    "resourceType": fetchResourceType,
                    "message": " data has been succesfully downloaded",
                    "timestamp": currentTime
                }))

                
            } catch (err) {
                // If error 403 is received, remove currentUser and Project object from session storage, then redirect to login page 
                if (err.response.status === 403) {
                    if (err.response.data.detail === "Invalid token or expired token.") {
                        sessionStorage.removeItem('currentUser');
                        sessionStorage.removeItem('Project');
                        const url = `${process.env.REACT_APP_PWC_ID_REDIRECT_URI}`
                        window.location.assign(url)
                    } else {
                        // If an API call was an unauthorised call (neither site admin or org admin), show access denied page
                        setError(err.response.data)

                        dispatch(toggleSiteAdminAccessDeniedWarningPage(!err.response.data.detail["isSiteAdmin"]))
                        dispatch(toggleOrgAdminAccessDeniedWarningPage(!err.response.data.detail["isOrgAdmin"])) 
                    }   
                }

                setIsPending(false);

                const currentDate = new Date()
                const currentTime = currentDate.toLocaleTimeString()

                dispatch(addLog({
                    "statusCode": "FAIL",
                    "status": "Fail",
                    "resourceType": fetchResourceType,
                    "message": " data request failed",
                    "timestamp": currentTime
                }))
            }

            // Remove data fetch progress
            if (isLoadingScreenOverlayEnabled) {
                dispatch(removeDataFetchProcess(1))
            }
        }
    }

    useEffect (() => {
        fetchData(url)  
    }, [url, currentUser, isSelected])
  
  return { data, isPending, error, fetchData }

}


export default useFetch;