import React from 'react';
import { Grid,  Box } from '@mui/material';

import AudioUploader from './components/AudioUploader'



export default function Upload()  {      
    return (
      <Grid container  direction="row" justifyContent="center" marginTop={5}>
        <Box sx={{ width: '80%' }}>
          <AudioUploader />
        </Box>
      </Grid>
    );
  }




