import React, { useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Contexts
import ReviewContext from '../../../../contexts/ReviewContext';
import ReviewFileContext from '../../../../contexts/ReviewFileContext';

export default function Queue() {
  // Review file context
  // 1. Upload batch files
  const { 
    uploadBatchFiles
  } = useContext(ReviewFileContext)

  // Review context
  // 1. Save file
  // 2. Selected upload batch ID
  // 3. Set selected upload batch ID (React hooks)
  // 4. Set selected upload batch file ID (React hooks)
  // 5. Set selected upload batch index (React hooks)
  // 6. Set selected upload batch file index (React hooks)
  // 7. Selected upload batch file index
  // 8. Upload batch files queue
  // 9. Set upload batch files queue (React hooks)
  const {
    saveFile,
    selectedUploadBatchId,
    setSelectedUploadBatchId, 
    setSelectedUploadBatchFileId, 
    setSelectedUploadBatchIndex, 
    setSelectedUploadBatchFileIndex,
    selectedUploadBatchFileIndex, 
    uploadBatchFilesQueue,
    setUploadBatchFilesQueue
  } = useContext(ReviewContext)

  useEffect(() => {
    // When Selected upload batch ID and Upload batch files are loaded
    // Set upload batch files queue, Selected upload batch file index, Selected upload batch index, and Selected upload batch file ID
    if (selectedUploadBatchId && uploadBatchFiles && uploadBatchFiles.length > 0) {
      const filteredUploadBatchFilesQueue = uploadBatchFiles.filter(uploadBatchFile => (uploadBatchFile.UploadBatchId === selectedUploadBatchId && uploadBatchFile.WorkFlowStatus.ProcessOrder !== 2))
      
      if (filteredUploadBatchFilesQueue.length > 0) {
        setUploadBatchFilesQueue(filteredUploadBatchFilesQueue)
      
        if (selectedUploadBatchFileIndex === -1) {
          setSelectedUploadBatchFileIndex(0)
          setSelectedUploadBatchIndex(0)
          setSelectedUploadBatchFileId(filteredUploadBatchFilesQueue[0].Id)
        }
      } else {
        setUploadBatchFilesQueue([])
        setSelectedUploadBatchFileIndex(-1)
        setSelectedUploadBatchIndex(0)
        setSelectedUploadBatchFileId(0)
      }
    } else {
        setUploadBatchFilesQueue([])
        setSelectedUploadBatchFileIndex(-1)
        setSelectedUploadBatchIndex(0)
        setSelectedUploadBatchFileId(0)
    }

  }, [selectedUploadBatchId, uploadBatchFiles])

  // Event handler: Change queue to previous review file
  const PreviousPage = () => {
    if (selectedUploadBatchFileIndex > 0) {
      saveFile("UPLOAD_BATCH")

      const newReviewFileIndex = selectedUploadBatchFileIndex - 1
      setSelectedUploadBatchFileIndex(newReviewFileIndex)
      setSelectedUploadBatchFileId(uploadBatchFilesQueue[newReviewFileIndex].Id)
      setSelectedUploadBatchId(uploadBatchFilesQueue[newReviewFileIndex].UploadBatchId)
    }
  }

  // Event handler: Change queue to next review file
  const NextPage = () => {
    if (selectedUploadBatchFileIndex < uploadBatchFilesQueue.length - 1) {
      saveFile("UPLOAD_BATCH")

      const newReviewFileIndex = selectedUploadBatchFileIndex + 1
      setSelectedUploadBatchFileIndex(newReviewFileIndex)
      setSelectedUploadBatchFileId(uploadBatchFilesQueue[newReviewFileIndex].Id)
      setSelectedUploadBatchId(uploadBatchFilesQueue[newReviewFileIndex].UploadBatchId)
    }
  }
  
  return (
    <ThemeProvider theme={createTheme()}>
      {
        uploadBatchFilesQueue && uploadBatchFilesQueue.length > 0 &&
        <span>
          <button disabled={selectedUploadBatchFileIndex === 0} type="button" className="btn btn-primary" align='center' style={{ fontSize: "0.8rem", margin: 5, padding: "5px", width: 70 }} onClick={PreviousPage}>Previous</button>
          &emsp;
          {selectedUploadBatchFileIndex + 1} of {uploadBatchFilesQueue.length} file{uploadBatchFilesQueue.length > 1 ? '(s)' : ''}
          &emsp;
          <button disabled={selectedUploadBatchFileIndex === uploadBatchFilesQueue.length - 1} type="button" className="btn btn-primary" align = 'center' style={{ fontSize: "0.8rem", margin: 5, padding: "5px", width: 70 }} onClick={NextPage}>Next</button>
        </span>
      }
      
    </ThemeProvider>
  );
}

