import React, { useContext, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Grid, Select, MenuItem, Menu, Button } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
// import {AddCircleOutline, RemoveCircleOutline, ArrowCircleUp,ArrowCircleDown}  from '@mui/icons-material';
import { authHeader } from '../../../Services/_helpers/auth-header';
import axios from 'axios';


// contexts
import ReviewContext from '../../../../contexts/ReviewContext';
import StatusContext from '../../../../contexts/StatusContext';
import ProjectContext from '../../../../contexts/ProjectContext';

const theme = createTheme({
  components: {
    // Name of the component
    MuiDataGrid: {
      styleOverrides: {
        // Name of the slot
        root: {
            '& p': {
                marginBottom: '0.2rem !important',
                marginTop: '0.2rem !important'
            }
        },
      },
    },
  },
});

export default function DataTable() {
  // Status context
  // state variable for status bar (setting the status and setting open)
  const {setStatus, setOpen } = useContext(StatusContext)

  // Review context
  // 1. Original transcript
  // 2. Reviewed transcript
  // 3. Set reviewed transcript
  // 4. Review
  // 5. Transcript ID
  // 6. Fetch review files
  // 7. Is edit transcript mode
  // 8. Set is edit transcript mode
  // 9. Set current audio time mark
  const { 
    originalTranscript, 
    reviewedTranscript, 
    setReviewedTranscript, 
    review, 
    transcriptId, 
    fetchReviewFiles, 
    isEditTranscriptMode, 
    setIsEditTranscriptMode,
    setCurrentAudioTimeMark
  } = useContext(ReviewContext);

  // Project context
  // 1. Selected project
  const {selectedproject} = useContext(ProjectContext)

  // React hooks
  // set anchor point for menu
  const [anchorEl, setAnchorEl] = useState(null);

  const open = anchorEl? {[anchorEl.id] : true} : false;

  // Event handler: Transcript row action button
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Event handler: Transcript row action closure event 
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Event handler: Add new transcript column
  const addColumn = (idx) => {

    setAnchorEl(null);

    const id = reviewedTranscript.length +1;
    const data = reviewedTranscript.filter(row => row.id === idx)[0];

    const newTranscript = {
      "id": id,
      "order": data.order+1,
      "time": "",
      "speaker": 1,
      "transcript": ""
    };

    var updatedTranscript = reviewedTranscript.map((rows) => {
      if (rows.order > data.order) {
        return ({...rows, "order" : rows.order+1 })
      }
      else {
        return(rows)
      }
    })

    updatedTranscript.push(newTranscript)
    
    setReviewedTranscript(updatedTranscript.sort((a,b) => a.order - b.order))

  }

  // Event handler: Delete transcription column
  const deleteColumn = (idx) => {

    setAnchorEl(null);

    const data = reviewedTranscript.filter(row => row.id === idx)[0];

    var updatedTranscript = reviewedTranscript.reduce((acc, rows) => {
      if (rows.id !== idx) {
        if (rows.order > data.order) {
          acc.push({...rows, "order" : rows.order - 1 })
        }
        else {
          acc.push(rows)
        }
      } 
      return (acc)
    }, [])


    setReviewedTranscript(updatedTranscript.sort((a,b) => a.order - b.order))

  }

  // Event handler: Move a transcript row above
  const moveUp = async (idx) => {

    setAnchorEl(null);

    const data = reviewedTranscript.filter(row => row.id === idx)[0];

    if (data.order !== 0 ) {

      const data1 = reviewedTranscript.filter(row => row.order === data.order-1)[0];


      var updatedTranscript = reviewedTranscript.map((rows) => {
        if (rows.id === data.id) {
          return ({...rows, "order" : rows.order -1 })
        }
        else if (rows.id === data1.id) {
          return ({...rows, "order" : rows.order + 1 })
        }
        else {
          return (rows)
        }
      })

      setReviewedTranscript(updatedTranscript.sort((a,b) => a.order - b.order))
    }
  }

  // Eevnt handler: Move a transcript row below
  const moveDown = async (idx) => {

    setAnchorEl(null);

    const data = reviewedTranscript.filter(row => row.id === idx)[0];

    if (data.order !== reviewedTranscript.length -1)
      {

      const data1 = reviewedTranscript.filter(row => row.order === data.order+1)[0];


      var updatedTranscript = reviewedTranscript.map((rows) => {
        if (rows.id === data.id) {
          return ({...rows, "order" : rows.order + 1 })
        }
        else if (rows.id === data1.id) {
          return ({...rows, "order" : rows.order - 1 })
        }
        else {
          return (rows)
        }
      })

      setReviewedTranscript(updatedTranscript.sort((a,b) => a.order - b.order))

    }
  }
    
  // Transcript table header during view mode
  const columns = [
    {
      field: 'time',
      headerName: 'Time',
      minWidth: 100,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const splitTime = params.value.split(':')
        const seconds = splitTime.reduce((acc,time) => (60 * acc) + +time);

        return (
          <Grid container direction="row" onClick={()  => setCurrentAudioTimeMark(seconds)} style={{cursor: "pointer"}} title={"Jump to audio timestamp"}> 
            {params.value} 
          </Grid>
        )
      }
    },
    {
      field: 'speaker',
      headerName: 'Speaker',
      minWidth: 150,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        review[`Speaker ${params.value}`]? (review[`Speaker ${params.value}`].Value !== " "? review[`Speaker ${params.value}`].Value : `Speaker ${params.value}`)  : `Speaker ${params.value}`
        )
    },
    {
      field: 'transcript',
      headerName: 'Transcript',
      flex: 10,
      minWidth: 380,
      // renderEditCell : renderEditTextarea,
      renderCell: (params) => (
        <p style={{display: 'inline-block', verticalAlign: "middle"}}> {params.value} </p>
      ),
      maxHeight: "none",
      editable: false,
      sortable: false,
      disableColumnMenu: true
    },
  ];

  // Transcript table header during edit mode
  const columns_edit = [
    {
      field: 'time',
      headerName: 'Time',
      minWidth: 100,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container  direction="row" justifyContent="center"> {params.value} </Grid>
      )
    },
    {
      field: 'speaker',
      headerName: 'Speaker',
      minWidth: 150,
      renderEditCell: renderEditInputCell,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        review[`Speaker ${params.value}`]? (review[`Speaker ${params.value}`].Value !== " "? review[`Speaker ${params.value}`].Value : `Speaker ${params.value}`)  : `Speaker ${params.value}`
        )
    },
    {
      field: 'transcript',
      headerName: 'Transcript',
      flex: 10,
      minWidth: 380,
      // renderEditCell : renderEditTextarea,
      renderCell: (params) => (
        <p style={{display: 'inline-block', verticalAlign: "middle"}}> {params.value} </p>
      ),
      maxHeight: "none",
      editable: true,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'id',
      renderHeader: (params) => ( <> </> ),
      renderCell: (params) => {
      return (
        <Grid container  direction="row" justifyContent="center">
           <div>
              <button 
                id= {`menu-button-${params.value}`}
                aria-controls= {`menu-${params.value}`}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                style={{margin: "5px"}} 
                type="button" 
                className="btn btn-outline-primary btn-sm" 
                onClick={handleClick}> 
                  Actions <KeyboardArrowDown sx={{fontSize: "1rem"}}/>
              </button> 
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open[`menu-button-${params.value}`] === undefined ? false : open[`menu-button-${params.value}`]}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': `menu-${params.value}`,
                }}
              >
                <MenuItem onClick={() => {addColumn(params.value)}}>Add</MenuItem>
                <MenuItem onClick={() => {deleteColumn(params.value)}}>Delete</MenuItem>
                <MenuItem onClick={() => {moveUp(params.value)}}>Move Up</MenuItem>
                <MenuItem onClick={() => {moveDown(params.value)}}>Move Down</MenuItem>
              </Menu>
          </div>



          {/* <div className="btn-group btn-group-sm" role="group" aria-label="ActionIcons">
          <button type="button" className="btn btn-secondary" onClick={() => {addColumn(params.value)}} >
            <AddCircleOutline />
          </button>  
          <button type="button" className="btn btn-secondary"  onClick={() => {deleteColumn(params.value)}} >
            <RemoveCircleOutline />
          </button>  
          <button type="button" className="btn btn-secondary"  onClick={() => {moveUp(params.value)}}>
          <ArrowCircleUp />
          </button>
          <button type="button" className="btn btn-secondary"  onClick={() => {moveDown(params.value)}}>
            <ArrowCircleDown />
            </button>
            </div> */}
          </Grid>
        )
      },    
      minWidth: 120,
      editable: false,
      sortable: false,
      disableColumnMenu: true
    },
  ];

  // Event handler: Reviewed transcript data change
  const handleInput = (event) => {
    const id = event.id;
    const name = event.field;
    const newValue = event.props.value;


 
    const UpdateItem = reviewedTranscript.map((rows) => { if (rows.id === id) {
      return {...rows, [name]: newValue}
    } else {
      return rows
    }
      
    })

    setReviewedTranscript(UpdateItem.sort((a,b) => a.order - b.order))


  }

  // Event handler: Update transcript in the database
  const handleSave = () => {

    const properties = { 'TranscriptionText' : JSON.stringify(reviewedTranscript) }

    axios.put(`${process.env.REACT_APP_API_URL}/Transcription/Update/${selectedproject.Id}/${transcriptId}`,properties, { 
      headers: authHeader()
      })
    .then(() => {
      setIsEditTranscriptMode(false)
      setStatus("Transcripts Successfully Saved.")
      setOpen(true)
      fetchReviewFiles();

    }).catch((error) => {
      console.info(error)
  })
    
  }

  // Event handler: Export transcript into a Word document
const handleExport = () => {
  setStatus("Generating Transcript.")
  setOpen(true)
  axios.get(`${process.env.REACT_APP_API_URL}/Export/${selectedproject.Id}/${transcriptId}?export_format=docx`, { 
    responseType: 'blob',
    headers: authHeader()
    })
  .then((response) => {
    const filename = response.headers['content-disposition'].split("filename=")[1]
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.download = filename
    // link.setAttribute('download', filename);
    // document.body.appendChild(link);
    link.click();      
    setIsEditTranscriptMode(false)
    setStatus("Transcripts Successfully Exported.")
    setOpen(true)
    fetchReviewFiles();

  }).catch((error) => {
    console.info(error)
})
  
}  

  // Event handler: Return edit cell for speakers
  function renderEditInputCell(params) {
    return <EditInputCell {...params} />;
  }

  // JSX: Edit input cell component
  function EditInputCell(props) {
    const { id, value, api, field } = props;

    const handleChange = (event) => {

      api.setEditCellValue({ id, field, value: event.target.value }, event);
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');
    

      const UpdateItem = reviewedTranscript.map((rows) => { if (rows.id === id) {
        return {...rows, [field]: event.target.value}
      } else {
        return rows
      }
        
      })

      
      setReviewedTranscript(UpdateItem.sort((a,b) => a.order - b.order))

    };
  
    return (
      <>
            <Select
                  id="Person"
                  name="Person"
                  value = {value}
                  style = {{width: 120}} 
                  onChange={handleChange}
                >
                  <MenuItem value={1}>{review[`Speaker 1`]?  (review["Speaker 1"].Value !== " "? review["Speaker 1"].Value : "Speaker 1") : "Speaker 1"}</MenuItem>
                  <MenuItem value={2}>{review[`Speaker 2`]?  (review["Speaker 2"].Value !== " "? review["Speaker 2"].Value : "Speaker 2") : "Speaker 2"}</MenuItem>
              </Select> 
      </>
    );
  }


// // edit cell for transcripts
// function renderEditTextarea (params) {
//     return <EditTextarea {...params} />;
//   }
  
  
  

// const EditTextarea = (props) => {
//   const { id, field, value, colDef, api } = props;
//   const [valueState, setValueState] = useState(value);
//   const [anchorEl, setAnchorEl] = useState();

//   const handleRef = (el) => {
//     setAnchorEl(el);
//   };

//   const handleChange = useCallback(
//     (event) => {
//       const newValue = event.target.value;
//       setValueState(newValue);
//       api.setEditCellValue({ id, field, value: newValue }, event);
//     },
//     [api, field, id]
//   );

//   return (
//     <div>
//       <div
//         ref={handleRef}
//         style={{
//           height: 1,
//           width: colDef.computedWidth,
//           display: "block",
//           position: "absolute",
//           top: 0
//         }}
//       />
//       {anchorEl && (
//         <Popper open anchorEl={anchorEl} placement="top-start">
//           <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
//             <InputBase
//               multiline
//               rows={4}
//               value={valueState}
//               sx={{ textarea: { resize: "both" }, width: "100%" }}
//               onChange={handleChange}
//             />
//           </Paper>
//         </Popper>
//       )}
//     </div>
//   );
// };

  const FilterOnlyToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton/>
      </GridToolbarContainer>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      {
        reviewedTranscript && reviewedTranscript.length !== 0 &&
        <>
        {/* <div style={{marginRight: "10px", marginBottom: "10px", textAlign:"left"}}>
          {
          !isEditTranscriptMode && 
            <>
              <Tooltip title="Edit">          
                <button style={{marginRight: "5px", marginBottom: "5px"}} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => {setIsEditTranscriptMode(true)}}> <Edit sx={{fontSize: "1rem"}}/> Edit</button>
              </Tooltip>
              <Tooltip title="Download">          
                <button style={{marginRight: "5px", marginBottom: "5px"}} type="button" className="btn btn-outline-secondary btn-sm" onClick={handleExport}> <FileDownload sx={{fontSize: "1rem"}}/> Download</button>
              </Tooltip>
            </>
          }
          {
          isEditTranscriptMode &&  
            <>           
            <Tooltip title="Discard">          
              <button style={{marginRight: "5px", marginBottom: "5px"}} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => {setIsEditTranscriptMode(false); fetchReviewFiles()}}> <Cancel sx={{fontSize: "1rem"}}/> Discard</button>
            </Tooltip> 
            <Tooltip title="Save">          
              <button style={{marginRight: "5px", marginBottom: "5px"}} type="button" className="btn btn-outline-secondary btn-sm" onClick={handleSave}> <Save sx={{fontSize: "1rem"}}/> Save</button>
            </Tooltip>
            </>
          }

        </div> */}
        {
          !isEditTranscriptMode &&
          <div style={{ height: 700, width: '100%' }}>
            <div style={{ height: 700, width: '100%' }}>
              <DataGrid
                getRowHeight={() => 'auto'} 
                rows={reviewedTranscript}
                columns={columns}
                pageSize={100}
                disableSelectionOnClick
                hideFooter={true}
                onEditCellPropsChange = {handleInput}
                components={{ Toolbar: FilterOnlyToolbar }}
              />
            </div>
          </div>
            
        }
        {
          isEditTranscriptMode && 
          <div style={{ height: 700, width: '100%' }}>
            <div style={{ height: 700, width: '100%' }}>
              <DataGrid
                getRowHeight={() => 'auto'} 
                rows={reviewedTranscript}
                columns={columns_edit}
                pageSize={100}
                disableSelectionOnClick
                hideFooter={true}
                onEditCellPropsChange = {handleInput}
              />
            </div>
          </div>
        }    
        </>
      }
      {
        reviewedTranscript && reviewedTranscript.length === 0 && originalTranscript && originalTranscript !== 0 &&
          <button className="btn btn-primary" onClick={() => {}} > Start Reviewing </button>
      }
    </ThemeProvider>
  );
}
