// React and MUI Components
import React, {useEffect, useState } from 'react';
import { Box, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, FormControl, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { useSelector } from 'react-redux';

//Context 
import useFetch from '../../../contexts/services/useFetch';
import { authenticationService } from '../../Services/_services/authenticationService';

export default function BillingCurrent() {
    const today = new Date(Date.now())
    let isOrgAdminAccessDenied = useSelector((state) => state.fetchDataStatus.isOrgAdminAccessDenied)

    const [ organisationName, setOrganisationName ] = useState("")
    const [ projects, setProjects ] = useState([])
    const [ pricingDetails, setPricingDetails ] = useState({})
    const [ currentBillingData, setCurrentBillingData ] = useState([])
    const [ totalUsage, setTotalUsage ] = useState({
        transcribedFiles: 0,
        storedFiles: 0,
        users: 0,
        projectCount: 0
    })
    
    const [ excessUsage, setExcessUsage ] = useState({
        transcribedFiles: 0,
        storedFiles: 0,
        users: 0,
        projectCount: 0
    })

    const [ excessUsageCost, setExcessUsageCost ] = useState({
        transcribedFiles: 0,
        storedFiles: 0,
        users: 0,
        projectCount: 0,
        total: 0
    })
    
    const [ isTableLoading, setIsTableLoading ] = useState(true)
    const { data: rawCurrentBillingData, isPending: rawCurrentBillingDataIsLoading } = useFetch(`${process.env.REACT_APP_API_URL}/BillingStats/Details?organisation_id=${authenticationService.currentUserValue.organisationId}&frequency=Current`, authenticationService.currentUserValue, "Current usage")
    const { data: projectsData, isPending: projectsDataIsLoading } = useFetch(`${process.env.REACT_APP_API_URL}/Projects/Organisation/${authenticationService.currentUserValue.organisationId}`, authenticationService.currentUserValue, "Projects")

    useEffect(() => {
        if (!projectsDataIsLoading && !isOrgAdminAccessDenied) {
          const filteredProjectsData = projectsData.filter(project => project.TerminationDate === "9999-12-31T00:00:00").map(item => {
            return {
                id: item.Id,
                name: item.ProjectName
            }
          })

          setProjects(filteredProjectsData)
        }
      }, [projectsDataIsLoading, isOrgAdminAccessDenied])

    useEffect(() => {
        if (!rawCurrentBillingDataIsLoading && !isOrgAdminAccessDenied) {
            if (rawCurrentBillingData.length > 0) {
                setIsTableLoading(false)

                const parsedCurrentBilling = rawCurrentBillingData[0].map(item => {
                    let billingComponents = {}

                    if (item.Details.length > 0) {
                        item.Details[0].Billing.Items.forEach(billingItem => {
                            billingComponents[camelCase(billingItem.Item)] = billingItem.TotalUnit    
                            billingComponents[camelCase(billingItem.Item)+"UnitType"] = billingItem.UnitType    
                        })
                    }

                    return {
                        "projectName": item.Project,
                        "projectCount": 1,
                        ...billingComponents
                    }
                    
                })

                const transcribedFiles = calculateCurrentUsage(parsedCurrentBilling, "transcribedFiles")
                const storedFiles = calculateCurrentUsage(parsedCurrentBilling, "storedFiles")
                const users = calculateCurrentUsage(parsedCurrentBilling, "users")
                const projectCount = parsedCurrentBilling.length

                setTotalUsage({
                    transcribedFiles: transcribedFiles,
                    storedFiles: storedFiles,
                    users: users,
                    projectCount: projectCount
                })

                const excessTranscribedFilesUsage = rawCurrentBillingData[2].IncludedTranscriptionHours > transcribedFiles ? 0 : transcribedFiles - rawCurrentBillingData[2].IncludedTranscriptionHours
                const excessStoredFilesUsage = rawCurrentBillingData[2].IncludedStorageHours > storedFiles ? 0 : storedFiles - rawCurrentBillingData[2].IncludedStorageHours
                const excessUsersUsage = rawCurrentBillingData[2].IncludedUserCount > users ? 0 : users - rawCurrentBillingData[2].IncludedUserCount
                const excessProjectCountUsage = rawCurrentBillingData[2].IncludedProjects > projectCount ? 0 : projectCount - rawCurrentBillingData[2].IncludedProjects
                
                setExcessUsage({
                    transcribedFiles: excessTranscribedFilesUsage,
                    storedFiles: excessStoredFilesUsage,
                    users: excessUsersUsage,
                    projectCount: excessProjectCountUsage
                })

                const excessTranscribedFilesCost = excessTranscribedFilesUsage * rawCurrentBillingData[2].AddTranscriptionCost
                const excessStoredFilesUsageCost = excessStoredFilesUsage * rawCurrentBillingData[2].AddStorageCost
                const excessUsersUsageCost = excessUsersUsage * rawCurrentBillingData[2].AddUserCost
                const excessProjectCountUsageCost = excessProjectCountUsage * rawCurrentBillingData[2].AddProjectCost

                setExcessUsageCost({
                    transcribedFiles: excessTranscribedFilesCost,
                    storedFiles: excessStoredFilesUsageCost,
                    users: excessUsersUsageCost,
                    projectCount: excessProjectCountUsageCost,
                    total: excessTranscribedFilesCost + excessStoredFilesUsageCost + excessUsersUsageCost + excessProjectCountUsageCost
                })

                setCurrentBillingData(parsedCurrentBilling)
                setOrganisationName(rawCurrentBillingData[1])
                setPricingDetails(rawCurrentBillingData[2])
            } else {
                setCurrentBillingData([])
            }
        }
    }, [rawCurrentBillingDataIsLoading, isOrgAdminAccessDenied])

    const calculateCurrentUsage = (parsedCurrentBilling, component) => {
        let totalUsage = 0
        // Calculating total users count across the organisation, not per project
        // Maximum 
        if (component === "users") {
            parsedCurrentBilling.forEach(billingItem => {
                if (totalUsage < billingItem.users) {
                    totalUsage = billingItem.users
                }
            })
        } else {
            totalUsage = parsedCurrentBilling.reduce((totalItemUsage, billingItem) => {
                let componentUsage = 0
    
                switch (component) {
                    case 'transcribedFiles': {
                        if (billingItem.transcribedFiles) {
                            componentUsage = billingItem.transcribedFiles
                        }
                        break
                    }
                    case 'storedFiles': {
                        if (billingItem.storedFiles) {
                            componentUsage = billingItem.storedFiles
                        }
                        break
                    }
                    default: {
                        componentUsage = totalItemUsage
                    }
                }
    
                return totalItemUsage + componentUsage
            }, 0)
        }

        return totalUsage
    }

    const calculateExcessUsage = (parsedCurrentBilling, component) => {
        const totalUsage = parsedCurrentBilling.reduce((totalItemUsage, billingItem) => {
            let componentUsage = 0

            switch (component) {
                case 'transcribedFiles': {
                    if (billingItem.transcribedFiles) {
                        componentUsage = billingItem.transcribedFiles
                    }
                    break
                }
                case 'storedFiles': {
                    if (billingItem.storedFiles) {
                        componentUsage = billingItem.storedFiles
                    }
                    break
                }
                case 'users': {
                    if (billingItem.users) {
                        componentUsage = billingItem.users
                    }
                    break
                }
                default: {
                    componentUsage = totalItemUsage
                }
            }

            return totalItemUsage + componentUsage
        }, 0)

        return totalUsage
    }

    const camelCase = (str) => str.replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+/g, '')

    const theme = createTheme({
        components: {
          // Name of the component
          MuiTableCell: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                padding: '3pt',
                fontSize: '0.8rem'
              },
            },
          },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="row" justifyContent="center">
                <Box elevation = {0} sx={{ width: '95%', height: '100%' }}>
                    {
                        !isOrgAdminAccessDenied &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h4>
                                    <strong>Current usage estimate per {today.getDate()}/{today.getMonth() + 1}/{today.getFullYear()}</strong>
                                </h4>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        { !isTableLoading && currentBillingData && currentBillingData.length > 0 && 
                                            <TableContainer component={Paper}>
                                                <Table sx={{minWidth: 650}} aria-label="current billing table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Project</TableCell>
                                                            <TableCell align="right">Transcribed files</TableCell>
                                                            <TableCell>Unit</TableCell>
                                                            <TableCell align="right">Stored files</TableCell>
                                                            <TableCell>Unit</TableCell>
                                                            <TableCell align="right">User count</TableCell>
                                                            <TableCell>Unit</TableCell>
                                                            <TableCell align="right">Project count</TableCell>
                                                            <TableCell align="right">Total cost</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            currentBillingData.map((item, index) => {
                                                                return (
                                                                    <TableRow 
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                            <TableCell component="th" scope="row">
                                                                                {item.projectName}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {item.transcribedFiles ? item.transcribedFiles : 0}
                                                                                </TableCell>
                                                                            <TableCell>
                                                                                {item.transcribedFilesUnitType ? item.transcribedFilesUnitType : "-"}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {item.storedFiles ? item.storedFiles : 0}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {item.storedFilesUnitType ? item.storedFilesUnitType : "-"}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {item.users ? item.users : 0}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {item.usersUnitType ? item.usersUnitType : "-"}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {item.projectCount}
                                                                            </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Total usage</strong>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <strong>{totalUsage.transcribedFiles}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{totalUsage.storedFiles}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{totalUsage.users}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{totalUsage.projectCount}</strong>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={9}>
                                                                <strong>Cost estimation</strong>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Monthly fee</TableCell>
                                                            <TableCell colSpan={8} align="right">
                                                                <strong>{pricingDetails.MonthlyFee}</strong>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                Entitlement
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {pricingDetails.IncludedTranscriptionHours}
                                                            </TableCell>
                                                            <TableCell>
                                                                {pricingDetails.TranscriptionUnit}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {pricingDetails.IncludedStorageHours}
                                                            </TableCell>
                                                            <TableCell>
                                                                {pricingDetails.StorageUnit}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {pricingDetails.IncludedUserCount}
                                                            </TableCell>
                                                            <TableCell>
                                                                Active users (organisation-wide)
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {pricingDetails.IncludedProjects}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Excess usage</strong>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsage.transcribedFiles}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsage.storedFiles}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsage.users}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsage.projectCount}</strong>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                Excess fee
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {pricingDetails.AddTranscriptionCost}
                                                            </TableCell>
                                                            <TableCell>{pricingDetails.Currency}</TableCell>
                                                            <TableCell align="right">
                                                                {pricingDetails.AddStorageCost}
                                                            </TableCell>
                                                            <TableCell>{pricingDetails.Currency}</TableCell>
                                                            <TableCell align="right">
                                                                {pricingDetails.AddUserCost}
                                                            </TableCell>
                                                            <TableCell>{pricingDetails.Currency}</TableCell>
                                                            <TableCell align="right">
                                                                {pricingDetails.AddProjectCost}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Excess cost</strong>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsageCost.transcribedFiles}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsageCost.storedFiles}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsageCost.users}</strong>
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsageCost.projectCount}</strong>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <strong>{excessUsageCost.total}</strong>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={8} align="right">Total Cost ({pricingDetails.Currency})</TableCell>
                                                            <TableCell align="right">
                                                                <strong>{pricingDetails.MonthlyFee + excessUsageCost.total}</strong>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                        {
                                            currentBillingData && currentBillingData.length === 0 &&
                                            <div style={{textAlign: 'center'}}>Current usage estimate is unavailable</div>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {
                        isOrgAdminAccessDenied && 
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{textAlign: 'center'}}>
                                    You're unauthorised to access this page
                                </div>
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Grid>
        </ThemeProvider>
    )
}