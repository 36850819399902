import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import ProjectContext from '../../../contexts/ProjectContext';
import { IsExpired } from '../_helpers/isExpired';

import { authenticationService } from '../_services/authenticationService';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { isSelected, selectedproject} = useContext(ProjectContext)

    return (<Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;

        if (!currentUser || !isSelected || IsExpired()) {
            // not logged in so redirect to login page with the return url
            // const response_type = "code"
            // const client_id = encodeURIComponent(process.env.REACT_APP_PWC_ID_CLIENT_ID)
            // const redirect_url = encodeURIComponent(process.env.REACT_APP_PWC_ID_REDIRECT_URI)
            // const scope = encodeURIComponent(process.env.REACT_APP_PWC_ID_SCOPE)
            // const state = uuidv4();               
            // const url = `${process.env.REACT_APP_PWC_ID_AUTH_URL}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_url}&scope=${scope}&state=${state}`
            // window.location.assign(url);

            const url = `${process.env.REACT_APP_PWC_ID_REDIRECT_URI}`
            window.location.assign(url)

            // return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }

        const checkRole = (roles) => {

            // if the claims is for the current project, get roles
            const RoleList = currentUser && currentUser.claims.reduce((acc, rows) => {
                if (rows.ProjectId === selectedproject.Id) {
                    return [...acc, rows.RoleName]
                }
                else {
                    return acc
                }
                },[])

            const Check = RoleList.reduce((acc, rows) => {
                if (roles.indexOf(rows) !== -1) {
                    return true
                }
                else {
                    return acc
                }
                }, false)

                return Check
        }
        
        // check if route is restricted by role
        if (roles) {
            if (!roles.includes("SiteAdmin") && !roles.includes("OrganisationAdmin")) {
                if (!checkRole(roles)) {
                    // role not authorised so redirect to home page
                    return <Redirect to={{ pathname: '/'}} />
                }
            } else {
                if (roles.includes("SiteAdmin") && !currentUser.isSiteAdministrator) {
                    return <Redirect to={{ pathname: '/'}} />
                }

                if (roles.includes("OrganisationAdmin") && !currentUser.isOrganisationAdministrator) {
                    return <Redirect to={{ pathname: '/'}} />
                }
            }
        }

        // authorised so return component
        return <Component {...props} />
    }} />
    )
}