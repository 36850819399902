// This page is the layout page, all pages that are logged in will follow this layout

// React Component 
import React, { Component } from 'react';

// MUI Elements
import { Grid, Box } from '@mui/material';

// Navigation Menu
import  NavMenu from '../NavMenu/NavMenu';
import  Footer  from '../Footer/Footer';

//Services
import { authenticationService } from '../../Services/_services/authenticationService';

// Context
import ProjectContext from '../../../contexts/ProjectContext';

export class Layout extends Component {
  // set dispaly name for the layout
  static displayName = Layout.name;
  // set context to project context
  static contextType = ProjectContext;


  //constructor to initialse 
  constructor(props, context) {
    super(props, context);

    this.state = {
        currentUser: null,
    };
}

// mount the current logged in user value
componentDidMount() {
  authenticationService.currentUser.subscribe(x => this.setState({
      currentUser: x
  }));
}

  render () {
    const { currentUser} = this.state;

    // return views with layout
    return (
      <>
          {currentUser && this.context.isSelected &&
            <NavMenu />}
            <Grid container  direction="row" justifyContent="center">
              <Box elevation = {0} sx={{ width: '95%', height: '100%', minHeight: "74vh"}}>
            {this.props.children}
              </Box>
            </Grid>
          <Footer/>
      </>
    );
  }
}
