import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Grid, MenuItem, Select, Box, InputLabel, TextField, FormControl } from '@mui/material';
import { authenticationService } from '../../../Services/_services/authenticationService';
import { authHeader } from '../../../Services/_helpers/auth-header';

//Contexts
import FileContext from '../../../../contexts/FileContext';
import ProjectContext from "../../../../contexts/ProjectContext";
 

export default function AudioUploader() {
    // variable indicating whether the page is submitting
    const [isSubmitting, setSubmitting] = useState(false)
    //variable indicating the submitting stauts
    const [status, setStatus] = useState([])
    // variable for status
    const [summary, setSummary] = useState("")
    // variable for files selected to be upload
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileDisplayList, setSelectedFileDisplayList] = useState([])
    const [selectedFileVerificationList, setSelectedFileVerificationList] = useState([])
    // variable storing description input
    const [description, setDescription] = useState("")
    // variable storing language input
    const [language, setLanguage] = useState("Unknown")
    // state variable showing error status
    const [ error, setError ] = useState()
    // Files Context
    const { fetchFiles, fetchBatches } = useContext(FileContext)
    // Project Context 
    const {selectedproject} = useContext(ProjectContext)
    
    const [successfulFileUpload, setSuccessfulFileUpload] = useState(0)
    let innerLoopSuccessfulFileUpload = 0

    const convertLanguageCode = code => {
      switch (code) {
        case "Unknown": return "Unknown"
        case "en-us": return "English"
        case "zh-hk": return "Cantonese"
        case "zh-cn": return "Mandarin"
        case "ja-jp": return "Japanese"
      }
    }

    // On file select (from the pop up)
    const onFileChange = event => {
      // Update the state
      setSelectedFile(event.target.files);

      const filesList = Array.from(event.target.files)
      setSelectedFileDisplayList(filesList.map(file => file.name))

      setSelectedFileVerificationList([])
    };

    // On file upload (click the upload button)
    const handleInput = (event) => {
      // Update the state
      setLanguage(event.target.value)    
    
    };
      
    // On file upload (click the upload button)
    const handleDescription = (event) => {
      // Update the state
      setDescription(event.target.value)    
    };
      
    // On file upload (click the upload button)
    const onFileUpload = async () => {
      if (description && language) {
        if (selectedFile) {
          // Verify selected files are using correct file extension (.wav)
          let isSelectedFilesValid = true
          setSelectedFileVerificationList([])
          for (let i = 0; i < selectedFile.length; i++) {
            if (selectedFile[i].type !== "audio/wav") {
              const newStatus = {
                "file": selectedFile[i].name,
                "desc": "is not an audio file"
              }
              setSubmitting(false); 
              setSelectedFileVerificationList(prevStatus => [...prevStatus, newStatus]) 
              isSelectedFilesValid = false
            }
          }

          if (isSelectedFilesValid) {
            setError()
            setSuccessfulFileUpload(0)
            innerLoopSuccessfulFileUpload = 0
            setSummary("Initialising Upload...")
            setStatus([]);
            setSubmitting(true);

            let updatedUploadStatusId = 1
            let updatedStatusShortSescription = "New"

            if (language !== "Unknown") {
              updatedUploadStatusId = 2
              updatedStatusShortSescription = "Detected"
            }
            
            const requestOptions = {
              method: 'POST',
              headers: {'Content-Type': 'application/json', ...authHeader()},
              body: JSON.stringify({
                "Id":0,
                "Description": description,
                "CreationDate": new Date(),
                "CreatedBy": authenticationService.currentUserValue.id,
                "CustodianId":  authenticationService.currentUserValue.id,
                "UploadStatusId": updatedUploadStatusId,
                "StatusShortDesc": updatedStatusShortSescription,
                "created_at": new Date()
              })
            };
            
            // Send BatchData object
            fetch(`${process.env.REACT_APP_API_URL}/UploadBatch/Create/${selectedproject.Id}`, requestOptions)
            .then((response) => {
                  // The response is a Response instance.
                  // You parse the data into a useable format using `.json()`
                  return response.json();
                }).then((data) => {
                  // `data` is the parsed version of the JSON returned from the above endpoint.
                  const upload_batch_id = data.Id
                  
                  // Details of the uploaded file
                  for (let i = 0; i < selectedFile.length; i++) {
                    
                    // check file type
                    if (selectedFile[i].type === "audio/wav") {

                        // Create an object of formData
                        const FileformData = new FormData();
                        
                        // Update the formData object
                        FileformData.append("file", selectedFile[i])
                        FileformData.append("upload_batch_id", upload_batch_id)
                        FileformData.append("language", language)
                    
                  
                        // Request made to the backend api
                        // Send formData object
                        axios.post(`${process.env.REACT_APP_API_URL}/File/Azure/Upload/${selectedproject.Id}`, FileformData, { 
                          headers: authHeader()
                        })
                        .then(function (response) {
                          const fileUploadDate = new Date(response.data)

                          // update status
                          const newStatus = selectedFile[i].name + ": uploaded successfully on " + fileUploadDate.toString()
                          setStatus(prevStatus => [...prevStatus, newStatus])
                          // set current summary
                          setSuccessfulFileUpload((prevSuccessfulFileUpload) => prevSuccessfulFileUpload + 1)
                          innerLoopSuccessfulFileUpload += 1
                          setSummary(innerLoopSuccessfulFileUpload + " out of " + selectedFile.length + " files uploaded successfully.")            
                        })
                        .catch(function (error) {
                          if (error.response) {
                            // message when error
                            console.info(error.response);
                          }
                          // add error into status
                          const newStatus = "Error in uploading " + selectedFile[i].name + ". Reason: " + error.response
                          setStatus(prevStatus => [...prevStatus, newStatus])
                  
                          })
                        .then(function () {
                          // indicate as the file not submitting
                          setSubmitting(false);  
                          fetchFiles(false);
                          fetchBatches(false);
                          
                        });
                    } else {

                      // update status
                      const newStatus = selectedFile[i].name + ": File is not an audio file"
                      setSubmitting(false); 
                      setStatus(prevStatus => [...prevStatus, newStatus])
              
              
                    }

                  }

                }).catch((error) => {
                  // set status to error
                  setSummary(error)  
                  // indicate as the file not submitting  
                  setSubmitting(false);  

                })
          }

          
        } else {
          setError("No files were selected for upload.")
        }
      }
      
      else {
        setError("Batch Name and Language are mandatory fields.")
      }
    } 
           
    

    // function for downlaoding the log for upload
    const downloadLog = () => {

      // creates log into file
      const semicolonSeparatedStatus = status.join(";\n")
      const file = new Blob([summary, "\n", semicolonSeparatedStatus], {type: 'text/plain'});
      // creates an link to the file
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = "File upload log.txt";
      // downloads the log file
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      
    }
        
       
    
      
      return (
        <Grid container  direction="row" justifyContent="center" marginTop={5} marginLeft = {5}>
          <Box
            sx={{
              minwidth: 120 
            }}
          >
            { error &&
              <div className={'alert alert-danger'}>{error}</div>
            }
            
            <div id="upload-box">
              {
                summary === "" && 
                <>
                  <h5>
                    Select files for upload (.wav file only)
                  </h5>
                  <br />
                  <div>
                    <TextField
                          id="description"
                          label="Batch Name"
                          sx = {{width: 300}}
                          value = {description}
                          onChange={handleDescription}
                        />
                  </div>
                  <br/>
                  <div>
                    <FormControl fullWidth>
                    <InputLabel id="language-label">Language</InputLabel>
                    <Select
                        defaultValue="Unknown"
                        labelId="language-label"
                        sx = {{width: 300}}
                        id="language-select"
                        value={language}
                        label="Langugage"
                        onChange={handleInput}
                      >
                        <MenuItem value="Unknown">Unknown</MenuItem>
                        <MenuItem value="en-us">English</MenuItem>
                        <MenuItem value="zh-hk">Cantonese</MenuItem>
                        <MenuItem value="zh-cn">Mandarin</MenuItem>
                        <MenuItem value="ja-jp">Japanese</MenuItem>
                    </Select>
                    </FormControl>
                    <br />
                  </div>
                  <br/>
                  <div>
                      <label className="btn btn-primary">
                        <input type="file" multiple onChange={onFileChange} accept="audio/wav" style={{ display: 'none' }}/>
                        Choose file
                      </label>
                      &nbsp;
                      <button className="btn btn-primary" autoFocus disabled={!(selectedFile && description && language) || isSubmitting} onClick={onFileUpload}    >
                        Upload Audio
                      </button>
                      {isSubmitting &&
                          <img alt = "" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                      }
                  </div>
                  {
                    selectedFileDisplayList && selectedFileDisplayList.length > 0 &&
                    <div>
                      <hr/>
                      Selected file(s):
                      <ol>
                        {
                          selectedFileDisplayList.map((file, index) => {
                            return (
                              <li key={index}>
                                <strong>{file}</strong>
                              </li>
                            )
                          })
                        }
                      </ol>
                    </div>
                  }
                  <hr/>
                  {
                    selectedFileVerificationList && selectedFileVerificationList.length > 0 &&
                    <div>
                      File selection error
                      <ol>
                        {
                          selectedFileVerificationList.map((file, index) => {
                            return (
                              <li key={index}>
                                <strong>{file.file}</strong> {file.desc}
                              </li>
                            )
                          })
                        }
                      </ol>
                      <strong>Please select .wav file only</strong>
                    </div>
                  }
                  <br />
                </>
              }
              <div>
                { summary !== "" && 
                      <>
                        <div className={'alert alert-secondary'}>
                            <ul>{summary}</ul>
                            <ul>------------------------------------------------------------------------------------------------------------------------</ul>
                            {status.map((a) =>
                            {return <ul key={a}>{a}</ul>}
                            )
                          }
                        </div>  
                      </>
                }
                {
                    selectedFile && selectedFile.length > 0 && selectedFile.length === successfulFileUpload && 
                    <>
                      <button className="btn btn-primary" onClick={downloadLog}>Download Log</button>
                        &nbsp;&nbsp;
                        {!isSubmitting &&
                            <Link to="/UploadBatch">
                              <button className="btn btn-primary" >Go to Transcript Management</button>  
                            </Link> 
                        }
                    </>
                }
              </div>
            </div>
          </Box>  
        </Grid>
      );
    }
    
   
  