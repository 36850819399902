import { useHistory } from 'react-router-dom';

export default function FilePreview() {
    const history = useHistory()

    return (
        <div>
            File putReviewOutcomeMaster
            <button onClick={() => history.goBack()}>Return to Assign page</button>
        </div>
    )
}