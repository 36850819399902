// React and MUI Components
import React, {useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { NavLink } from 'react-router-dom';

import useFetch from '../../../../contexts/services/useFetch';
import { authenticationService } from '../../../Services/_services/authenticationService';
import { useSelector } from 'react-redux';

export default function ProjectsOrgAdmin() {
  // React hooks
  // 1. Projects list
  const [ projects, setProjects ] = useState([])
  // 2. Organisations list
  const [ organisations, setOrganisations ] = useState([])
  // 3. Users list
  const [ users, setUsers ] = useState([])
  // 4. Project deletion status
  const [status, setStatus] = useState()

  // API data pull
  // 1. Projects
  const { data: projectsData, isPending: projectsDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Projects/Organisation/${authenticationService.currentUserValue.organisationId}`, authenticationService.currentUserValue, "Projects")
  // 2. Organisation
  const { data: organisationData, isPending: organisationDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/Organisation/OrganisationAdmin`, authenticationService.currentUserValue, "Organisation")
  // 3. Users
  const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/Organisation/${authenticationService.currentUserValue.organisationId}`, authenticationService.currentUserValue, "Users")

  let isOrgAdminAccessDenied = useSelector((state) => state.fetchDataStatus.isOrgAdminAccessDenied)

  useEffect(() => {
    // Two conditions to set projects state
    // 1. Projects API data is fully loaded
    // 2. Site administrator access level is required (Only site admin can open this page)
    if (!projectsDataIsLoading && !isOrgAdminAccessDenied) {
      const filteredProjectsData = projectsData.filter(project => project.TerminationDate === "9999-12-31T00:00:00")
      setProjects(filteredProjectsData)
    }
  }, [projectsDataIsLoading, isOrgAdminAccessDenied])

  useEffect(() => {
    // Two conditions to set organisation state
    // 1. Organisation API data is fully loaded
    // 2. Site administrator access level is required (Only site admin can open this page)
    if (!organisationDataIsLoading && !isOrgAdminAccessDenied) {
        // Re-map raw organisation data into ID and name
        const mappedOrganisationData = organisationData.map(organisation => ({
          id: organisation.Id,
          name: organisation.OrganisationName
        }))
        setOrganisations(mappedOrganisationData)
    } 
  }, [organisationDataIsLoading, isOrgAdminAccessDenied])

  useEffect(() => {
    // Two conditions to set user state
    // 1. Users API data is fully loaded
    // 2. Site administrator access level is required (Only site admin can open this page)
    if (!userDataIsLoading && !isOrgAdminAccessDenied) {
      // Re-map raw User data into id and name
      const mappedUserData = userData.filter(user => user.TerminationDate === "9999-12-31T00:00:00")
      .map(user => ({
        id: user.Id,
        name: user.FullName
      }))
      setUsers(mappedUserData)
    }
  }, [userDataIsLoading, isOrgAdminAccessDenied])
  
  // Projects table header
  const projectsHeaders = [
    {
      name: "ProjectName",
      label: "Name",
      options: {
        filter: true,
        filterType: 'textField'
      }
    },
    {
      name: "ProjectDescription",
      label: "Description",
      options: {
        filter: true,
        filterType: 'textField'
      }
    },
    {
      name: "Id",
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <span>
            <NavLink className="btn btn-light" to={"/Org/Projects/Members/" + value}> Project members</NavLink>
          </span>
        }
      }
    }
  ]
      
  // rendering page
  return (
    // container and box for the page
    <Grid container  direction="row" justifyContent="center" marginTop={5} >
      {
        !isOrgAdminAccessDenied && 
        <Box elevation = {0} sx={{ width: '90%', height: '100%' }}>
          {/* // theme provider for the MUI datatable */}
          <ThemeProvider theme={createTheme()}>
            {/* // if tags are loaded, display the tags in datatable */}
            {status && <div className={'alert alert-danger'}>{status} </div>}
              { projects !== {} &&
                <MUIDataTable
                  data={projects}
                  columns={projectsHeaders}
                  options={{
                    selectableRows: "none",
                    print: false,
                    viewColumns: false
                  }}
                />
              }
          </ThemeProvider>
        </Box>
      }
      {
          isOrgAdminAccessDenied &&
          <div style={{textAlign: 'center'}}>
              You're unauthorised to access this page
          </div>
      }
    </Grid>
    );
  };


