import { authenticationService } from '../_services/authenticationService';
import axios from 'axios';

export function IsExpired() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    // const {setIsSelected} = useContext(ProjectContext)

    if (currentUser) {
        
      axios.get(`${process.env.REACT_APP_PWC_ID_TOKEN_ENDPOINT_URL}`, {
          headers: {
            Authorization: `Bearer ${currentUser.access_token}`
          }
        })
        .then(resp => {
          const exp_date = new Date(0);
          exp_date.setUTCSeconds(resp.data.exp);

          if (Date.now() >= exp_date) {
            authenticationService.logout();
            return true;
          }
        })
        .catch(err => {
          if (err.response.status === 401 || err.response.status === 403) {
            console.info("JWT token expired")
            authenticationService.logout();
          } else {
            console.info(err)
          }
          
        })
      
      return false;
    }
}
