import React, { useContext, useEffect, useRef, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, Dropdown,  DropdownToggle,  DropdownMenu,  DropdownItem, Row, Col, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';
import { PersonOutline} from '@mui/icons-material'
import { Backdrop, Box, LinearProgress, Typography } from '@mui/material';
import '../css/NavMenu.css';
import logo from '../../Images/PwC-logo.svg';
import { authenticationService } from '../../Services/_services/authenticationService';
import { history } from '../../Services/_helpers/history';
import ProjectContext from '../../../contexts/ProjectContext';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { useSelector, useDispatch } from 'react-redux';
import { toggleOrgAdminAccessDeniedWarningPage } from '../../../features/fetchData/fetchDataStatusSlice'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function NavMenu() {
  // Selected project and Is selected context 
  const { selectedproject, setIsSelected } = useContext(ProjectContext)

  // React hooks
  // 1. Current user object
  const [ currentUser, setCurrentUser] = useState(null)  
  // 2. File manager status
  const [ isFileManager, setIsFileManager] = useState(false)
  // 3. Review manager status
  const [ isReviewManager, setIsReviewManager] = useState(false)
  // 4. Reviewer status
  const [ isReviewer, setIsReviewer] = useState(false)
  // 5. Site administrator
  const [ isSiteAdmin, setIsSiteAdmin ] = useState(false)
  // 6. Organisation admin status
  const [ isOrganisationAdmin, setIsOrganisationAdmin ] = useState(false)
  // 7. Navigation bar collapse status
  const [ collapsed, setCollapsed] = useState(true)  
  // 8. File manager menu open status
  const [ isFileManagerMenuOpen, setIsFileManagerMenuOpen] = useState(false)
  // 9. Review manager menu open status
  const [ isReviewManagerMenuOpen, setIsReviewManagerMenuOpen] = useState(false)
  // 10. Reviewer menu open status
  const [ isReviewerMenuOpen, setIsReviewerMenuOpen ] = useState(false)
  // 11. Activity log display status
  const [ isOpenActivityLog, setIsOpenActivityLog ] = useState(false)
  // 12. Loading screen display status
  const [ openLoadingScreen, setOpenLoadingScreen ] = useState(false)
  // 13. API data fetch progress in percentage
  const [ fetchDataProcessPercentage, setFetchDataProcessPercentage ] = useState(0)
  // 14. Current activity log
  const [ currentActivityLog, setCurrentActivityLog ] = useState("")

  // Redux
  // 1. Activity logs details
  let activityLogsDetails = useSelector((state) => state.activityLogs.logs)
  // 2. API data fetch progress count
  let fetchDataProcessCount = useSelector((state) => state.fetchDataStatus.fetchingDataProcessCount)
 
  const dispatch = useDispatch()

  useEffect(() => {
    // If fetching data process count reach is zero, show loading screen overlay/disable navigation menu
    if (fetchDataProcessCount > 0) {
      setOpenLoadingScreen(true)

      const percentage = Math.round(100 / fetchDataProcessCount)
      setFetchDataProcessPercentage(percentage)
    } else {
      setFetchDataProcessPercentage(100)

      setTimeout(() => {
        setOpenLoadingScreen(!openLoadingScreen)
      }, [500])
    }
  }, [fetchDataProcessCount])

  useEffect(() => {
    // When activity logs details state changed, display the details in the progress bar
    if (activityLogsDetails && activityLogsDetails.length > 0) {
      const currentActivityLogMessage = "- " + activityLogsDetails[0].resourceType + activityLogsDetails[0].message
      setCurrentActivityLog(currentActivityLogMessage)
    }
  }, [activityLogsDetails])

  const descriptionElementRef = useRef(null)

  useEffect(() => {
    // When Activity Log is opened, change the screen focus
    if (isOpenActivityLog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [isOpenActivityLog])

  
  // When loading the page in the beginning, these states are being prepared:
  // 1. currentUser
  // 2. isFileManager
  // 3. isReviewManager
  // 4. isReviewer
  // 5. isSiteAdmin
  // 6. isOrganisationAdmin
  useEffect(() => {
    authenticationService.currentUser.subscribe(x => {
      // if the claims is for the current project, get roles
      const RoleList = x && x.claims.reduce((acc, rows) => {
        if (rows.ProjectId === selectedproject.Id) {
          return [...acc, rows.RoleName]
        }
        else {
          return acc
        }
      },[])
  
      setCurrentUser(x)
      setIsFileManager(x && RoleList.indexOf("File Manager") !== -1)
      setIsReviewManager(x && RoleList.indexOf("Review Manager") !== -1)
      setIsReviewer(x && RoleList.indexOf("Reviewer") !== -1)

      setIsSiteAdmin(x && x.isSiteAdministrator)
      setIsOrganisationAdmin(x && x.isOrganisationAdministrator)
    });
  }, [])
  
  // Event handler: Change navigation bar display
  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  // Event handler: Change file manager menu display
  const toggleFileManagerMenu = () => {
    setIsFileManagerMenuOpen(!isFileManagerMenuOpen)
  }

  // Event handler: Change review manager menu display
  const toggleReviewManagerMenu = () => {
    setIsReviewManagerMenuOpen(!isReviewManagerMenuOpen)
  }

  // Event handler: Change reviewer menu display
  const toggleReviewerMenu = () => {
    setIsReviewerMenuOpen(!isReviewerMenuOpen)
  }

  // Cut project name length into first 47 characters
  const sliceProjectNameLength = () => {
    let projectName = selectedproject.ProjectName

    if (projectName.length > 50) {
      projectName = projectName.slice(0,47) + "..."
    }

    return projectName
  }

  // Event handler: Open activity logs modal
  const openActivityLogs = () => {
    setIsOpenActivityLog(true)
  }

  // Event handler: Close activity logs modal
  const closeActivityLogs = () => {
    setIsOpenActivityLog(false)
  }

  // Event handler: Log out from Voice Detect
  const logout = () => {
    authenticationService.logout();
    history.push('/login');
    sessionStorage.removeItem('Project');
    setIsSelected(false);
  }

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom mb-3" light>
        <Backdrop
          sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={openLoadingScreen}
        >
          <Box sx={{ width: '50%', alignItems: 'center'}}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress color='inherit' variant='determinate' value={fetchDataProcessPercentage} />
            </Box>
            <Box sx={{ width: '100%'}}>
              <Typography>
                {fetchDataProcessPercentage}% {currentActivityLog}
              </Typography>
            </Box>
          </Box>
        </Backdrop>
        <Container fluid className='justify-content-md-end'>
          <Dialog
            open={isOpenActivityLog}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeActivityLogs}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="scroll-dialog-title">Activity Logs</DialogTitle>
            <DialogContent dividers={true}>
              { 
                activityLogsDetails && activityLogsDetails.map((log, index) => {
                  return (
                    <div key={index}>
                      Status: <b style={{color: log.statusCode === "FAIL" ? '#e0301e': '#000000'}}>{ log.status }</b> [{log.timestamp}]<br/> 
                      Message: <b>{log.resourceType}</b> {log.message}<hr/>
                    </div>
                  )
                })
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={closeActivityLogs}>Close</Button>
            </DialogActions>
          </Dialog>
          <a href="/">
          <img src={logo} width="100" height="65" alt="" />
          </a>
          <NavbarBrand id="vtt-logo" tag={Link} to="/" >&emsp; | &emsp;Voice Detect&emsp; |</NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex">
            <Container style={{position: "absolute", bottom: "10px"}}>
              <Row>
                <Col md={12} style={{fontSize: "10px"}}>
                  Project
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <strong>
                    {sliceProjectNameLength()}
                  </strong>
                </Col>
              </Row>
            </Container>
          </Collapse>
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar style={{zIndex: 999}}>
            <Nav>
              <NavItem>
              <button className="btn btn-primary" onClick={openActivityLogs}>
                Activity logs
              </button>
              </NavItem>
              <NavItem>
                <Dropdown
                    isOpen={isFileManagerMenuOpen}
                    toggle={toggleFileManagerMenu}
                  >
                    <DropdownToggle
                      className="text-dark"
                      caret
                      nav
                    >
                      File Manager
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem disabled={ isFileManager ? false : true}>
                        <NavLink tag={Link} className="text-dark" to="/AudioUploader">Upload</NavLink>
                      </DropdownItem>
                      <DropdownItem disabled={ isFileManager ? false : true}>
                        <NavLink tag={Link} className="text-dark" to="/UploadBatch">Transcribe</NavLink>
                      </DropdownItem>
                      <DropdownItem disabled={ isFileManager ? false : true}>
                        <NavLink tag={Link} className="text-dark" to="/FileManagerDashboard">Dashboard</NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
              </NavItem>
              <NavItem>
                <Dropdown
                  isOpen={isReviewerMenuOpen}
                  toggle={toggleReviewerMenu}
                >
                  <DropdownToggle
                    className="text-dark"
                    caret
                    nav
                  >
                    Review Manager
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem disabled={ isReviewManager ? false : true}>
                      <NavLink tag={Link} className="text-dark" to="/FileManagement">Assign</NavLink>
                    </DropdownItem>
                    <DropdownItem disabled={ isReviewManager ? false : true}>
                      <NavLink tag={Link} className="text-dark" to="/Dashboard">Review Dashboard</NavLink>
                    </DropdownItem>
                    <DropdownItem disabled={ isReviewManager ? false : true}>
                      <NavLink tag={Link} className="text-dark" to="/Manager/Review/All">Review all</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <Dropdown
                  isOpen={isReviewManagerMenuOpen}
                  toggle={toggleReviewManagerMenu}
                >
                  <DropdownToggle
                    className="text-dark"
                    caret
                    nav
                  >
                    Reviewer
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem disabled={ isReviewer ? false : true}>
                      <NavLink tag={Link} className="text-dark" to="/MyReviewQueue">My Queue</NavLink>
                    </DropdownItem>
                    <DropdownItem disabled={ isReviewer ? false : true}>
                      <NavLink tag={Link} className="text-dark" to="/Review">Review (queue)</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              { isOrganisationAdmin && 
                <NavItem>
                    <UncontrolledDropdown inNavbar>
                      <DropdownToggle className="text-dark" nav caret>
                        Org Admin
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem color="secondary">
                          <NavLink tag={Link} className="text-dark" to="/Org/Billing/Current">Current Usage</NavLink>
                        </DropdownItem>
                        {/* <DropdownItem color="secondary">
                          <NavLink tag={Link} className="text-dark" to="/Org/Billing/History">Billing History</NavLink>
                        </DropdownItem> */}
                        <DropdownItem color="secondary">
                          <NavLink tag={Link} className="text-dark" to="/Org/Users">Manage Users</NavLink>
                        </DropdownItem>
                        <DropdownItem color="secondary">
                          <NavLink tag={Link} className="text-dark" to="/Org/Projects">Manage Projects</NavLink>
                        </DropdownItem>
                        <DropdownItem color="secondary">
                          <NavLink tag={Link} className="text-dark" to="/Org/IssueTags">Manage Issue Tags</NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                </NavItem>
              }
              { isSiteAdmin && 
                <NavItem>
                    <UncontrolledDropdown inNavbar>
                      <DropdownToggle className="text-dark" nav caret>
                        Site Admin
                      </DropdownToggle>
                      <DropdownMenu right>
                      <DropdownItem color="secondary">
                        <NavLink tag={Link} className="text-dark" to="/Site/Users">Manage Users</NavLink>
                      </DropdownItem>
                      <DropdownItem color="secondary">
                        <NavLink tag={Link} className="text-dark" to="/Site/Organisations">Manage Organisations</NavLink>
                      </DropdownItem>
                      <DropdownItem color="secondary">
                        <NavLink tag={Link} className="text-dark" to="/Site/Projects">Manage Projects</NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                    </UncontrolledDropdown>
                </NavItem>
              }
              <NavItem>
                <UncontrolledDropdown inNavbar>
                  <DropdownToggle nav className={"text-dark"} style={{padding:"5px"}}>
                    <div  id="account-button" style={{textAlign:"center", height:"30px", width: "30px", background: "#e0301e", borderRadius: "50%"}}>
                    <span id="account-button-text" style={{verticalAlign: "middle", color: "#ffffff"}}>{currentUser ? (currentUser.firstName[0] + currentUser.lastName[0]) : (<PersonOutline/> )}</span>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {currentUser && <DropdownItem color="secondary">
                      Logged in as {currentUser.fullname}
                    </DropdownItem>}
                    <DropdownItem divider />
                    <DropdownItem color="secondary">
                      <NavLink className="text-dark" to="/login" onClick={() => { 
                          setIsSelected(false)
                          sessionStorage.removeItem('Project');
                        }}> Change Project </ NavLink>
                    </DropdownItem>
                    <DropdownItem color="secondary">
                      <NavLink onClick={logout} className="text-dark" to="/Login">Logout</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  )
}