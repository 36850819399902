import { Paper, Box, Grid } from '@mui/material';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import Background from '../../Images/background.jpg';
import logo from '../../Images/PwC-logo.svg';

import { authenticationService } from '../../Services/_services/authenticationService';

export default function ContactUs() {
    let isProjectSelected = false

    if(sessionStorage.getItem('Project')) {
        isProjectSelected = true
    }

    const contactUsContent = <div>
        <div style={{ display: "flex",  justifyContent: "center"}} >
            <h5> 
                <b>Contact Us</b> 
            </h5>
        </div> 
        <p>For any general enquiry, please contact Voice Detect support at <a href='mailto:voicedetectsupport@hk.pwc.com'>voicedetectsupport@hk.pwc.com</a></p>
        <p>For urgent enquiry, please contact your specified number in the contract.</p>
    </div>

    return (
        <Grid container direction="row" justifyContent="center" >
            {
                authenticationService.currentUserValue && isProjectSelected && contactUsContent
            }
            {
                (!authenticationService.currentUserValue || !isProjectSelected) &&
                <Box style={{            
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    height: '90vh',
                    backgroundImage: `url(${Background})`,
                    }} >
                
                    <Box style={{            
                        width: "100vw",
                        height: '90vh',
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        }} >
                
                        <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                        }}>
                            <Paper elevation={3} sx={{backgroundColor : '#ffffff',  margin: '10vh', padding: 5, overflow: 'auto'}} >
                                {contactUsContent}
                                <hr/>
                                <NavLink tag={Link} className="btn btn-primary" to="/login" style={{color: '#ffffff'}}>
                                    Return to previous page
                                </NavLink>
                            </Paper>
                        </Box>
                    </Box>  
                </Box>  
            }
        </Grid>
    )
}