// React and MUI Components
import React, {useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { NavLink } from 'react-router-dom';

//Components
import Modal from '../../SharedComponents/Modal';

//Authentication
import { authHeader } from '../../../Services/_helpers/auth-header';

import useFetch from '../../../../contexts/services/useFetch';
import { authenticationService } from '../../../Services/_services/authenticationService';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function ManageOrganisations() {
    // React hooks
    // 1. Organisation list
    const [ organisations, setOrganisations ] = useState([])
    // 2. Users list
    const [ users, setUsers ] = useState([])
    // 3. Status
    const [ status, setStatus] = useState()

    // API data pull
    // 1. Organisation
    const { data: organisationRawData, isPending: organisationRawDataIsLoading, error: organisationLoadingError} = useFetch(`${process.env.REACT_APP_API_URL}/Organisation/SiteAdmin`, authenticationService.currentUserValue, "Organisation")
    // 2. User
    const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/`, authenticationService.currentUserValue, "Organisation")

    // Site administrator access permission from Redux
    let isSiteAdminAccessDenied = useSelector((state) => state.fetchDataStatus.isSiteAdminAccessDenied)

    useEffect(() => {
        // Two conditions to set organisation state
        // 1. Organisation API data is fully loaded and no error
        // 2. Site administrator access level is required (Only site admin can open this page) 
        if (!organisationRawDataIsLoading && !organisationLoadingError && !isSiteAdminAccessDenied) {
            // Filter active organisation only by termination date
            const filteredOrganisationData = organisationRawData.filter(organisation => organisation.TerminationDate === "9999-12-31T00:00:00")
            setOrganisations(filteredOrganisationData)
        } 
    }, [organisationRawDataIsLoading, organisationLoadingError, isSiteAdminAccessDenied])
    
    useEffect(() => {
        // Two conditions to set user state
        // 1. User API data is fully loaded
        // 2. Site administrator access level is required (Only site admin can open this page) 
        if (!userDataIsLoading && !isSiteAdminAccessDenied) {
            // Filter active user only by termination date
            const mappedUserData = userData.filter(user => user.TerminationDate === "9999-12-31T00:00:00")
            // Re-map raw user data into ID and name
            .map(user => ({
                id: user.Id,
                name: user.FullName
            }))
            setUsers(mappedUserData)
        }
    }, [userDataIsLoading, isSiteAdminAccessDenied])

    // Refresh organisation state by calling Organisation for site administrator API
    const fetchOrganisationData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/Organisation/SiteAdmin`, {
            headers: authHeader()
        })
        .then(resp => {
            // Filter active organisation only by termination date
            const filteredOrganisationData = resp.data.filter(organisation => organisation.TerminationDate === "9999-12-31T00:00:00")
            setOrganisations(filteredOrganisationData)
        })
        .catch(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                console.info("JWT token expired")
                authenticationService.logout();
            } else {
                console.info(err)
            }
        })
    }

    // Event handler: Delete organisation then pricing data by organisation ID
    const deleteOrganisation = async (organisationId, organisationName) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/Organisation/Delete/${organisationId}`, { 
          headers: authHeader()
        })
        .then(deleteExistingOrganisationResponse => {
          if (deleteExistingOrganisationResponse.status === 200) {
            axios.delete(`${process.env.REACT_APP_API_URL}/Pricing/Delete/${organisationId}`, { 
              headers: authHeader()
            })
            .then(deleteOrganisationResponse => {
              if (deleteOrganisationResponse.status === 200) {
                setStatus("Organisation " + organisationName +" pricing detail is successfully removed")
                fetchOrganisationData()
              }
            })
            .catch(error => {
                console.info("Organisation pricing details removal error:")
                console.info(error)
                setStatus("Organisation " + organisationName + " pricing details removal failed, please contact admin for troubleshooting")
            })
          }
        })
        .catch(error => {
            console.info("Organisation removal error:")
            console.info(error)
            setStatus("Organisation removal failed, please contact admin for troubleshooting")
        })
    }

    // Organisation table header
    const organisationHeaders = [
    {
        name: "OrganisationName",
        label: "Name",
        options: {
            filter: true,
            filterType: 'textField'
        } 
    },
    {
        name: "OrganisationDescription",
        label: "Description",
        options: {
            filter: true,
            filterType: 'textField'
        } 
    },
    {
        name: "iPowerClientCode",
        label: "iPower client code",
        options: {
            filter: true,
            filterType: 'textField'
        } 
    },
    {
        name: "OwnerUser",
        label: "Owner",
        options: {
            filter: true,
            filterType: 'textField',
            customBodyRender: (value) => {
                return value["FullName"]
            }
        }
    },
    {
        name: "CreatedByUser",
        label: "Creator",
        options: {
            filter: true,
            filterType: 'textField',
            customBodyRender: (value) => {
                return value["FullName"]
            }
        }
    },
    {
        name: "Id",
        label: "Actions",
        options: {
            filter: false,
            customBodyRender: (value) => {
                return <span>
                    <NavLink className="btn btn-light" to={"/Site/Organisations/Edit/" + value}> Edit</NavLink>
                </span>
            }
        }
    }
    ]
    
    // Delete warning modal dialog
    const deleteOrganisationModal = {
        name: "",
        options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
            return (
                <Modal 
                titlename = {"Delete"} 
                idx = {organisations[dataIndex]["Id"]}
                handleSubmit = {() => {deleteOrganisation(organisations[dataIndex].Id, organisations[dataIndex].OrganisationName)}}
                saveButtonLabel = "Delete"
                clearInput={() => {}}>
                
                Are you sure you want to delete project <strong>{organisations[dataIndex].OrganisationName}</strong>?
                </Modal>
            );
            }
        }
    }

    return (
        <Grid container  direction="row" justifyContent="center" marginTop={5} >
            <Box elevation = {0} sx={{ width: '90%', height: '100%' }}>
                {
                    !isSiteAdminAccessDenied &&
                    <ThemeProvider theme={createTheme()}>
                        <NavLink className="btn btn-primary" to="/Site/Organisations/New"> New organisation</NavLink>
                        <br/>
                        <br/>
                        {status && <div className={'alert alert-danger'}>{status} </div>}
                            { organisations !== {} &&
                            <MUIDataTable
                                data={organisations}
                                columns={organisationHeaders.concat(deleteOrganisationModal)}
                                options={{
                                    selectableRows: "none",
                                    print: false,
                                    viewColumns: false
                                }}
                            />
                            }
                    </ThemeProvider>
                } 
                {
                    isSiteAdminAccessDenied &&
                    <div style={{textAlign: 'center'}}>
                        You're unauthorised to access this page
                    </div>
                }
            </Box>
        </Grid>
    )
}