// React and MUI Components
import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, TextField, InputLabel } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles"
import MUIDataTable from "mui-datatables";

import { useSelector } from 'react-redux';

// authentication
import { authHeader } from '../../Services/_helpers/auth-header';

// Component
import Modal from '../SharedComponents/Modal';
import useFetch from '../../../contexts/services/useFetch';
import { authenticationService } from '../../Services/_services/authenticationService';
import axios from 'axios';
import ProjectContext from '../../../contexts/ProjectContext';

export default function UsersOrgAdmin() {
  // API data pull
  // 1. Users
  const { data: userData, isPending: userDataIsLoading} = useFetch(`${process.env.REACT_APP_API_URL}/User/Organisation/${authenticationService.currentUserValue.organisationId}`, authenticationService.currentUserValue, "User")

  // Redux
  // 1. Organisation admin access denied status
  let isOrgAdminAccessDenied = useSelector((state) => state.fetchDataStatus.isOrgAdminAccessDenied)

  // React hooks
  // 1. New user form completion status
  const [ isNewUserFormCompleted, setIsNewUserFormCompleted ] = useState(false)
  // 2. Selected user object
  const [selectedUser, setSelectedUser] = useState({
    "OrganisationId": authenticationService.currentUserValue.organisationId,
    "UID": "",
    "FirstName": "",
    "LastName": "",
    "EmailAddress": "",
    "SiteAdministrator": false,
    "OrganisationAdministrator": false
  })
  // 3. Progress status
  const [status, setStatus] = useState()
  // 4. Users lists
  const[ users, setUsers ] = useState([])

  useEffect(() => {
    // Two conditions to set User state:
    // 1. Users API data is fully loaded
    // 2. Organisation administrator access level is required (Only organisation admin can open this page) 
    if (!userDataIsLoading && !isOrgAdminAccessDenied) {
      // Filter users by termination date, only active users are loaded
      const ActiveUsers = userData.filter((rows) => rows.TerminationDate ===  "9999-12-31T00:00:00")
      setUsers(ActiveUsers)
    }
  }, [userDataIsLoading, isOrgAdminAccessDenied])

  // Event handler: Change selectedIssueTag state and validate issue tag form
  const handleInput = (event) => {
    let updatedItem = selectedUser
    updatedItem[event.target.name] = event.target.value;
    setSelectedUser(updatedItem);

    // Validation rule
    // 1. First name      -> Not empty string
    // 2. Last name       -> Not empty string
    // 3. Email address   -> Not empty string
    if (selectedUser["FirstName"] !== "" && selectedUser["LastName"] !== "" && selectedUser["EmailAddress"] !== "") {
      setIsNewUserFormCompleted(true)
    } else {
      setIsNewUserFormCompleted(false)
    }
  }
  
  // Clear all inputs when modals are closed
  const clearInput = ()  => {
    setSelectedUser({
      "OrganisationId": authenticationService.currentUserValue.organisationId,
      "UID": "",
      "FirstName": "",
      "LastName": "",
      "EmailAddress": "",
      "SiteAdministrator": false,
      "OrganisationAdministrator": false
    });
    setIsNewUserFormCompleted(false)
  }

  // Refresh user state by calling User API
  const fetchUserData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/User/`, {
      headers: authHeader()
    })
    .then(resp => {
      // Filter users by termination date, only active users are loaded
      const ActiveUsers = resp.data.filter((rows) => rows.TerminationDate ===  "9999-12-31T00:00:00")
      setUsers(ActiveUsers)
    })
    .catch(err => {
      if (err.response.status === 401 || err.response.status === 403) {
        console.info("JWT token expired")
        authenticationService.logout();
      } else {
        console.info(err)
      }
      
    })
  }

  // Event handler: Register new user
  const handleSubmit = async () => {
    const postNewUserData = {
      "organisation_id": authenticationService.currentUserValue.organisationId,
      "uid": selectedUser["UID"],
      "first_name": selectedUser["FirstName"],
      "last_name":  selectedUser["LastName"],
      "email_address": selectedUser["EmailAddress"],
      "site_administrator": false,
      "organisation_administrator": false,
    }

    // Register new user through '/User/Create' API
    axios.post(`${process.env.REACT_APP_API_URL}/User/Create`, postNewUserData, { 
      headers: authHeader()
    })
    .then(createNewUserResponse => {
      if (createNewUserResponse.status === 200) {
        setStatus("User is successfully created")
        fetchUserData()
      }
    })
    .catch(error => {
      console.info("New user creation error")
      console.info(error)
    })
  }

  // Event handler: Update user
  const updateUsers = async (dataIndex, userId) => {
    const updatedUserData = {
      "OrganisationId":  authenticationService.currentUserValue.organisationId,
      "UID": selectedUser["UID"] === undefined ? users[dataIndex].UID : selectedUser["UID"],
      "FirstName": selectedUser["FirstName"] === undefined ? users[dataIndex].FirstName : selectedUser["FirstName"],
      "LastName":  selectedUser["LastName"] === undefined ? users[dataIndex].LastName : selectedUser["LastName"],
      "EmailAddress": selectedUser["EmailAddress"] === undefined ? users[dataIndex].EmailAddress : selectedUser["EmailAddress"],
      "SiteAdministrator": users[dataIndex].SiteAdministrator ? 1 : 0,
      "OrganisationAdministrator": users[dataIndex].OrganisationAdministrator ? 1 : 0,
    }
    
    const updatedForm = []

    if (users[dataIndex].FirstName !== selectedUser["FirstName"]) {
      updatedForm.push("First name")
    }

    if (users[dataIndex].LastName !== selectedUser["LastName"]) {
      updatedForm.push("Last name")
    }

    if (users[dataIndex].EmailAddress !== selectedUser["EmailAddress"]) {
      updatedForm.push("Email")
    }

    // Update user through '/User/Update/' API
    axios.put(`${process.env.REACT_APP_API_URL}/User/Update/${userId}`, updatedUserData, { 
      headers: authHeader()
    })
    .then(updateUserResponse => {
      if (updateUserResponse.status === 200) {
        setStatus("User is successfully updated")
        fetchUserData()
      }
    })
    .catch(error => {
      console.info("Edit user error")
      console.info(error)
    })
  }

  // Event handler: Delete user
  const deleteUsers = async (dataIndex, userId) => {
    // Delete user through '/User/Delete/' API
    axios.delete(`${process.env.REACT_APP_API_URL}/User/Delete/${userId}`, { 
      headers: authHeader()
    })
    .then(updateUserResponse => {
      if (updateUserResponse.status === 200) {
        setStatus("User is successfully removed")
        fetchUserData()
      }
    })
    .catch(error => {
      console.info("Delete user error")
      console.info(error)
    })
  }

  // User table header
  const headers = [ 
    {
      name: "FirstName",
      label: "First Name",
      options: {
        filter: true,
        filterType: 'textField'
      }
    },
    {
      name: "LastName",
      label: "Last Name",
      options: {
        filter: true,
        filterType: 'textField'
      }
    },
    {
      name: "UID",
      label: "UID",
      options: {
        filter: true,
        filterType: 'textField'
      }  
    },
    {
      name: "EmailAddress",
      label: "Email Address",
      options: {
        filter: true,
        filterType: 'textField'
      }
    },
    {
      name: "OrganisationAdministrator",
      label: "Organisation Administrator",
      options: {
        filter: true,
        customBodyRender: (value) => {

          if (value) {
            return "Yes"
          }
          else {
            return "No"
          }
        }
      }
    }          
    
  ];
  
  const edit = {
    name: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Modal 
            titlename = {"Edit User"} 
            idx = {users[dataIndex]["Id"]}
            handleSubmit = {() => {updateUsers(dataIndex, users[dataIndex]["Id"])}}
            clearInput = {clearInput} 
            disableSaveButton={!isNewUserFormCompleted}
            initialData = {() => {setSelectedUser({
              "OrganisationId": users[dataIndex].OrganisationId,
              "FirstName": users[dataIndex].FirstName,
              "LastName": users[dataIndex].LastName,
              "EmailAddress": users[dataIndex].EmailAddress
            })}}>            
            <InputLabel id="FirstName-label">First Name</InputLabel>
            <TextField id="FirstName" 
                    name="FirstName"
                    type = "text"
                    style = {{width: 250}} 
                    onChange={handleInput}
                    defaultValue = {users[dataIndex].FirstName}
                    variant="outlined"
                    /> 
            <br/>
            <InputLabel id="LastName-label">Last Name</InputLabel>
            <TextField id="LastName" 
                    name="LastName"
                    type = "text"
                    style = {{width: 250}} 
                    onChange={handleInput}
                    defaultValue = {users[dataIndex].LastName}
                    variant="outlined"
                    /> 
            <br/>
            <InputLabel id="UID-label">UID</InputLabel>
            <TextField id="UID" 
                    name="UID"
                    type = "text"
                    style = {{width: 250}} 
                    onChange={handleInput}
                    defaultValue = {users[dataIndex].UID}
                    variant="outlined"
                    /> 
            <br/>
            <InputLabel id="EmailAddress-label">Email Address</InputLabel>
            <TextField id="EmailAddress" 
                    name="EmailAddress"
                    type = "email"
                    style = {{width: 250}} 
                    onChange={handleInput}
                    defaultValue = {users[dataIndex].EmailAddress}
                    variant="outlined"
                    /> 
            <br/>
          </Modal>
        );
      }
    }
  }

  const Delete = {
    name: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        return (
          <Modal 
          titlename = {"Delete User"} 
          idx = {users[dataIndex]["Id"]}
          handleSubmit = {() => deleteUsers(dataIndex, users[dataIndex]["Id"])}
          saveButtonLabel = "Delete"
          clearInput = {clearInput} >
            Are you sure you want to delete the user?
          </Modal>
        );
      }
    }
  }
  
  return (
    <Grid container  direction="row" justifyContent="center" marginTop={5} >
      <Box elevation = {0} sx={{ width: '90%', height: '100%' }}>
        {
          !isOrgAdminAccessDenied &&
          <ThemeProvider theme={createTheme()}>
            {
              users && authenticationService.currentUserValue.isOrganisationAdministrator && 
              <div>
                <Modal           
                  titlename = {"Add User"} 
                  idx = {users.length + 1}
                  handleSubmit = {handleSubmit}
                  clearInput = {clearInput} 
                  disableSaveButton={!isNewUserFormCompleted}>                
                      <InputLabel id="FirstName-label">First Name</InputLabel>
                      <TextField id="FirstName" 
                              name="FirstName"
                              type = "text"
                              style = {{width: 250}} 
                              onChange={handleInput}
                              variant="outlined"
                              /> 
                      <br/>
                      <InputLabel id="LastName-label">Last Name</InputLabel>
                      <TextField id="LastName" 
                              name="LastName"
                              type = "text"
                              style = {{width: 250}} 
                              onChange={handleInput}
                              variant="outlined"
                              /> 
                      <br/>
                      <InputLabel id="UID-label">UID</InputLabel>
                      <TextField id="UID" 
                              name="UID"
                              type = "text"
                              style = {{width: 250}} 
                              onChange={handleInput}
                              variant="outlined"
                              /> 
                      <br/>
                      <InputLabel id="EmailAddress-label">Email Address</InputLabel>
                      <TextField id="EmailAddress" 
                              name="EmailAddress"
                              type = "email"
                              style = {{width: 250}} 
                              onChange={handleInput}
                              variant="outlined"
                              /> 
                    <br/>
                </Modal>
                <br/>
                <br/>
              </div>
            }
              {status && <div className={'alert alert-danger'}>{status} </div>}
              {users  &&
                <MUIDataTable
                  data={users}
                  columns={headers.concat(edit).concat(Delete)}
                  options={{
                    selectableRows: "none",
                    print: false,
                    viewColumns: false
                  }}
                />
              }
          </ThemeProvider>
        }
        {
          isOrgAdminAccessDenied &&
          <div style={{textAlign: 'center'}}>
              You're unauthorised to access this page
          </div>
        }
      </Box>
    </Grid>

    );
  };


