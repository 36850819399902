import React, { useContext } from 'react';
import { Paper, Tab, Tabs, Tooltip } from '@mui/material';
import { Edit, Save, Cancel, FileDownload } from '@mui/icons-material';
import ReviewContext from '../../../../contexts/ReviewContext'
import ReviewFileContext from '../../../../contexts/ReviewFileContext'
import StatusContext from '../../../../contexts/StatusContext';
import ProjectContext from '../../../../contexts/ProjectContext';
import { authHeader } from '../../../Services/_helpers/auth-header';
import axios from 'axios';

export default function UploadBatchTranscriptToolbar() {
    // Status context
    // 1. Set status (React hooks)
    // 2. Set open (React hooks)
    const { setStatus, setOpen } = useContext(StatusContext)

    // Project context: Selected project
    const { selectedproject } = useContext(ProjectContext)

    // Review file context
    // 1. Reviewed transcript
    // 2. Selected transcript tab
    // 3. Set selected transcript tab (React hooks) 
    // 4. Is edit transcript mode
    // 5. Set is edit transcript mode (React hooks)
    // 6. Fetch review files (Function)
    // 7. Open edit transcript tools (Function) 
    // 8. Set open edit transcript tools (React hooks)
    // 9. Transcript ID
    // 10. Upload batch files queue
    const { 
        reviewedTranscript, 
        selectedTranscriptTab, 
        setSelectedTranscriptTab, 
        isEditTranscriptMode, 
        setIsEditTranscriptMode, 
        fetchReviewFiles, 
        openEditTranscriptTools, 
        setOpenEditTranscriptTools, 
        transcriptId,
        uploadBatchFilesQueue
    } = useContext(ReviewContext)

    // Review file context
    // 1. Fetch transcription (Function)
    const { 
        fetchTranscription
    } = useContext(ReviewFileContext)

    // Transcription tab selection props
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    // Event handler: Change transcript tab selection
    const changeTranscriptTab = (event, tabIndex) => {
        if (tabIndex !== 0) {
            setOpenEditTranscriptTools(false)
        } else {
            setOpenEditTranscriptTools(true)
        }

        setSelectedTranscriptTab(tabIndex);
    }

    // Event handler: Export transcript document
    const handleExport = () => {
        setStatus("Generating Transcript.")
        setOpen(true)
        axios.get(`${process.env.REACT_APP_API_URL}/Export/${selectedproject.Id}/${transcriptId}?export_format=docx`, { 
          responseType: 'blob',
          headers: authHeader()
        })
        .then((response) => {
            const filename = response.headers['content-disposition'].split("filename=")[1]
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = filename
            //   link.setAttribute('download', filename);
            //   document.body.appendChild(link);
            link.click();      
            setIsEditTranscriptMode(false)
            setStatus("Transcripts Successfully Exported.")
            setOpen(true)
            fetchReviewFiles();
      
        }).catch((error) => {
            console.info(error)
        })
    }

    // Event handler: Save transcription
    const handleSave = () => {
        const properties = { 'TranscriptionText' : JSON.stringify(reviewedTranscript) }
    
        axios.put(`${process.env.REACT_APP_API_URL}/Transcription/Update/${selectedproject.Id}/${transcriptId}`,properties, { 
            headers: authHeader()
        })
        .then(() => {
            setIsEditTranscriptMode(false)
            setStatus("Transcripts Successfully Saved.")
            setOpen(true)
            fetchTranscription()    
        }).catch((error) => {
            console.info(error)
        })   
    }

    return (
        <div>
            {
                uploadBatchFilesQueue.length > 0 &&
                <Paper sx={{padding: 2, marginBottom: "10px"}} elevation={4}>
                    Transcription tabs
                    <Paper sx={{padding: 1, marginTop: "10px"}}>
                        <Tabs value={selectedTranscriptTab} onChange={changeTranscriptTab} aria-label="simple tabs example" indicatorColor="primary" textColor="primary">
                            <Tab label="Reviewed" {...a11yProps(0)} />
                            <Tab label="Original" {...a11yProps(1)} />
                        </Tabs>
                    </Paper>
                    
                    {
                        openEditTranscriptTools && 
                        <div style={{textAlign: "center"}}>
                            <hr/>
                            {
                                !isEditTranscriptMode && 
                                <>
                                    <Tooltip title="Edit">          
                                        <button style={{marginRight: "5px", marginBottom: "5px"}} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => {setIsEditTranscriptMode(true)}}> <Edit sx={{fontSize: "1rem"}}/> Edit</button>
                                    </Tooltip>
                                    <Tooltip title="Download">          
                                        <button style={{marginRight: "5px", marginBottom: "5px"}} type="button" className="btn btn-outline-secondary btn-sm" onClick={handleExport}> <FileDownload sx={{fontSize: "1rem"}}/> Download</button>
                                    </Tooltip>
                                </>
                            }
                            {
                                isEditTranscriptMode &&
                                <>           
                                    <Tooltip title="Discard">          
                                        <button style={{marginRight: "5px", marginBottom: "5px"}} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => {setIsEditTranscriptMode(false); fetchReviewFiles(); fetchTranscription()}}> <Cancel sx={{fontSize: "1rem"}}/> Discard changes</button>
                                    </Tooltip> 
                                    <Tooltip title="Save">          
                                        <button style={{marginRight: "5px", marginBottom: "5px"}} type="button" className="btn btn-outline-secondary btn-sm" onClick={handleSave}> <Save sx={{fontSize: "1rem"}}/> Save transcript</button>
                                    </Tooltip>
                                </>
                            }
                        </div>
                    }
                </Paper>
            }
        </div>
    )
}