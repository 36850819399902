import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logs: []
}

export const activityLogsSlice = createSlice({
    name: 'activityLogs',
    initialState,
    reducers: {
        addLog: (state, action) => {
            const newState = state.logs

            // Add log in the beginning of the array (opposite of .push function)
            newState.unshift(action.payload)

            state.logs = newState
        }
    }
})

export const { addLog } = activityLogsSlice.actions

export default activityLogsSlice.reducer